<template>
  <el-main class="mobile_pay">
    <img src="@/assets/img/icon_login_logo.svg" alt="logo" />

    <div class="mobile_success">
      <img src="@/assets/img/icon_study_pay_title.png" />
      <div class="content-ret" v-if="state.userState === -1">
        <p class="tips">&nbsp; &nbsp; </p>
      </div>
      <div class="content-ret" v-else-if="state.userState === 3">
        <img
            src="@/assets/img/qrcode_study_wx_work_contact.png"
            alt=""
            class="qr-code"
        />
        <p class="tips">长按添加企业微信获取</p>
        <p class="tips2">自习室或交流群加入方法</p>
      </div>
      <div class="content-ret" v-else>
        <img
            src="@/assets/img/qrcode_study_wx_official.png"
            alt=""
            class="qr-code"
        />
        <p class="tips">长按关注公众号</p>
        <p class="tips2">获取自习室及交流群加入方法</p>
      </div>
    </div>
  </el-main>
</template>

<script setup>
import { computed, reactive } from "@vue/reactivity";
import { nextTick, onMounted } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import studyApi from "../../apis/study_api";

const route = useRoute();
const router = useRouter();

const state = reactive({
  userState:-1
});

const onClickOfficial = () => {
  window.location.replace("https://mp.weixin.qq.com/mp/profile_ext?action=home&biz=Mzg3NDcyMzQ4Mw==#wechat_redirect");
}

const initData = async () => {
  if (/MicroMessenger/.test(window.navigator.userAgent)) {
    var study_result_has_redirected = sessionStorage.getItem(
        "study_result_has_redirected"
    );

    if (!route.query || !route.query.code) {
      if (study_result_has_redirected) {
        sessionStorage.removeItem("study_result_has_redirected");
        nextTick(() => {
          WeixinJSBridge.call("closeWindow");
        });
      } else {
        sessionStorage.setItem("study_result_has_redirected", true);
        const appUrl = process.env.VUE_APP_URL;
        let redirectUrl = encodeURIComponent(`${appUrl}/m/study_pay_result`);
        window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc520e71a0a38fde5&redirect_uri=" +
            redirectUrl +
            "?response_type=code&scope=snsapi_base&state=123&connect_redirect=1#wechat_redirect";
      }
    } else {
      // 已获取code参数
      try {
        const result = await studyApi.queryOfficialUserState(
            route.query.code
        );
        if (result.code === 0) {
          state.userState = result.data;
        } else {
          ElMessage.warning(result.msg);
        }
      } catch ({ msg: msg }) {
        ElMessage.warning(msg);
      }
    }
  } else {
    state.userState = 0
  }
};

onMounted(() => {
  document.title = "StrongHire自习室";
  initData()
});

</script>

<style lang="scss" scoped>
.mobile_pay {
  width: 100vw;
  height: 100vh;
  background-color: #30313a;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

  & > img {
    margin-top: calc(0.1 * 100vh);
    width: 200px;
    height: 39px;
  }
  & > p {
    margin-top: 5.3px;
    span {
      font-size: 12px;
      line-height: 17px;
      color: white;
      &:nth-child(2) {
        color: #e47470;
      }
    }
  }
  .mobile_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;

    .content-ret {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .title {
      font-size: 25px;
      line-height: 35px;
      background: linear-gradient(90deg, #fed8b1 0%, #d1a17b 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 500;
    }

    .qr-code {
      width: 200px;
      height: 200px;
      margin-top: 40px;
    }

    .official-guide {
      width: 315px;
      height: 96px;
      margin-top: 100px;
    }

    .tips {
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      margin-top: 10px;
    }
    .tips2 {
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
  }
  .pay_not_success {
    margin-top: 70px;
    font-size: 20px;
    line-height: 28px;
    color: white;
  }
}
</style>