<template>
  <div class="content">
    <div class="title">
      <h1>StrongHire会员服务协议</h1>
      <p
        class="download"
        @click="onDownLoadClick"
        :style="{ opacity: downloadVisible }"
      >
        下载
      </p>
    </div>
    <p>欢迎您开通StrongHire会员。</p>
    <p>
      StrongHire会员由StrongHire（网址：www.stronghire.cn)的所有者开发，旨在为StrongHire用户（以下更多的称之为“您”）提供付费的增值会员权益。
    </p>
    <p>
      请您务必审慎阅读、充分理解本协议各条款内容，特别是免除或限制责任的相应条款。
    </p>
    <p>
      您以任何形式获得并使用StrongHire会员资格，都视为您已阅读并同意接受本协议的约束。如果您对本协议的任何条款表示异议，您可以选择不开通本产品。
    </p>
    <p>
      由于互联网高速发展，您与子丑寅卯网络科技签署的本协议列明的条款并不能完整罗列并覆盖您与子丑寅卯网络科技之间的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，子丑寅卯网络科技法律声明、用户协议及隐私权政策等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用StrongHire或子丑寅卯网络科技的其他服务，视为您同意上述补充协议。
    </p>
    <h2>一、服务内容</h2>
    <p>
      1.StrongHire会员是StrongHire为用户打造的增值产品，包含多种专有的会员权益。
    </p>
    <p>
      2.您使用StrongHire时的设备或软件版本型号，可能影响您正常享受StrongHire会员权益。当因前述情况出现时，我们将建议您更换设备或提醒您及时升级，以免影响您正常享受全部会员权益。
    </p>
    <p>
      3.StrongHire的简历会员分为不同等级，不同会员等级之间的会员权益会存有差异，具体亦以上述页面的说明为准。部分会员权益详细注解如下：
    </p>
    <p>
      创建多份简历：会员用户可在StrongHire平台创建多份简历，具体份数与会员等级有关。
    </p>
    <p>
      AI简历诊断：即对会员用户在StrongHire平台创建的简历进行评分和检测，并基于StrongHire现有算法给出简历优化建议，算法精准度随软件版本的差异有所不同，检测可用次数与会员等级有关。
    </p>
    <p>
      AI简历画像及AI人岗匹配计算：即对会员用户在StrongHire平台创建的简历进行画像分析及目标岗位匹配度测算，检测可用次数与会员等级有关。
    </p>
    <p>
      会员简历模板：会员用户可查看和直接使用平台所有的简历风格模板和牛人简历模板，该简历模板由StrongHire官方创作或用户分享，仅供会员用户创建简历时学习和参考，未经允许，禁止任何个人和公司以直接或间接的商业目的进行传播和使用。
    </p>
    <p>
      一对一求职导师：会员用户在StrongHire平台可拥有一名专属求职顾问，可对会员提出的问题做出简单解答，若涉及简历修改建议、面试建议等服务可能需要您额外付费。
    </p>
    <p>
      为免疑义，除非上述页面有相反说明，会员权益不包括「免广告」或类似功能，也不意味着可免费访问StrongHire网站内全部内容。 StrongHire有权根据业务发展情况调整全部或部分会员权益。前述调整将在上述会员服务页面进行公告，您也可以随时向客服了解当下会员权益的具体情况。
    </p>
    <h2>二、开通和终止</h2>
    <p>
      1.您同意本协议并支付会员费用（包含微信支付、支付宝支付等现有支付方式或未来StrongHire指定的支付方式付费）或通过使用兑换码等其他方式（包含StrongHire官方活动赠送、合作机构活动赠送、通过积分兑换成为会员），即可获得StrongHire会员资格（以下简称“会员资格”）。
    </p>
    <p>
      2.会员价格可能会因为您使用的软件版本的不同而产生差异，开通服务时以显示的金额为准，因软件版本差异所造成的会员费差异不予追缴或补偿。
    </p>
    <p>
      3.StrongHire会员有效期：自会员资格开通之日起算，具体有效期限可以在会员个人页面查看。会员在会员有效期内续费或通过其他方式续展会员期限，有效期将相应延长。会员在会员资格到期后未以任何方式续展期限，StrongHire有权停止提供相应会员权益。
    </p>
    <p>
      4.您在开通StrongHire会员时，应仔细核对帐号信息、会员期限、会员费用等信息。由于StrongHire会员是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受会员权益，StrongHire不能退还相应会员费。
    </p>
    <p>
      5.会员未使用StrongHire会员权益的，不视为放弃相应权利。但会员如果违反StrongHire平台各项管理规定，或会员帐号有异常情况，StrongHire有权根据管理规定对相应会员帐号采取限制或冻结措施。此种情况下，会员的帐号功能及会员权益将受到限制。
    </p>
    <p>
      6.如因不可抗力导致StrongHire平台无法持续经营，StrongHire将退返您已经交纳但未享受的会员期限所对应的费用，终身会员如使用超过15个月，不予退还费用，少于15个月，以15个月为总的会员期限，对未使用部分的会员期限费用进行折算和退返。
    </p>
    <h2>三、会员费用及续费</h2>
    <p>
      1.StrongHire会员费以您购买StrongHire会员时，StrongHire在会员服务页面标示的价格为准。StrongHire将不定期推出优惠活动，并在会员服务页面公示活动信息及活动价格。若您已购买StrongHire会员，且与当前优惠活动价格不一致，StrongHire不承担您的损失。
    </p>
    <p>
      2.若您在购买StrongHire会员时开通了自动续费，则StrongHire会员到期将自动续费。如您不想续期，或不希望自动扣费，您有权在自动扣划下一期费用前关闭自动续费功能。
    </p>
    <h2>四、会员帐号管理</h2>
    <p>
      1.会员应合理使用会员权益、享受会员服务。StrongHire将通过页面公告或私信等方式向会员发布通知。会员应及时关注相关信息的更新。
    </p>
    <p>
      2.会员应遵守StrongHire的各项规则，包括但不限于本协议，以及《StrongHire用户协议》《StrongHire隐私政策》 等制度规范，以及StrongHire不时发布的与StrongHire用户和StrongHire会员相关的各项通知、公告（本协议内统称“管理规定”）。
    </p>
    <p>
      3.若您通过兑换码或赠送等途径获得会员资格。StrongHire有权根据管理规定将不当赠送或违规提供的兑换码作废，取消您的会员资格，我们也无需因此向您提供任何补偿。如果第三方向您赠送会员资格(例如与第三方的促销活动有关)，StrongHire不对该第三方提供的任何第三方产品或服务以及任何营销宣传或促销方案承担责任。
    </p>
    <p>
      4.因不能归责于StrongHire的原因导致的会员帐号密码泄露或会员权益受损，StrongHire不承担任何责任。会员发现帐号被盗，应及时修改密码或通过管理规定找回帐号。为避免帐号盗用引起的风险，StrongHire亦有权主动限制、冻结异常帐号，暂停或终止为异常帐号提供会员权益。
    </p>
    <p>
      5.如果会员因违反StrongHire管理规定而被限制帐号权限（包括禁言、帐号锁定等），其会员资格依旧有效，但所享有的相关会员权益将受到一定限制，例如与发布信息相关的会员权益将无法实现。
    </p>
    <p>
      6.会员不得以不正当手段获取会员资格，不得将会员帐号以出借、出租、出售等任何形式提供给第三方使用，不得以任何形式向第三方分享或售卖其所享有的会员权益，不得滥用会员权益，不得利用会员资格获取不正当利益。如会员存在不当行为，违反管理规定，或以任何形式损害StrongHire或其他StrongHire用户利益的，StrongHire有权暂停或取消该会员的会员资格，且不退还已收取的会员费。此外，违规会员需承担因此引起的一切责任和后果，包括但不限于StrongHire的财产损失。
    </p>
    <p>
      7.因可归责于会员自身的原因引发的纠纷均与StrongHire无关，会员应当自行承担相应责任。如果该等纠纷损害StrongHire的合法权益，StrongHire有权取消该会员的会员资格，且不退还已收取的会员费。同时，StrongHire有权以合法手段维护自身权益。
    </p>
    <p>8.您不得通过以下任何方式为自己和他人开通会员：</p>
    <p>
      1)通过机器软件、蜘蛛软件、爬虫软件等任何程序和软件等不正当方式为自己和他人开通会员；
    </p>
    <p>2）通过非StrongHire指定的方式为自己或他人开通会员；</p>
    <p>3）通过盗用StrongHire账户的方式为自己和他人开通会员</p>
    <p>4）通过违反法律、行政法规、国家政策等方面为自己和他人开通会员。</p>
    <p>
      若违反以上规定，StrongHire有权暂停或取消该会员的会员资格，且不退还已收取的会员费。此外，违规会员需承担因此引起的一切责任和后果。
    </p>
    <h2>五、协议修改</h2>
    <p>
      1.根据互联网的发展和有关法律、法规及规范性文件的变化，或者因StrongHire业务发展需要，StrongHire有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，StrongHire将根据实际情况选择以包括但不限于系统提示、公告等方式提示您。
    </p>
    <p>
      2.如果您不同意StrongHire对本协议相关条款所做的修改，您有权停止使用StrongHire会员，并由StrongHire退返您已经交纳但未享受的会员期限所对应的费用。如果您继续使用，则视为您接受StrongHire对本协议相关条款所做的修改。
    </p>
    <h2>六、适用法律及争议解决</h2>
    <p>
      1.本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。如本协议的任何约定因与中华人民共和国大陆地区的法律抵触而无效，则这些条款将按照尽可能接近本协议原条文的目的进行重新解释，本协议其它约定仍应具有完整的效力及效果。
    </p>
    <p>
      2.本协议的签署地点为中华人民共和国南京市雨花台区。若您与StrongHire发生争议的，双方应尽量友好协商解决。协商不成，双方应尽量友好协商解决。协商不成，双方均同意将争议提交至有管辖权的法院
    </p>
    <h2>七、其他</h2>
    <p>
      如对本协议内容有任何疑问、意见或建议，您可通过service@stronghire.cn与我们联系。
    </p>
    <p>
      如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向子丑寅卯网络科技住所地的法院提起诉讼来寻求解决方案。
    </p>
    <p>南京子丑寅卯网络科技有限公司</p>
    <p>江苏省南京市雨花台区西善桥街道善创文化园11层1200室</p>
  </div>
</template>

<script setup>
import htmlToPdf from "@/utils/htmlToPdf";
import { nextTick, ref } from "@vue/runtime-core";

const downloadVisible = ref(1);

const onDownLoadClick = () => {
  downloadVisible.value = 0;
  nextTick(async () => {
    htmlToPdf("content", "会员服务协议");
    setTimeout(() => {
      downloadVisible.value = 1;
    }, 2);
  });
};
</script>

<style lang="scss" scoped>
@import "src/style/agreement.scss";
</style>