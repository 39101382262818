<template>
  <el-main>
    <MobileTitleBar></MobileTitleBar>
    <div class="title">
      <p>请前往电脑端在线制作简历</p>
      <p>stronghire.cn</p>
    </div>
    <img
      src="@/assets/img/icon_add_friend_qr_code.png"
      alt="专属求职导师二维码"
      class="qr-code"
      v-if="showQRCode"
    />
    <p class="code_desc" v-if="showQRCode">截屏-打开微信扫一扫</p>
    <div class="bottom_desc" v-if="showQRCode">
      <p>添加您的专属求职导师</p>
      <p>即可获得<span>StrongHire 3日会员</span></p>
    </div>
  </el-main>
</template>

<script setup>
import { computed, onMounted } from "@vue/runtime-core";
import MobileTitleBar from "./MobileTitleBar.vue";
import analyze from "@/plugin/analyze.js";

onMounted(() => {
  analyze.sendClickEvent("mobile_guide_show");
});

const showQRCode = computed(() => {
  // 判断 userAgent 是百度小程序
  const ua = navigator.userAgent;
  const isBaiduMiniProgram = ua.indexOf("baiduboxapp") > -1 && ua.indexOf("webCore") === -1;
  return !isBaiduMiniProgram;
});
</script>

<style lang="scss" scoped>
.el-main {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#img_logo {
  width: 136px;
  height: 25px;
  margin-top: 20px;
  margin-left: 20px;
  align-self: flex-start;
}

.title {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(100vh * 0.185);
  p {
    &:nth-child(1) {
      font-family: PingFang SC;
      font-style: normal;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
    }
    &:nth-child(2) {
      margin-top: 10px;
      font-family: PingFang SC;
      font-size: 35px;
      line-height: 49px;
      color: #f56c6c;
    }
  }
}

.qr-code {
  width: 200px;
  height: 200px;
  margin-top: calc(100vh * 0.125);
}

.code_desc {
  font-size: 12px;
  line-height: 17px;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}

.bottom_desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  p {
    font-size: 14px;
    line-height: 20px;
    color: black;

    span {
      color: #f56c6c;
    }
  }
}
</style>