<template>
  <div
    class="preview-title"
    :style="{
      '--title-text-size': title_text_size,
      '--title-height': title_height, //14
      '--title-height-half': title_height_half, //14
      '--title-height1': title_height1, // 8
      '--title-height2': title_height2, // 20
      '--title-after-trigger-width': title_after_triger_width,
      '--title-after-trigger-width1': title_after_triger_width1,
    }"
  >
    <div v-if="style === 1" class="preview_title_style1">
      <strong>
        {{ getModuleTitle() }}
      </strong>
    </div>

    <div v-else-if="style === 2" class="preview_title_style2">
      <strong>
        {{ getModuleTitle() }}
      </strong>
    </div>

    <div v-else-if="style === 3" class="preview_title_style3">
      <div class="preview_bg3"></div>
      <strong>
        {{ getModuleTitle() }}
      </strong>
    </div>

    <div v-else-if="style === 4" class="preview_title_style4">
      <div>
        <img :src="getIcon" alt="" />
      </div>
      <strong>
        {{ getModuleTitle() }}
      </strong>
    </div>

    <div v-else-if="style === 5" class="preview_title_style5">
      <div class="preview_bg5"></div>
      <strong>
        {{ getModuleTitle() }}
      </strong>
      <div class="preview_before5"></div>
    </div>

    <div v-else-if="style === 6" class="preview_title_style6">
      <strong>
        {{ getModuleTitle() }}
      </strong>
    </div>

    <div v-else-if="style === 7">
      <strong>
        {{ getModuleTitle() }}
      </strong>
    </div>

    <div v-else-if="style === 8" class="preview_title_style8">
      <div class="preview-middle8"></div>
      <strong>
        {{ getModuleTitle() }}
      </strong>
    </div>

    <div v-else>
      <p>
        {{ getModuleTitle() }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "vuex";
import editMix from "@/plugin/edit";

const { getModuleTitleValue } = editMix();

const props = defineProps({
  moduleType: {
    type: String,
    required: true,
  },
  moduleId: {
    type: String,
    required: true,
  },
});

const getModuleTitle = () => {
  return getModuleTitleValue(props.moduleType, props.moduleId);
};

const Color = require("color");

const getIcon = computed(() => {
  const themeColor = Color(color.value).color;
  const tempValue =
    0.213 * themeColor[0] + 0.715 * themeColor[1] + 0.072 * themeColor[2];
  var isLight = tempValue < 255 / 2;
  if (color.value === "#6DB557" || color.value === "#EE8732") {
    isLight = true;
  }
  switch (props.moduleType) {
    case "edu_experience":
      return isLight
        ? require("@/assets/img/icon_title_edu_light.png")
        : require("@/assets/img/icon_title_edu_night.png");
    case "work_experience":
      return isLight
        ? require("@/assets/img/icon_title_work_light.png")
        : require("@/assets/img/icon_title_work_night.png");
    case "project_experience":
      return isLight
        ? require("@/assets/img/icon_title_project_light.png")
        : require("@/assets/img/icon_title_project_night.png");
    case "school_experience":
      return isLight
        ? require("@/assets/img/icon_title_school_light.png")
        : require("@/assets/img/icon_title_school_night.png");
    case "custom_experience":
      return isLight
        ? require("@/assets/img/icon_title_custom_light.png")
        : require("@/assets/img/icon_title_custom_night.png");
    case "personal_summary":
      return isLight
        ? require("@/assets/img/icon_title_person_light.png")
        : require("@/assets/img/icon_title_person_night.png");
    case "awards":
      return isLight
        ? require("@/assets/img/icon_title_horor_light.png")
        : require("@/assets/img/icon_title_horor_night.png");
    case "portfolios":
      return isLight
        ? require("@/assets/img/icon_title_collection_light.png")
        : require("@/assets/img/icon_title_collection_night.png");
    case "evaluation":
      return isLight
        ? require("@/assets/img/icon_title_evaluation_light.png")
        : require("@/assets/img/icon_title_evaluation_night.png");

    default:
      return null;
  }
});

const store = useStore();
const style = computed(() => {
  return store.state.edit.style;
});

const title_height = computed(
  () => `${store.state.edit.formatInfo.textSize + 14}px`
);
const title_height_half = computed(
  () => `${(store.state.edit.formatInfo.textSize + 14) / 2}px`
);
const title_text_size = computed(
  () => `${store.state.edit.formatInfo.textSize + 2}px`
);
const title_height1 = computed(
  () => `${store.state.edit.formatInfo.textSize + 8}px`
);
const title_height2 = computed(
  () => `${store.state.edit.formatInfo.textSize + 20}px`
);
const title_after_triger_width = computed(
  () => `${(store.state.edit.formatInfo.textSize + 14) / 2}px`
);
const title_after_triger_width1 = computed(
  () => `${-(store.state.edit.formatInfo.textSize + 19) / 2}px`
);
const color = computed(() => store.state.edit.formatInfo.themeColor);
</script>

<style lang="scss" scoped>
@import "src/style/edit.scss";

strong {
  font-size: var(--title-text-size);
  color: var(--theme-color);
  transition: var(--transition);
  font-weight: bold;
  font-family: var(--font-family);
  user-select: none;
  -webkit-font-smoothing: antialiased;
}

.preview_title_style1 {
  padding-top: 3px;
  padding-bottom: 3px;
  height: var(--title-height1);
  border-bottom: 1px solid var(--theme-color);
  transition: var(--transition);
}

.preview_title_style2 {
  height: var(--title-height);
  border-bottom: 1px solid var(--theme-color);
  transition: var(--transition);
  display: flex;
  align-items: flex-end;
  strong {
    padding-bottom: 5px;
    height: var(--title-height);
    line-height: var(--title-height);
    position: relative;
    transition: var(--transition);
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0px;
      height: 2px;
      width: 100%;
      background-color: var(--theme-color);
      transition: var(--transition);
    }
  }
}

.preview_title_style3 {
  height: var(--title-height);
  position: relative;
  overflow: hidden;
  display: flex;
  .preview_bg3 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-color: var(--theme-color);
    transition: var(--transition);
  }
  strong {
    height: 100%;
    line-height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--theme-color);
    color: white;
    transition: var(--transition);
  }
}

.preview_title_style4 {
  display: flex;
  align-items: center;
  height: var(--title-height2);
  border-bottom: 1px solid var(--theme-color);
  transition: var(--transition);
  & > div {
    width: var(--body-text-size);
    height: var(--body-text-size);
    border-radius: var(--body-text-size);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--theme-color);
    transition: var(--transition);
  }
  img {
    width: calc(var(--body-text-size) * 0.83);
    height: calc(var(--body-text-size) * 0.83);
  }
  strong {
    margin-left: 5px;
  }
}

.preview_title_style5 {
  position: relative;
  display: flex;
  height: var(--title-height);
  .preview_bg5 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #f0f0f0;
  }
  strong {
    position: absolute;
    left: 5px;
    height: 100%;
    line-height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--theme-color);
    color: white;
  }
  .preview_before5 {
    width: 2px;
    height: 100%;
    position: absolute;
    left: 0px;
    background-color: var(--theme-color);
    transition: var(--transition);
  }
}

.preview_title_style6 {
  position: relative;
  display: flex;
  height: var(--title-height);
  transition: var(--transition);
  strong {
    position: absolute;
    left: 0px;
    height: 100%;
    line-height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    background-color: var(--theme-color);
    font-size: var(--title-text-size);
    transition: var(--transition);
    &::after {
      content: "";
      position: absolute;
      right: calc(var(--title-after-trigger-width1) + 3px);
      width: 0;
      height: 0;
      transform: scaleX(1);
      border-top: var(--title-height) solid transparent;
      border-left: var(--title-after-trigger-width) solid var(--theme-color);
      transition: var(--transition);
    }
    &::before {
      content: "";
      position: absolute;
      right: 0px;
      background-color: var(--theme-color);
      opacity: 0.1;
      bottom: 0px;
      transform: skew(25deg, 0) translateX(9px);
      transform-origin: left top;
      width: 20px;
      height: calc(100% - 6px);
      transition: var(--transition);
    }
  }
}
.preview_title_style8 {
  position: relative;
  display: flex;
  height: var(--title-height);

  strong {
    position: absolute;
    left: 0px;
    height: 100%;
    line-height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-right: 20px;
    color: white;
    background: var(--theme-color);
    clip-path: polygon(
      0 0,
      calc(100% - var(--title-height-half)) 0,
      100% var(--title-height-half),
      100% calc(100% - var(--title-height-half)),
      calc(100% - var(--title-height-half)) 100%,
      0 100%,
      0 0
    );

    font-size: var(--title-text-size);
    transition: var(--transition);
  }
  .preview-middle8 {
    width: 100%;
    position: absolute;
    height: 1px;
    left: 0px;
    top: 50%;
    background-color: var(--theme-color);
    transform: translateY(-0.5px);
    transition: var(--transition);
  }
}
</style>