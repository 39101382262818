<template>
  <div class="preview-honor">
    <PreviewModuleHolder
      class="preview-honor-container moudle-left-right"
      :mainId="props.moduleId"
    >
      <div class="module-gap-div"></div>
      <PreviewTitle moduleType="awards" :moduleId="props.moduleId"></PreviewTitle>
      <p
        class="preview-empty"
        v-if="checkModuleEmpty(awards, 'awards')"
        @click="onEmptyClick"
      >
        展示高含金量奖项，如奖学金、比赛
      </p>
      <TransitionGroup tag="ul" name="fade" class="container" v-else>
        <PreviewModuleHolder
          class="preview-honor-item preview-content"
          v-for="(item, index) in awards"
          :class="{
            'preview-sub-module-margin-top': checkAddSubModuleMarginTop(
              index,
              awards,
              'awards'
            ),
          }"
          :key="item.id"
          @click="onItemClick(item)"
          :mainId="props.moduleId"
          :subId="item.id"
          :subIds="awards.map((item) => item.id)"
        >
          <p>{{ item.title }}</p>
          <p>{{ formatTime(item.time) }}</p>
        </PreviewModuleHolder>
      </TransitionGroup>

      <!-- <div class="module-gap-div"></div> -->
    </PreviewModuleHolder>
  </div>
</template>

<script setup>
import PreviewTitle from "@/components/preview/PreviewTitle.vue";
import PreviewModuleHolder from "@/components/preview/PreviewModuleHolder.vue";
import editMix from "@/plugin/edit";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { defineProps } from "vue";

const props = defineProps({
  moduleId: String,
});

const store = useStore();

const awards = computed(() => store.state.edit.contentModules.awards);

const onEmptyClick = () => {
  startModify("awards", null, props.moduleId, true);
};

const onItemClick = (item) => {
  startModify("awards", item, props.moduleId, false);
};
const { startModify, checkModuleEmpty, checkAddSubModuleMarginTop, formatTime } = editMix();
</script>

<style lang="scss" scoped>
@import "src/style/edit.scss";

.preview-honor {
  @include module-card;
  overflow: auto;
}

.preview-honor-item {
  @include sub-module-card;
  display: flex;
  justify-content: space-between;
  p {
    &:nth-child(1) {
      word-break: break-all;
    }
    &:nth-child(2) {
      flex-shrink: 0;
    }
  }
}
</style>