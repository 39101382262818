export default {
    refreshCurrenJsonId: function () {
        var webSiteData = {
            "@context": "https://ziyuan.baidu.com/contexts/cambrian.jsonld",
            "@id": window.location.href,
            "appid": "否",
            "pubDate": new Date().toISOString(),
            "upDate": new Date().toISOString(),
            "title": document.title,
            "description": document.title,
        }
        console.log("refreshCurrenJsonId webSiteData:", webSiteData);
        var script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(webSiteData);
        document.head.appendChild(script);
    }
}

// import seo from "@/plugin/seo.js";
// const { refreshCurrenJsonId } = seo();