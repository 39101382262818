<template>
  <div
    class="resume-container"
    :style="{
      '--resume-left-height': resume_left_height,
      '--resume-right-height': resume_right_height,
    }"
  >
    <div class="resume-left">
      <p>我的简历</p>
      <div class="resume-new-import">
        <div @click="onNewResumeClick">新建简历</div>
        <div @click="onImportResumeClick">导入简历</div>
      </div>
      <el-scrollbar
        class="resume-item-container"
        v-if="!state.hasNoData"
        max-height="600px"
      >
        <div class="resume_overdue_tips" v-show="overnewVisible">
          <img src="@/assets/img/icon_template_overdue.svg" alt="" />
          <div class="gap"></div>
          <p>
            您的StrongHire会员已到期，您可选择一份简历免费编辑，或
            <span @click="onRenewClick">立即续费会员></span>
          </p>
        </div>
        <div
          class="resume-item"
          v-for="item in state.data"
          :key="item.id"
          @click="onItemClick(item)"
          :class="{
            select: item.id === state.selectId,
          }"
        >
          <img
            src="@/assets/img/icon_resume_stub_lock.svg"
            alt=""
            v-if="item.locked"
          />
          <img src="@/assets/img/icon_resume_stub.svg" alt="" v-else />
          <div>
            <p class="name">{{ item.title }}</p>
            <div class="tag-container">
              <span
                :class="item.locked ? 'lock_score' : 'score'"
                v-if="item.score"
                >{{ item.score }}分</span
              >
              <span class="other" v-if="false">导师修改</span>
            </div>
            <p class="time">
              最后更新：{{ utils.getDateDiff(item.updateTime) }}
            </p>
            <div
              class="edit"
              @click="onItemEditClick(item)"
              v-if="!item.locked"
            >
              编辑
            </div>
            <div class="right-top" v-if="!item.locked">
              <img src="@/assets/img/icon_more.svg" alt="" class="more" />
              <div class="pop">
                <div @click.stop="onDeleteResumeClick(item.id)">
                  <img src="@/assets/img/icon_resume_delete.svg" alt="" />
                  <span>删除简历</span>
                </div>
                <div @click.stop="onCopyResumeClick(item.id)">
                  <img src="@/assets/img/icon_resume_copy.svg" alt="" />
                  <span>复制简历</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div v-else class="resume-no-data">
        <img src="@/assets/img/icon_resume_no_data.svg" alt="" class="more" />
        <p>暂无简历，快去创建一个吧</p>
      </div>
    </div>
    <div class="resume-left-gap"></div>
    <div class="resume-right">
      <div v-if="!state.hasNoData">
        <ResumePortrait></ResumePortrait>
        <ResumeAIPredict></ResumeAIPredict>
      </div>
      <ResumeRecommend v-else></ResumeRecommend>
    </div>
  </div>
</template>

<script setup>
import ResumePortrait from "@/components/resume/ResumePortrait";
import ResumeAIPredict from "@/components/resume/ResumeAIPredict";
import ResumeRecommend from "@/components/resume/ResumeRecommend";
import { useStore } from "vuex";
import { computed, reactive, ref } from "@vue/reactivity";
import { onMounted, onUnmounted } from "@vue/runtime-core";
import editApi from "@/apis/edit_api";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import utils from "@/utils/utils.js";
import useEventBus from "@/plugin/event";
import analyze from "@/plugin/analyze.js";
import vipMix from "@/plugin/vip";

const store = useStore();
const router = useRouter();
const eventBus = useEventBus();
const { checkVipWhenCreateResumeForLogin } = vipMix();

const onItemClick = (item) => {
  // 判断是否过期
  if (!item.locked) {
    eventBus.emit("mineResumeSelect", {
      id: item.id,
      name: item.title,
    });
    state.selectId = item.id;
    analyze.sendClickEvent("mine_card_item_click");
  } else {
    // 判断是否全部过期，如果全部过期，则提示用户
    if (allOverdue.value === true) {
      // 提示用户
      ElMessageBox({
        title: "是否激活该简历",
        message: "激活后您可免费编辑该简历，其余简历将不可编辑",
        showCancelButton: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      })
        .then(async () => {
          try {
            const result = await editApi.unlockResume(item.id);
            if (result && result.code === 0) {
              ElMessage.success("解锁成功");
              fetchAllData();
            } else {
              ElMessage.error(result.msg);
            }
          } catch ({ msg: msg }) {
            ElMessage.error(msg);
          }
        })
        .catch(() => {
          // 取消激活
        });
    } else {
      store.commit("updateVipDialog", true);
    }
  }
};

const onDeleteResumeClick = (id) => {
  analyze.sendClickEvent("mine_resume_delete_click");
  ElMessageBox({
    title: "是否删除简历",
    message: "删除简历后将无法找回，请谨慎操作",
    showCancelButton: true,
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(async () => {
    try {
      const result = await editApi.deleteResume(id);
      if (result && result.code === 0) {
        ElMessage.success("删除成功");
        eventBus.emit("mineResumeSelect", {
          id: "",
          name: "",
        });
        state.selectId = "";
        setTimeout(() => {
          fetchAllData();
        }, 500);
      } else {
        ElMessage.error("删除失败");
      }
    } catch ({ msg: msg }) {
      ElMessage.error(msg);
    }
  });
};

const onCopyResumeClick = async (id) => {
  analyze.sendClickEvent("mine_resume_copy_click");
  if (!checkVipWhenCreateResumeForLogin()) {
    return;
  }
  try {
    const result = await editApi.duplicateResume(id);
    if (result && result.code === 0) {
      ElMessage.success("复制成功");
      await fetchAllData();
      if (result.data) {
        router.push({ path: `/edit/${result.data}` });
        anaylzeEnterEdit("我的简历-复制简历");
      }
    } else {
      ElMessage.error("复制失败");
    }
  } catch ({ msg: msg }) {
    ElMessage.error(msg);
  }
};

const onItemEditClick = (item) => {
  router.push({ path: `/edit/${item.id}` });
  anaylzeEnterEdit("我的简历-列表点击");
  analyze.sendClickEvent("mine_resume_edit_click");
};

const anaylzeEnterEdit = (from) => {
  analyze.sendClickEvent("edit_enter_from", {
    value: from,
  });
};

const onNewResumeClick = () => {
  analyze.sendClickEvent("mine_create_resume_click");
  anaylzeEnterEdit("我的简历-创建简历");
  if (!checkVipWhenCreateResumeForLogin()) {
    return;
  }
  const contentModules = [];
  contentModules.push({
    id: "1",
    module: "basic_info",
    basicInfo: {},
  });
  contentModules.push({
    id: "2",
    module: "edu_experience",
    eduExperience: [],
  });
  contentModules.push({
    id: "3",
    module: "work_experience",
    workExperience: [],
  });
  contentModules.push({
    id: "4",
    module: "project_experience",
    projectExperience: [],
  });
  store.commit("setImportResumeData", {
    resumeData: { contentModules: contentModules, style: 1 },
  });
  router.push({ path: `/preview/` });
};

const onImportResumeClick = () => {
  anaylzeEnterEdit("我的简历-导入简历");
  if (!checkVipWhenCreateResumeForLogin()) {
    return;
  }
  store.commit("setUploadData", {
    uploadVisible: true,
    uploadType: "resume",
    uploadTitle: "导入已有简历",
    uploadSubTitle: "仅支持Word、PDF格式的简历，不超过5MB",
    uploadResumeId: "",
  });
};

const state = reactive({
  data: [],
  hasNoData: false,
  selectId: "",
});

const resume_left_height = computed(() => {
  return `${state.hasNoData ? 453 : 500}px`;
});

const resume_right_height = computed(() => {
  return `${state.hasNoData ? 493 : 730}px`;
});

const fetchAllData = async () => {
  try {
    store.commit("setCloudResumeCount", 0);
    const result = await editApi.getAllResumeData();
    if (result && result.code === 0) {
      state.data = result.data;
      if (state.data.length > 0) {
        store.commit("setCloudResumeCount", state.data.length);
        state.hasNoData = false;
      } else {
        state.hasNoData = true;
      }
    } else {
      state.hasNoData = true;
    }
  } catch ({ msg: msg }) {
    ElMessage.error(msg);
  }
};

const width = ref(document.documentElement.clientWidth);
const handleResize = (event) => {
  width.value = document.documentElement.clientWidth;
};
const actionLeft = computed(() => {
  return `${(width.value - 1200) / 2}px`;
});

onMounted(() => {
  window.addEventListener("resize", handleResize);
  eventBus.on("payVipSuccess", (data) => {
    fetchAllData();
  });
  fetchAllData();
  document.title = "StrongHire简历-大学生个人简历模板实习校招社招免费制作下载";
  document
    .querySelector("meta[name='Keywords']")
    .setAttribute(
      "content",
      "简历,个人简历,简历模板免费下载,求职简历,大学生简历模板"
    );
  document
    .querySelector("meta[name='Description']")
    .setAttribute(
      "content",
      "StrongHire简历是一个大厂必备的在线简历制作平台，适用于实习、校招、社招群体，平台包含大量免费简历模板及大厂优秀简历范文，是一款智能、便捷的在线简历制作工具，还包含自研AI简历评分、人岗匹配度测算、简历通过率预估，助你获得StrongHire Offer"
    );
});

onUnmounted(() => {
  eventBus.off("payVipSuccess");
  window.removeEventListener("resize", handleResize);
});

const allOverdue = computed(() => {
  // 是否所有简历全部过期
  return state.data.every((item) => {
    return item.locked;
  });
});

const overnewVisible = computed(() => {
  // 如果不是 vip，并且简历数量超过1个
  const isVip = store.getters.isVip;
  const cloudResumeCount = store.state.cloudResumeCount;
  console.log(isVip, cloudResumeCount);
  if (!isVip && cloudResumeCount > 1) {
    return true;
  } else {
    return false;
  }
});

const onRenewClick = () => {
  store.commit("updateVipDialog", true);
};
</script>

<style scoped lang="scss">
@import "src/style/base.scss";

.resume-container {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  min-height: calc(100vh - 56px - 30px - 176px);

  .resume-left-gap {
    width: 330px;
    min-width: 330px;
    height: var(--resume-left-height);
  }

  .resume-left {
    width: 330px;
    min-width: 330px;
    height: var(--resume-left-height);
    padding-top: 20px;
    padding-bottom: 20px;
    @include card-bg;
    position: fixed;
    top: 78px;
    left: v-bind(actionLeft);

    & > p {
      font-size: 16px;
      line-height: 22px;
      color: black;
      font-weight: 700;
      margin-left: 18px;
    }

    .resume-new-import {
      display: flex;
      margin-top: 20px;
      margin-left: 18px;
      margin-right: 18px;

      div {
        flex: 1;
        height: 40px;
        cursor: pointer;
        transition: all 250ms;
        border-radius: 4px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;

        &:nth-child(1) {
          background: #fef0f0;
          border: 1px solid #f56c6c;
          color: $primary-color;

          &:hover {
            background: rgba($color: #fef0f0, $alpha: 0.5);
          }
        }

        &:nth-child(2) {
          background: #ffffff;
          border: 1px solid #dcdfe6;
          margin-left: 10px;
          color: #606266;
          &:hover {
            background: rgba($color: #000000, $alpha: 0.05);
          }
        }
      }
    }
  }

  .resume-right {
    margin-left: 20px;
    width: 850px;
    min-width: 850px;
    height: var(--resume-right-height);
  }
}

.resume-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 380px;
  img {
    width: 115px;
    height: 142px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    color: rgba($color: #000000, $alpha: 0.7);
  }
}

.resume-item-container {
  height: 425px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume-item {
  width: 294px;
  height: 112px;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 5px;
  transition: all 250ms;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    box-shadow: 2px 4px 8px rgba(203, 203, 203, 0.2);
  }

  &.select {
    border: 1px solid #f56c6c;
  }

  &:hover .edit {
    visibility: visible;
    opacity: 1;
  }

  &:hover .right-top {
    visibility: visible;
    opacity: 1;
  }

  & > img {
    width: 56px;
    height: 72px;
    margin-left: 20px;
  }

  & > div {
    flex: 1;
    margin-left: 15px;
    height: 100%;
    position: relative;

    .name {
      margin-top: 15px;
      width: 185px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.7);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .time {
      position: absolute;
      right: 15px;
      bottom: 15px;
      font-size: 12px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.5);
    }

    .tag-container {
      display: flex;
      margin-top: 5px;
      span {
        height: 17px;
        line-height: 17px;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
        border-radius: 2px;

        &:nth-child(n + 2) {
          margin-left: 5px;
        }
      }

      .score {
        color: $primary-color;
        background: rgba(245, 108, 108, 0.15);
      }
      .lock_score {
        color: rgba(0, 0, 0, 0.2);
        background: #f4f5f5;
      }
      .other {
        background: #f0f9eb;
        color: #67c23a;
      }
    }

    .edit {
      width: 60px;
      height: 28px;
      position: absolute;
      left: 0px;
      bottom: 15px;
      background: #ffffff;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      font-size: 12px;
      line-height: 18px;
      color: #606266;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 500;
      visibility: hidden;
      opacity: 0;
      transition: all 250ms;

      &:hover {
        background: rgba($color: #000000, $alpha: 0.05);
      }
    }

    .right-top {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 15px;
      right: 10px;
      transition: all 250ms;
      visibility: hidden;
      opacity: 0;

      .more {
        width: 15px;
        height: 15px;
        right: 0px;
        top: 0px;
        position: absolute;
        cursor: pointer;
      }

      .pop {
        background: #ffffff;
        box-shadow: 0px 0px 5px rgba(203, 203, 203, 0.3);
        border-radius: 5px;
        width: 116px;
        height: 100px;
        position: absolute;
        top: 23px;
        right: 0px;
        transition: all 250ms;
        visibility: hidden;
        opacity: 0;
        display: flex;
        flex-direction: column;

        div {
          flex: 1;
          width: 100%;
          transition: all 250ms;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 15px;
            height: 15px;
          }

          span {
            font-size: 14px;
            line-height: 20px;
            color: black;
            margin-left: 9px;
          }

          &:nth-child(1) {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          &:nth-child(2) {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          &:hover {
            background: rgba($color: #000000, $alpha: 0.05);
          }
        }
      }

      &:hover .pop {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.resume_overdue_tips {
  width: 294px;
  height: 70px;
  display: flex;
  background: #f4f5f5;
  border-radius: 5px;
  align-items: center;

  img {
    margin-left: 15px;
    margin-top: 3px;
    width: 15px;
    height: 15px;
    align-self: flex-start;
    margin-top: 18px;
  }

  .gap {
    width: 10px;
    height: 1px;
  }

  & > p {
    margin-right: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
  }

  span {
    font-weight: normal;
    color: #f56c6c;
    cursor: pointer;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1.5px;
      background-color: #f56c6c;
      left: 0;
    }
  }
}
</style>