<template>
  <div class="blog_may_like" v-if="state.data.length > 0">
    <p>大家还喜欢看</p>
    <div class="blog_list">
      <a
        class="blog_item"
        v-for="item in state.data"
        :key="item.id"
        :href="`/job_strategy_detail/${item.id}`"
        target="_blank"
      >
        <p class="title">{{ item.title }}</p>
        <img src="@/assets/img/icon_blog_list_view_count.svg" alt="" />
        <p class="num">{{ item.readBaseNum + item.readNum }}</p>
      </a>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch, defineProps, computed } from "vue-demi";
import blogApi from "@/apis/blog_api";
import { ElMessage } from "element-plus/lib/components";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();

const state = reactive({
  data: [],
});

watch(
  () => route.params.id,
  (val, oldVal) => {
    console.log("blog-relative id change", val, oldVal);
    requestData();
  }
);

const blogRelativeTitle = computed(() => {
  if (route.name === "jobStrategyDetail") {
    return "相关文章";
  } else {
    return "求职攻略";
  }
});

const requestData = async () => {
  // 如果是求职攻略，会展示相关的文章
  if (route.name === "jobStrategyDetail") {
    try {
      const maxCount = 10;
      const result = await blogApi.getRecommendList(maxCount, "");
      if (result.code === 0 && result.data) {
        state.data = result.data;
      } else {
        ElMessage.error(result.msg);
      }
    } catch (error) {
      ElMessage.error(error.message);
      console.log(error);
    }
  }
};

onMounted(() => {
  console.log("blog relative mounted category: ");
  requestData();
});

</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
.blog_may_like {
  @include card-bg;
  box-sizing: border-box;
  padding: 20px;
  width: 850px;
  padding-bottom: 15px;
}

.blog_list {
  margin-top: 10px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.blog_item {
  display: flex;
  height: 30px;
  width: 385px;
  position: relative;
  cursor: pointer;
  align-items: center;

  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 314px;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7);
  }

  .num {
    font-size: 12px;
    line-height: 17px;
    margin-left: 2px;
    color: rgba(0, 0, 0, 0.7);
  }

  img {
    margin-left: 20px;
    width: 17px;
    height: 17px;
  }
}
</style>