<template>
  <div class="blog_side_bar">
    <div
      class="item parent new"
      :class="{ select: category === 'latest' }"
      @click="onTabClick('latest')"
    >
      <img
        src="@/assets/img/icon_blog_new_select.svg"
        alt=""
        v-if="category === 'latest'"
      />
      <img src="@/assets/img/icon_blog_new_normal.svg" alt="" v-else />
      <span>最新</span>
    </div>
    <div class="item parent recommend">
      <img src="@/assets/img/icon_blog_recommend_normal.svg" alt="" />
      <span>推荐</span>
    </div>
    <p
      class="item child"
      v-for="(item, index) in recommend_category_desc"
      :key="index"
      :class="{ select: category === recommend_category[index] }"
      @click="onTabClick(recommend_category[index])"
    >
      {{ item }}
    </p>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue-demi";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const route = useRoute();
const router = useRouter();
const store = useStore();

const recommend_category = [
  "xiaozhaoqiuzhi",
  "jianlimiji",
  "bijingmianjing",
  "hangyezixun",
  "zhichangrensheng",
];

const recommend_category_desc = [
  "校招求职",
  "简历秘籍",
  "笔经面经",
  "行业资讯",
  "职场人生",
];

const category = ref("latest");

const isRecommendSelect = computed(() => {
  return recommend_category.includes(category.value);
});

watch(
  () => route.params.category,
  (to, from) => {
    onCategoryChange(to);
  }
);

const onCategoryChange = (param) => {
  console.log("onCategoryChange", "param: ", param, "name:", route.name);
  if (route.name !== "jobStrategy") {
    return;
  }
  if (param) {
    category.value = param;
  } else {
    category.value = "latest";
  }
};

const onTabClick = (item) => {
  router.push("/job_strategy/" + item);
};

onMounted(() => {
  // 需要判断是否是推荐分类
  if (!recommend_category.includes(route.params.category)) {
    router.push("/job_strategy/latest");
  }
  onCategoryChange(route.params.category);
});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.blog_side_bar {
  @include card-bg;
  width: 160px;
  height: 324px;
  box-sizing: border-box;
  padding: 10px;
}

.item {
  width: 100%;
  height: 42px;
}

span,
p {
  font-size: 16px;
  line-height: 22px;
  font-family: "PingFang SC";
}

.parent {
  display: flex;
  align-items: center;
  transition: all 250ms;
  margin-top: 1px;
  border-radius: 5px;

  img {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }

  span {
    margin-left: 10px;
  }
}

.new {
  cursor: pointer;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
  &.select {
    background-color: #fef0f0;
    color: $primary-color;

    &:hover {
      background-color: #fadddd;
    }
  }
}

.child {
  display: flex;
  align-items: center;
  padding-left: 37px;
  box-sizing: border-box;
  transition: all 250ms;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 300;
  margin-top: 2px;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }

  &.select {
    background-color: #fef0f0;
    color: $primary-color;
    font-weight: normal;

    &:hover {
      background-color: #fadddd;
    }
  }
}
</style>