import { useStore } from "vuex";
import { computed } from 'vue'
import useEventBus from "@/plugin/event"
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import editApi from "@/apis/edit_api";
import utils from "@/utils/utils.js";
import { h } from "vue";

export default function () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const fetchMineCoundResumeCount = async () => {
        console.log("fetchMineCoundResumeCount");
        try {
            const result = await editApi.getAllResumeData();
            if (result.code === 0 && result?.data) {
                store.commit("setCloudResumeCount", result.data.length);
                return 0;
            } else {
                return 0;
            }
        } catch (error) {
            console.log(error)
            return 0;
        }
    }

    const checkVipWhenCreateResumeForLogin = () => {
        // 当创建新的简历时，检查已登录用户是否是vip用户
        const isVip = store.getters.isVip;
        const cloudResumeCount = store.state.cloudResumeCount;
        console.log("checkVipWhenCreateResumeForLogin", isVip, cloudResumeCount);
        if (!isVip && cloudResumeCount >= 1) {
            // 如果不是vip用户，并且云简历数量大于等于1
            onVipToast("已达简历份数上限，升级会员可创建更多份简历~");
            return false;
        }
        if (isVip && cloudResumeCount >= 16) {
            // 如果是vip用户，并且云简历数量大于等于16
            ElMessage.warning("最高可创建16份简历，已达上限~");
            return false;
        }
        return true;
    }

    const checkVipWhenCreateResume = async (tips = "使用新模板") => {
        // 当创建简历时，需要判断是否登录，已经用户 vip 的情况
        const hasLogin = store.getters.hasLogin;
        const localData = localStorage.getItem("edit_data");
        if (hasLogin) {
            // 已经登录，需判断是否是vip用户
            const checkVipResult = checkVipWhenCreateResumeForLogin();
            console.log("checkVipWhenCreateResume - 当前已经登录，需判断是否是vip用户", checkVipResult);
            return checkVipResult;
        } else {
            // 未登录，需判断是否有本地缓存
            if (localData) {
                // 如果本地有缓存，需要提示用户是否登录
                try {
                    const loginDialogResult = await ElMessageBox({
                        title: "已有一个简历缓存在本地",
                        message: h(
                            "p",
                            null,
                            [h("p", null, `如您继续${tips}，原简历将被覆盖。`)],
                            null,
                            [
                                h("span", null, "强烈建议您登录后再进行后续操作，"),
                                h(
                                    "span",
                                    { style: "color: #F56C6C" },
                                    "登录后简历将安全存储在云端并可随时修改"
                                ),
                            ]
                        ),
                        showCancelButton: true,
                        confirmButtonText: "立即登录",
                        cancelButtonText: tips,
                        distinguishCancelAndClose: true,
                    })
                    if (loginDialogResult === "confirm") {
                        // 登录，并且需要添加简历
                        store.commit("updateLoginDialog", {
                            showLoginDialog: true,
                            needAddResumeData: true,
                            from: "简历详情进入编辑前",
                        });
                    }
                    console.log("checkVipWhenCreateResume - 未登录&有缓存，弹窗提示是否登录，结果: ", loginDialogResult);
                    return false;
                } catch (error) {
                    console.log("checkVipWhenCreateResume - 未登录&有缓存，弹窗提示是否登录，结果: ", error);
                    if (error === "cancel") {
                        return true;
                    }
                }
            } else {
                // 如果本地没有缓存，直接走下面流程
                console.log("checkVipWhenCreateResume - 未登录&无缓存，继续后续流程！");
                return true;
            }
        }
    }

    const checkSyncResumeWhenLoginSuccess = async () => {
        // 当登录成功后，发现本地有缓存，需要同步简历
        const isVip = store.getters.isVip;
        const cloudResumeCount = store.state.cloudResumeCount;
        console.log("checkSyncResumeWhenLoginSuccess", isVip, cloudResumeCount);
        if (!isVip && cloudResumeCount >= 1) {
            // 如果不是vip用户，并且云简历数量大于等于1，需要弹窗提示开通 vip
            try {
                const openVipResult = await ElMessageBox({
                    title: "已有一个简历缓存在本地",
                    message: h("p", null, [
                        h(
                            "span",
                            null,
                            "您在云端已有一份简历，非StrongHire会员仅可创建一份简历，建议您开通StrongHire VIP，以便于将本地缓存简历同步至云端"
                        ),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "开通会员",
                    cancelButtonText: "舍弃本地缓存简历",
                    distinguishCancelAndClose: true,
                })
                if (openVipResult === "confirm") {
                    store.commit("updateVipDialog", true);
                }
                console.log("checkSyncResumeWhenLoginSuccess - 开通会员弹窗结果，结果: ", openVipResult);
            } catch (error) {
                if (error === "cancel") {
                    // 删除本地缓存简历
                    store.commit("deleteLocalData");
                }
                console.log("checkSyncResumeWhenLoginSuccess - 开通会员弹窗结果: ", error);
            }
            return false;
        } else if (isVip && cloudResumeCount >= 16) {
            // 如果是vip用户，并且云简历数量大于等于16，同步失败
            ElMessage.warning("同步失败，最高可创建16份简历，已达上限~");
            return false;
        } else {
            // 同步简历
            return true;
        }
    }

    const onVipToast = (msg) => {
        // vip 相关的 toast 处理，并弹出 vip 弹窗
        store.commit("updateVipDialog", true);
        setTimeout(() => {
            ElMessage.warning(msg);
        }, 300);
    }

    return {
        checkVipWhenCreateResumeForLogin,
        checkVipWhenCreateResume,
        checkSyncResumeWhenLoginSuccess,
        fetchMineCoundResumeCount,
        onVipToast,
    }
}