<template>
  <div class="operate-libs">
    <div
      v-for="item in state.libData"
      :key="item.id"
      class="operate-libs-item"
      :class="{ select: item.id === style }"
      @click="onStyleChange(item.id)"
    >
      <img :src="item.imgUrl" alt="" class="item" />
      <img
        src="@/assets/img/icon_operate_libs_vip.svg"
        alt=""
        class="tag"
        v-if="item.isVip"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import editMix from "@/plugin/edit";
import analyze from "@/plugin/analyze.js";

const store = useStore();
const { updateFormatInfo } = editMix();

const style = computed({
  get: () => store.state.edit.style,
  set: (val) => {
    store.state.edit.style = val;
  },
});
const onStyleChange = (val) => {
  store.commit("updateStyle", { style: val });
  onDataChange();
  analyze.sendClickEvent("edit_change_module_click", {
    value: val,
  });
};

const onDataChange = () => {
  updateFormatInfo();
};

const state = reactive({
  libData: [
    {
      id: 1,
      // imgUrl: require("@/assets/img/icon_lib_tempate1.png"),
      imgUrl: 'https://cdn.stronghire.cn/preview_resume_pic/9J4ZIXq.png?x-oss-process=image/resize,h_1000,m_lfit',
      isVip: false,
    },
    {
      id: 2,
      // imgUrl: require("@/assets/img/icon_lib_tempate2.png"),
      imgUrl: 'https://cdn.stronghire.cn/preview_resume_pic/FwETVY3.png?x-oss-process=image/resize,h_1000,m_lfit',
      isVip: true,
    },
    {
      id: 3,
      // imgUrl: require("@/assets/img/icon_lib_tempate3.png"),
      imgUrl: 'https://cdn.stronghire.cn/preview_resume_pic/dRBQGOY.png?x-oss-process=image/resize,h_1000,m_lfit',
      isVip: true,
    },
    {
      id: 4,
      // imgUrl: require("@/assets/img/icon_lib_tempate4.png"),
      imgUrl: 'https://cdn.stronghire.cn/preview_resume_pic/wGQZxT1.png?x-oss-process=image/resize,h_1000,m_lfit',
      isVip: true,
    },
    {
      id: 5,
      // imgUrl: require("@/assets/img/icon_lib_tempate5.png"),
      imgUrl: 'https://cdn.stronghire.cn/preview_resume_pic/lPzcIXk.png?x-oss-process=image/resize,h_1000,m_lfit',
      isVip: true,
    },
    {
      id: 6,
      // imgUrl: require("@/assets/img/icon_lib_tempate6.png"),
      imgUrl: 'https://cdn.stronghire.cn/preview_resume_pic/3iUB4ku.png?x-oss-process=image/resize,h_1000,m_lfit',
      isVip: true,
    },
    {
      id: 7,
      // imgUrl: require("@/assets/img/icon_lib_tempate7.png"),
      imgUrl: 'https://cdn.stronghire.cn/preview_resume_pic/16yWpyr.png?x-oss-process=image/resize,h_1000,m_lfit',
      isVip: true,
    },
    {
      id: 8,
      // imgUrl: require("@/assets/img/icon_lib_tempate8.png"),
      imgUrl: 'https://cdn.stronghire.cn/preview_resume_pic/4FKa5sv.png?x-oss-process=image/resize,h_1000,m_lfit',
      isVip: true,
    },
  ],
});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
.operate-libs {
  width: $operate-width;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding-bottom: 18px;
  .operate-libs-item {
    border-radius: 3px;
    overflow: hidden;
    width: 127px;
    height: 188px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 5px rgba(203, 203, 203, 0.4));
    position: relative;
    border: none;
    padding: 1px;
    &:nth-child(2n + 1) {
      margin-right: 10px;
    }
    &:nth-child(n + 3) {
      margin-top: 10px;
    }
    &:hover {
      filter: drop-shadow(0px 0px 5px rgba(203, 203, 203, 1));
      border: 1px solid #f56c6c;
      padding: 0px;
    }
    .item {
      border-radius: 3px;
      overflow: hidden;
      width: 127px;
      height: 188px;
      object-fit: cover;
    }
    .tag {
      width: 30px;
      height: 15px;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    &.select {
      padding: 0px;
      border: 1px solid #f56c6c;
    }
  }
}
</style>