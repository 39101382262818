<template>
  <div id="error">
    <div class="error_img">
      <img src="@/assets/img/icon_error.svg" alt="" />
    </div>
    <p>
      <span>推荐您以下简历模板</span>
    </p>
    <TemplateList :keyword="''"></TemplateList>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import TemplateList from "../components/template/TemplateList.vue";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

#error {
  @include content-layout;

  .error_img {
    margin-top: 80px;
    margin-bottom: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 211px;
      height: 176px;
      margin: auto;
    }
  }

  span {
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold;
  }
}
</style>