import { createGetApi, createPostApi, SERVER_URL } from './network'

const USER_URL = SERVER_URL + '/api/auth';

export default {
    getWechatQRCodes: function () {
        return createGetApi(`${USER_URL}/getWechatQRCodes`)
    },
    loginByQRScene: function (scene) {
        return createPostApi(`${USER_URL}/loginByQRScene?scene=${scene}`)
    },
}