<template>
  <div class="chart">
    <Charts :options="options" />
  </div>
</template>

<script setup>
import Charts from "@/components/Charts.vue";
import { onMounted, ref, defineProps, watch, toRefs, computed } from "vue";

const props = defineProps({
  data: {
    type: Object,
    default: function () {
      return {
        companyScore: 0,
        declareSkillScore: 0,
        degreeScore: 0,
        expScore: 0,
        inferSkillScore: 0,
        schoolScore: 0,
        titleScore: 0,
      };
    }, //dark为深色模式
    required: false,
  },
});

const { data } = toRefs(props);

const options = computed(() => {
  return {
    color: ["#F56C6C"],
    radar: {
      shape: "circle",
      indicator: [
        { name: "过往公司匹配度", max: 5 },
        { name: "学校匹配度", max: 5 },
        { name: "学历匹配度", max: 5 },
        { name: "工作经验匹配度", max: 5 },
        { name: "职能匹配度", max: 5 },
        { name: "技能匹配度", max: 5 },
      ],
      axisName: {
        color: "rgba(0, 0, 0, 0.7)",
      },
      axisLine: {
        lineStyle: {
          color: "#E3E7ED",
        },
      },
    },
    series: [
      {
        type: "radar",
        lineStyle: {
          width: 1,
        },
        symbol: "none",
        data: [
          {
            value: [
              props.data.companyScore,
              props.data.schoolScore,
              props.data.degreeScore,
              props.data.expScore,
              props.data.titleScore,
              (props.data.declareSkillScore + props.data.inferSkillScore) / 2,
            ],
            areaStyle: {
              color: "rgba(245, 108, 108, 0.1)",
            },
          },
        ],
      },
    ],
  };
});

onMounted(() => {
  console.log("data", data.value);
});
</script>

<style lang="scss" scoped>
.chart {
  width: 420px;
  height: 220px;
}
</style>