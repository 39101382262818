<template>
  <div class="vip_card_container">
    <div class="vip_cards">
      <div
        class="vip_card_item_bg"
        v-for="(cardItem, index) in data"
        :key="index"
        :class="{ showDetail: state.showDetail }"
      >
        <div class="vip_card_item">
          <div class="vip_recommend" v-if="cardItem.forever">推荐</div>
          <p
            class="vip-title"
            :style="{ color: cardItem.forever ? '#Dd9D77' : 'white' }"
          >
            {{ cardItem.title }}
          </p>
          <div class="vip-money">
            <span :style="{ color: cardItem.forever ? '#Dd9D77' : 'white' }"
              >￥</span
            >
            <span :style="{ color: cardItem.forever ? '#Dd9D77' : 'white' }">{{
              cardItem.money
            }}</span>
          </div>
          <div class="vip_open" @click="onOpenClick(index)">
            开通{{ cardItem.title }}
          </div>
          <div class="list-item-container">
            <div
              class="list-item"
              v-for="(item, index) in cardItem.itemData"
              :key="index"
            >
              <span :class="{ itemBottom: item.desc === '' }">{{
                item.title
              }}</span>
              <span v-if="item.desc !== '1'">{{ item.desc }}</span>
              <img src="@/assets/img/icon_vip_gou.svg" alt="" v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
    <p :class="{ showDetail: state.showDetail }" @click="dialogVisible = true">
      兑换StrongHire会员
    </p>
    <div class="vip_detail_container" :class="{ showDetail: state.showDetail }">
      <div class="vip_detail">
        <img
          class="close"
          src="@/assets/img/icon_vip_detail_close.svg"
          @click="onDetailClose"
        />
        <div class="pay-name">
          <img src="@/assets/img/icon_avatar.svg" />
          <span>{{ userData?.name }}</span>
        </div>
        <div class="pay_select">
          <div
            class="item"
            v-for="(item, index) in data"
            :key="index"
            @click="onPayItemClick(index)"
            :class="{ select: state.paySelectIndex === index }"
          >
            <span>{{ item.title }}</span>
            <div class="money">
              <span>￥</span>
              <span>{{ item.money }}</span>
            </div>
            <div class="vip_recommend" v-if="item.forever">推荐</div>
          </div>
        </div>
        <VipBottom
          ref="vipBottomRef"
          :vipType="vipType"
          :style="{ marginTop: '60px' }"
        ></VipBottom>
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      title="兑换StrongHire会员"
      width="420px"
      top="35vh"
      :destroy-on-close="true"
      @open="onDialogOpen"
    >
      <el-input
        v-model="codeInput"
        placeholder="请输入劵码"
        size="large"
        clearable
        autofocus
        class="name-input"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click="onConfirmClick"
            :disabled="codeInput === ''"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { nextTick } from "@vue/runtime-core";
import { useStore } from "vuex";
import VipBottom from "@/components/buy_vip/VipBottom";
import { ElMessage } from "element-plus";
import homeApi from "@/apis/home_api";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();
const userData = computed(() => store.state.userData);

const data = [
  {
    itemData: [
      {
        title: "在线简历份数",
        desc: "16份",
      },
      {
        title: "AI简历诊断",
        desc: "无限次",
      },
      {
        title: "AI简历画像",
        desc: "无限次",
      },
      {
        title: "AI人岗匹配计算",
        desc: "2次/日",
      },
      {
        title: "AI简历通过率预估",
        desc: "无限次",
      },
      {
        title: "会员简历模板",
        desc: "1",
      },
      {
        title: "一对一求职导师",
        desc: "1",
      },
      {
        title: "求职辅导20元优惠券",
        desc: "",
      },
    ],
    title: "月会员",
    money: 19,
    forever: false,
  },
  {
    itemData: [
      {
        title: "在线简历份数",
        desc: "16份",
      },
      {
        title: "AI简历诊断",
        desc: "无限次",
      },
      {
        title: "AI简历画像",
        desc: "无限次",
      },
      {
        title: "AI人岗匹配计算",
        desc: "2次/日",
      },
      {
        title: "AI简历通过率预估",
        desc: "无限次",
      },
      {
        title: "会员简历模板",
        desc: "1",
      },
      {
        title: "一对一求职导师",
        desc: "1",
      },
      {
        title: "求职辅导20元优惠券",
        desc: "",
      },
    ],
    title: "季会员",
    money: 45,
    forever: false,
  },
  {
    itemData: [
      {
        title: "在线简历份数",
        desc: "16份",
      },
      {
        title: "AI简历诊断",
        desc: "无限次",
      },
      {
        title: "AI简历画像",
        desc: "无限次",
      },
      {
        title: "AI人岗匹配计算",
        desc: "2次/日",
      },
      {
        title: "AI简历通过率预估",
        desc: "无限次",
      },
      {
        title: "会员简历模板",
        desc: "1",
      },
      {
        title: "一对一求职导师",
        desc: "1",
      },
      {
        title: "求职辅导20元优惠券",
        desc: "",
      },
    ],
    title: "年会员",
    money: 79,
    forever: false,
  },
  {
    itemData: [
      {
        title: "在线简历份数",
        desc: "16份",
      },
      {
        title: "AI简历诊断",
        desc: "无限次",
      },
      {
        title: "AI简历画像",
        desc: "无限次",
      },
      {
        title: "AI人岗匹配计算",
        desc: "无线次",
      },
      {
        title: "AI简历通过率预估",
        desc: "无限次",
      },
      {
        title: "会员简历模板",
        desc: "1",
      },
      {
        title: "一对一求职导师",
        desc: "1",
      },
      {
        title: "求职辅导20元优惠券",
        desc: "1",
      },
    ],
    title: "终身会员",
    money: 99,
    forever: true,
  },
];

const vipBottomRef = ref(null);

const onOpenClick = (index) => {
  state.showDetail = true;
  state.paySelectIndex = index;
  vipBottomRef.value.showRefreshButton();
};

const onPayItemClick = (index) => {
  state.paySelectIndex = index;
};

const onDetailClose = () => {
  state.showDetail = false;
};

const state = reactive({
  showDetail: false,
  paySelectIndex: 0,
});

const vipType = computed(() => {
  return state.paySelectIndex + 1;
});

const dialogVisible = ref(false);
const codeInput = ref("");

const onConfirmClick = async () => {
  console.log(codeInput.value);
  try {
    const result = await homeApi.exchangeVipRedeemCode(codeInput.value);
    if (result.code === 0) {
      ElMessage.success("兑换成功");
      setTimeout(() => {
        router.push("/account/vip_welcome")
      }, 500);
    } else if (result.code === 1300) {
      ElMessage.error("兑换码不存在");
    } else if (result.code === 1301) {
      ElMessage.error("兑换码已被使用");
    } else {
      ElMessage.error("兑换失败");
    }
    dialogVisible.value = false;
  } catch ({ msg: msg }) {
    ElMessage.error(msg);
    dialogVisible.value = false;
  }
};

const onDialogOpen = () => {
  console.log("onDialogOpen");
  codeInput.value = "";
  nextTick(() => {
    document
      .getElementsByClassName("name-input")[0]
      .getElementsByClassName("el-input__inner")[0]
      .focus();
  });
};
</script>

<style lang="scss" scoped>
.vip_cards {
  margin-top: 50px;
  display: flex;

  div,
  span,
  p {
    font-family: "PingFang SC";
  }

  .vip_card_item_bg {
    width: 200px;
    height: 408px;
    display: flex;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-image: linear-gradient(
      45deg,
      #f9e0c4 20%,
      #fdf5ed 30%,
      #f9e0c4 80%,
      #847868 95%
    );
    opacity: 1;
    visibility: visible;
    transition: all 400ms;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;

    .vip_card_item {
      width: 196px;
      height: 404px;
      position: relative;
      overflow: hidden;
      border-radius: 9px;
      background-image: linear-gradient(105.87deg, #838488, #32333a);
      display: flex;
      flex-direction: column;
      align-items: center;

      .vip_recommend {
        position: absolute;
        width: 58px;
        height: 17px;
        background: #d49d77;
        transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
        left: -14px;
        top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: white;
      }

      .vip-title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: white;
        margin-top: 20px;
      }

      .vip-money {
        display: flex;
        justify-content: center;
        align-items: baseline;
        span {
          color: white;
          &:nth-child(1) {
            font-weight: 700;
            font-size: 15px;
            line-height: 21px;
          }
          &:nth-child(2) {
            font-weight: 700;
            font-size: 30px;
            line-height: 42px;
            margin-right: 15px;
          }
        }
      }

      .vip_open {
        width: 120px;
        height: 30px;
        flex-shrink: 0;
        cursor: pointer;
        margin-top: 10px;
        background-color: white;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #d49d77;

        transition: all 250ms;

        &:hover {
          background-color: rgb(218, 218, 218);
        }
      }

      .list-item-container {
        margin-top: 5px;
        display: flex;
        width: 100%;
        flex-direction: column;

        .list-item {
          padding-left: 15px;
          padding-right: 15px;
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            &:nth-child(1) {
              color: white;
            }
            &:nth-child(2) {
              color: #d1a17b;
            }
          }
        }
        .itemBottom {
          line-height: 20px;
          color: rgba(255, 255, 255, 0.5) !important;
        }
      }
    }

    &:hover {
      transform: scale(1.05) translateY(-10px);
      box-shadow: 0 5px 18px rgba(255, 255, 255, 0.3);
    }

    &.showDetail {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.vip_card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & > p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    color: #ffffff;
    line-height: 22px;
    font-feature-settings: "tnum" on, "lnum" on;
    transition: all 500ms;
    position: relative;
    &.showDetail {
      opacity: 0;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: white;
      bottom: 0px;
      left: 0px;
    }
  }

  .vip_detail_container {
    width: 704px;
    height: 412px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-image: linear-gradient(
      45deg,
      #95887e 20%,
      #979494 40%,
      #585145 80%
    );
    opacity: 0;
    visibility: hidden;
    top: 50px;
    transition: all 500ms;
    .vip_detail {
      width: 640px;
      height: 388px;
      border-radius: 8px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      background-image: linear-gradient(105.87deg, #838488, #32333a);
      position: relative;

      .close {
        cursor: pointer;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 23px;
        top: 23px;
      }

      .pay-name {
        display: flex;
        align-items: center;
        & > img {
          width: 40px;
          height: 40px;
          border-radius: 40px;
        }
        & > span {
          margin-left: 12px;
          font-size: 18px;
          line-height: 25px;
          color: white;
        }
      }

      .pay_select {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        .item {
          width: 145px;
          height: 90px;
          background: #ffffff;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all 250ms;
          box-sizing: border-box;

          span {
            transition: all 250ms;
          }

          & > span {
            font-size: 18px;
            line-height: 25px;
            color: black;
            font-weight: 500;
          }
          & > div {
            span {
              color: #d49d77;
              &:nth-child(1) {
                font-size: 15px;
                line-height: 21px;
              }
              &:nth-child(2) {
                font-size: 30px;
                line-height: 42px;
                font-weight: 700;
              }
            }
          }

          .vip_recommend {
            position: absolute;
            width: 58px;
            height: 17px;
            transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
            background: linear-gradient(
              269.08deg,
              #d1a17b 18.18%,
              #fed8b1 85.36%
            );
            left: -14px;
            top: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            color: white;
          }

          &:hover {
            transform: scale(1.05);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
          }
          &.select {
            background: #d49d77;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            span {
              color: white;
            }
          }
        }
      }
    }
    &.showDetail {
      opacity: 1;
      visibility: visible;
    }
  }
}

::v-deep(.el-dialog__body) {
  padding-left: 20px;
  padding-right: 20px;
}

::v-deep(.el-dialog) {
  border-radius: 5px;
}
</style>