<template>
  <div class="preview-evaluation">
    <PreviewModuleHolder
      class="preview-evaluation-container moudle-left-right"
      :mainId="props.moduleId"
      :showAdd="false"
    >
      <div class="module-gap-div"></div>
      <PreviewTitle moduleType="evaluation" :moduleId="props.moduleId"></PreviewTitle>
      <p
        class="preview-empty"
        v-if="desc === null || desc === '' || desc === undefined"
        @click="onEmptyClick"
      >
        给出合理的自我评价有助于面试官了解你
      </p>
      <div class="evaluation-content preview-content" v-else @click="onItemClick">
        <div v-html="desc" class="html-desc"></div>
      </div>
      <!-- <div class="module-gap-div"></div> -->
    </PreviewModuleHolder>
  </div>
</template>

<script setup>
import PreviewTitle from "@/components/preview/PreviewTitle.vue";
import PreviewModuleHolder from "@/components/preview/PreviewModuleHolder.vue";
import editMix from "@/plugin/edit";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { defineProps } from "vue";

const props = defineProps({
  moduleId: String,
});
const store = useStore();

const evaluation = computed(
  () => store.state.edit.contentModules.evaluation
);

const desc = computed(() => {
  return evaluation.value.desc;
});

const checkValueNotEmpty = (v) => {
  return v.value !== "" && v.value !== null && v.value !== undefined;
};


const onEmptyClick = () => {
  startModify("evaluation", null, props.moduleId, true);
};

const onItemClick = () => {
  startModify("evaluation", evaluation.value, null, false);
};

const { startModify } = editMix();
</script>

<style lang="scss" scoped>
@import "src/style/edit.scss";

.preview-evaluation {
  @include module-card;
  overflow: auto;
}

.evaluation-content {
  @include sub-module-card;
}

.preview-evaluation-item {
  display: flex;
  strong {
    flex-shrink: 0;
    font-weight: bold;
  }
  p {
    margin-left: 10px;
    word-break: break-all;
  }
}
</style>