<template>
  <div id="template">
    <div class="side-bar-swiper">
      <SideBar></SideBar>
      <Swiper></Swiper>
    </div>
    <TemplateList></TemplateList>
    <BlogHomeMayLike/>
  </div>
</template>

<script setup>
import { onMounted } from "vue-demi";
import SideBar from "../components/template/SideBar.vue";
import Swiper from "../components/template/Swiper.vue";
import TemplateList from "../components/template/TemplateList.vue";
import BlogHomeMayLike from "../components/blog/BlogHomeMayLike.vue";

onMounted(() => {
  document.title = "StrongHire简历-大学生个人简历模板实习校招社招免费制作下载";
  document
    .querySelector("meta[name='Keywords']")
    .setAttribute(
      "content",
      "简历,个人简历,简历模板免费下载,求职简历,大学生简历模板"
    );
  document
    .querySelector("meta[name='Description']")
    .setAttribute(
      "content",
      "StrongHire简历是一个大厂必备的在线简历制作平台，适用于实习、校招、社招群体，平台包含大量免费简历模板及大厂优秀简历范文，是一款智能、便捷的在线简历制作工具，还包含自研AI简历评分、人岗匹配度测算、简历通过率预估，助你获得StrongHire Offer"
    );
});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

#template {
  @include content-layout;
}

.side-bar-swiper {
  display: flex;
  height: 223px;
  overflow: hidden;

  #side-bar {
    width: 330px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(203, 203, 203, 0.1);
  }

  #swiper {
    flex: 1;
  }
}
</style>