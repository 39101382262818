<template>
  <div>
    <el-dialog
      v-model="showVipDialog"
      :width="700"
      top="15vh"
      :destroy-on-close="true"
      :show-close="false"
      @close="onDialogClose"
      custom-class="vip_dialog"
      @open="onVipDialogOpen"
    >
      <div class="vip-container-root">
        <img
          class="close"
          src="@/assets/img/icon_vip_detail_close.svg"
          @click="onCloseClick"
        />
        <div class="vip-success-container" v-if="state.showSuccessContainer">
          <p class="title">欢迎您，</p>
          <p class="title">尊贵的StrongHire会员</p>
          <img
            src="@/assets/img/icon_add_friend_qr_code.png"
            alt=""
            class="qr-code"
          />
          <p class="tips">扫码添加您的一对一求职导师</p>
          <p class="add_success" @click="onAddSuccessClick">添加完成</p>
        </div>
        <div class="vip-container" v-else>
          <div class="line"></div>
          <p class="duihuan" @click="dialogVisible = true">
            兑换StrongHire会员
          </p>
          <div class="pay-name">
            <img src="@/assets/img/icon_avatar.svg" />
            <span>{{ userData?.name }}</span>
          </div>
          <div class="pay_select">
            <div
              class="item"
              v-for="(item, index) in data"
              :key="index"
              @click="onPayItemClick(index)"
              :class="{ select: state.paySelectIndex === index }"
            >
              <span>{{ item.title }}</span>
              <div class="money">
                <span>￥</span>
                <span>{{ item.money }}</span>
              </div>
              <div class="vip_recommend" v-if="item.forever">推荐</div>
            </div>
          </div>
          <div class="pay_middle">
            <div
              v-for="(item, parentIndex) in middleData"
              :key="parentIndex"
              class="pay_middle_child"
            >
              <p class="title">{{ item.title }}</p>
              <div class="list_container">
                <div v-for="(list, index) in item.list" :key="index">
                  <p
                    :style="{ color: getTextColor(parentIndex) }"
                    v-if="list !== '1'"
                  >
                    {{ list }}
                  </p>
                  <img src="@/assets/img/icon_vip_gou.svg" alt="" v-else />
                </div>
              </div>
            </div>
          </div>
          <VipBottom
            ref="vipBottomRef"
            :vipType="vipType"
            :style="{ marginTop: '35px' }"
            @onPaySuccess="onPaySuccess"
            :isDialog="true"
          ></VipBottom>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      v-model="dialogVisible"
      title="兑换StrongHire会员"
      width="420px"
      top="35vh"
      :destroy-on-close="true"
      @open="onDialogOpen"
      custom-class="duihuan_dialog"
    >
      <el-input
        v-model="codeInput"
        placeholder="请输入劵码"
        size="large"
        clearable
        autofocus
        class="name-input"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click="onConfirmClick"
            :disabled="codeInput === ''"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import authApi from "@/apis/auth_api";
import { onActivated, onMounted } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import analyze from "@/plugin/analyze.js";
import VipBottom from "@/components/buy_vip/VipBottom";
import homeApi from "@/apis/home_api";
import useEventBus from "@/plugin/event";
import editMix from "@/plugin/edit";
import editApi from "@/apis/edit_api";

const store = useStore();
const eventBus = useEventBus();
const userData = computed(() => store.state.userData);
const { sysncLocalData } = editMix();

const state = reactive({
  paySelectIndex: 0,
  showSuccessContainer: false,
});

const vipType = computed(() => {
  return state.paySelectIndex + 1;
});

const onPayItemClick = (index) => {
  state.paySelectIndex = index;
};

const data = [
  {
    title: "月会员",
    money: 19,
    forever: false,
  },
  {
    title: "季会员",
    money: 45,
    forever: false,
  },
  {
    title: "年会员",
    money: 79,
    forever: false,
  },
  {
    title: "终身会员",
    money: 99,
    forever: true,
  },
];

const middleData = [
  {
    title: "会员权益",
    list: [
      "在线简历份数",
      "AI简历诊断",
      "AI简历画像",
      "AI人岗匹配计算",
      "AI简历通过率预估",
      "会员简历模板",
      "一对一求职导师",
      "求职辅导20元优惠券",
    ],
  },
  {
    title: "普通用户",
    list: ["1份", "1次/天", "1次/简历", "1次/简历", "1次/简历"],
  },
  {
    title: "月/季/年会员",
    list: ["16份", "无限次", "无限次", "无限次", "无限次", "1", "1"],
  },
  {
    title: "终身会员",
    list: ["16份", "无限次", "无限次", "无限次", "无限次", "1", "1", "1"],
  },
];

const showVipDialog = computed({
  get: () => store.state.showVipDialog,
  set: (val) => {
    store.commit("updateVipDialog", val);
  },
});

const getTextColor = (index) => {
  if (index === 0) {
    return "white";
  } else if (index === 1) {
    return "rgba(255, 255, 255, 0.5)";
  } else {
    return "#D1A17B";
  }
};

const vipBottomRef = ref(null);

const onDialogOpen = () => {
  console.log("onDialogOpen");
  codeInput.value = "";
};

const onVipDialogOpen = () => {
  setTimeout(() => {
    vipBottomRef.value.showRefreshButton();
  }, 200);
};

const onDialogClose = () => {
  console.log("onDialogClose");
};

const onCloseClick = () => {
  showVipDialog.value = false;
};

const dialogVisible = ref(false);
const codeInput = ref("");

const onPaySuccess = () => {
  console.log("onPaySuccess");
  onPayVipSuccess();
};

const onConfirmClick = async () => {
  console.log(codeInput.value);
  try {
    const result = await homeApi.exchangeVipRedeemCode(codeInput.value);
    if (result.code === 0) {
      ElMessage.success("兑换成功");
      onPayVipSuccess();
    } else if (result.code === 1300) {
      ElMessage.error("兑换码不存在");
    } else if (result.code === 1301) {
      ElMessage.error("兑换码已被使用");
    } else {
      ElMessage.error("兑换失败");
    }
    dialogVisible.value = false;
  } catch ({ msg: msg }) {
    ElMessage.error(msg);
    dialogVisible.value = false;
  }
};

const onPayVipSuccess = async () => {
  // 开通会员成功!
  state.showSuccessContainer = true;
  eventBus.emit("payVipSuccess", {});
  // 检测本地是否有简历缓存需要上传，针对在首页登录后弹登录提示框的情况
  const localData = localStorage.getItem("edit_data");
  if (localData) {
    sysncLocalData(false);
  }
  // 请求首页接口，用于刷新本地的 vip 信息
  try {
    const result = await editApi.getRecommendKeywords();
    console.log("onPayVipSuccess - 刷新本地 vip 信息", result.code);
  } catch (e) {
    console.log(e);
  }
};

const onAddSuccessClick = () => {
  showVipDialog.value = false;
};
</script>

<style lang="scss" scoped>
::v-deep(.vip_dialog) {
  height: 650px;
  border-radius: 10px;
  background: #30313a;
}

::v-deep(.vip_dialog .el-dialog__header) {
  display: none;
  padding: 0px;
}

::v-deep(.vip_dialog .el-dialog__body) {
  padding: 0px;
  margin: 0px;
}

::v-deep(.duihuan_dialog) {
  border-radius: 5px;
}

::v-deep(.duihuan_dialog .el-dialog__body) {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0px;
}

.vip-container-root {
  height: 650px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;

  .close {
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 23px;
    top: 23px;
    z-index: 10;
  }
}

.vip-success-container {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

  .title {
    font-size: 30px;
    line-height: 42px;
    background: linear-gradient(90deg, #fed8b1 0%, #d1a17b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 500;

    &:nth-child(1) {
      margin-top: 60px;
    }
  }

  .qr-code {
    width: 248px;
    height: 248px;
    margin-top: 50px;
  }

  .tips {
    font-size: 19px;
    line-height: 27px;
    color: #ffffff;
    margin-top: 20px;
    border-color: white;
  }

  .add_success {
    width: 150px;
    height: 40px;
    margin-top: 50px;
    background: #d1a17b;
    border-radius: 222222px;
    color: white;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms;
    cursor: pointer;
    &:hover {
      background: rgba($color: #d1a17b, $alpha: 0.8);
    }
  }
}

.vip-container {
  position: relative;
  top: 0;

  .line {
    background-color: rgba(255, 255, 255, 0.5);
    width: 640px;
    height: 1px;
    position: absolute;
    top: 199px;
  }

  .duihuan {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    right: 48px;
    top: 499px;
    cursor: pointer;
    color: #ffffff;
    line-height: 22px;
    font-feature-settings: "tnum" on, "lnum" on;
    position: absolute;
    z-index: 10;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: white;
      bottom: 0px;
      left: 0px;
    }
  }

  .pay-name {
    display: flex;
    align-items: center;
    & > img {
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
    & > span {
      margin-left: 12px;
      font-size: 18px;
      line-height: 25px;
      color: white;
    }
  }

  .pay_select {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    .item {
      width: 145px;
      height: 90px;
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 250ms;
      box-sizing: border-box;

      span {
        transition: all 250ms;
      }

      & > span {
        font-size: 18px;
        line-height: 25px;
        color: black;
        font-weight: 500;
      }
      & > div {
        span {
          color: #d49d77;
          &:nth-child(1) {
            font-size: 15px;
            line-height: 21px;
          }
          &:nth-child(2) {
            font-size: 30px;
            line-height: 42px;
            font-weight: 700;
          }
        }
      }

      .vip_recommend {
        position: absolute;
        width: 58px;
        height: 17px;
        transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
        background: linear-gradient(269.08deg, #d1a17b 18.18%, #fed8b1 85.36%);
        left: -14px;
        top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: white;
      }

      &:hover {
        transform: scale(1.05);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
      }
      &.select {
        background: linear-gradient(111.41deg, #ffd9b2 1.24%, #d2a27c 57.75%);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        span {
          color: white;
        }
      }
    }
  }

  .pay-bottom {
    margin-top: 60px;
    display: flex;
    & > img {
      width: 120px;
      height: 120px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
      .money {
        display: flex;
        align-items: baseline;
        & > span {
          color: white;
          &:nth-child(1) {
            font-size: 25px;
          }
          &:nth-child(2) {
            font-size: 40px;
            font-weight: 600;
          }
        }
      }
      & > p {
        font-size: 16px;
        line-height: 22px;
        color: white;
        margin-top: 20px;
      }
    }
  }

  .pay_middle {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .pay_middle_child {
      width: 145px;
      .title {
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        font-weight: bold;
      }
      .list_container {
        margin-top: 15px;

        & > div {
          height: 30px;
          display: flex;
          align-items: center;

          p {
            font-size: 12px;
            color: white;
          }
        }
      }
    }
  }
}
</style>