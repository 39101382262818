<template>
  <div>
    <el-dialog
      v-model="showAddWechatDialog"
      width="350px"
      top="25vh"
      :destroy-on-close="true"
      :show-close="true"
      @open="onDialogOpen"
      @close="onDialogClose"
    >
      <div class="modal-add-friend">
        <p>{{showAddWechatDialogDesc[0]}}</p>
        <p>{{showAddWechatDialogDesc[1]}}</p>
        <img
          src="@/assets/img/icon_add_friend_qr_code.png"
          alt=""
          class="qr-code"
        />
        <img
          src="@/assets/img/icon_add_friend_close.svg"
          alt=""
          class="delete"
          @click="onCloseClick"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, reactive } from "@vue/reactivity";
import { useStore } from "vuex";

const store = useStore();

const showAddWechatDialog = computed({
  get: () => store.state.showAddWechatDialog,
  set: (val) => {
    store.commit("updateAddWechatDialog", {
      show: val,
    });
  },
});

const showAddWechatDialogDesc = computed(() => {
  if (store.state.showAddWechatDialogDescType === 0) {
    return ["微信扫码添加好友即可", "获得StrongHire终身会员"];
  } else {
    return ["微信扫码添加", "您的一对一求职导师"];
  }
});

const onDialogOpen = () => {
  console.log("onDialogOpen");
};

const onDialogClose = () => {
  console.log("onDialogClose");
};

const onCloseClick = () => {
  showAddWechatDialog.value = false;
};
</script>

<style lang="scss" scoped>
::v-deep(.el-dialog) {
  height: 376px;
  border-radius: 10px;
}

::v-deep(.el-dialog__header) {
  display: none;
  padding: 0px;
}

::v-deep(.el-dialog__body) {
  padding: 0px;
  margin: 0px;
}

.modal-add-friend {
  width: 350px;
  height: 376px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & > p {
    font-weight: 500;
    font-size: 21px;
    line-height: 29px;
    color: #000000;

    &:nth-child(1) {
      margin-top: 50px;
    }
  }

  .qr-code {
    width: 200px;
    height: 200px;
    margin-top: 18px;
  }

  .delete {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>