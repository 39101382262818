import template from '../views/template'
import counseling from '../views/counseling'
import resume from '../views/resume'
import JobStrategy from '../views/JobStrategy'
import BlogDetail from '../views/BlogDetail'
import calendar from '../views/calendar'
import search from '../views/search'
import home from '../views/home'
import edit from '../views/ResumeEdit'
import buyVip from '../views/BuyVip'
import vipWelcome from '../views/VipWelcome'
import account from '../views/Account'
import agreement from '../views/Agreement'
import privacy from '../views/Privacy'
import vipService from '../views/VipService'
import licenseCenter from '../views/LicenseCenter'
import resumePrint from '../views/ResumePrint'
import resumeDetail from '../views/ResumeDetail'
import error from '../views/Error'

import exclusiveTutor from '../views/mobile/ExclusiveTutor'
import mobileHome from '../views/mobile/MobileHome.vue'
import mobile from '../views/mobile/Mobile.vue'
import mobileDetail from '../views/mobile/MobileDetail.vue'
import mobileGuide from '../views/mobile/MobileGuide.vue'
import mobilePay from '../views/mobile/MobilePay.vue'
import mobilePayStudy from '../views/mobile/MobilePayStudy.vue'
import mobilePayStudy66 from '../views/mobile/MobilePayStudy66.vue'
import mobilePayStudyResult from '../views/mobile/MobilePayStudyResult.vue'
import mobileStudyJoinArticle from '../views/mobile/MobileStudyJoinArticle.vue'
import mobileStudyJoinArticleRecall from '../views/mobile/MobileStudyJoinArticleRecall.vue'
import mobileStudyPunchIntroduce from '../views/mobile/MobileStudyPunchIntroduce.vue'

export default [
    {
        path: '/',
        component: home,
        children: [
            {
                path: '/',
                component: template,
                name: 'template',
            },
            {
                path: '/counseling',
                name: 'counseling',
                component: counseling,
            },
            {
                path: '/resume',
                name: 'resume',
                component: resume,

            },
            {
                path: '/job_strategy/:category?',
                name: 'jobStrategy',
                component: JobStrategy,
            },
            {
                path: '/job_strategy_detail/:id?',
                name: 'jobStrategyDetail',
                component: BlogDetail,
            },
            {
                path: '/calendar',
                name: 'calendar',
                component: calendar,

            },
            {
                path: '/search/:keyword?',
                name: 'search',
                component: search,
            },
            {
                path: '/error',
                name: 'error',
                component: error,
            },
            {
                path: '/agreement',
                name: 'agreement',
                component: agreement,
            },
            {
                path: '/privacy',
                name: 'privacy',
                component: privacy,
            },
            {
                path: '/vip_service',
                name: 'vipService',
                component: vipService,
            },
            {
                path: '/license_center',
                name: 'licenseCenter',
                component: licenseCenter,
            },
            {
                path: '/detail/:id?',
                name: 'detail',
                component: resumeDetail,
            },
        ]
    },
    {
        path: '/preview/:id?',
        name: 'preview',
        component: edit,
    },
    {
        path: '/edit/:id?',
        name: 'edit',
        component: edit,
    },
    {
        path: '/resumePrint/:id?',
        name: 'resumePrint',
        component: resumePrint,
    },
    {
        path: '/account',
        name: 'account',
        component: account,
        redirect: '/account/buy_vip',
        children: [
            {
                path: '/account/buy_vip',
                component: buyVip,
                name: 'buyVip',
            },
            {
                path: '/account/vip_welcome',
                component: vipWelcome,
                name: 'vipWelcome',
            },
        ]
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/error',
    },
    {
        path: '/m',
        component: mobile,
        children: [
            {
                path: '/m/exclusive_tutor',
                name: 'exclusiveTutor',
                component: exclusiveTutor,
            },
            {
                path: '/m/home',
                name: 'mobileHome',
                component: mobileHome,
            },
            {
                path: '/m/detail/:id?',
                name: 'mobileDetail',
                component: mobileDetail,
            },
            {
                path: '/m/guide',
                name: 'mobileGuide',
                component: mobileGuide,
            },
            {
                path: '/m/vip_pay',
                name: 'mobilePay',
                component: mobilePay,
            },
            {
                path: '/m/study_pay',
                name: 'mobilePayStudy',
                component: mobilePayStudy,
            },
            {
                path: '/m/study_pay66',
                name: 'mobilePayStudy66',
                component: mobilePayStudy66,
            },
            {
                path: '/m/study_pay_result',
                name: 'mobilePayStudyResult',
                component: mobilePayStudyResult,
            },
            {
                path: '/m/study_join',
                name: 'mobileStudyJoinArticle',
                component: mobileStudyJoinArticle,
            },
            {
                path: '/m/study_join/:source?',
                name: 'mobileStudyJoinArticle',
                component: mobileStudyJoinArticle,
            },
            {
                path: '/m/study_join_recall',
                name: 'mobileStudyJoinArticleRecall',
                component: mobileStudyJoinArticleRecall,
            },
            {
                path: '/m/punch_introduce',
                name: 'mobileStudyPunchIntroduce',
                component: mobileStudyPunchIntroduce,
            },
        ]
    },


]
