<template>
  <el-main class="exclusive_tutor">
    <img src="@/assets/img/icon_login_logo.svg" alt="logo" />
    <p>
      <span>让</span>
      <span>每一个候选人</span>
      <span>都能获得心怡的Offer</span>
    </p>
    <div>
      <p class="title">长按二维码添加</p>
      <p class="title">您的专属求职导师</p>
      <img
        src="@/assets/img/icon_add_friend_qr_code_resume_default.png"
        alt="专属求职导师二维码"
        class="qr-code"
      />
      <p class="desc">职业规划·简历指导·建议反馈·使用帮助</p>
    </div>
    <img
      src="@/assets/img/icon_exclusive_tulor_back.svg"
      alt="返回"
      class="back"
      @click="back"
      v-if="false"
    />
  </el-main>
</template>

<script setup>
import { onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const back = () => {
  router.back();
};
onMounted(() => {
  document.title = "添加专属求职导师";
});
</script>

<style lang="scss" scoped>
.exclusive_tutor {
  width: 100vw;
  height: 100vh;
  background-color: #30313a;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

  & > img {
    margin-top: calc(0.1 * 100vh);
    width: 200px;
    height: 39px;
  }
  & > p {
    margin-top: 5.3px;
    span {
      font-size: 12px;
      line-height: 17px;
      color: white;
      &:nth-child(2) {
        color: #e47470;
      }
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .title {
      font-size: 20px;
      line-height: 28px;
      color: white;
    }
    & > img {
      margin-top: 10px;
      width: 200px;
      height: 200px;
    }
    .desc {
      margin-top: 10px;
      font-size: 12px;
      line-height: 17px;
      color: white;
    }
  }

  .back {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 53px;
    margin-top: 0px;
    left: 23px;
  }
}
</style>
