<template>
  <div class="preview-job">
    <PreviewModuleHolder
      class="preview-job-container moudle-left-right"
      :mainId="props.moduleId"
    >
      <div class="module-gap-div"></div>
      <PreviewTitle moduleType="work_experience" :moduleId="props.moduleId"></PreviewTitle>
      <p
        class="preview-empty"
        v-if="checkModuleEmpty(workExperience, 'work_experience')"
        @click="onEmptyClick"
      >
        介绍过往工作或实习内容
      </p>
      <TransitionGroup tag="ul" name="fade" class="container" v-else>
        <PreviewModuleHolder
          class="preview-job-item preview-content"
          v-for="(item, index) in workExperience"
          :class="{
            'preview-sub-module-margin-top': checkAddSubModuleMarginTop(
              index,
              workExperience,
              'work_experience'
            ),
          }"
          :key="item.id"
          @click="onItemClick(item)"
          :mainId="props.moduleId"
          :subId="item.id"
          :subIds="workExperience.map((item) => item.id)"
        >
          <div class="line-1">
            <div>
              <strong class="preview-school">{{ item.company }}</strong>
              <div v-if="title_style === 1">
                <p v-for="(item, index) in row(item)" :key="index">
                  {{ item }}
                </p>
              </div>
            </div>
            <p>
              {{ getTime(item) }}
            </p>
          </div>
          <div class="line-2" v-if="title_style !== 1">
            <div>
              <p v-for="(item, index) in row(item)" :key="index">{{ item }}</p>
            </div>
            <p>{{ item.city }}</p>
          </div>
          <div v-html="item.desc" class="html-desc"></div>
        </PreviewModuleHolder>
      </TransitionGroup>

      <!-- <div class="module-gap-div"></div> -->
    </PreviewModuleHolder>
  </div>
</template>

<script setup>
import PreviewTitle from "@/components/preview/PreviewTitle.vue";
import PreviewModuleHolder from "@/components/preview/PreviewModuleHolder.vue";
import editMix from "@/plugin/edit";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { defineProps } from "vue";

const props = defineProps({
  moduleId: String,
});

const store = useStore();

const workExperience = computed(
  () => store.state.edit.contentModules.workExperience
);
const title_style = computed(() => store.state.edit.formatInfo.titleStyle);

const row = (item) => {
  // 专业 学历 学院
  const rowArray = Array.of(item.position, item.department);
  return rowArray.filter((v) => v !== "");
};

const onEmptyClick = () => {
  startModify("work_experience", null, props.moduleId, true);
};

const onItemClick = (item) => {
  startModify("work_experience", item, props.moduleId, false);
};

const { startModify, getTime, checkModuleEmpty, checkAddSubModuleMarginTop } =
  editMix();
</script>

<style lang="scss" scoped>
@import "src/style/edit.scss";

.preview-job {
  @include module-card;
}

.preview-job-item {
  @include sub-module-card;
  display: flex;
  flex-direction: column;

  .line-2 {
    display: flex;
    & > div {
      display: flex;
      flex: 1;
      p {
        margin-left: 10px;
        &:nth-child(1) {
          margin-left: 0px;
        }
      }
    }
  }

  .line-1 {
    display: flex;
    & > div {
      display: flex;
      flex: 1;
      & > div {
        display: flex;
        & > p {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>