<template>
  <div
    class="resume_detail"
    :style="{ visibility: state.loadSuccess ? 'visible' : 'hidden' }"
  >
    <div>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item><a href="/">简历模板</a></el-breadcrumb-item>
        <el-breadcrumb-item>{{ state.data.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="template_img">
      <ResumeDetailSeo v-if="resumeDetailSeoVisible" :data="state.data" />
      <el-image
        :src="utils.getImageUrl(state.data.previewPicUrl)"
        :alt="state.data.title"
        @load="onImageLoad"
      />
    </div>
    <div class="resume_detail_right_container">
      <div class="resume_detail_right">
        <h1>{{ state.data.title }}</h1>
        <h2>{{ state.data.desc }}</h2>
        <div class="time-chose">
          <div class="action-time">
            <span>更新于{{ state.data.updateTime }}</span>
          </div>
          <div class="action-chose">
            <span>{{ state.data.totalUseNum }}人已选择</span>
          </div>
        </div>
        <div
          class="label_tag"
          v-if="state.data.labels && state.data.labels.length > 0"
        >
          <el-tag v-for="(item, index) in state.data.labels" :key="index">{{
            item
          }}</el-tag>
        </div>
        <div class="resume_button">
          <button class="template_try" @click="checkLocalData(false)">
            <img
              src="@/assets/img/icon_template_vip.svg"
              alt=""
              v-if="state.data.isVip"
            />
            <span>{{
              state.data.isVip ? "使用会员专享模板" : "免费使用此模板"
            }}</span>
            <div class="mask"></div>
          </button>

          <button class="templatge_import" @click="checkLocalData(true)">
            <span>导入已有简历</span>
            <div class="mask"></div>
          </button>
        </div>
      </div>
      <BlogRelative :style="{width: '386px', marginTop: '20px'}"/>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, readonly, ref } from "@vue/reactivity";
import { onMounted, onUnmounted } from "@vue/runtime-core";
import { ElMessage, ElMessageBox } from "element-plus";
import { useStore } from "vuex";
import editApi from "@/apis/edit_api";
import { useRoute, useRouter } from "vue-router";
import utils from "@/utils/utils.js";
import { h } from "vue";
import analyze from "@/plugin/analyze.js";
import vipMix from "@/plugin/vip";
import ResumeDetailSeo from "@/components/detail/ResumeDetailSeo.vue";
import BlogRelative from "@/components/blog/BlogRelative";
import seo from "@/plugin/seo.js";

const store = useStore();
const route = useRoute();
const router = useRouter();
const { checkVipWhenCreateResume } = vipMix();

const width = ref(document.documentElement.clientWidth);
const state = reactive({
  loadSuccess: false,
  data: {
    previewPicUrl: "",
    title: "",
    desc: "",
    updateTime: "",
    totalUseNum: 0,
    isVip: false,
    labels: [],
    resumeData: {},
  },
});

const actionRight = computed(() => {
  return `${(width.value - 1200) / 2}px`;
});

const handleResize = (event) => {
  width.value = document.documentElement.clientWidth;
};

const hasLogin = computed(() => {
  return store.getters.hasLogin;
});

const requestData = async () => {
  try {
    const result = await editApi.getDetail(route.params.id);
    console.log("request data", result.data);
    if (result?.code === 0) {
      state.data = {
        previewPicUrl: result.data.resumeData.previewPicUrl,
        title: result.data.resumeData.title,
        desc: result.data.desc,
        updateTime: utils.formatDateTime(result.data.resumeData.updateTime),
        totalUseNum: result.data.totalUseNum,
        isVip: result.data.vip,
        labels: result.data.labels,
        resumeData: result.data.resumeData,
      };
      state.loadSuccess = true;
      document.title = `${result.data.resumeData.title}-StrongHire简历，大厂必备免费简历模板校招社招实习在线制作平台`;
      if (result.data.labels && result.data.labels.length > 0) {
        document
          .querySelector("meta[name='Keywords']")
          .setAttribute("content", result.data.labels.join(","));
      }
      document
        .querySelector("meta[name='Description']")
        .setAttribute("content", result.data.desc);
      seo.refreshCurrenJsonId();
    } else {
      ElMessage.error(result?.msg ?? "获取简历详情失败");
    }
  } catch (e) {
    ElMessage.error(e);
    // router.replace({ path: "/" });
  }
};

const checkVip = () => {
  // 模板如果是 vip，但是用户不是 vip 那么则提示
  const isTemplateVip = state.data.isVip;
  const isUserVip = store.getters.isVip;
  if (isTemplateVip && !isUserVip) {
    ElMessage.error("该模板仅限VIP会员使用~");
    return false;
  }
  return true;
};

const checkLocalData = async (isUpload) => {
  if (!checkVip()) {
    console.log("当前模板是 vip，但是用户不是 vip");
    return;
  }

  if (await checkVipWhenCreateResume()) {
    if (isUpload) {
      enterUpload();
    } else {
      loadTemplate();
    }
  }
};

const enterUpload = () => {
  store.commit("setUploadData", {
    uploadVisible: true,
    uploadType: "resume",
    uploadTitle: "导入已有简历",
    uploadSubTitle: "仅支持Word、PDF格式的简历，不超过5MB",
    uploadResumeId: route.params.id,
  });
  analyze.sendClickEvent("home_template_click", {
    template_id: route.params.id,
    value: "导入已有简历",
  });
};

const loadTemplate = () => {
  router.push({ path: `/preview/${route.params.id}` });
  analyze.sendClickEvent("home_template_click", {
    template_id: route.params.id,
    value: "直接使用模板",
  });
  analyze.sendClickEvent("edit_enter_from", {
    value: "模板详情",
  });
};

const resumeDetailSeoVisible = ref(false);
const onImageLoad = () => {
  console.log("图片加载完成");
  setTimeout(() => {
    resumeDetailSeoVisible.value = true;
  }, 200);
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
  if (route.params.id) {
    requestData();
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.resume_detail {
  @include content-layout;
}

.template_img {
  width: 794px;
  height: 1123px;
  margin-top: 19px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  .el-image {
    width: 100%;
    height: 100%;
  }
  .resume_detail_seo {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.resume_detail_right_container {
  position: fixed;
  width: 386px;
  box-sizing: border-box;
  top: 108px;
  right: v-bind(actionRight);
  display: flex;
  flex-direction: column;
}

.resume_detail_right {
  width: 100%;
  box-sizing: border-box;
  padding: 18px;
  background-color: white;
  border-radius: 5px;
  h1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin: 0;
  }
  h2 {
    margin-top: 10px;
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
    margin: 0px;
    margin-top: 10px;
    font-weight: normal;
  }

  .time-chose {
    margin-top: 10px;
    display: flex;
    div {
      display: flex;
      align-items: center;
    }
    span {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
    }
    img {
      width: 12px;
      height: 12px;
    }
    .action-chose {
      margin-left: 6px;
    }
  }

  button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    position: relative;

    .mask {
      border-radius: 4px;
      background-color: transparent;
      transition: all 300ms;
      width: inherit;
      height: inherit;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    &:hover .mask {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .resume_button {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }

  .template_try {
    width: 200px;
    background: $primary-color;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: white;
      margin-left: 4px;
    }
  }
  .templatge_import {
    width: 136px;
    border: 0.5px solid #dcdfe6;
    background-color: white;
    margin-left: 10px;
    span {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.label_tag {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  & > span {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
</style>