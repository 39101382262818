<template>
  <Transition name="fade" mode="out-in">
    <div class="edit-modify-experience-libs" v-show="showCard">
      <div class="main-title">我的经历库</div>
      <div
        v-for="item in state.data"
        :key="item.id"
        class="inner-card"
        @click.self="onCardClick(item)"
      >
        <img
          src="@/assets/img/icon_libs_delete.svg"
          alt=""
          @click="handleDeleteClick(item)"
        />
        <div class="title">{{ item.title }}</div>
        <div class="sub-title">
          {{ item.subTitle }}
        </div>
        <div class="content">
          {{ item.content }}
        </div>
        <div class="bottom">
          <div class="from">{{ item.from }}</div>
          <el-button
            class="start-use"
            type="danger"
            :disabled="item.hasUse"
            @click="handleUseClick(item)"
            round
            >{{ item.hasUse ? "已使用" : "使用" }}</el-button
          >
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed, reactive } from "@vue/reactivity";
import { useStore } from "vuex";
const store = useStore();
const state = reactive({
  data: [
    {
      title: "小米",
      subTitle: "国际管理与市场营销 硕士 管理学院  南京 2017年07月-2018年8月",
      content: "国际管理与市场营销 硕士 管理学院  南京 2017年07月-2018年8月",
      from: "来自：白东澍的简历 最后更新：2022-02-04 23:10",
      hasUse: false,
      id: 1,
    },
    {
      title: "谢菲尔德大学",
      subTitle: "产品经理实习生 软件与体验部 南京 2017年07月-2018年08月",
      content:
        "负责：小米MIUI手机系统天气APP的产品工作产品方案设计：通过天气首页各数据板块曝光、点击等行为数据进行分析，并结合用户反馈对首页数据板块布局进行调整优化，同时对天气现象进行更加可视化展示，输出高保真原型3套。经过20余名用户的易用性测试，选择最佳方案并推动研发编码工作。上线后用户满意度4.12提升至4.87（5分满）",
      from: "来自：未命名简历 最后更新：2022-02-04 23:10",
      hasUse: false,
      id: 2,
    },
  ],
});

const handleUseClick = (item) => {
  item.hasUse = true;
};
const handleDeleteClick = (item) => {
  const index = state.data.indexOf(item);
  state.data.splice(index, 1);
};
const onCardClick = (item) => {
  item.hasUse = true;
};

const showCard = computed(() => {
  const modifyModule = store.state.edit.modifyModule;
  // return (
  //   state.data.length > 0 &&
  //   (modifyModule === "basic_info" ||
  //     modifyModule === "work_experience" ||
  //     modifyModule === "project_experience" ||
  //     modifyModule === "school_experience" ||
  //     modifyModule === "custom_experience")
  // );
  return false;
});
</script>

<style lang="scss" scoped>
.edit-modify-experience-libs {
  padding: 10px;
  margin-top: 20px;

  div {
    font-size: 14px;
    line-height: 20px;
    color: rgba($color: #000000, $alpha: 0.7);
  }
  .main-title {
    font-weight: bold;
  }
  img {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
  }
  .inner-card {
    position: relative;
    background: #f9f9f9;
    border-radius: 5px;
    margin-top: 10px;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    transition: all 250ms;
    cursor: pointer;

    &:hover {
      background: #e4e4e4;
    }

    .title {
      font-weight: bold;
    }
    .sub-title {
      margin-top: 5px;
    }
    .content {
      margin-top: 5px;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .from {
        font-size: 12px;
        line-height: 17px;
        color: rgba($color: #000000, $alpha: 0.5);
      }
      .start-use {
        width: 66px;
        height: 24px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease 0s;
  transition-delay: 0.1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
</style>