<template>
  <div class="vip_welcome_container">
    <div class="gap_top"></div>
    <p class="title">欢迎您，</p>
    <p class="title">尊贵的StrongHire会员</p>
    <img
      src="@/assets/img/icon_add_friend_qr_code.png"
      alt=""
      class="qr-code"
    />
    <p class="tips">扫码添加您的一对一求职导师</p>
    <div class="gap_bottom"></div>
  </div>
</template>

<script setup>
//引入vue方法
import { useStore } from "vuex";
import { reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import VipCards from "@/components/buy_vip/VipCards";
import Avatar from "@/components/common/Avatar";

const router = useRouter();
const route = useRoute();
const store = useStore();

const onLogoClick = () => {
  router.push("/");
};
</script>

<style scope lang="scss">
@import "src/style/base.scss";

.vip_welcome_container {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

  .gap_top {
    flex: 10;
  }
  .gap_bottom {
    flex: 20;
  }

  .title {
    font-size: 30px;
    line-height: 42px;
    background: linear-gradient(90deg, #fed8b1 0%, #d1a17b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 500;
  }

  .qr-code {
    width: 248px;
    height: 248px;
    margin-top: 80px;
  }

  .tips {
    font-size: 19px;
    line-height: 27px;
    color: #ffffff;
    margin-top: 20px;
    border-color: white;
  }
}
</style>