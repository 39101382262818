<template>
  <div class="modify-evaluation-info">
    <StrongEditor
      v-model="desc"
      placeholder="给出合理的自我评价有助于面试官了解你"
    ></StrongEditor>
  </div>
</template> 

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import utils from "@/utils/utils.js";

const store = useStore();

const desc = computed({
  get: () => {
    return store.state.edit.contentModules.evaluation.desc;
  },
  set: (val) => {
    store.commit("updateEvaluation", { desc: val });
  },
});

</script>

<style lang="scss">
@import "src/style/base.scss";

.modify-evaluation-info {
  

}
</style>