import { createStore } from "vuex"
import Edit from './edit'
import Blog from './blog'

export default createStore({
    state: {
        suggestionsVisible: 'hidden',
        count: 1,
        uploadData: {
            uploadVisible: false,
            uploadType: '',
            uploadTitle: '',
            uploadSubTitle: '',
            uploadResumeId: "",
        },
        loginData: {
            // 登录弹窗
            showLoginDialog: false,
            needAddResumeData: false, // 登录成功后，需要将本地数据同步到服务器
            redictPath: "",
            from: "", // 来源，用于打点
        },

        userData: null,
        // vip dialog
        showVipDialog: false,
        // 添加微信会员弹窗
        showAddWechatDialog: false,
        showAddWechatDialogDescType: 0, // 0 是 banner ，其他是非0
        cloudResumeCount: 0, // 云简历数量
    },
    getters: {
        hasLogin: (state) => {
            return state.userData !== null && state.userData.name !== null && state.userData.name !== undefined;
        },
        isVip: (state) => {
            if (!state.userData || !state.userData.vipExpireTime) {
                return false;
            }
            const now = new Date().getTime();
            const vipExpireTime = state.userData.vipExpireTime;
            return now < vipExpireTime;
        }
    },
    mutations: {
        setCloudResumeCount(state, count) {
            state.cloudResumeCount = count;
        },
        increment(state, payload) {
            console.log("mutations", state, payload);
            state.count += payload.count
        },
        updateSuggestionsVisible(state, payload) {
            state.suggestionsVisible = payload
        },
        setUploadData(state, payload) {
            Object.assign(state.uploadData, payload)
        },
        updateLoginDialog(state, payload) {
            // 更新登录弹窗的状态
            Object.assign(state.loginData, payload)
        },
        updateVipDialog(state, payload) {
            // 更新 vip 弹窗的状态
            state.showVipDialog = payload
        },
        updateAddWechatDialog(state, payload) {
            // 更新添加微信会员弹窗的状态
            state.showAddWechatDialog = payload.show;
            state.showAddWechatDialogDescType = payload.type;
        },
        setUserData(state, payload) {
            state.userData = payload
            try {
                var jwt = require("jsonwebtoken");
                var token = payload.token;
                var result = jwt.decode(token);
                state.userData.vipExpireTime =  result.vipExpireTime;
            } catch (e) {
                console.log("setUserData", e);
            }
            localStorage.setItem('user_data', JSON.stringify(state.userData))
        },
        updateUserData(state, payload) {
            Object.assign(state.userData, payload)
            try {
                var jwt = require("jsonwebtoken");
                var token = payload.token;
                var result = jwt.decode(token);
                state.userData.vipExpireTime =  result.vipExpireTime;
            } catch (e) {
                console.log("updateUserData", e);
            }
            localStorage.setItem('user_data', JSON.stringify(state.userData))
        },
        restoreUserData(state) {
            const value = localStorage.getItem('user_data')
            if (value) {
                const data = JSON.parse(value)
                if (data) {
                    state.userData = data
                    try {
                        var jwt = require("jsonwebtoken");
                        var token = data.token;
                        var result = jwt.decode(token);
                        if (result?.name) {
                            state.userData.name = result.name;
                        }
                        state.userData.vipExpireTime =  result.vipExpireTime;
                        console.log("restoreUserData", state.userData);
                    } catch (e) {
                        console.log("restoreUserData", e);
                    }
                }
            }
        },
        resetUserData(state) {
            console.log('reset user data');
            state.userData = {}
            localStorage.removeItem('user_data')
        }

    },
    actions: {
        incrementAsync({ commit, state }, value) {
            setTimeout(() => {
                console.log(`action: `, commit, state, value);
                commit('increment', value)
            }, 1000);
        },

    },
    modules: {
        edit: Edit,
        blog: Blog,
    }
})