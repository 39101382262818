import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import './style/element/index.scss'
import { createRouter, createWebHistory } from 'vue-router'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './views/App.vue'
import routes from './routes'
import 'normalize.css/normalize.css'
import store from './store'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import analyze from "@/plugin/analyze.js";
import AutoComplete from '@/components/common/AutoComplete.vue'
import StrongEditor from "@/components/StrongEditor.vue";
import 'quill-paste-smart';
import VueCropper from 'vue-cropper';
import 'vue-cropper/dist/index.css'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      el: '.el-main',
      top: 60,
    }
  },
})

router.beforeEach((to, from, next) => {
  // 判断是否 userAgent 是否是手机
  console.log("beforeEach", navigator.userAgent, to.path, process.env.VUE_APP_ENVIRONMENT);
  // 如果来源于手机，贼关闭 robot 爬虫
  if (to.path.indexOf('/m/') > -1 || to.name === 'agreement' || to.name === 'privacy'
    || to.name === 'vipService' || to.name === 'licenseCenter' || process.env.VUE_APP_ENVIRONMENT !== 'production') {
    document
      .querySelector("meta[name='robots']")
      .setAttribute("content", "noindex, nofollow");
  } else {
    document
      .querySelector("meta[name='robots']")
      .setAttribute("content", "index, follow");
  }
  if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    // 如果是手机端，跳转到 mobile 目录下
    if (to.path.indexOf('/m/') === -1) {
      next({
        path: '/m/home',
        replace: true
      })
    } else {
      next()
    }
  }
  next()
})

const app = createApp(App)

// 初始化编辑器
const globalOptions = {
  debug: 'warn',
  modules: {
    toolbar: [
      ['bold', 'italic'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      ['link'],
    ],
    clipboard: {
      allowed: {
        tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
        attributes: ['href', 'rel', 'target', 'class']
      },
      keepSelection: true,
      substituteBlockElements: true,
      magicPasteLinks: true,
      hooks: {
        uponSanitizeElement(node, data, config) {
          console.log(node);
        },
      },
    },
  },
  theme: 'snow'
}
// set default globalOptions prop
QuillEditor.props.globalOptions.default = () => globalOptions
// register QuillEditor component
app.component('QuillEditor', QuillEditor)
app.component('AutoComplete', AutoComplete)
app.component('StrongEditor', StrongEditor)

app.use(ElementPlus, {
  locale: zhCn,
})
app.use(router)
app.use(store)
app.use(VueCropper)
app.mount('#app')

app.use('/robots.txt', "./dist/robots.txt");