<template>
  <div class="main">
    <router-view v-slot="{ Component }">
      <Transition name="fade" mode="out-in">
        <component :is="Component"></component>
      </Transition>
    </router-view>
    <LoginDialog></LoginDialog>
    <VipDialog></VipDialog>
    <UploadDialog></UploadDialog>
    <AddFriendVip></AddFriendVip>
    <div class="preload-div">
      <img src="@/assets/img/icon_login_left_top.svg" alt="" />
      <img src="@/assets/img/icon_login_left_bottom.svg" alt="" />
    </div>
  </div>
</template>

<script setup>
//引入vue方法
import { ElMessage } from "element-plus";
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import { reactive, watch, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import analyze from "@/plugin/analyze.js";
import LoginDialog from "@/components/common/LoginDialog";
import VipDialog from "@/components/common/VipDialog";
import UploadDialog from "@/components/common/UploadDialog";
import AddFriendVip from "@/components/common/AddFriendVip";
import myCookie from "@/apis/cookie";
import homeApi from "@/apis/home_api";
import editApi from "@/apis/edit_api";

const state = reactive({
  locale: zhCn,
});

const route = useRoute();
const store = useStore();
const router = useRouter();

const hasLogin = computed(() => store.getters.hasLogin);

watch(
  () => route.path,
  (to, from) => {
    analyze.sendPV(route);
  }
);

const fetchMineData = async () => {
  console.log("fetchMineData");
  const hasLogin = store.getters.hasLogin;
  if (hasLogin === false) {
    console.log("没有登录");
    return;
  }
  try {
    store.commit("setCloudResumeCount", 0);
    const result = await editApi.getAllResumeData();
    if (result && result.code === 0) {
      store.commit("setCloudResumeCount", result.data.length);
    }
  } catch (e) {
    console.log(e);
    if (e?.code === 3) {
      myCookie.delStaffUserInfo();
      store.commit("resetUserData");
      ElMessage.error(e.msg ?? "登录失效，请重新登录");
      setTimeout(() => {
        router.push("/");
      }, 500);
    }
  }
};

onMounted(() => {
  store.commit("restoreUserData");
  initAxios();
  initAnalyze();
  router.beforeEach((to, from, next) => {
    if (to.path === "/resume" && !hasLogin.value) {
      next("/");
      store.commit("updateLoginDialog", {
        showLoginDialog: true,
        redictPath: "/resume",
        from: "我的简历 TAB",
      });
    } else if (
      (to.query.type === "template_resume" ||
        to.query.type === "user_resume") &&
      to.name !== "resumePrint"
    ) {
      // ! 对于从 CMS 跳转过来的，需要校验是否登录，以及 token 的合法性
      if (!hasLogin.value) {
        next("/");
        store.commit("updateLoginDialog", {
          showLoginDialog: true,
          redictPath: to.fullPath,
          from: "CMS",
        });
        ElMessage.error("请先登录");
      } else {
        console.log("sss");
        try {
          var jwt = require("jsonwebtoken");
          const token = myCookie.getStaffUserToken();
          const result = jwt.decode(token);
          if (result?.role !== 1) {
            next("/");
            ElMessage.error("您没有权限");
          } else {
            next();
          }
        } catch (e) {
          console.log(e);
          next("/");
        }
      }
    } else {
      next();
    }
  });
  fetchMineData();
});

const initAnalyze = () => {
  analyze.init();
};

import axios from "axios";
const initAxios = async () => {
  axios.interceptors.response.use(
    function (response) {
      //对响应数据做点什么
      try {
        if (response.headers["token"]) {
          console.log("post has token", response.headers["token"]);

          var jwt = require("jsonwebtoken");
          var token = response.headers["token"];
          var result = jwt.decode(token);
          console.log("parse token", result);
          if (result?.name) {
            // ! 更新本地 token， 更新名称
            store.commit("updateUserData", {
              name: result.name,
              token: token,
            });
            myCookie.setStaffUserInfo(store.state.userData);
          }
        }
      } catch (e) {
        console.log("restoreUserData", e);
      }
      return response;
    },
    function (error) {
      //对响应错误做点什么
      return Promise.reject(error);
    }
  );
  // 请求 ip 接口
  // http://pv.sohu.com/cityjson?ie=utf-8
  axios.get("https://api.ipify.org?format=json").then((res) => {
    localStorage.setItem("extra_ip", res.data.ip);
  });
};
</script>

<style scope lang="scss">
@import "src/style/base.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease 0s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
.preload-div {
  position: absolute;
  visibility: hidden;
  right: 0px;
  top: 0px;
}
</style>