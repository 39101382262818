<template>
  <div v-loading="state.isLoading" class="mobile_detail">
    <MobileTitleBar></MobileTitleBar>
    <div class="top">
      <p>{{ state.data.title }}</p>
      <p>{{ state.data.desc }}</p>
      <div class="time-chose">
        <div class="action-time">
          <span>更新于{{ state.data.updateTime }}</span>
        </div>
        <div class="action-chose">
          <span>{{ state.data.totalUseNum }}人已选择</span>
        </div>
      </div>
      <div
        class="label_tag"
        v-if="state.data.labels && state.data.labels.length > 0"
      >
        <el-tag v-for="(item, index) in state.data.labels" :key="index">{{
          item
        }}</el-tag>
      </div>
    </div>
    <img :src="getUrl(state.data.previewPicUrl)" alt="" class="template_img" v-show="state.data.previewPicUrl !== ''"/>
    <div class="bottom"></div>
    <p class="action" @click="onActionClick">立即在线制作</p>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { onMounted, onUnmounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import editApi from "@/apis/edit_api";
import { ElMessage } from "element-plus";
import utils from "@/utils/utils.js";
import MobileTitleBar from "./MobileTitleBar.vue";
import analyze from "@/plugin/analyze.js";

const route = useRoute();
const router = useRouter();

const state = reactive({
  isLoading: false,
  data: {
    previewPicUrl: "",
    title: "",
    desc: "",
    updateTime: "",
    totalUseNum: 0,
    isVip: false,
    labels: [],
  },
});

const width = ref(document.documentElement.clientWidth);
const handleResize = (event) => {
  width.value = document.documentElement.clientWidth;
};
const itemWidth = computed(() => {
  return width.value - 25 * 2;
});

const itemWidthPx = computed(() => {
  return itemWidth.value + "px";
});

const itemHeightPx = computed(() => {
  console.log("itemWidth", itemWidth.value);
  return `${itemWidth.value * 1.44}px`;
});

const requestData = async () => {
  try {
    state.isLoading = true;
    const result = await editApi.getDetail(route.params.id);
    if (result?.code === 0) {
      state.data = {
        previewPicUrl: result.data.resumeData.previewPicUrl,
        title: result.data.resumeData.title,
        desc: result.data.desc,
        updateTime: utils.formatDateTime(result.data.resumeData.updateTime),
        totalUseNum: result.data.totalUseNum,
        isVip: result.data.vip,
        labels: result.data.labels,
      };

      state.isLoading = false;
      document.title = `${result.data.resumeData.title}-StrongHire简历，大厂必备免费简历模板校招社招实习在线制作平台`;
      document
        .querySelector("meta[name='Keywords']")
        .setAttribute("content", result.data.labels.join(","));
      document
        .querySelector("meta[name='Description']")
        .setAttribute("content", result.data.desc);
    } else {
      state.isLoading = false;
      ElMessage.error(result?.msg);
    }
  } catch ({ msg }) {
    state.isLoading = false;
    ElMessage.error(msg);
  }
};

const getUrl = (url) => {
  return `${utils.getImageUrl(url)}?x-oss-process=image/resize,h_1000,m_lfit`;
};

const onActionClick = () => {
  router.push({ name: "mobileGuide" });
  analyze.sendClickEvent("mobile_template_use_click");
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
  if (route.params.id) {
    requestData();
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<style lang="scss" scoped>
.mobile_detail {
  display: flex;
  flex-direction: column;
}

#img_logo {
  width: 136px;
  height: 25px;
  margin-top: 20px;
  margin-left: 20px;
}

.top {
  width: calc(100vw - 50px);
  background-color: white;
  border-radius: 5px;
  margin-left: 25px;
  padding: 20px;
  padding-bottom: 15px;
  box-sizing: border-box;
  margin-top: 84px;

  & > p {
    &:nth-child(1) {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
    }
    &:nth-child(2) {
      margin-top: 10px;
      font-family: PingFang SC;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.7);
    }
  }
  .time-chose {
    margin-top: 10px;
    display: flex;
    div {
      display: flex;
      align-items: center;
    }
    span {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
    }
    img {
      width: 12px;
      height: 12px;
    }
    .action-chose {
      margin-left: 6px;
    }
  }

  .label_tag {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    & > span {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}

.template_img {
  width: v-bind(itemWidthPx);
  height: v-bind(itemHeightPx);
  margin-top: 20px;
  margin-left: 25px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(203, 203, 203, 0.1);
  overflow: hidden;
}

.bottom {
  height: 40px;
}

.action {
  width: 268px;
  height: 45px;
  background-color: #f56c6c;
  color: white;
  margin-top: 25px;
  font-size: 16px;
  text-align: center;
  line-height: 45px;
  border-radius: 50px;
  align-self: center;
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  left: calc((100vw - 268px) / 2);
}
</style>