<template>
  <div
    class="ai-predict-container"
    v-loading="isLoading"
    element-loading-text="计算中"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="title">
      <img src="@/assets/img/icon_vip.svg" alt="" class="more" />
      <strong>{{ showTitle }}</strong>
    </div>
    <div class="not_select" v-if="state.current.state === ''">
      <img
        src="@/assets/img/icon_mine_resume_no_data.svg"
        alt=""
        class="more"
      />
      <p>从左侧“我的简历”中选择一个简历</p>
    </div>
    <div
      class="success"
      v-else-if="
        state.current.state === 'success' || state.current.state === 'fail'
      "
    >
      <div class="success_no_data" v-if="hasNoData">
        <img src="@/assets/img/icon_resume_portrait.svg" />
        <div class="title">
          <p>根据你的简历内容，测算与<span>目标岗位的匹配度</span></p>
          <p>结合10万+Offer数据，预估你的<span>简历通过率</span></p>
        </div>
        <p @click="onRefreshClick()" class="resume_button">立即计算</p>
      </div>
      <div class="success_content" v-else>
        <p @click="onRefreshClick()" class="resume_button">重新计算</p>
        <p>{{ showTime }}</p>
        <ResumeAIChart
          class="resume_chart_left"
          :data="state.current.detail.matchScoreDetail"
        />
        <div class="resume_right">
          <p>{{ showCompanyPosition }}</p>
          <div class="score_container">
            <div>
              <p>岗位匹配度</p>
              <p>{{ positionScore }}<span>%</span></p>
            </div>
            <div>
              <p>简历通过率</p>
              <p>{{ resumeRate }}<span>%</span></p>
            </div>
          </div>
          <div class="resume_bottom">
            <img src="@/assets/img/icon_resume_advise.svg" alt="" />
            <span>{{ adviseDesc }}</span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="showDialog"
      width="500px"
      top="25vh"
      :destroy-on-close="true"
      :show-close="false"
    >
      <div class="modal_dialog">
        <p>填写岗位信息</p>
        <div class="close" @click="onCloseClick">
          <img src="@/assets/img/icon_upload_close.svg" alt="" />
        </div>
        <div class="top_input">
          <AutoComplete
            class="input"
            v-model="state.jobCompany"
            type="Company"
            placeholder="请输入目标公司"
            clearable
            size="large"
          />
          <AutoComplete
            class="input"
            v-model="state.jobPosition"
            placeholder="请输入目标岗位"
            type="Job"
            clearable
            size="large"
          />
        </div>
        <el-input
          v-model="state.jobDesc"
          :placeholder="inputPlaceHolder"
          clearable
          class="desc"
          type="textarea"
          :rows="9"
        />
        <div class="bottom">
          <p @click="onCloseClick">取消</p>
          <p @click="onCalcClick">开始计算</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import editApi from "@/apis/edit_api";
import { ElMessage } from "element-plus";
import useEventBus from "@/plugin/event";
import { onMounted, onUnmounted } from "@vue/runtime-core";
import ResumeAIChart from "@/components/resume/ResumeAIChart";
import utils from "@/utils/utils.js";
import analyze from "@/plugin/analyze.js";
import vipMix from "@/plugin/vip";

const store = useStore();
const eventBus = useEventBus();
const { onVipToast } = vipMix();
const state = reactive({
  id: "",
  title: "",
  jobCompany: "",
  jobPosition: "",
  jobDesc: "",
  current: {
    detail: {},
    state: "", // '':未选择, 'success':加载成功, 'fail':加载失败
  },
  portraitData: [
    // {
    //   id: "",
    //   detail: {},
    // }
  ],
});

const hasNoData = computed(() => {
  if (
    state.current.detail === null ||
    !state.current.detail?.matchScoreDetail ||
    state.current.state === "fail"
  ) {
    return true;
  } else {
    return false;
  }
});

const onIdChange = async (id) => {
  const containData = contain(id);
  console.log("onIdChange", id, containData?.state);
  state.current.state = containData?.state;
  if (containData === null) {
    // 如果不存在，需要请求
    requestData(id);
    return;
  }
  if (containData.state === "fail") {
    // 如果加载失败
    return;
  }
  if (containData.state === "success") {
    // 如果已经加载成功，直接设置数据
    refreshView(id, containData.detail);
    return;
  }
};

const requestData = async (id) => {
  // 请求接口
  console.log("requestData", id);
  const itemData = {
    id: id,
    detail: {},
    state: "",
  };
  try {
    isLoading.value = true;
    const result = await editApi.getLastResumeAiPrediction(id);
    if (result.code === 0) {
      itemData.detail = result.data;
      itemData.state = "success";
      refreshView(id, result.data);
      isLoading.value = false;
    } else {
      // ElMessage.error(result?.msg);
      if (result.code === 4 || result.code === 5) {
        onVipToast(result?.msg ?? "");
      }
      state.current.state = "fail";
      itemData.state = "fail";
      isLoading.value = false;
    }
  } catch ({ msg: msg }) {
    ElMessage.error(msg);
    state.current.state = "fail";
    itemData.state = "fail";
    isLoading.value = false;
  }
  state.portraitData.push(itemData);
};

const isLoading = ref(false);

const refreshView = async (id, data) => {
  console.log("更新 UI", id, state.id);
  if (id !== state.id) {
    return;
  }
  state.current.detail = data;
  state.current.state = "success";
};

const onRefreshClick = () => {
  showDialog.value = true;
  analyze.sendClickEvent("mine_resume_ai_refresh_click");
};

const onCalcClick = () => {
  if (!state.jobCompany) {
    ElMessage.error("请输入目标公司");
    return;
  }
  if (!state.jobPosition) {
    ElMessage.error("请输入目标岗位");
    return;
  }
  if (!state.jobDesc) {
    ElMessage.error("请输入目标职位描述");
    return;
  }

  showDialog.value = false;
  refresh(state.id);
};

const refresh = async (id) => {
  const itemData = {
    id: id,
    detail: {},
    state: "",
  };
  try {
    isLoading.value = true;
    const result = await editApi.refreshAiPrediction(
      id,
      state.jobCompany,
      state.jobPosition,
      state.jobDesc
    );
    if (result.code === 0) {
      itemData.detail = result.data;
      itemData.state = "success";
      refreshView(id, result.data);
      isLoading.value = false;
    } else {
      ElMessage.error(result?.msg);
      state.current.state = "fail";
      itemData.state = "fail";
      isLoading.value = false;
    }
  } catch ({ msg: msg }) {
    ElMessage.error(msg);
    state.current.state = "fail";
    itemData.state = "fail";
    isLoading.value = false;
  }

  const containData = contain(id);
  // 如果存在，移除
  if (containData !== null) {
    if (itemData.state === "fail" && containData.state === "success") {
      // 如果当前请求失败，但是缓存中的数据是成功的，则不需要更新
      console.log("onRefreshClick-失败了，但是有数据");
      state.current.state = "success";
    } else {
      console.log("onRefreshClick-没有失败，但是有数据");
      state.portraitData.splice(state.portraitData.indexOf(containData), 1);
      state.portraitData.push(itemData);
    }
  } else {
    state.portraitData.push(itemData);
  }
};

const contain = (id) => {
  // 判断是否有数据
  const filterResult = state.portraitData.filter((item) => {
    return item.id === id;
  });
  if (filterResult.length > 0) {
    return filterResult[0];
  } else {
    return null;
  }
};

const showTitle = computed(() => {
  return "StrongHire AI预测";
});

const showTime = computed(() => {
  if (state.current.detail.updateTime) {
    return `最后更新：${utils.getDateDiff(state.current.detail.updateTime)}`;
  } else {
    return "";
  }
});

const showCompanyPosition = computed(() => {
  return `${state.current.detail.jdCompany} · ${state.current.detail.jdTitle}`;
});

const positionScore = computed(() => {
  // 岗位匹配度
  return `${Math.round(state.current.detail.matchScore * 100) / 100}`;
});

const resumeRate = computed(() => {
  // 简历通过率 passScore
  var percent = Math.round(state.current.detail.passScore * 100) / 100;
  return `${percent}`;
});

const adviseDesc = computed(() => {
  let passScore = state.current.detail.passScore;
  if (passScore >= 0 && passScore < 40) {
    return "强烈建议联系StrongHire进行简历辅导";
  } else if (passScore < 60) {
    return "再补充一些亮点吧，可大幅提升简历质量";
  } else if (passScore < 80) {
    return "建议根据目标岗位要求继续修改简历";
  } else if (passScore < 85) {
    return "已超越88.23%的StrongHire用户";
  } else if (passScore < 90) {
    return "已超越91.69%的StrongHire用户";
  } else {
    return "已超越93.38%的StrongHire用户";
  }
});

onMounted(() => {
  eventBus.on("mineResumeSelect", async (data) => {
    if (!data.id) {
      state.id = "";
      state.title = "";
      state.current.state = "";
      state.current.detail = {};
      return;
    }
    if (state.id !== data.id) {
      state.id = data.id;
      state.title = data.name;
      onIdChange(data.id);
    }
  });
});

onUnmounted(() => {
  eventBus.off("mineResumeSelect");
});

const showDialog = ref(false);
const inputPlaceHolder = ref(
  "请粘贴岗位描述（JD）\n岗位描述可以从各公司招聘网站的岗位详情中获取"
);

const onCloseClick = () => {
  showDialog.value = false;
};
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
.ai-predict-container {
  min-height: 208px;
  margin-top: 20px;
  padding: 20px;
  padding-top: 23px;
  padding-bottom: 23px;
  @include card-bg;
  position: relative;
  .title {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 16px;
    }
    strong {
      margin-left: 5px;
      font-size: 16px;
      line-height: 22px;
      color: black;
    }
  }
}

.not_select {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 77px;
  }
  p {
    margin-top: 9px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
  }
}

.success {
  width: calc(100% - 40px);
}

.success_no_data {
  position: absolute;
  top: 0;
  left: 0;
  & > img {
    width: 100px;
    height: 80px;
    position: absolute;
    left: 230px;
    top: 88px;
  }

  .title {
    position: absolute;
    left: 350px;
    top: 84px;
    width: 290px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      line-height: 20px;
      span {
        color: $primary-color;
      }
    }
  }

  p {
    &:nth-child(3) {
      position: absolute;
      left: 350px;
      top: 134px;
    }
  }
}

.resume_button {
  width: 96px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  color: #606266;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 250ms;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
}

.success_content {
  height: calc(100% - 57px);
  display: flex;
  margin-top: 20px;

  & > p {
    &:nth-child(1) {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    &:nth-child(2) {
      position: absolute;
      right: 126px;
      top: 30px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      line-height: 17px;
    }
  }

  .resume_chart_left {
    flex: 1;
    margin-left: 28px;
  }
  .resume_right {
    flex: 1;
    margin-left: 40px;
    margin-top: 20px;

    & > p {
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      color: $primary-color;
      font-weight: bold;
    }

    .score_container {
      display: flex;

      & > div {
        margin-top: 20px;
        width: 150px;
        & > p {
          &:nth-child(1) {
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.7);
          }
          &:nth-child(2) {
            margin-top: 5px;
            font-size: 30px;
            line-height: 42px;
            font-weight: 400;
            color: $primary-color;
            font-weight: bold;
            span {
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
  .resume_bottom {
    display: flex;
    margin-top: 10px;
    align-items: center;

    & > img {
      width: 11px;
      height: 11px;
    }

    & > span {
      font-size: 12px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.7);
      margin-left: 5px;
    }
  }
}

::v-deep(.el-dialog) {
  height: 358px;
  border-radius: 5px;
}

::v-deep(.el-dialog__header) {
  display: none;
  padding: 0px;
}

::v-deep(.el-dialog__body) {
  padding: 0px;
  margin: 0px;
}

::v-deep(.el-upload-list) {
  display: none;
}

.modal_dialog {
  height: 358px;
  width: 500px;
  position: relative;
  display: flex;
  flex-direction: column;

  & > p {
    font-size: 16px;
    line-height: 26px;
    margin-left: 20px;
    margin-top: 12px;
    font-weight: bold;
    color: #303133;
  }

  .close {
    position: absolute;
    width: 28px;
    height: 28px;
    right: 0px;
    top: 18px;
    cursor: pointer;
  }
  .top_input {
    display: flex;
    margin-top: 12px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;

    ::v-deep(.el-input) {
      width: 225px !important;
      min-width: 225px !important;
    }
  }
  .desc {
    margin-top: 10px;
    margin-left: 20px;
    width: 460px;
    height: 200px;
    min-height: 200px;
    max-height: 200px;
  }
  .bottom {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 16px;
    & > p {
      cursor: pointer;
      transition: all 250ms;
      &:nth-child(1) {
        width: 54px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        font-size: 12px;
        color: #606266;
        margin-right: 10px;
        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.05);
        }
      }

      &:nth-child(2) {
        width: 78px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #f56c6c;
        border-radius: 4px;
        font-size: 12px;
        color: white;
        margin-right: 20px;
        &:hover {
          background-color: rgba($color: $primary-color, $alpha: 0.8);
        }
      }
    }
  }
}

::v-deep(.path),
::v-deep(.el-loading-text) {
  color: rgba(0, 0, 0, 0.7);
  stroke: rgba(0, 0, 0, 0.7);
}
</style>