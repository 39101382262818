<template>
  <div>
    <el-dialog
      v-model="showDialog"
      :width="600"
      top="25vh"
      :destroy-on-close="true"
      :show-close="false"
      @open="onDialogOpen"
      @close="onDialogClose"
      :before-close="handleClose"
    >
      <div class="login-parent">
        <div class="left">
          <img
            src="@/assets/img/icon_login_left_top.svg"
            alt=""
            class="left-top-bg"
          />
          <img
            src="@/assets/img/icon_login_left_bottom.svg"
            alt=""
            class="left-bottom-bg"
          />
          <img src="@/assets/img/icon_login_logo.svg" alt="" class="logo" />
          <ul>
            <li>1000+原创在线简历模板</li>
            <li>云端存储，随时修改</li>
            <li>多重加密，时刻保护你的隐私安全</li>
            <li>AI智能简历诊断，快速提升简历质量</li>
            <li>AI岗位匹配度检测，离Offer更进一步</li>
          </ul>
        </div>
        <div class="right">
          <p>简历制作中...</p>
          <el-progress
            type="circle"
            :percentage="progress"
            :stroke-width="12"
            color="#F56C6C"
            :width="150"
          >
            <template #default="{ percentage }">
              <div class="percentage-container">
                <span class="percentage-value">{{ percentage }}</span>
                <span class="percentage-label">%</span>
              </div>
            </template>
          </el-progress>
          <p>{{ exportDesc }}</p>
        </div>
        <img
          src="@/assets/img/icon_upload_close.svg"
          alt=""
          @click="onCloseClick"
          class="close-dialog"
          v-if="false"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import authApi from "@/apis/auth_api";
import { onActivated, onMounted } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();



const showDialog = computed({
  get: () => store.state.edit.editExportDialog.visible,
  set: (val) => {
    store.commit("updateEditExportDialog", {
      visible: val,
    });
  },
});

const progress = computed(() => store.state.edit.editExportDialog.progress);

const exportDesc = computed(
  () => "StrongHire的自研AI智能简历诊断，更贴近Hr视角"
);

const onDialogOpen = () => {
  console.log("onDialogOpen");
};

const onDialogClose = () => {
  console.log("onDialogClose");
};

const onCloseClick = () => {
  showDialog.value = false;
};

const handleClose = (done) => {
  console.log("handleClose");
}
</script>

<style lang="scss" scoped>
::v-deep(.el-dialog) {
  height: 400px;
  border-radius: 5px;
}

::v-deep(.el-dialog__header) {
  display: none;
  padding: 0px;
}

::v-deep(.el-dialog__body) {
  padding: 0px;
  margin: 0px;
}

.login-parent {
  height: 400px;
  display: flex;

  .close-dialog {
    width: 15px;
    height: 15px;
    right: 21px;
    top: 21px;
    position: absolute;
    cursor: pointer;
  }

  .left {
    width: 270px;
    height: 100%;
    position: relative;
    padding-left: 19px;

    .logo {
      width: 133px;
      height: 26px;
      position: absolute;
      top: 21px;
      left: 19px;
    }

    ul {
      position: absolute;
      top: 68px;
      padding: 0px;
      padding-left: 19px;
      margin: 0px;
      li {
        font-size: 12px;
        margin-top: 12px;
        font-size: 12px;
        color: white;
      }
    }

    .left-top-bg {
      position: absolute;
      top: -12px;
      left: 0px;
      width: 270px;
      height: 263px;
    }
    .left-bottom-bg {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 270px;
      height: 155px;
    }
  }

  .right {
    width: 330px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      &:nth-child(1) {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: black;
        margin-top: 83px;
      }
      &:nth-child(3) {
        font-size: 12px;
        line-height: 17px;
        color: rgba($color: #000000, $alpha: 0.7);
        margin-top: 20px;
      }
    }

    .el-progress {
      margin-top: 20px;
    }

    .percentage-container {
      display: flex;
      align-items: baseline;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .percentage-value {
        font-size: 30px;
        line-height: 42px;
        font-weight: 500;
        color: black;
      }
      .percentage-label {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}
</style>