<template>
  <div class="counseling_middle2">
    <p class="title">StrongHire导师，</p>
    <p class="title">强的很</p>
    <div class="list_compayn_container">
      <img v-for="item in companyData" :key="item.id" :src="item.src" alt="" />
    </div>
    <p class="title1">20余位<span>大厂在职面试官</span>担任StrongHire导师</p>
    <div class="list_container">
      <div v-for="(item, index) in data" :key="index" class="list_item">
        <img :src="item.src" alt="" class="avatar" />
        <div class="right">
          <p class="title">{{ item.title }}</p>
          <p class="desc">
            {{ item.desc }} <span>{{ item.desc1 }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const data = [
  {
    title: "大厂业务负责人",
    desc: "世界五百强公司，8年面试官经验",
    src: require("@/assets/img/icon_counseling_avatar1.png"),
  },
  {
    title: "高级人力资源经理",
    desc: "筛选简历超20万份， 沟通Offer超3000个",
    src: require("@/assets/img/icon_counseling_avatar2.png"),
  },
  {
    title: "大厂高级产品经理",
    desc: "经手多个部门战略项目， 5年面试官经验",
    src: require("@/assets/img/icon_counseling_avatar3.png"),
  },
];

const companyData = [
  {
    id: 1,
    src: require("@/assets/img/icon_counseling_company1.png"),
  },
  {
    id: 2,
    src: require("@/assets/img/icon_counseling_company2.png"),
  },
  {
    id: 3,
    src: require("@/assets/img/icon_counseling_company3.png"),
  },
  {
    id: 4,
    src: require("@/assets/img/icon_counseling_company4.png"),
  },
  {
    id: 5,
    src: require("@/assets/img/icon_counseling_company5.png"),
  },
  {
    id: 6,
    src: require("@/assets/img/icon_counseling_company6.png"),
  },
];
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.counseling_middle2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    align-self: flex-start;
    font-size: 40px;
    line-height: 56px;
    color: black;
    font-weight: bold;
  }

  .title1 {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
    margin-top: 100px;
    span {
      color: $primary-color;
    }
  }

  .list_container {
    width: 100%;
    display: flex;
    margin-top: 40px;
  }
}

.list_item {
  flex: 1;
  height: 128px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(203, 203, 203, 0.3);
  border-radius: 20px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);

  &:nth-child(n + 2) {
    margin-left: 20px;
  }

  .avatar {
    width: 80px;
    height: 80px;
    margin-left: 20px;
  }

  .right {
    width: 170px;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }

  .title {
    font-size: 20px;
    line-height: 28px;
  }

  .desc {
    margin-top: 10px;
    font-size: 18px;
    line-height: 25px;
  }
}

.list_compayn_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;

  img {
    &:nth-child(1) {
      width: 253px;
      height: 70px;
    }
    &:nth-child(2) {
      width: 365px;
      height: 70px;
      object-fit: contain;
    }
    &:nth-child(3) {
      width: 253px;
      height: 40px;
    }
    &:nth-child(4) {
      width: 221px;
      height: 70px;
      margin-top: 50px;
    }
    &:nth-child(5) {
      width: 193px;
      height: 70px;
      margin-top: 50px;
    }
    &:nth-child(6) {
      width: 253px;
      height: 68px;
      margin-top: 50px;
    }
  }
}
</style>