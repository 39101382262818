<template>
  <el-autocomplete
    :fetch-suggestions="querySearchAsync"
    :placeholder="props.placeholder"
    @select="handleSelect"
    clearable
    @input="onInputChange"
    :model-value="props.modelValue"
    :popper-append-to-body="false"
    :hide-loading="true"
    :debounce="50"
  >
    <template #default="{ item }">
      <div v-html="htmlValue(item.value)"></div>
    </template>
  </el-autocomplete>
</template>

<script setup>
import { defineProps, defineEmits, onUnmounted, ref, computed } from "vue";
import editApi from "@/apis/edit_api";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  modelValue: String,
  type: {
    // City: 城市， College: 学院， Company: 公司， Job: 岗位， Major: 专业，University: 学校
    type: String,
    default: "City",
  },
  placeholder: {
    type: String,
    default: "",
  },
  isBadge: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "onChange", "onSelectChange"]);

var cacheMap = {};
var key = "";

const suggestionsVisible = computed({
  get: () => store.state.suggestionsVisible,
  set: (val) => {
    store.commit("updateSuggestionsVisible", val);
  },
});

const querySearchAsync = (queryString, cb) => {
  suggestionsVisible.value = "hidden";
  console.log(queryString, props.type, props.placeholder);
  if (!queryString) {
    cb([]);
    return;
  }
  key = queryString.trim();
  if (cacheMap[queryString]) {
    if (cacheMap[queryString].length > 0) {
      suggestionsVisible.value = "visible";
    }
    cb(cacheMap[queryString]);
    return;
  }

  editApi
    .getAutoData(queryString.trim(), props.type, props.isBadge)
    .then(({ data }) => {
      const dataList = [];
      data.map((item) => {
        if (typeof item === "string") {
          dataList.push({
            value: item,
          });
        } else {
          if (props.isBadge) {
            if (item.logo) {
              dataList.push({
                value: item.name,
                labels: item.labels,
                logo: item.logo,
              });
            }
          } else {
            dataList.push({
              value: item.name,
              labels: item.labels,
              logo: item.logo,
            });
          }
        }
      });
      cacheMap[queryString] = dataList;
      if (dataList.length > 0) {
        suggestionsVisible.value = "visible";
      }
      cb(dataList);
    });
};

const htmlValue = (val) => {
  if (key.length > 0) {
    return val.replace(
      new RegExp(key, "g"),
      "<span style='color:#F56C6C;'>" + key + "</span>"
    );
  }
  return val;
};

const handleSelect = (item) => {
  emit("update:modelValue", item.value);
  emit("onChange", item);
  emit("onSelectChange", item.value);
};

const onInputChange = (value) => {
  emit("update:modelValue", value);
  var hasChange = false;
  if (cacheMap[value]) {
    const listData = cacheMap[value];
    if (listData.length > 0) {
      const resultData = listData.filter((item) => {
        return item.value === value;
      });
      if (resultData.length > 0) {
        emit("onChange", resultData[0]);
        hasChange = true;
      }
    }
  }
  if (!hasChange) {
    emit("onChange", null);
  }
};

onUnmounted(() => {
  cacheMap = {};
});
</script>

<style lang="scss">
.el-autocomplete__popper {
  visibility: var(--suggestion-visible);
}

::v-deep(.el-autocomplete__popper) {
  visibility: var(--suggestion-visible);
}
</style>