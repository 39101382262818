<template>
  <el-main class="mobile_pay">
    <img src="@/assets/img/icon_login_logo.svg" alt="logo" />

    <div class="mobile_success">
      <img src="@/assets/img/icon_study_pay_title.png" />
    </div>

    <p class="pay_not_success">
      {{payDesc}}
    </p>
  </el-main>
</template>

<script setup>
import { computed, reactive } from "@vue/reactivity";
import { nextTick, onMounted } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import studyApi from "../../apis/study_api";

const route = useRoute();
const router = useRouter();

const state = reactive({
  payStatus: 0, // 0: 未支付 1: 支付成功 2: 支付失败 3: 支付取消
});

const payDesc = computed(() => {
  switch (state.payStatus) {
    case 0:
      return "支付准备中...";
    case 1:
      return "支付成功，准备跳转中...";
    case 2:
      return "支付失败";
    case 3:
      return "支付取消";
    default:
      return "";
  }
});

const jumpWeixin = async (code) => {
  try {
    const result = await studyApi.createOder(
      code
    );
    if (result.code === 0) {
      if (typeof WeixinJSBridge == "undefined") {
        console.log("WeixinJSBridg undefined");
        state.payStatus = 2;
      } else {
        onWeixinBridgeReady(result.data);
      }
    } else {
      ElMessage.warning(result.msg);
      state.payStatus = 2;
    }
  } catch ({ msg: msg }) {
    ElMessage.warning(msg);
    state.payStatus = 2;
  }
};

const onWeixinBridgeReady = (data) => {
  WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      appId: data.appId,
      timeStamp: data.timeStamp,
      nonceStr: data.nonceStr,
      package: data.packageValue,
      signType: data.signType,
      paySign: data.paySign,
    },
    function (res) {
      const appUrl = process.env.VUE_APP_URL;
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        //成功
        console.log("ok");
        state.payStatus = 1;
        setTimeout(() => {
          window.location.replace(`${appUrl}/m/study_pay_result`)
        }, 500);
      } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
        //取消
        console.log("cancel");
        state.payStatus = 3;
      } else {
        //失败
        console.log("fail" + res.err_desc);
        state.payStatus = 2;
      }
    }
  );
};

const initData = () => {
  // 判断是微信客户端 or 支付宝客户端，做不同支付逻辑
  if (/MicroMessenger/.test(window.navigator.userAgent)) {
    var study_pay_has_redirected = sessionStorage.getItem(
      "study_pay_has_redirected"
    );
    if (!route.query || !route.query.code) {
      if (study_pay_has_redirected) {
        sessionStorage.removeItem("study_pay_has_redirected");
        nextTick(() => {
          WeixinJSBridge.call("closeWindow");
        });
      } else {
        sessionStorage.setItem("study_pay_has_redirected", true);
        const appUrl = process.env.VUE_APP_URL;
        let redirectUrl = encodeURIComponent(`${appUrl}/m/study_pay66`);
        const wxUrl = (window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc520e71a0a38fde5&redirect_uri=" +
          redirectUrl +
          "?response_type=code&scope=snsapi_base&state=123&connect_redirect=1#wechat_redirect");
        window.location.href = wxUrl;
      }
    } else {
      // 已经有code参数，直接调用微信接口
      jumpWeixin(route.query.code);
    }
  } else {
    // todo 处理同时非支付宝和微信扫码时的case
    console.log("not weixin: ua=" + window.navigator.userAgent);
    state.payStatus = 2;
  }
};

onMounted(() => {
  document.title = "StrongHire自习室";
  initData();
});
</script>

<style lang="scss" scoped>
.mobile_pay {
  width: 100vw;
  height: 100vh;
  background-color: #30313a;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

  & > img {
    margin-top: calc(0.1 * 100vh);
    width: 200px;
    height: 39px;
  }
  & > p {
    margin-top: 5.3px;
    span {
      font-size: 12px;
      line-height: 17px;
      color: white;
      &:nth-child(2) {
        color: #e47470;
      }
    }
  }
  .mobile_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;

    .title {
      font-size: 25px;
      line-height: 35px;
      background: linear-gradient(90deg, #fed8b1 0%, #d1a17b 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 500;
    }

    .qr-code {
      width: 200px;
      height: 200px;
      margin-top: 40px;
    }

    .tips {
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      margin-top: 10px;
    }
  }
  .pay_not_success {
    margin-top: 70px;
    font-size: 20px;
    line-height: 28px;
    color: white;
  }
}
</style>