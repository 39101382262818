<template>
  <el-main class="mobile_pay">
    <img src="@/assets/img/icon_login_logo.svg" alt="logo" />
    <p>
      <span>让</span>
      <span>每一个候选人</span>
      <span>都能获得心怡的Offer</span>
    </p>
    <p v-if="state.payStatus !== 1" class="pay_not_success">
      {{ payDesc }}
    </p>
    <div class="mobile_success" v-else>
      <p class="title">欢迎您，</p>
      <p class="title">尊贵的StrongHire会员</p>
      <img
        src="@/assets/img/icon_add_friend_qr_code.png"
        alt=""
        class="qr-code"
      />
      <p class="tips">扫码添加您的一对一求职导师</p>
    </div>
    <span v-html="state.rawHtml"></span>
  </el-main>
</template>

<script setup>
import { computed, reactive } from "@vue/reactivity";
import { nextTick, onMounted } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import vipApi from "../../apis/vip_api";

const route = useRoute();
const router = useRouter();

const state = reactive({
  payStatus: 0, // 0: 未支付 1: 支付成功 2: 支付失败 3: 支付取消
  vipType: "", // vip购买类型 VIP_MONTH-月卡 VIP_QUARTER-季卡 VIP_YEAR-年卡 VIP_PERMANENT-永久
  accountId: "", // 购买的用户accountId
  rawHtml: "",
});

const payDesc = computed(() => {
  switch (state.payStatus) {
    case 0:
      return "等待支付...";
    case 1:
      return "支付成功";
    case 2:
      return "支付失败";
    case 3:
      return "支付取消";
    default:
      return "";
  }
});

const convertVipType = (type) => {
  switch (type) {
    case "1":
      return "VIP_MONTH";
    case "2":
      return "VIP_QUARTER";
    case "3":
      return "VIP_YEAR";
    case "4":
      return "VIP_PERMANENT";
    default:
      return "";
  }
};

const jumpAlipay = async () => {
  try {
    const result = await vipApi.createOder(
      "AlipayWap",
      convertVipType(state.vipType),
      state.accountId
    );
    if (result.code === 0) {
      console.log(result.data);
      state.rawHtml = result.data.body;
      nextTick(() => {
        document.forms[0].submit(); //DOM加载完成之后再调用点击事件才能发起支付
      });
    } else {
      ElMessage.warning(result.msg);
    }
  } catch ({ msg: msg }) {
    ElMessage.warning(msg);
  }
};

const jumpWeixin = async (code) => {
  try {
    const result = await vipApi.createOder(
      "WeixinJSPay",
      convertVipType(state.vipType),
      state.accountId,
      code
    );
    if (result.code === 0) {
      if (typeof WeixinJSBridge == "undefined") {
        console.log("WeixinJSBridg undefined");
      } else {
        onWeixinBridgeReady(result.data);
      }
    } else {
      ElMessage.warning(result.msg);
    }
  } catch ({ msg: msg }) {
    ElMessage.warning(msg);
  }
};

const onWeixinBridgeReady = (data) => {
  WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      appId: data.appId,
      timeStamp: data.timeStamp,
      nonceStr: data.nonceStr,
      package: data.packageValue,
      signType: data.signType,
      paySign: data.paySign,
    },
    function (res) {
      const appUrl = process.env.VUE_APP_URL;
      var status = 0;
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        //成功
        console.log("ok");
        // todo 跳转成功页面
        status = 1;
      } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
        //取消
        console.log("cancel");
        status = 3;
      } else {
        //失败
        console.log("fail" + res.err_desc);
        status = 2;
      }
      const param = `?payResult=${status}`;
      window.location.href = `${appUrl}/m/vip_pay/${param}`;
    }
  );
};

const initData = () => {
  // 判断是微信客户端 or 支付宝客户端，做不同支付逻辑
  if (/MicroMessenger/.test(window.navigator.userAgent)) {
    var vip_pay_has_redirected = sessionStorage.getItem(
      "vip_pay_has_redirected"
    );
    if (!route.query.code) {
      if (vip_pay_has_redirected) {
        sessionStorage.removeItem("vip_pay_has_redirected");
        nextTick(() => {
          WeixinJSBridge.call("closeWindow");
        });
      } else {
        sessionStorage.setItem("vip_pay_has_redirected", true);
        const appUrl = process.env.VUE_APP_URL;
        const param = `?a=${Buffer.from(state.accountId).toString(
          "base64"
        )}&v=${state.vipType}`;
        let redirectUrl = encodeURIComponent(`${appUrl}/m/vip_pay/${param}`);
        const wxUrl = (window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc520e71a0a38fde5&redirect_uri=" +
          redirectUrl +
          "&response_type=code&scope=snsapi_base&state=123&connect_redirect=1#wechat_redirect");
        window.location.href = wxUrl;
      }
    } else {
      // 已经有code参数，直接调用微信接口
      jumpWeixin(route.query.code);
    }
  } else if (/AlipayClient/.test(window.navigator.userAgent)) {
    jumpAlipay();
  } else {
    // todo 处理同时非支付宝和微信扫码时的case
    console.log("not weixin and alipay: ua=" + window.navigator.userAgent);
    state.payStatus = 2;
  }
};

onMounted(() => {
  document.title = "购买StrongHire VIP";
  const query = route.query;
  console.log("params", query);
  if (query) {
    state.accountId = Buffer.from(query.a ?? "", "base64").toString();
    console.log("accountId", state.accountId);
    state.vipType = query.v;
    const payResult = query.payResult;
    if (payResult) {
      state.payStatus = parseInt(payResult);
      if (state.payStatus === 1) {
        document.title = "添加专属求职导师";
      }
    } else {
      initData();
    }
  } else {
    router.push("/m/home");
  }
});
</script>

<style lang="scss" scoped>
.mobile_pay {
  width: 100vw;
  height: 100vh;
  background-color: #30313a;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

  & > img {
    margin-top: calc(0.1 * 100vh);
    width: 200px;
    height: 39px;
  }
  & > p {
    margin-top: 5.3px;
    span {
      font-size: 12px;
      line-height: 17px;
      color: white;
      &:nth-child(2) {
        color: #e47470;
      }
    }
  }
  .mobile_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;

    .title {
      font-size: 25px;
      line-height: 35px;
      background: linear-gradient(90deg, #fed8b1 0%, #d1a17b 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 500;
    }

    .qr-code {
      width: 200px;
      height: 200px;
      margin-top: 40px;
    }

    .tips {
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      margin-top: 10px;
    }
  }
  .pay_not_success {
    margin-top: 70px;
    font-size: 20px;
    line-height: 28px;
    color: white;
  }
}
</style>