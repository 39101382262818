import axios from 'axios'
import myCookie from "@/apis/cookie";
import { useStore } from 'vuex';

export const SERVER_URL = process.env.VUE_APP_BASEURL;
// export const SERVER_URL = 'https://api.stronghire.cn';

// 全局配置
// axios.defaults.baseURL = ''

function createParams(params) {
    // todo 通用参数添加

    // filter "" null undef
    return filterObject(params)
}

export function createGetApi(url, params, timeout = 30000) {
    const realParams = createParams(params)
    axios.withCredentials = true
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: realParams,
            timeout: timeout,
            withCredentials: true,
            headers: {
                "Authorization": myCookie.getStaffUserToken() ? myCookie.getStaffUserToken() : ""
            }
        }).then((response) => {
            const res = response.data

            if (response.status === 200) {
                if (!res) {
                    reject({ msg: '请求结果为空' })
                } else if (typeof res !== 'object') {
                    reject({ msg: '服务端返回异常' })
                } else {
                    resolve(res)
                }
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {  // BAD REQUEST
                    let res = error.response.data
                    if (res && res.msg) {
                        reject({ msg: res.msg, code: res.code })
                        return;
                    }
                }
            }

            if (!axios.isCancel(error)) {
                reject({ msg: '亲，您的网络不太顺畅哦' })
            }
        })
    })
}

export function createPostApi(url, params, convertParams = true, timeout = 30000) {
    console.log(url, params);
    const realParams = convertParams ? createParams(params) : params;
    axios.withCredentials = true
    return new Promise((resolve, reject) => {
        axios.post(url, realParams, {
            timeout: timeout,
            withCredentials: true,
            headers: {
                "Authorization": myCookie.getStaffUserToken() ? myCookie.getStaffUserToken() : ""
            },
            // onUploadProgress: function (progressEvent) {
            //     console.log(progressEvent);
            //     console.log(progressEvent.loaded);
            //     console.log(progressEvent.total);
            //     console.log(Math.round(progressEvent.loaded / progressEvent.total * 100));
            // }
        }).then((response) => {
            const res = response.data


            if (response.status === 200) {
                if (!res) {
                    reject({ msg: '请求结果为空' })
                } else if (typeof res !== 'object') {
                    reject({ msg: '服务端返回异常' })
                } else {
                    resolve(res)
                }
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {  // BAD REQUEST
                    let res = error.response.data
                    if (res && res.msg) {
                        reject({ msg: res.msg, code: res.code })
                        return;
                    }
                }
            }

            if (!axios.isCancel(error)) {
                reject({ msg: '亲，您的网络不太顺畅哦' })
            }
        })
    })
}

export function filterObject(obj) {
    var param = {}
    if (obj === null || obj === undefined || obj === '') return param
    for (var key in obj) {
        if (dataType(obj[key]) === 'Object') {
            param[key] = filterObject(obj[key])
        } else if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
            param[key] = obj[key]
        }
        // else {
        //     // 防止在提交删除某个信息时，没有提交上去。
        //     param[key] = ''
        // }
    }
    return param
}

function dataType(obj) {
    if (obj === null) return 'Null'
    if (obj === undefined) return 'Undefined'
    return Object.prototype.toString.call(obj).slice(8, -1)
}
