<template>
  <a
    class="homeResumeTemplate auto-exp-component"
    @click="show"
    :data-itemid="resumeId"
    :ip="ip"
    :ua="ua"
    target="_blank"
    :href="`/detail/${props.itemData.id}`"
  >
    <img :src="picUrl" :alt="props.itemData.title" />
    <img
      src="@/assets/img/icon_operate_libs_vip.svg"
      alt=""
      class="vip-tag"
      v-if="isVip"
    />
    <div class="resume-card-cover">
      <p>查看该模板</p>
    </div>
    <div class="resume-card-bottom">
      <div>
        <p>{{ props.itemData.title }}</p>
        <p>{{ props.itemData.totalUseNum }} 人选择</p>
      </div>
      <p>{{ getDesc(props.itemData.labels) }}</p>
    </div>
    <div class="resume-card-mask"></div>
  </a>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, defineProps, onMounted } from "vue";
import analyze from "@/plugin/analyze.js";
import utils from "@/utils/utils.js";
import { useRoute } from "vue-router";

const route = useRoute();

const props = defineProps({
  itemData: {
    title: "",
    totalUseNum: 0,
    labels: [],
    resumeId: "",
    vip: false,
    updateTime: 0,
  },
});

const resumeId = computed(() => props.itemData.resumeId);
const ip = computed(() => localStorage.getItem("extra_ip"));
const ua = computed(() => navigator.userAgent);
const isVip = computed(() => props.itemData.vip);

const getDesc = (desc) => {
  if (desc) {
    return desc.join("/");
  }
  return "";
};

const store = useStore();

const show = () => {
  console.log("show");
  analyze.sendClickEvent("home_template_click", {
    template_id: props.itemData.resumeId,
  });
};

const picUrl = computed(() => {
  var height = 1000;
  if (route.query.height) {
    height = route.query.height;
  }
  return `${utils.getImageUrl(
    props.itemData.previewPicUrl
  )}?x-oss-process=image/resize,h_${height},m_lfit`;
});
</script>

<style lang="scss" scoped>
$card-width: 285px;
$card-height: 422px;

.homeResumeTemplate {
  width: $card-width;
  height: $card-height;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(203, 203, 203, 0.1);
  overflow: hidden;
  position: relative;
  cursor: pointer;

  .vip-tag {
    width: 34px;
    height: 18px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 10px;
      height: 8px;
    }
    span {
      margin-left: 3px;
      font-size: 10px;
      line-height: 18px;
      color: white;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .resume-card-cover {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    top: 0px;
    transition: all 200ms;
    p {
      width: 111px;
      height: 40px;
      position: absolute;
      left: 50%;
      top: 145px;
      transform: translate(-50%);
      background: #f56c6c;
      border-radius: 5px;
      color: white;
      font-size: 14px;
      font-family: PingFang SC;
      line-height: 40px;
      text-align: center;
      visibility: hidden;
      opacity: 0;
      transition: all 200ms;
    }
  }

  .resume-card-mask {
    width: 100%;
    height: 50px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.05) 100%
    );
    position: absolute;
    bottom: 90px;
    left: 0px;
  }

  .resume-card-bottom {
    width: 100%;
    height: 90px;
    bottom: 0px;
    position: absolute;
    background-color: white;
    border-radius: 0px 0px 5px 5px;
    p {
      font-family: PingFang SC;
      font-style: normal;
      margin: 0px;
    }

    div {
      display: flex;
      margin-top: 18px;
      margin-left: 18px;
      margin-right: 18px;
      align-items: baseline;
      p {
        &:nth-child(1) {
          flex: 1;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          text-align: start;
          color: #000000;
          top: 0px;
          left: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:nth-child(2) {
          width: 90px;
          font-weight: normal;
          text-align: right;
          font-size: 12px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    & > p {
      font-weight: normal;
      font-size: 12px;
      width: calc(100% - 40px);
      line-height: 17px;
      color: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 18px;
      bottom: 18px;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:hover .resume-card-cover {
    background-color: rgba($color: #000000, $alpha: 0.3);
    p {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>