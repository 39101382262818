<template>
  <div
    class="edit-modify"
    :style="{
      '--suggestion-visible': suggestionsVisible, // 建议弹窗的visible
    }"
  >
    <div class="edit-content">
      <Transition name="fade" mode="out-in">
        <component :is="currentTabComponent"></component>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import ModifyHead from "@/components/modify/ModifyHead.vue";
import ModifyBasicInfo from "@/components/modify/ModifyBasicInfo.vue";
import ModifyEdu from "@/components/modify/ModifyEdu.vue";
import ModifyJob from "@/components/modify/ModifyJob.vue";
import ModifyProject from "@/components/modify/ModifyProject.vue";
import ModifySchool from "@/components/modify/ModifySchool.vue";
import ModifyCustom from "@/components/modify/ModifyCustom.vue";
import ModifyPersonalSummary from "@/components/modify/ModifyPersonalSummary.vue";
import ModifyEvaluation from "@/components/modify/ModifyEvaluation.vue";
import ModifyHonor from "@/components/modify/ModifyHonor.vue";
import ModifyCollection from "@/components/modify/ModifyCollection.vue";

import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
const store = useStore();

const currentTabComponent = computed(() => {
  const modifyModule = store.state.edit.modifyModule;
  switch (modifyModule) {
    case "basic_info":
      return ModifyBasicInfo;
    case "edu_experience":
      return ModifyEdu;
    case "work_experience":
      return ModifyJob;
    case "project_experience":
      return ModifyProject;
    case "school_experience":
      return ModifySchool;
    case "custom_experience":
      return ModifyCustom;
    case "personal_summary":
      return ModifyPersonalSummary;
    case "awards":
      return ModifyHonor;
    case "portfolios":
      return ModifyCollection;
    case "evaluation":
      return ModifyEvaluation;
    default:
      return null;
  }
});

const suggestionsVisible = computed(() => store.state.suggestionsVisible);
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
.edit-modify {
  box-sizing: border-box;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease 0s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
</style>