<template>
  <div class="resume-recommend">
    <p>推荐模板</p>
    <div>
      <el-image src="https://cdn.stronghire.cn/preview_resume_pic/9J4ZIXq.png?x-oss-process=image/resize,h_1000,m_lfit" alt="" />
      <div>
        <div class="title">
          <span>StrongHire推荐简历模板</span>
          <div>推荐</div>
        </div>
        <p class="desc">
          2022年StrongHire推荐简历模板，有过往工作经历、荣誉奖项、个人总结、自我评价模块，帮你的简历在招聘中脱颖而出
        </p>
        <div class="card">
          <div>
            <img src="@/assets/img/sidebar_logo.svg" />
            <strong> 推荐</strong>
          </div>
          <p>
            该模板是较为标准的求职模板，工作经历中描述的撰写可量化，非常清晰地阐述工作业绩，句式使用合理。结合个人的过往经历定会让你的Offer更上一层楼！
          </p>
        </div>
        <div class="action">
          <div @click="startUse">立即使用</div>
          <div @click="gotoCounseling" v-if="false">在职面试官帮我写</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const gotoCounseling = () => {
  router.push({ name: "counseling" });
};

const startUse = () => {
  router.push({ path: `/preview/1526196462698020866` });
};
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
.resume-recommend {
  width: 100%;
  height: 453px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  @include card-bg;

  & > p {
    font-size: 16px;
    line-height: 22px;
    color: black;
    font-weight: 500;
  }

  & > div {
    flex: 1;
    padding-top: 15px;
    display: flex;
    & > .el-image {
      width: 285px;
      height: 422.02px;
      filter: drop-shadow(0px 0px 5px rgba(203, 203, 203, 0.3));
      margin-left: 0;
    }
    & > div {
      flex: 1;
      margin-left: 20px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    & > span {
      font-size: 18px;
      line-height: 25px;
      font-weight: 500;
      color: black;
    }
    & > div {
      width: 34px;
      height: 18px;
      margin-left: 17px;
      background: linear-gradient(90deg, #f69595 0%, #f66e6e 100%);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      line-height: 14px;
      color: white;
    }
  }

  .desc {
    margin-top: 5px;
    font-size: 16px;
    line-height: 22px;
    color: rgba($color: #000000, $alpha: 0.7);
  }

  .card {
    background: #f9f9f9;
    border-radius: 5px;
    padding: 20px;
    width: 495px;
    margin-top: 23px;

    & > div {
      display: flex;
      align-items: center;
      img {
        width: 81px;
        height: 14px;
      }
      strong {
        margin-left: 2px;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
    }

    & > p {
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
      color: rgba($color: #000000, $alpha: 0.7);
    }
  }

  .action {
    display: flex;
    margin-top: 95px;

    div {
      height: 40px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      border-radius: 4px;
      transition: all 250ms;

      &:nth-child(1) {
        background: #f56c6c;
        color: white;
        width: 96px;
        &:hover {
          background: rgba($color: $primary-color, $alpha: 0.8);
        }
      }

      &:nth-child(2) {
        width: 192px;
        background: #fef0f0;
        border: 1px solid #f56c6c;
        box-sizing: border-box;
        border-radius: 4px;
        color: $primary-color;
        margin-left: 10px;

        &:hover {
          background: rgba($color: #fef0f0, $alpha: 0.8);
        }
      }
    }
  }
}
</style>