<template>
  <div class="editor-container">
    <div class="editor-title">描述</div>
    <div class="editor-parent">
      <QuillEditor
        :content="props.modelValue"
        contentType="html"
        toolbar="#my-toolbar"
        @update:content="onContentChange"
        ref="editor"
        :placeholder="placeholder"
      >
        <template #toolbar>
          <div id="my-toolbar">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="+1"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-link"></button>
          </div>
        </template>
      </QuillEditor>
    </div>
    <div style="height: 20px; width: 100px"></div>
  </div>
</template>

<script setup>
import { onMounted, defineProps, defineEmits, ref, reactive } from "vue";
import { useStore } from "vuex";
import useEventBus from "@/plugin/event";

const eventBus = useEventBus();

const store = useStore();

const editor = ref(null);

const state = reactive({
  value: "",
});

const props = defineProps({
  placeholder: String,
  modelValue: String,
});

const contentChangeFromInit = ref(false);

const emit = defineEmits(["update:modelValue"]);

const onContentChange = (val) => {
  if (val && val !== "" && val !== undefined && val !== "<p>undefined</p>") {
    console.log("contentChange", val);
    if (val === "<p><br></p>") {
      emit("update:modelValue", "");
    } else {
      emit("update:modelValue", val);
    }
    if (!contentChangeFromInit.value) {
      store.commit("updateContentChanged", true);
    }
    contentChangeFromInit.value = false;
  }
};

onMounted(() => {
  contentChangeFromInit.value = true;
  eventBus.on("showModify", (data) => {
    contentChangeFromInit.value = true;
    console.log("strong editor show modify", store.state.edit.edit_object.desc);
    if (store.state.edit.edit_object.desc !== undefined) {
      state.value = store.state.edit.edit_object.desc;
      editor.value.setHTML(store.state.edit.edit_object.desc);
    }
  });
});
</script>

<style lang="scss">
.editor-title {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  line-height: 20px;
}
.ql-editor {
  min-height: 250px;
  max-height: 250px;
  overflow: auto;

  p,
  li {
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 14px;
  }
}

div.ql-editor.ql-blank::before {
  color: #c0c4cc;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

div.ql-container.ql-snow {
  border: none;
}

.editor-container {
  margin-left: 20px;
  margin-right: 20px;
}

.editor-parent {
  border: 0.5px solid #e4e7ed;
  border-radius: 4px;
  margin-top: 10px;
}

#my-toolbar {
  overflow: auto;
  height: 36px;
  padding: 0px;
  display: flex;
  align-items: center;
  background: #f9f9f9;
  border: none;
  border-bottom: 0.5px solid #e4e7ed;
  button {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    padding: 0px;
  }
}

div.ql-tooltip.ql-editing {
  position: absolute;
  left: 100px !important;
}
</style>