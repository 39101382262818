<template>
  <div class="blog_home_may_like" v-if="state.data.length > 0">
    <div class="blog_list">
      <a
          class="blog_item"
          v-for="(item, index) in state.data"
          :key="item.id"
          target="_blank"
          :href="`/job_strategy_detail/${item.id}`"
        >
          <p class="title">
            {{ item.title }}
          </p>
          <div class="middle">
            <el-image :src="utils.getImageUrl(item.coverPicUrl)" />
            <div class="right">
              <p>
                {{ item.description }}
              </p>
              <div class="right_bottom">
                <el-avatar :src="item.author.avatarUrl" />
                <p>{{ item.author.name }}</p>
                <img src="@/assets/img/icon_blog_list_view_count.svg" alt="" />
                <p>{{ item.readBaseNum + item.readNum }}</p>
              </div>
            </div>
            <div
              class="bottom_line"
              :style="{
                width: `${index % 2 === 0 ? 'calc(100% + 40px)' : '100%'}`,
              }"
              v-show="index !== state.data.length - 1 && index !== state.data.length - 2"
            ></div>
          </div>
        </a>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch, defineProps, computed } from "vue-demi";
import blogApi from "@/apis/blog_api";
import { ElMessage } from "element-plus/lib/components";
import { useRoute, useRouter } from "vue-router";
import utils from "@/utils/utils.js";

const router = useRouter();
const route = useRoute();

const state = reactive({
  data: [],
});

watch(
  () => route.params.id,
  (val, oldVal) => {
    console.log("blog-relative id change", val, oldVal);
    requestData();
  }
);


const requestData = async () => {
  console.log("requestData route name", route.name);
  if (route.name === "template") {
    try {
      const maxCount = 10;
      const result = await blogApi.getRecommendList(maxCount, "");
      if (result.code === 0 && result.data) {
        state.data = result.data;
      } else {
        ElMessage.error(result.msg);
      }
    } catch (error) {
      ElMessage.error(error.message);
      console.log(error);
    }
  }
};

onMounted(() => {
  requestData();
});

</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.blog_home_may_like {
  @include card-bg;
  box-sizing: border-box;
  padding: 20px;
  width: $content-width;
  padding-bottom: 15px;
  padding-bottom: 0px;
  margin-top: 40px;
}

.blog_list {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.blog_item {
  display: flex;
  height: 164px;
  width: 560px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  flex-direction: column;

  .title {
    font-size: 17px;
    line-height: 24px;
    color: #252933;
    font-weight: 500;
  }

  .middle {
    display: flex;
    height: 90px;
    width: 100%;
    margin-top: 10px;
    position: relative;

    & > .el-image {
      width: 144px;
      height: 100%;
      border-radius: 5px;
    }

    & > .right {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 20px;
      position: relative;

      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        font-size: 14px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: 400;
      }
    }

    .bottom_line {
      height: 1px;
      background-color: rgba(0, 0, 0, 0.05);
      position: absolute;
      bottom: -20px;
      left: 0;
    }
  }
}

.right_bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;

  .el-avatar {
    width: 19px;
    height: 19px;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    margin-left: 2px;
    color: rgba(0, 0, 0, 0.7);
  }

  img {
    margin-left: 15px;
  }
}
</style>