<template>
  <div class="modify-edu-info">
    <el-form
      label-position="top"
      :model="edit_object"
      size="large"
      :inline="true"
      label-width="1000px"
      :rules="rules"
    >
      <el-form-item label="学校" prop="school" class="edu-school">
        <template #label>
          <div class="school-label">
            <span class="school-title">学校</span>
            <el-checkbox
              v-model="showSchoolLabels"
              label="显示标签"
              size="large"
            />
          </div>
        </template>
        <AutoComplete
          v-model="school"
          type="University"
          placeholder="请输入学校"
          @onChange="onSchoolChange"
        />
      </el-form-item>
      <el-form-item label="专业">
        <AutoComplete v-model="major" type="Major" placeholder="请输入专业" />
      </el-form-item>
      <el-form-item label="学历" prop="education">
        <el-select v-model="education" placeholder="请选择" clearable>
          <el-option
            v-for="(select, index) in state.eduBgSelect"
            :key="index"
            :label="select.label"
            :value="select.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="&nbsp;" prop="eduBg">
        <el-select v-model="educationType" placeholder="请选择" clearable>
          <el-option
            v-for="(select, index) in state.eduBgSelect1"
            :key="index"
            :label="select.label"
            :value="select.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在城市">
        <AutoComplete v-model="city" type="City" placeholder="请输入城市" />
      </el-form-item>
      <el-form-item label="学院">
        <AutoComplete
          v-model="college"
          type="College"
          placeholder="请输入学院"
        />
      </el-form-item>
      <el-form-item label="起止时间">
        <el-date-picker
          v-model="start_time"
          type="month"
          placeholder="请选择"
          :editable="false"
          format="YYYY-MM"
          value-format="YYYY-MM"
          @change="onStartTimeChange"
        >
        </el-date-picker>
      </el-form-item>
      <span class="edu-split">至</span>
      <el-form-item label="&nbsp;" class="edu-end-time" prop="endTime">
        <el-date-picker
          v-model="end_time"
          type="month"
          placeholder="请选择"
          :editable="false"
          format="YYYY-MM"
          value-format="YYYY-MM"
          :shortcuts="shortcuts"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <StrongEditor
      v-model="desc"
      placeholder="可输入与目标岗位相关的课程、课程成绩等信息"
    ></StrongEditor>
  </div>
</template> 

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import {
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
} from "@vue/runtime-core";
import { useStore } from "vuex";
import utils from "@/utils/utils.js";

const store = useStore();

const tagViewVisible = ref("none");

const shortcuts = [
  {
    text: "至今",
    value: new Date(),
  },
];

const edit_object = computed(() => store.state.edit.edit_object);

const school = computed({
  get: () => store.state.edit.edit_object.school,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { school: val });
  },
});

const onSchoolChange = (val) => {
  console.log("onSchoolChange", val);
  if (val && val.labels && val.labels.length > 0) {
    tagViewVisible.value = "flex";
    labels.value = val.labels;
  } else {
    tagViewVisible.value = "none";
    labels.value = [];
  }
};

const showSchoolLabels = computed({
  get: () => {
    const result = store.state.edit.edit_object.showSchoolLabels;
    return result;
  },
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { showSchoolLabels: val });
  },
});

const labels = computed({
  get: () => store.state.edit.edit_object.schoolLabels,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { schoolLabels: val });
  },
});

const city = computed({
  get: () => store.state.edit.edit_object.city,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { city: val });
  },
});

const major = computed({
  get: () => store.state.edit.edit_object.major,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { major: val });
  },
});

const college = computed({
  get: () => store.state.edit.edit_object.college,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { college: val });
  },
});

const education = computed({
  get: () => {
    if (store.state.edit.edit_object.education === 0) {
      return "";
    } else {
      return store.state.edit.edit_object.education;
    }
  },
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { education: val ? val : 0 });
  },
});

const educationType = computed({
  get: () => {
    if (store.state.edit.edit_object.educationType === 0) {
      return "";
    } else {
      return store.state.edit.edit_object.educationType;
    }
  },
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", {
      educationType: val ? val : 0,
    });
  },
});

const desc = computed({
  get: () => {
    return store.state.edit.edit_object.desc;
  },
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { desc: val });
  },
});

const start_time = computed({
  get: () => store.state.edit.edit_object.start,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", {
      start: val === null ? "" : val,
    });
    // 结束日期，自动添加1年
    if (val !== null) {
      // 获取开始时间的年，月
      let year = new Date(val).getFullYear();
      var addYear = 4;
      if (education.value === 9) {
        addYear = 5;
      } else if (education.value === 6 || education.value === 4) {
        addYear = 3;
      }
      let targetDate = new Date(year + addYear, 5);
      var targetDateStr = `${targetDate.getFullYear()}-06`;
      if (targetDate.getTime() >= new Date().getTime()) {
        targetDateStr = utils.formatDateTime2(new Date().getTime());
      }
      end_time.value = targetDateStr;
    }
  },
});

const end_time = computed({
  get: () => store.state.edit.edit_object.end,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", {
      end: val === null ? "" : val,
      stillActive: utils.formatDateTime2(new Date().getTime()) === val,
    });
  },
});

const state = reactive({
  eduBgSelect: [
    { label: "初中", value: 1 },
    { label: "中专", value: 2 },
    { label: "高中", value: 3 },
    { label: "大专", value: 4 },
    { label: "本科", value: 5 },
    { label: "硕士", value: 6 },
    { label: "EMBA", value: 7 },
    { label: "MBA", value: 8 },
    { label: "博士", value: 9 },
  ].reverse(),
  eduBgSelect1: [
    {
      label: "全日制",
      value: 1,
    },
    {
      label: "非全日制",
      value: 2,
    },
    {
      label: "交换/交流",
      value: 3,
    },
  ],
});

onMounted(() => {
  if (showSchoolLabels.value) {
    tagViewVisible.value = "flex";
  } else {
    tagViewVisible.value = "none";
  }
});

const rules = reactive({
  // school: [
  //   {
  //     required: true,
  //     message: "请输入学校名称",
  //     trigger: "blur",
  //   },
  // ],
  // education: [
  //   {
  //     required: true,
  //     message: "请选择学历",
  //     trigger: "change",
  //   },
  // ],
  // endTime: [
  //   {
  //     message: "结束时间需大于开始时间",
  //     trigger: "blur",
  //     validator: (rule, value) => {
  //       console.log("validator", start_time.value, value, end_time.value);
  //       if (
  //         start_time.value !== "" &&
  //         start_time.value !== null &&
  //         end_time.value !== "" &&
  //         end_time.value !== null
  //       ) {
  //         const startInt = parseInt(start_time.value.replace("-", ""));
  //         const valueInt = parseInt(end_time.value.replace("-", ""));
  //         console.log("validator", startInt, valueInt);
  //         return valueInt > startInt;
  //       }
  //       return true;
  //     },
  //   },
  // ],
});
const onStartTimeChange = (val) => {
  console.log("start time change: ", val);
  if (val !== "" && val !== null) {
    const startYear = parseInt(val.split("-")[0]);
    const startMonth = val.split("-")[1];
    if (end_time.value === "" || end_time.value === null) {
      console.log(startYear, startMonth);
      end_time.value = `${startYear + 3}-${startMonth}`;
      console.log(end_time.value);
    } else {
      const endInt = parseInt(end_time.value.replace("-", ""));
      const valueInt = parseInt(val.replace("-", ""));
      if (endInt <= valueInt) {
        const endMonth = end_time.value.split("-")[1];
        end_time.value = `${startYear + 3}-${endMonth}`;
      }
    }
  }
};
</script>

<style lang="scss">
@import "src/style/base.scss";

.modify-edu-info {
  .el-input {
    margin-top: -6px;
    width: var(--form-width);
    .el-input__validateIcon {
      display: none;
    }
  }
  .el-select {
    width: var(--form-width);
  }
  .el-form-item {
    width: var(--form-width);
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: 16px;
    &:nth-child(9) {
      margin-left: 0px;
    }
  }
  .edu-split {
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 14px;
    margin-top: 48px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .el-form-item__label {
    display: flex;
    position: relative;

    .el-checkbox {
      position: absolute;
      right: 0px;
      top: -5px;
    }
  }

  .school-label {
    display: flex;
    align-items: center;
    .el-checkbox {
      display: v-bind(tagViewVisible);
      align-items: center;
      margin-top: 1.5px;
    }
  }
}
</style>