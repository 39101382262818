import {createGetApi, createPostApi, SERVER_URL} from './network'

const STUDY_URL = SERVER_URL + '/api/study';

export default {
    getOpenid: function (authCode = "", source) {
        return createPostApi(`${STUDY_URL}/getOpenid`, {
            authCode,
            source
        })
    },
    createOder: function (openid = "") {
        return createPostApi(`${STUDY_URL}/createOrder`, {
            openid
        })
    },
    createWapOder: function () {
        return createPostApi(`${STUDY_URL}/createWapOrder`)
    },
    createOder66: function (openid = "") {
        return createPostApi(`${STUDY_URL}/createOrder66`, {
            openid
        })
    },

    createOderDouble: function (openid = "") {
        return createPostApi(`${STUDY_URL}/createOrderDouble`, {
            openid
        })
    },

    queryOfficialUserState: function (code) {
        return createGetApi(`${STUDY_URL}/queryOfficialUserState`, {
            code
        })
    },
}
