<template>
  <el-container class="account-container">
    <div class="vip-header">
      <div class="header">
        <img
          id="img_logo"
          src="@/assets/img/icon_login_logo.svg"
          @click="onLogoClick"
        />

        <div class="el-menu-demo">
          <div
            class="tab-item"
            v-for="(tabName, index) in state.tabs"
            :key="index"
            :index="'' + (index + 1)"
            @click="onSelect('' + (index + 1))"
          >
            {{ tabName }}
          </div>
        </div>

        <Avatar></Avatar>
      </div>
    </div>

    <el-main>
      <router-view v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
          <component :is="Component"></component>
        </Transition>
      </router-view>
    </el-main>

    <el-footer>
      <div class="footer">
        <el-space wrap :size="30">
          <el-link type="info" @click="onLinkUsClick">联系我们</el-link>
          <el-link type="info">关于我们</el-link>
          <el-link type="info" href="/agreement">用户协议</el-link>
          <el-link type="info" href="/privacy">隐私政策</el-link>
          <el-link type="info" href="/vip_service">会员服务协议</el-link>
        </el-space>
        <el-space wrap spacer="|" :size="15">
          <span>©2022 StrongHire</span>
          <el-link type="info" href="http://beian.miit.gov.cn/" target="_blank"
            >苏ICP备2022001160号-1</el-link
          >
          <div class="gov">
            <img src="@/assets/img/icon_gov.png" />
            <el-link
              type="info"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011402011116"
              target="_blank"
              >苏公网安备 32011402011116号</el-link
            >
          </div>
          <el-link type="info" href="/license_center">证照中心</el-link>
        </el-space>
      </div>
    </el-footer>
  </el-container>
</template>

<script setup>
//引入vue方法
import { useStore } from "vuex";
import { reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import VipCards from "@/components/buy_vip/VipCards";
import Avatar from "@/components/common/Avatar";

const router = useRouter();
const route = useRoute();
const store = useStore();

const state = reactive({
  activeIndex: "1",
  tabs: ["我的简历", "简历模板", "求职辅导", "求职攻略"],
});

const onSelect = (index) => {
  if (index === "1") {
    router.push("/resume");
  } else if (index === "2") {
    router.push("/");
  } else if (index === "3") {
    router.push("/counseling");
  } else if (index === "4") {
    router.push("/job_strategy/latest");
    // router.push({ name: "calendar" });
  }
};

const onLinkUsClick = () => {
  store.commit("updateAddWechatDialog", {
    show: true,
    type: 1,
  });
};

const onLogoClick = () => {
  router.push("/");
};
</script>

<style scope lang="scss">
@import "src/style/base.scss";

.account-container {
  background-color: $account-vip-bg-color;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .vip-header {
    height: $header-height;
    width: 100%;
    z-index: 100;
    background-color: $account-vip-bg-color;
    background-color: transparent;
    position: relative;
    .header {
      @include content-layout;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    #img_logo {
      width: 133px;
      height: 26px;
      cursor: pointer;
    }
  }

  .el-menu-demo {
    height: $header-height;
    border: 0;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .tab-item {
      width: 104px;
      font-size: 16px;
      color: white;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: $header-height;
      position: relative;
      font-weight: normal;
    }
  }

  .el-main {
    flex: 1;
    margin: 0;
  }

  .el-footer {
    height: 100px;
    .gov {
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
      .el-link {
        margin-top: 2px;
        margin-left: 2px;
      }
    }
  }

  .footer {
    @include content-layout;
    display: flex;
    flex-direction: column;
    .el-link {
      color: rgba(255, 255, 255, 0.35);
      font-weight: normal;
      font-size: 12px;
    }
    .el-space {
      margin-top: 15px;
      span {
        color: rgba(255, 255, 255, 0.35);
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease 0s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
</style>