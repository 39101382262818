<template>
  <div class="operate-analyze">
    <div class="analyze-card">
      <div class="analyze-score-container">
        <span><CountTo :end="score" :autoPlay="true" :duration="2000" /></span>
        <span>分</span>
      </div>
      <div class="analyze-button" @click="onAnalyzeClick">智能检测</div>
      <div class="analyze-desc">{{ desc }}</div>
      <img src="@/assets/img/icon_analyze_card.svg" alt="" />
    </div>
    <div class="operate-collapse" v-if="data.length > 0">
      <el-collapse>
        <el-collapse-item
          v-for="(parentItem, parentIndex) in data"
          :key="parentIndex"
        >
          <template #title>
            <div class="collapse-title">
              <img
                :src="getIcon(parentItem.groupModule)"
                :alt="parentItem.groupModule"
              />
              <span>{{ getTitle(parentItem.groupModule) }}</span>
            </div>
          </template>
          <div
            v-for="(item, index) in parentItem.list"
            :key="index"
            class="collapse-item"
          >
            {{ item.msg }}
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, readonly, ref } from "vue";
import editApi from "@/apis/edit_api";
import { useRoute } from "vue-router";
import editMix from "@/plugin/edit";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import CountTo from "../common/CountTo.vue";
import analyze from "@/plugin/analyze.js";
import vipMix from "@/plugin/vip";

const { onVipToast } = vipMix();
const { getTitle, onBackDeal } = editMix();

const route = useRoute();
const store = useStore();

const state = reactive({
  result: {
    score: 0,
    listMsg: [],
    isLoading: false,
  },
  msg: "",
  code: 0,
  loadFinish: true,
  animateFinish: false,
  polling: null,
});

const onAnalyzeClick = async () => {
  analyze.sendClickEvent("edit_analyze_click");
  const hasLogin = store.getters.hasLogin;
  if (!hasLogin) {
    // 如果没有登录，需要先登录
    if (await onBackDeal(false)) {
      store.commit("updateLoginDialog", {
        showLoginDialog: true,
        from: "智能监测",
      });
    }
    return;
  }

  if (route.name === "preview") {
    state.loadFinish = false;
    state.animateFinish = true;
    state.result = {
      isLoading: false,
      score:
        score.value !== undefined && score.value !== null ? score.value : 0,
      listMsg:
        store.state.edit.analyzeData.listMsg === undefined
          ? []
          : store.state.edit.analyzeData.listMsg,
    };
    store.commit("setShowAnalyzeAnimation", "block");
    setTimeout(() => {
      onLoadFinish();
    }, 2000);
    return;
  }

  try {
    // ? 简历诊断
    if (state.loadFinish === false) {
      return;
    }
    state.polling = setTimeout(() => {
      state.animateFinish = true;
      onLoadFinish();
    }, 2000);
    store.commit("setShowAnalyzeAnimation", "block");
    state.loadFinish = false;
    state.animateFinish = false;
    state.result = { isLoading: true };
    const result = await editApi.diagnosis(
      route.params.id,
      route.query.type === "template_resume"
    );
    if (result && result.code === 0) {
      state.result.score = result.data.score;
      state.result.listMsg = result.data.listMsg;
      state.result.isLoading = false;
      onLoadFinish();
    } else {
      state.result = { isLoading: false };
      state.msg = result.msg;
      state.code = result.code;
      onLoadFinish();
    }
  } catch ({ msg: msg }) {
    state.result = { isLoading: false };
    state.msg = msg;
    state.code = -1;
    onLoadFinish();
  }
};

const score = computed(() => {
  if (store.state.edit.analyzeData.score !== undefined) {
    return store.state.edit.analyzeData.score;
  } else {
    return 0;
  }
});
const data = computed(() => {
  if (store.state.edit.analyzeData.listMsg) {
    return groupArr(store.state.edit.analyzeData.listMsg, "module");
  } else {
    return [];
  }
});
const desc = computed(() => {
  const name = store.state.edit.contentModules.basicInfo?.name;
  var name1 = "";
  var name2 = "";
  if (name) {
    name1 = `${name}`;
    name2 = `${name},`;
  }
  console.log("type of", typeof score.value);
  if (typeof score.value === "number") {
    if (score.value === 0) {
      return `简历填写完成后点击按钮进行AI检测~${name1}`;
    } else if (score.value <= 20) {
      return `当前简历急需优化，fighting~${name1}`;
    } else if (score.value <= 50) {
      return `加油${name1}，你的简历可以更好！`;
    } else if (score.value <= 80) {
      return "简历评分超过85分，更容易获得面试邀约~";
    } else {
      return `${name2}你的简历已经非常棒了！恭喜你`;
    }
  } else {
    return "简历填写完成后点击按钮进行AI检测~";
  }
});

const onLoadFinish = () => {
  console.log("onLoadFinish--start", state.loadFinish, state.animateFinish);
  // ? 如果数据没加载完成，或者动画没有执行接结束都不结束
  if (
    state.animateFinish == true &&
    state.result.isLoading === false &&
    state.loadFinish === false
  ) {
    console.log("onLoadFinish--end", state.result);
    if (state.result.score !== null && state.result.score !== undefined) {
      store.commit("setAnalyzeData", state.result);
      ElMessage.success("智能检测成功");
    } else {
      if (state.code === 4 || state.code === 5) {
        onVipToast(state.msg);
      } else {
        ElMessage.error(state.msg);
      }
    }
    store.commit("setShowAnalyzeAnimation", "none");
    state.loadFinish = true;
  }
};

const groupArr = (list, field) => {
  var fieldList = new Set(),
    att = [];
  list.map((e) => {
    fieldList.add(e[field]);
  });
  for (let item of fieldList) {
    var arr = list.filter((e) => {
      return e[field] == item;
    });
    att.push({
      groupModule: item,
      list: arr,
    });
  }
  console.log(att);
  return att;
};

onMounted(() => {});

const getIcon = (module) => {
  switch (module) {
    case "basic_info":
      return require("@/assets/img/icon_add_basic_info.svg");
    case "work_experience":
      return require("@/assets/img/icon_add_work.svg");
    case "edu_experience":
      return require("@/assets/img/icon_add_edu.svg");
    case "project_experience":
      return require("@/assets/img/icon_add_project.svg");
    case "school_experience":
      return require("@/assets/img/icon_add_school.svg");
    case "awards":
      return require("@/assets/img/icon_add_awards.svg");
    case "portfolios":
      return require("@/assets/img/icon_add_portfolios.svg");
    case "custom_experience":
      return require("@/assets/img/icon_add_custom.svg");
    case "personal_summary":
      return require("@/assets/img/icon_add_personal.svg");
    case "evaluation":
      return require("@/assets/img/icon_add_evaluate.svg");
    default:
      return "";
  }
};
</script>

<style lang="scss">
@import "src/style/base.scss";

.operate-analyze {
  width: $operate-width;
}

.el-collapse-item {
}

.el-collapse {
  border: 1px solid white;
}

.el-collapse-item__content {
  padding: 0px;
}

.el-collapse-item__header {
  border: 1px solid white;
}

.collapse-item {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 10px;

  &:nth-child(n + 2) {
    margin-top: 5px;
  }
}

.collapse-title {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
  span {
    font-size: 14px;
    line-height: 20px;
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.7);
  }
}

.analyze-card {
  background: linear-gradient(107.21deg, #ffffff 48.2%, #fbe4e4 100%);
  border: 0.5px solid #fbe4e4;
  box-shadow: 0px 0px 5px rgba(203, 203, 203, 0.2);
  border-radius: 5px;
  width: 264px;
  height: 128px;
  margin: auto;
  position: relative;

  img {
    width: 40px;
    height: 50px;
    right: 11px;
    position: absolute;
    bottom: 4px;
  }

  .analyze-score-container {
    position: absolute;
    top: 6px;
    left: 18px;
    display: flex;
    align-items: flex-end;

    span {
      color: $primary-color;

      &:nth-child(1) {
        font-weight: 900;
        font-size: 40px;
        line-height: 56px;
      }
      &:nth-child(2) {
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 12px;
        margin-left: 2px;
      }
    }
  }
  .analyze-button {
    position: absolute;
    width: 84px;
    height: 32px;
    background: #f56c6c;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    line-height: 32px;
    color: white;
    right: 18px;
    top: 18px;
    transition: all 300ms;
    cursor: pointer;
    &:hover {
      background: rgba($color: $primary-color, $alpha: 0.8);
    }
  }

  .analyze-desc {
    position: absolute;
    bottom: 18px;
    left: 18px;
    width: 170px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
  }
}

.operate-collapse {
  margin-top: 0px;
  margin-left: 18px;
  margin-right: 18px;
}

[id^="el-collapse-head"] {
  border: none;
}
[id^="el-collapse-content-"] {
  border: none;
}
</style>