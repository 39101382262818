import { createGetApi, createPostApi, SERVER_URL } from './network'

const BLOG_URL = SERVER_URL + '/api/blog';

export default {
    listByPage(page, category, limit) {
        return createGetApi(`${BLOG_URL}/listByPage`, { page, category, limit })
    },
    getDetail(id, hasRecommend) {
        return createGetApi(`${BLOG_URL}/getDetail`, { id })
    },
    getRecommendList(count, category) {
        return createGetApi(`${BLOG_URL}/getRecommendList`, { count, category })
    },
    read(id) {
        return createPostApi(`${BLOG_URL}/read`, { id })
    },
}