<template>
  <div class="edit-operate">
    <div class="operate-tabs">
      <div
        v-for="(item, index) in tabContent"
        :key="index"
        @click="updateTabIndex(index)"
        :class="{ 'select': state.currentIndex !== index }"
      >
        <img :src="state.tabIcons[index]" alt="" />
        <span :style="{ color: state.tabTextColor[index], fontWeight: state.fontWeight[index] }">{{ item }}</span>
      </div>
    </div>

    <div class="operate-content">
      <Transition name="fade" mode="out-in">
        <component :is="currentTabComponent"></component>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import OperateAnalyze from "@/components/edit/OperateAnalyze.vue";
import OperateStyle from "@/components/edit/OperateStyle.vue";
import OperateLibs from "@/components/edit/OperateLibs.vue";
import OperateAddModule from "@/components/edit/OperateAddModule.vue";
import analyze from "@/plugin/analyze.js";

const { reactive, ref, computed } = require("@vue/reactivity");
const { onMounted } = require("@vue/runtime-core");

const tabContent = ["简历诊断", "排版格式", "模板库", "添加模块"];

const tabIconArray = [
  [
    require("@/assets/img/icon_resume_analyze.svg"),
    require("@/assets/img/icon_resume_analyze_select.svg"),
  ],
  [
    require("@/assets/img/icon_layout_style.svg"),
    require("@/assets/img/icon_layout_style_select.svg"),
  ],
  [
    require("@/assets/img/icon_module_lib.svg"),
    require("@/assets/img/icon_module_lib_select.svg"),
  ],
  [
    require("@/assets/img/icon_add_module.svg"),
    require("@/assets/img/icon_add_module_select.svg"),
  ],
];

const currentTabComponent = computed(() => {
  if (state.currentIndex === 0) {
    return OperateAnalyze;
  } else if (state.currentIndex === 1) {
    return OperateStyle;
  } else if (state.currentIndex === 2) {
    return OperateLibs;
  } else {
    return OperateAddModule;
  }
});
const state = reactive({
  tabIcons: ["", "", "", ""],
  tabTextColor: ["", "", "", ""],
  fontWeight: ["", "", "", ""],
  currentIndex: 0,
});

const updateTabIndex = (index) => {
  state.currentIndex = index;
  for (let index = 0; index < 4; index++) {
    if (state.currentIndex === index) {
      state.tabIcons[index] = tabIconArray[index][1];
      state.tabTextColor[index] = "#F56C6C";
      state.fontWeight[index] = 700;
    } else {
      state.tabIcons[index] = tabIconArray[index][0];
      state.tabTextColor[index] = "#000000B2";
      state.fontWeight[index] = "normal";
    }
  }
  analyze.sendClickEvent("edit_switch_tool_tab", {
    value: tabContent[index],
  });
};

onMounted(() => {
  console.log("edit-operate mouted!");
  updateTabIndex(0);
});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
::-webkit-scrollbar {
  display: none;
}
.operate-tabs {
  display: flex;
  justify-content: space-around;
  padding: 18px;
  padding-bottom: 20px;
  box-sizing: border-box;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: all 250ms;
    img {
      width: 22px;
      height: 22px;
    }
    span {
      font-size: 12px;
      line-height: 17px;
      transition: all 250ms;
    }
  }
  .select {
    &:hover {
      span {
        font-weight: 700;
      }
    }
  }
}
.operate-content {
  width: 100%;
  height: var(--operate-height);
  overflow: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease 0s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
</style>