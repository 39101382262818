<template>
  <div class="modify-basic-info">
    <div style="margin-left: 20px">个人信息</div>
    <div style="height: 14px"></div>
    <el-form
      label-position="top"
      :model="basicInfo1"
      size="large"
      :inline="true"
      label-width="1000px"
      :rules="rules"
    >
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model="name"
          placeholder="如: 陈圆圆Cherry"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="电话" class="basic-info-phone" prop="phone">
        <CountryCode
          v-model="country_code"
          placeholder="如: +86"
          class="country-code"
        />
        <el-input
          v-model="phone"
          placeholder="如: 136-6666-6666"
          class="basic-info-phone-input"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input
          v-model="email"
          placeholder="如: Ch@stronghire.cn"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="现居地">
        <AutoComplete
          v-model="liveCity"
          placeholder="如: 北京"
          class="live-city"
          type="City"
        ></AutoComplete>
      </el-form-item>
      <el-form-item label="出生日期/年龄" class="basic-form-birth">
        <template #label>
          <div class="basic-form-label">
            <span class="basic-form-title">出生日期/年龄</span>
            <div>
              <el-radio v-model="birthType" :label="0">年龄</el-radio>
              <el-radio v-model="birthType" :label="1">生日</el-radio>
            </div>
          </div>
        </template>
        <el-date-picker
          v-model="birth_value"
          type="month"
          placeholder="请选择"
          :editable="false"
          format="YYYY-MM"
          value-format="YYYY-MM"
          :disabled-date="disabledDate"
          :default-value="defaultBirthValue"
        >
        </el-date-picker>
        <span class="basic-birth-desc">{{ birthDesc }}</span>
      </el-form-item>

      <el-form-item label="民族">
        <el-input v-model="nation" placeholder="如: 汉族" clearable></el-input>
      </el-form-item>
      <el-form-item
        v-for="data in dynamicShowData"
        :key="data.id"
        :label="data.label"
        :prop="data.prop"
        class="dynamic-item"
        :data-id="data.id"
      >
        <el-select
          v-model="data.value"
          placeholder="请选择"
          clearable
          v-if="data.id === 1"
          @change="onChange(data)"
        >
          <el-option
            v-for="(select, index) in data.selectData"
            :key="index"
            :label="select.label"
            :value="select.value"
          ></el-option>
        </el-select>

        <AutoComplete
          v-model="data.value"
          :placeholder="data.eg"
          type="City"
          v-else-if="data.id === 2"
          @update:modelValue="onChange(data)"
        />

        <el-input
          v-model="data.value"
          v-else-if="data.id === 5"
          :type="data.type"
          :placeholder="data.eg"
          @input="onChange(data)"
          clearable
        >
          <template #append>cm</template></el-input
        >

        <el-input
          v-model="data.value"
          v-else-if="data.id === 6"
          :type="data.type"
          :placeholder="data.eg"
          @input="onChange(data)"
          clearable
        >
          <template #append>kg</template></el-input
        >

        <el-input
          v-model="data.value"
          v-else
          :type="data.type"
          :placeholder="data.eg"
          @input="onChange(data)"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item
        v-for="data in extraListShowData"
        :key="data.id"
        :label="data.label"
        :prop="data.prop"
        class="dynamic-item"
        :data-id="data.id"
      >
        <el-input
          v-model="data.value"
          :type="data.type"
          :placeholder="data.eg"
          @input="onExtraChange(data)"
          clearable
        ></el-input>
      </el-form-item>

      <ModifyBasicPhoto></ModifyBasicPhoto>

      <div class="basic-div-job">求职意愿</div>
      <div style="height: 14px; display: block; width: 440px"></div>
      <el-form-item label="当前状态">
        <el-select v-model="jobHuntStatus" placeholder="请选择" clearable>
          <el-option
            v-for="(select, index) in basicInfo.jobStatus"
            :key="index"
            :label="select.label"
            :value="select.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="目标岗位">
        <AutoComplete
          v-model="targetJob"
          placeholder="如: 产品经理"
          type="Job"
        ></AutoComplete>
      </el-form-item>
      <el-form-item label="目标城市">
        <AutoComplete
          v-model="targetCity"
          placeholder="如: 北京"
          type="City"
        ></AutoComplete>
      </el-form-item>
      <el-form-item label="期望工资">
        <div class="basic-expect-pay">
          <el-select v-model="targetSalaryMin" placeholder="最低" clearable>
            <el-option
              v-for="(select, index) in minSelectArray"
              :key="index"
              :label="select.label"
              :value="select.label"
              :disabled="select.disabled"
            ></el-option>
          </el-select>
          <span>至</span>
          <el-select v-model="targetSalaryMax" placeholder="最高" clearable>
            <el-option
              v-for="(select, index) in maxSelectArray"
              :key="index"
              :label="select.label"
              :value="select.label"
              :disabled="select.disabled"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>

    <div class="basic-info-add-tag">
      <div
        v-for="data in dynamicHideData"
        :key="data.id"
        class="basic-tag-item"
        @click="onHideTagClick(data)"
      >
        <img src="@/assets/img/icon_basic_add.svg" />
        <span>{{ data.label }}</span>
      </div>
      <div
        class="basic-tag-item"
        v-show="extraListShowData.length < 3"
        @click="onExtraTagClick"
      >
        <img src="@/assets/img/icon_basic_add.svg" />
        <span>自定义</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import ModifyBasicPhoto from "@/components/modify/ModifyBasicPhoto.vue";
import { onBeforeMount, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import CountryCode from "../common/CountryCode.vue";

const store = useStore();

const basicInfo1 = computed(() => store.state.edit.contentModules.basicInfo);

const country_code = computed({
  get: () => {
    if (store.state.edit.contentModules.basicInfo.phoneCountryCode) {
      return store.state.edit.contentModules.basicInfo.phoneCountryCode;
    } else {
      return "";
    }
  },
  set: (val) => {
    if (val) {
      store.commit("updateBasicInfo", { phoneCountryCode: val });
    } else {
      store.commit("updateBasicInfo", { phoneCountryCode: "" });
    }
  },
});

const select = ref("");

const defaultBirthValue = computed(() => {
  const currentDate = new Date();
  // 获取去年的 date
  return new Date(currentDate.getTime() - 1000 * 60 * 60 * 24 * 365 * 20);
});

const name = computed({
  get: () => store.state.edit.contentModules.basicInfo.name,
  set: (val) => {
    store.commit("updateBasicInfo", { name: val });
  },
});

const phone = computed({
  get: () => store.state.edit.contentModules.basicInfo.phone,
  set: (val) => {
    store.commit("updateBasicInfo", { phone: val });
  },
});

const email = computed({
  get: () => store.state.edit.contentModules.basicInfo.email,
  set: (val) => {
    store.commit("updateBasicInfo", { email: val });
  },
});

const liveCity = computed({
  get: () => store.state.edit.contentModules.basicInfo.liveCity,
  set: (val) => {
    store.commit("updateBasicInfo", { liveCity: val });
  },
});

const targetCity = computed({
  get: () => store.state.edit.contentModules.basicInfo.targetCity,
  set: (val) => {
    store.commit("updateBasicInfo", { targetCity: val });
  },
});

const nation = computed({
  get: () => store.state.edit.contentModules.basicInfo.nation,
  set: (val) => {
    store.commit("updateBasicInfo", { nation: val });
  },
});

const birthType = computed({
  get: () => store.state.edit.contentModules.basicInfo.birthType,
  set: (val) => {
    store.commit("updateBasicInfo", { birthType: val });
  },
});

const birth_value = computed({
  get: () => store.state.edit.contentModules.basicInfo.birth,
  set: (val) => {
    store.commit("updateBasicInfo", { birth: val });
  },
});

const jobHuntStatus = computed({
  get: () => {
    if (store.state.edit.contentModules.basicInfo.jobHuntStatus === 0) {
      return null;
    } else {
      return store.state.edit.contentModules.basicInfo.jobHuntStatus;
    }
  },
  set: (val) => {
    store.commit("updateBasicInfo", { jobHuntStatus: val });
  },
});

const targetJob = computed({
  get: () => store.state.edit.contentModules.basicInfo.targetJob,
  set: (val) => {
    store.commit("updateBasicInfo", { targetJob: val });
  },
});

const targetSalaryMin = computed({
  get: () => {
    const min = store.state.edit.contentModules.basicInfo.targetSalaryMin;
    if (min) {
      return min + "k";
    } else {
      null;
    }
  },
  set: (val) => {
    if (val) {
      store.commit("updateBasicInfo", {
        targetSalaryMin: parseInt(val.replace("k", "")),
      });
    } else {
      store.commit("updateBasicInfo", { targetSalaryMin: null });
    }
  },
});

const targetSalaryMax = computed({
  get: () => {
    const max = store.state.edit.contentModules.basicInfo.targetSalaryMax;
    if (max) {
      return max + "k";
    } else {
      null;
    }
  },
  set: (val) => {
    if (val) {
      store.commit("updateBasicInfo", {
        targetSalaryMax: parseInt(val.replace("k", "")),
      });
    } else {
      store.commit("updateBasicInfo", { targetSalaryMax: null });
    }
  },
});

const extraList = computed({
  get: () => {
    if (store.state.edit.contentModules.basicInfo.extraList) {
      return store.state.edit.contentModules.basicInfo.extraList;
    } else {
      return [];
    }
  },
  set: (val) => {
    store.commit("updateBasicInfo", { extraList: val });
  },
});

const basicInfo = reactive({
  jobStatus: [
    { label: "应届生", value: 1 },
    { label: "随时到岗", value: 2 },
    { label: "在职，月内到岗", value: 3 },
    { label: "在职，考虑机会", value: 4 },
  ],
  dynamicFormData: [
    {
      prop: "sex",
      value: "",
      label: "性别",
      show: false,
      id: 1,
      selectData: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
      ],
    },
    {
      prop: "nativePlace",
      value: "",
      label: "籍贯",
      show: false,
      id: 2,
      eg: "如: 北京",
      type: "text",
    },
    {
      prop: "political",
      value: "",
      label: "政治面貌",
      show: false,
      id: 3,
      eg: "如: 群众",
      type: "text",
    },
    {
      prop: "marriage",
      value: "",
      label: "婚姻状况",
      show: false,
      id: 4,
      eg: "如: 已婚",
      type: "text",
    },
    {
      prop: "height",
      value: "",
      label: "身高",
      show: false,
      id: 5,
      eg: "如: 180 cm",
      type: "number",
    },
    {
      prop: "weight",
      value: "",
      label: "体重",
      show: false,
      id: 6,
      eg: "如: 60 kg",
      type: "number",
    },
  ],
  extraListData: [
    {
      prop: "custom1",
      value: "",
      label: "自定义",
      show: false,
      id: 11,
      eg: "自定义",
      type: "text",
    },
    {
      prop: "custom1",
      value: "",
      label: "自定义",
      show: false,
      id: 12,
      eg: "自定义",
      type: "text",
    },
    {
      prop: "custom1",
      value: "",
      label: "自定义",
      show: false,
      id: 13,
      eg: "自定义",
      type: "text",
    },
  ],
});

const dynamicShowData = computed(() => {
  return basicInfo.dynamicFormData.filter((value) => value.show);
});

const dynamicHideData = computed(() => {
  return basicInfo.dynamicFormData.filter((value) => !value.show);
});

const extraListShowData = computed(() => {
  return basicInfo.extraListData.filter((value) => value.show);
});

const showItemBounceAnimation = (id) => {
  if (id) {
    setTimeout(() => {
      const dynamicShowEles = document.getElementsByClassName("dynamic-item");
      for (let i = 0; i < dynamicShowEles.length; i++) {
        let ele = dynamicShowEles[i];
        const dataId = ele.getAttribute("data-id");
        console.log("dataId", dataId);
        if (dataId === `${id}`) {
          const modifyEle = document.querySelector(".edit-modify-without-head");
          if (ele) {
            modifyEle.scrollTop = ele.offsetTop;
            ele.classList.add("bounceIn");
            console.log("add bounceIn class");
            setTimeout(() => {
              ele.classList.remove("bounceIn");
            }, 3000);
          }
        }
      }
    }, 200);
  }
};

const onHideTagClick = (data) => {
  data.show = true;
  showItemBounceAnimation(data.id);
  console.log("onHideTagClick", data.id);
};

const onExtraTagClick = () => {
  var id = "";
  if (!basicInfo.extraListData[0].show) {
    basicInfo.extraListData[0].show = true;
    id = basicInfo.extraListData[0].id;
  } else if (!basicInfo.extraListData[1].show) {
    basicInfo.extraListData[1].show = true;
    id = basicInfo.extraListData[1].id;
  } else if (!basicInfo.extraListData[2].show) {
    basicInfo.extraListData[2].show = true;
    id = basicInfo.extraListData[2].id;
  }
  showItemBounceAnimation(id);
};

const birthDesc = computed(() => {
  console.log("birth value: ", birth_value.value, birthType.value);
  if (birth_value.value === "" || !birth_value.value) {
    return "请选择";
  }
  var result = "";
  if (birthType.value === 0) {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const targetYear = parseInt(birth_value.value.split("-")[0]);
    const targetMonth = parseInt(birth_value.value.split("-")[1]);
    if (targetMonth <= currentMonth) {
      result = `${currentYear - targetYear} 岁`;
    } else {
      result = `${currentYear - targetYear - 1} 岁`;
    }
  } else {
    result = birth_value.value;
  }
  return result;
});
const getInitSelectArray = (start, end) => {
  const array = new Array();
  for (let index = 0; index < 100; index++) {
    var disabled = false;
    if (index >= start && index <= end) {
      disabled = false;
    } else {
      disabled = true;
    }
    array[index] = {
      label: `${index + 1}k`,
      disabled: disabled,
    };
  }
  return array;
};
const minSelectArray = computed(() => {
  console.log("lalal", targetSalaryMax.value);
  if (!targetSalaryMax.value) {
    return getInitSelectArray(0, 100);
  } else {
    const maxValue = parseInt(targetSalaryMax.value.split("k")[0]);
    return getInitSelectArray(0, maxValue - 1);
  }
});
const maxSelectArray = computed(() => {
  if (!targetSalaryMin.value) {
    return getInitSelectArray(0, 100);
  } else {
    const minValue = parseInt(targetSalaryMin.value.split("k")[0]);
    return getInitSelectArray(minValue - 1, 100);
  }
});
const disabledDate = (time) => {
  return time.getTime() > Date.now();
};

const rules = reactive({
  // name: [
  //   {
  //     required: true,
  //     message: "请输入姓名",
  //     trigger: "blur",
  //   },
  // ],
  // phone: [
  //   {
  //     required: true,
  //     message: "请输入电话号码",
  //     trigger: "blur",
  //   },
  // ],
  // email: [
  //   {
  //     required: true,
  //     message: "请输入邮箱",
  //     trigger: "blur",
  //   },
  //   {
  //     type: "email",
  //     message: "请输入正确的邮箱",
  //     trigger: ["blur", "change"],
  //   },
  // ],
});

const onChange = (val) => {
  if (val.id === 1) {
    store.commit("updateBasicInfo", { gender: val.value ? val.value : 0 });
  } else if (val.id === 2) {
    store.commit("updateBasicInfo", { nativeCity: val.value });
  } else if (val.id === 3) {
    store.commit("updateBasicInfo", { politicalStatus: val.value });
  } else if (val.id === 4) {
    store.commit("updateBasicInfo", { maritalStatus: val.value });
  } else if (val.id === 5) {
    store.commit("updateBasicInfo", { bodyHeight: val.value });
  } else if (val.id === 6) {
    store.commit("updateBasicInfo", { bodyWeight: val.value });
  }
};

const onExtraChange = (val) => {
  const result = basicInfo.extraListData
    .filter(
      (value) =>
        value.value !== "" && value.value !== null && value.value !== undefined
    )
    .map((value) => {
      return value.value;
    });
  extraList.value.splice(0, extraList.value.length, ...result);
  store.commit("updateBasicInfo", { extraList: result });
  console.log("on extra change", extraList.value);
};

onMounted(() => {
  if (store.state.edit.contentModules.basicInfo.gender) {
    basicInfo.dynamicFormData[0].show = true;
    basicInfo.dynamicFormData[0].value =
      store.state.edit.contentModules.basicInfo.gender;
  }
  if (store.state.edit.contentModules.basicInfo.nativeCity) {
    basicInfo.dynamicFormData[1].show = true;
    basicInfo.dynamicFormData[1].value =
      store.state.edit.contentModules.basicInfo.nativeCity;
  }
  if (store.state.edit.contentModules.basicInfo.politicalStatus) {
    basicInfo.dynamicFormData[2].show = true;
    basicInfo.dynamicFormData[2].value =
      store.state.edit.contentModules.basicInfo.politicalStatus;
  }
  if (store.state.edit.contentModules.basicInfo.maritalStatus) {
    basicInfo.dynamicFormData[3].show = true;
    basicInfo.dynamicFormData[3].value =
      store.state.edit.contentModules.basicInfo.maritalStatus;
  }
  if (store.state.edit.contentModules.basicInfo.bodyHeight) {
    basicInfo.dynamicFormData[4].show = true;
    basicInfo.dynamicFormData[4].value =
      store.state.edit.contentModules.basicInfo.bodyHeight;
  }
  if (store.state.edit.contentModules.basicInfo.bodyWeight) {
    basicInfo.dynamicFormData[5].show = true;
    basicInfo.dynamicFormData[5].value =
      store.state.edit.contentModules.basicInfo.bodyWeight;
  }
  if (store.state.edit.contentModules.basicInfo.extraList) {
    var index = 0;
    store.state.edit.contentModules.basicInfo.extraList.forEach((value) => {
      if (index <= 2) {
        basicInfo.extraListData[index].show = true;
        basicInfo.extraListData[index].value = value;
        index++;
      }
    });
  }
});
</script>

<style lang="scss">
@import "src/style/base.scss";

.modify-basic-info {
  .el-input {
    margin-top: -6px;
    width: var(--form-width);
    .el-input__validateIcon {
      display: none;
    }
  }
  .el-select {
    width: var(--form-width);
  }
  .el-form-item {
    width: var(--form-width);
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .el-form-item__label,
  basic-form-title {
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 14px;
  }
  .basic-form-birth {
    position: relative;
    .basic-birth-desc {
      position: absolute;
      bottom: 2px;
      left: 36px;
      padding-left: 4px;
      width: 100px;
      height: 34px;
      color: rgba($color: #000000, $alpha: 0.7);
      font-size: 14px;
      background-color: white;
      pointer-events: none;
    }
    .basic-form-label {
      display: flex;
      align-items: center;
      height: 32px;
      span {
        flex: 1;
      }

      .el-radio {
        &:nth-child(1) {
          margin-right: 10px;
        }
      }
    }
  }
}
.el-date-editor {
  width: var(--form-width);
}

.basic-expect-pay {
  margin-top: -2px;
  .el-select,
  .el-input {
    width: 108px;
  }
  span {
    padding-left: 5px;
    padding-right: 5px;
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 14px;
  }
}

.basic-info-add-tag {
  $basic-add-tag-width: cal(var(--modify-width) - 20px * 2);
  width: $basic-add-tag-width;
  min-width: $basic-add-tag-width;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;

  .basic-tag-item {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    background: #e4e7ed;
    opacity: 0.5;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms;
    margin-right: 10px;
    margin-bottom: 10px;
    img {
      width: 14px;
      height: 14px;
    }
    span {
      margin-left: 2px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #000;
    }
    &:hover {
      background: #cdcfd4;
    }
  }
}

.basic-div-job {
  margin-left: 20px;
  width: 100%;
  min-width: var(--modify-width);
  margin-top: 20px;
}

.live-city {
  transform: translateY(-2px);
}

.basic-info-phone {
  display: flex;
  .country-code {
    width: 80px;
    transform: translateY(-2px);
    .el-input {
      width: 80px;
    }
  }
  .basic-info-phone-input {
    flex: 1;
    margin-left: 10px;
    transform: translateY(-1px);
  }
}

.bounceIn {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
</style>