<template>
  <div class="blog_resume_template">
    <p class="title">简历模板</p>
    <div class="tempate_list">
      <a v-for="item in data" :key="item.id" class="template_item" :href="item.href" target="_blank">
        <img :src="item.img" class="avatar" />
        <p class="item_title">{{ item.title }}</p>
        <p class="item_desc">{{ item.useDesc }}</p>
      </a>
    </div>
  </div>
</template>

<script setup>
const data = [
  {
    id: 1,
    title: "实习生简历模板",
    useDesc: "10.3万人已用",
    img: require("@/assets/img/icon_blog_resume_template_avatar1.svg"),
    href: "/search/shixi",
  },
  {
    id: 2,
    title: "校招简历模板",
    useDesc: "25.9万人已用",
    img: require("@/assets/img/icon_blog_resume_template_avatar2.svg"),
    href: "/search/xiaozhao",
  },
  {
    id: 3,
    title: "社招简历模板",
    useDesc: "29.8万人已用",
    img: require("@/assets/img/icon_blog_resume_template_avatar3.svg"),
    href: "/search/shezhao",
  },
];
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.blog_resume_template {
  width: 300px;
  height: 236px;
  @include card-bg;
  box-sizing: border-box;
  padding-top: 20px;

  .title {
    padding-left: 20px;
    font-size: 16px;
    line-height: 22px;
    color: #252933;
    font-weight: 500;
  }
}

.tempate_list {
  margin-top: 19px;
}

.template_item {
  display: flex;
  align-items: center;
  transition: all 250ms;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  height: 56px;
  cursor: pointer;
  .avatar {
    width: 30px;
    height: 30px;
  }
  .item_title {
    flex: 1;
    font-size: 14px;
    line-height: 20px;
    color: #252933;
    font-weight: 500;
    margin-left: 10px;
  }
  .item_desc {
    font-size: 12px;
    line-height: 17px;
    color: #8a919f;
    font-weight: 300;
  }

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
}
</style>