<template>
  <div class="modify-personal-info">
    <el-form
      label-position="top"
      :model="personalSummary"
      size="large"
      :inline="true"
      label-width="1000px"
    >
      <el-form-item label="擅长技能">
        <el-input
          v-model="skill"
          placeholder="如：CAD，Photoshop，AE，Figma等"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="职业证书">
        <el-input
          v-model="certificate"
          placeholder="如：CFA三级"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="语言等级">
        <el-input
          v-model="languageLevel"
          placeholder="如：英语CET-4，英语雅思6.5等"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="兴趣爱好">
        <el-input
          v-model="hobby"
          placeholder="如：写作，公众号运营，美食等"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { onMounted } from "@vue/runtime-core";


const store = useStore();

const skill = computed({
  get: () => store.state.edit.contentModules.personalSummary.skill,
  set: (val) => {
    store.commit("updatePersonalSummary", { skill: val });
  },
});
const certificate = computed({
  get: () => store.state.edit.contentModules.personalSummary.certificate,
  set: (val) => {
    store.commit("updatePersonalSummary", { certificate: val });
  },
});
const languageLevel = computed({
  get: () => store.state.edit.contentModules.personalSummary.languageLevel,
  set: (val) => {
    store.commit("updatePersonalSummary", { languageLevel: val });
  },
});
const hobby = computed({
  get: () => store.state.edit.contentModules.personalSummary.hobby,
  set: (val) => {
    store.commit("updatePersonalSummary", { hobby: val });
  },
});

const personalSummary = computed(() => store.state.edit.contentModules.personalSummary);

</script>

<style lang="scss">
@import "src/style/base.scss";

.modify-personal-info {
  .el-input {
    margin-top: -6px;
    width: calc(var(--form-width) * 2);
    .el-input__validateIcon {
      display: none;
    }
  }
  .el-select {
    width: calc(var(--form-width) * 2);
  }
  .el-form-item {
    width: calc(var(--form-width) * 2);
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: 16px;
    &:nth-child(4) {
      margin-bottom: 25px;
    }
  }
}
</style>