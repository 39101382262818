<template>
  <el-main class="mobile_home_main">
    <div class="top_bar">
      <img src="@/assets/img/icon_login_logo.svg" alt="logo" class="logo" />
      <img src="@/assets/img/icon_m_home_bg.svg" alt="" class="bg" />
      <div class="title_container">
        <p>大厂必备的</p>
        <p>在线简历制作工具</p>
      </div>
      <div class="title_container_small">
        <p>大厂必备的在线简历制作工具</p>
        <p>stronghire.cn</p>
      </div>
      <p class="action" @click="onActionClick">立即在线制作</p>
    </div>
    <div class="top_stub"></div>

    <div class="template_list">
      <div
        class="template_item auto-exp-component_mobile"
        v-for="item in state.templateData"
        :key="item.id"
        @click="onItemClick(item)"
        :data-itemid="item.id"
        :ip="ip"
        :ua="ua"
      >
        <img :src="getUrl(item.previewPicUrl)" alt="" />
        <div class="resume-card-bottom">
          <div>
            <p>{{ item.title }}</p>
            <p>{{ item.totalUseNum }} 人选择</p>
          </div>
          <p>{{ getDesc(item.labels) }}</p>
        </div>
        <div class="resume-card-mask"></div>
      </div>
    </div>
    <div
      class="loading"
      v-loading="state.isLoading"
      element-loading-background="rgba(255, 255, 255, 0)"
      v-show="state.isLoading"
    ></div>
    <img id="img_logo" src="@/assets/img/sidebar_logo.svg" />
  </el-main>
</template>

<script setup>
import { computed, isRef, reactive, ref } from "@vue/reactivity";
import { onMounted, onUnmounted } from "@vue/runtime-core";
import homeApi from "@/apis/home_api";
import { ElMessage } from "element-plus";
import utils from "@/utils/utils.js";
import { useRouter } from "vue-router";
import analyze from "@/plugin/analyze.js";

const router = useRouter();

const state = reactive({
  isLoading: false,
  hasNoMoreData: false,
  currentPage: 1,
  templateData: [],
});

const topBarHeight = ref("220px");
const smallWidgetOpacity = ref(0);
const bigWidgetOpacity = ref(1);
const ip = computed(() => localStorage.getItem('extra_ip'));
const ua = computed(() => navigator.userAgent);

const width = ref(document.documentElement.clientWidth);

const handleResize = (event) => {
  console.log("handleResize");
  width.value = document.documentElement.clientWidth;
};

const itemWidth = computed(() => {
  return width.value - 25 * 2;
});

const itemWidthPx = computed(() => {
  return itemWidth.value + "px";
});

const itemHeightPx = computed(() => {
  console.log("itemWidth", itemWidth.value);
  return `${itemWidth.value * 1.44}px`;
});

const getUrl = (url) => {
  return `${utils.getImageUrl(url)}?x-oss-process=image/resize,h_1000,m_lfit`;
};

const getDesc = (desc) => {
  if (desc) {
    return desc.join("/");
  }
  return "";
};

const requestData = async (isRefresh = true) => {
  try {
    if (state.isLoading || state.hasNoMoreData) {
      console.log("requestData", state.isLoading, state.hasNoMoreData);
      return;
    }
    if (isRefresh) {
      state.currentPage = 1;
    } else {
      state.currentPage++;
    }
    state.isLoading = true;
    const result = await homeApi.getTemplateData(state.currentPage, 8, "", "");
    if (result.code === 0) {
      if (result.data.record.length === 0) {
        state.hasNoMoreData = true;
      } else {
        if (isRefresh) {
          state.templateData = result.data.record;
        } else {
          state.templateData = [...state.templateData, ...result.data.record];
        }
      }
      refreshTitle(result.data.keyword);
    } else {
      ElMessage.error(result.msg);
    }
    state.isLoading = false;
  } catch ({ msg: msg }) {
    state.isLoading = false;
    ElMessage.error(msg);
  }
};

const refreshTitle = (keyword) => {
  if (keyword) {
    document.title = keyword.title;
    document
      .querySelector("meta[name='Keywords']")
      .setAttribute("content", keyword.keywords);
    document
      .querySelector("meta[name='Description']")
      .setAttribute("content", keyword.desc);
  }
};

const onActionClick = () => {
  if (bigWidgetOpacity.value >= 0.1) {
    router.push({ name: "mobileGuide" });
    analyze.sendClickEvent("mobile_template_banner_use_click");
  }
};

const onItemClick = (data) => {
  console.log("onItemClick", data.id);
  router.push({ name: "mobileDetail", params: { id: data.id } });
  analyze.sendClickEvent("mobile_home_template_click", {
    template_id: data.id,
  });
};

const onScrollChange = (event) => {
  const offsetY = Math.max(0, window.pageYOffset);
  // 228 -> 64
  const topBarHeightValue = Math.max(64, 228 - offsetY);
  topBarHeight.value = `${topBarHeightValue}px`;
  smallWidgetOpacity.value = Math.min(1, offsetY / 64);
  bigWidgetOpacity.value = 1 - smallWidgetOpacity.value;
  console.log("onScrollChange", offsetY);
  // 判断滑动到底部
  if (offsetY + window.innerHeight >= document.body.scrollHeight - 5) {
    console.log("滑动到底部");
    requestData(false);
  }
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
  requestData();
  window.addEventListener("scroll", onScrollChange);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
  window.removeEventListener("scroll", onScrollChange);
});
</script>

<style lang="scss" scoped>
.el-main {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.top_bar {
  width: 100vw;
  height: v-bind(topBarHeight);
  background-color: #f56c6c;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;

  .logo {
    width: 156px;
    height: 25px;
    margin-top: 20px;
    margin-left: 20px;
  }
  .bg {
    width: 117px;
    height: 80px;
    position: absolute;
    right: 25px;
    top: 45px;
    opacity: v-bind(bigWidgetOpacity);
  }
  .title_container {
    margin-top: 24px;
    opacity: v-bind(bigWidgetOpacity);
    p {
      font-size: 20px;
      color: white;
      margin-left: 20px;
      line-height: 25px;
    }
  }
  .title_container_small {
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 12px;
    line-height: 17px;
    color: white;
    font-weight: 400;
    opacity: v-bind(smallWidgetOpacity);
  }
  .action {
    width: 268px;
    height: 45px;
    background-color: white;
    color: #f56c6c;
    margin-top: 25px;
    font-size: 16px;
    text-align: center;
    line-height: 45px;
    border-radius: 50px;
    align-self: center;
    cursor: pointer;
    opacity: v-bind(bigWidgetOpacity);
  }
}

.top_stub {
  width: 100vw;
  height: 225px;
}

.template_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  .template_item {
    width: v-bind(itemWidthPx);
    height: v-bind(itemHeightPx);
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(203, 203, 203, 0.1);
    overflow: hidden;
    margin-top: 20px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .resume-card-bottom {
      width: 100%;
      height: 90px;
      bottom: 0px;
      position: absolute;
      background-color: white;
      border-radius: 0px 0px 5px 5px;
      p {
        font-family: PingFang SC;
        font-style: normal;
        margin: 0px;
      }

      div {
        display: flex;
        margin-top: 18px;
        margin-left: 18px;
        margin-right: 18px;
        align-items: baseline;
        p {
          &:nth-child(1) {
            flex: 1;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            text-align: start;
            color: #000000;
            top: 0px;
            left: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:nth-child(2) {
            width: 90px;
            font-weight: normal;
            text-align: right;
            font-size: 12px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
      & > p {
        font-weight: normal;
        font-size: 12px;
        width: calc(100% - 40px);
        line-height: 17px;
        color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 18px;
        bottom: 18px;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .resume-card-mask {
      width: 100%;
      height: 50px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.05) 100%
      );
      position: absolute;
      bottom: 90px;
      left: 0px;
    }
  }
}

.loading {
  margin-top: 20px;
  height: 40px;
  width: 100vw;
}

#img_logo {
  width: 136px;
  height: 25px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>