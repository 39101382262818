<template>
  <div id="search">
    <div class="search-bar">
      <div class="seach-bar-input">
        <img src="@/assets/img/icon_search.svg" alt="" />
        <input
          v-model="searchKey"
          placeholder="请输入搜索关键字"
          autofocus
          @keyup.enter="onSearchClick"
          ref="searchRef"
        />
      </div>
      <el-button type="danger" @click="onSearchClick">搜索</el-button>
    </div>
    <div class="seach-title" v-if="showTitle">
      <span>以下是“</span>
      <span>{{ state.searchTitleKey }}</span>
      <span>”的搜索结果</span>
    </div>
    <div class="search-no-data" v-if="state.hasNodata">
      <img src="@/assets/img/icon_no_search_result.svg" alt="" />
      <span>未搜索到结果</span>
      <span>推荐您以下简历模板</span>
    </div>
    <TemplateList
      :keyword="searchKeyword"
      @updateNoData="updateNoData"
    ></TemplateList>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import TemplateList from "../components/template/TemplateList.vue";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import analyze from "@/plugin/analyze.js";

const route = useRoute();
const router = useRouter();

const searchKey = ref("");
const searchRef = ref(null);
const searchKeyword = ref("");

const state = reactive({
  searchTitleKey: "",
  hasNodata: false,
});

const updateNoData = (value) => {
  console.log("updateNoData", value);
  state.hasNodata = value;
};

const onSearchClick = () => {
  console.log("search click", searchKey.value, state.searchTitleKey);
  if (state.searchTitleKey !== searchKey.value) {
    state.searchTitleKey = searchKey.value;
    doSearch(searchKey.value);
    analyze.sendClickEvent("custom_key_word", {
      value: searchKey.value,
    });
  }
};

const doSearch = async (searchKeyParam) => {
  console.log("do search!");
  searchKeyword.value = searchKeyParam;
  router.replace({ path: "/search", query: { key: searchKeyParam } });
};

const showTitle = computed(() => {
  console.log("showTitle", state.searchTitleKey);
  return (
    !state.hasNodata && state.searchTitleKey && state.searchTitleKey !== ""
  );
});

onMounted(() => {
  state.searchTitleKey = route.query.key;
  searchKey.value = route.query.key;
  searchKeyword.value = route.query.key;
  searchRef.value.select();
});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

#search {
  @include content-layout;
}

.search-bar {
  width: 100%;
  height: 66px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  .el-button {
    margin-left: 15px;
  }
  .seach-bar-icon {
    width: 13px;
    height: 14px;
  }

  .seach-bar-input {
    flex: 1;
    height: 36px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding-right: 18px;
    img {
      width: 13px;
      height: 14px;
      margin-left: 11px;
    }
    input {
      color: rgba(0, 0, 0, 0.7);
      text-decoration: none;
      height: 32px;
      width: 100%;
      margin-left: 7px;
      border: 0;
      background-color: transparent;
      outline: none;
      font-size: 14px;
    }
    input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgba(0, 0, 0, 0.2);
      font-size: 14px;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 ~ 18 */
      color: rgba(0, 0, 0, 0.2);
      opacity: 1;
      font-size: 14px;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(0, 0, 0, 0.2);
      opacity: 1;
      font-size: 14px;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10 ~ 11 */
      color: rgba(0, 0, 0, 0.2);
      font-size: 14px;
    }
    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(0, 0, 0, 0.2);
      font-size: 14px;
    }

    input::placeholder {
      /* 大部分现代浏览器 */
      color: rgba(0, 0, 0, 0.2);
      font-size: 14px;
    }
  }
}

.search-no-data {
  width: 100%;
  height: 322px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 115px;
    height: 142px;
  }
  span {
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);

    &:nth-child(3) {
      position: absolute;
      font-weight: bold;
      bottom: 0;
      left: 0;
    }
  }
}

.seach-title {
  margin-top: 15px;
  span {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
    &:nth-child(2) {
      color: $primary-color;
    }
  }
}

.search-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .homeResumeTemplate {
    &:nth-child(n) {
      margin-top: 20px;
    }
    &:nth-child(n + 4) {
      margin-top: 20px;
    }
  }
}

.search-pagination {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>