<template>
  <div style="background-color: #FFFFFF">
  </div>
</template>

<script setup>
import {onMounted} from "@vue/runtime-core";

onMounted(() => {
  document.title = "「学习打卡群」打卡机器人升级啦~";
  window.location.replace(`https://mp.weixin.qq.com/s?__biz=Mzg3NDcyMzQ4Mw==&mid=2247487512&idx=1&sn=f2c97df48ec77fbccd0d7f89e4a34581&chksm=cecd31ecf9bab8fa3a39cf319f7d4146ee744e4a9d84c87122ce801e527b0f7889bcaae2902e#rd`)
});

</script>
