<template>
  <div class="mobile_title_bar">
    <img src="@/assets/img/icon_login_logo.svg" alt="logo" class="logo" />
    <div class="title_container_small">
      <p>大厂必备的在线简历制作工具</p>
      <p>stronghire.cn</p>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.mobile_title_bar {
  width: 100vw;
  height: 64px;
  background-color: #f56c6c;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  .logo {
    width: 156px;
    height: 25px;
    margin-top: 20px;
    margin-left: 20px;
  }
  .title_container_small {
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 12px;
    line-height: 17px;
    color: white;
    font-weight: 400;
  }
}
</style>