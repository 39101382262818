<template>
  <div class="preview-edu">
    <PreviewModuleHolder
      class="preview-edu-container moudle-left-right"
      :mainId="props.moduleId"
    >
      <div class="module-gap-div"></div>
      <PreviewTitle
        moduleType="edu_experience"
        :moduleId="props.moduleId"
      ></PreviewTitle>
      <p
        class="preview-empty"
        v-if="checkModuleEmpty(eduExperience, 'edu_experience')"
        @click="onEmptyClick"
      >
        介绍个人学校学历背景及专业
      </p>

      <TransitionGroup tag="ul" name="fade" class="container" v-else>
        <PreviewModuleHolder
          class="preview-edu-item preview-content"
          v-for="(item, index) in eduExperience"
          :class="{
            'preview-sub-module-margin-top': checkAddSubModuleMarginTop(
              index,
              eduExperience,
              'edu_experience'
            ),
          }"
          :key="item.id"
          @click="onItemClick(item)"
          :mainId="props.moduleId"
          :subId="item.id"
          :subIds="eduExperience.map((item) => item.id)"
        >
          <div class="line-1">
            <div>
              <strong class="preview-school">{{ school(item) }}</strong>
              <div v-if="title_style === 1">
                <p v-for="(item, index) in row(item)" :key="index">
                  {{ item }}
                </p>
              </div>
            </div>
            <p>
              {{ getTime(item) }}
            </p>
          </div>
          <div class="line-2" v-if="title_style !== 1">
            <div>
              <p v-for="(item, index) in row(item)" :key="index">{{ item }}</p>
            </div>
            <p>{{ item.city }}</p>
          </div>
          <div v-html="item.desc" class="html-desc"></div>
        </PreviewModuleHolder>
      </TransitionGroup>

      <!-- <div class="module-gap-div"></div> -->
    </PreviewModuleHolder>
  </div>
</template>

<script setup>
import PreviewTitle from "@/components/preview/PreviewTitle.vue";
import PreviewModuleHolder from "@/components/preview/PreviewModuleHolder.vue";
import editMix from "@/plugin/edit";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { defineProps } from "vue";

const props = defineProps({
  moduleId: String,
});

const store = useStore();

const eduExperience = computed(
  () => store.state.edit.contentModules.eduExperience
);
const title_style = computed(() => store.state.edit.formatInfo.titleStyle);

const school = (item) => {
  if (item.showSchoolLabels && item.schoolLabels && item.schoolLabels.length > 0) {
    return `${item.school} (${item.schoolLabels
      .map((label) => label.toUpperCase().replace(":", ""))
      .join("、")})`;
  } else {
    return item.school;
  }
};

const row = (item) => {
  // 专业 学历 学院
  const rowArray = Array.of(
    item.major,
    getEducation(item.education),
    getEducationType(item.educationType),
    item.college
  );
  return rowArray.filter((v) => v !== "");
};

const getEducation = (education) => {
  if (education) {
    switch (education) {
      case 2:
        return "中专";
      case 3:
        return "高中";
      case 4:
        return "大专";
      case 5:
        return "本科";
      case 6:
        return "硕士";
      case 7:
        return "EMBA";
      case 8:
        return "MBA";
      case 9:
        return "博士";
      default:
        return "初中";
    }
  } else {
    return "";
  }
};

const getEducationType = (educationType) => {
  if (educationType) {
    switch (educationType) {
      case 1:
        return "全日制";
      case 2:
        return "非全日制";
      default:
        return "交换/交流";
    }
  } else {
    return "";
  }
};

const onEmptyClick = () => {
  startModify("edu_experience", null, props.moduleId, true);
};

const onItemClick = (item) => {
  startModify("edu_experience", item, props.moduleId, false);
};

const { startModify, getTime, checkModuleEmpty, checkAddSubModuleMarginTop } =
  editMix();
</script>

<style lang="scss" scoped>
@import "src/style/edit.scss";

.preview-edu {
  @include module-card;
}

.preview-edu-item {
  @include sub-module-card;
  display: flex;
  flex-direction: column;

  .line-2 {
    display: flex;
    & > div {
      display: flex;
      flex: 1;
      p {
        margin-left: 10px;
        &:nth-child(1) {
          margin-left: 0px;
        }
      }
    }
  }

  .line-1 {
    display: flex;
    & > div {
      display: flex;
      flex: 1;
      & > div {
        display: flex;
        & > p {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>