<template>
  <div id="counseling">
    <div class="top">
      <div class="left">
        <p class="title">获得StrongHire</p>
        <p class="title">从一份好的简历开始</p>
        <p class="desc desc1">近80%的企业简历初筛由<span>机器学习</span>完成</p>
        <p class="desc desc2">
          简历复筛时面试官平均<span>每6秒</span>看完一个简历
        </p>
        <p class="desc desc3">
          StrongHire的<span>大厂在职面试官</span>让你的简历通过率至少<span>提升1倍</span>
        </p>
      </div>
      <img src="@/assets/img/icon_counseling_top.svg" alt="" class="right" />
    </div>
    <CounselingMiddle />
    <CounselingMiddle1 />
    <CounselingMiddle2 />
    <p class="bottom_p1">现在，开始优化你的简历吧</p>
    <p class="bottom_p2" @click="onStartClick">立即优化</p>
    <p class="bottom_gap"></p>
  </div>
</template>

<script setup>
import CounselingMiddle from "@/components/counseling/CounselingMiddle";
import CounselingMiddle1 from "@/components/counseling/CounselingMiddle1";
import CounselingMiddle2 from "@/components/counseling/CounselingMiddle2";
import { onMounted } from "vue-demi";
import { useStore } from "vuex";

const store = useStore();

const onStartClick = () => {
  store.commit("updateAddWechatDialog", {
    show: true,
    type: 1,
  });
};

onMounted(() => {
  document.title = "大厂在职面试官简历优化模拟面试辅导-StrongHire简历";
  document
    .querySelector("meta[name='Keywords']")
    .setAttribute(
      "content",
      "简历优化,个人简历优化,简历代写,面试辅导,求职辅导,模拟面试职业规划"
    );
  document
    .querySelector("meta[name='Description']")
    .setAttribute(
      "content",
      "StrongHire简历提供专业高质量简历优化代写及模拟面试辅导服务，导师来自世界500强等互联网、四大在职面试官。StrongHire 求职辅导，你的造梦引擎"
    );
});
</script>

<style scoped lang="scss">
@import "src/style/base.scss";
#counseling {
  width: 1000px;
  display: flex;
  margin: auto;
  flex-direction: column;
}

.top {
  margin-top: 204px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .left {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 40px;
      line-height: 56px;
      color: black;
      font-weight: bold;
    }

    .desc {
      font-size: 16px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.7);

      span {
        color: $primary-color;
      }
    }

    .desc1 {
      margin-top: 40px;
    }

    .desc2 {
      margin-top: 15px;
    }

    .desc3 {
      margin-top: 15px;
    }
  }

  .right {
    width: 383px;
    height: 219px;
  }
}

.counseling_middle {
  margin-top: 200px;
}

.counseling_middle1 {
  margin-top: 200px;
}

.counseling_middle2 {
  margin-top: 200px;
}

.bottom_p1 {
  font-size: 40px;
  line-height: 56px;
  font-weight: bold;
  color: black;
  margin-top: 200px;
  align-self: center;
}

.bottom_p2 {
  width: 250px;
  height: 60px;
  background: $primary-color;
  border-radius: 50px;
  transition: all 250ms;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  line-height: 34px;
  margin-top: 50px;
  align-self: center;

  &:hover {
    background: rgba($color: $primary-color, $alpha: 0.86);
  }
}

.bottom_gap {
  height: 200px;
  width: 100%;
}
</style>