<template>
  <div class="preview-personal">
    <PreviewModuleHolder
      class="preview-personal-container moudle-left-right"
      :mainId="props.moduleId"
      :showAdd="false"
    >
      <div class="module-gap-div"></div>
      <PreviewTitle moduleType="personal_summary" :moduleId="props.moduleId"></PreviewTitle>
      <p
        class="preview-empty"
        v-if="row === null || row.length === 0"
        @click="onEmptyClick"
      >
        总结个人优势，尽可能契合岗位要求
      </p>
      <div class="personal-content" v-else>
        <div
          class="preview-personal-item preview-content"
          v-for="(item) in row"
          :key="item.id"
          @click="onItemClick()"
        >
          <strong>{{ item.title }}</strong>
          <p>{{ item.value }}</p>
        </div>
      </div>
      <!-- <div class="module-gap-div"></div> -->
    </PreviewModuleHolder>
  </div>
</template>

<script setup>
import PreviewTitle from "@/components/preview/PreviewTitle.vue";
import PreviewModuleHolder from "@/components/preview/PreviewModuleHolder.vue";
import editMix from "@/plugin/edit";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { defineProps } from "vue";

const props = defineProps({
  moduleId: String,
});
const store = useStore();

const personalSummary = computed(
  () => store.state.edit.contentModules.personalSummary
);

const checkValueNotEmpty = (v) => {
  return v.value !== "" && v.value !== null && v.value !== undefined;
};

const row = computed(() => {
  const rowArray = Array.of(
    { title: "技能", value: personalSummary.value.skill, id: 1 },
    { title: "证书", value: personalSummary.value.certificate, id: 2 },
    { title: "语言", value: personalSummary.value.languageLevel, id: 3 },
    { title: "爱好", value: personalSummary.value.hobby, id: 4 }
  );
  return rowArray.filter((v) => checkValueNotEmpty(v));
});

const onEmptyClick = () => {
  startModify("personal_summary", null, props.moduleId, true);
};

const onItemClick = () => {
  startModify("personal_summary", personalSummary.value, null, false);
};

const { startModify } = editMix();
</script>

<style lang="scss" scoped>
@import "src/style/edit.scss";

.preview-personal {
  @include module-card;
  overflow: auto;
}

.personal-content {
  @include sub-module-card;
}

.preview-personal-item {
  display: flex;
  strong {
    flex-shrink: 0;
    font-weight: bold;
  }
  p {
    margin-left: 10px;
    word-break: break-all;
  }
}
</style>