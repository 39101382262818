<template>
  <div :class="getModuleClass">
    <slot></slot>
    <div :class="getModuleAcitonClass" v-if="canEdit">
      <div
        v-for="item in acitonData"
        :key="item.id"
        :style="{
          '--item-click': item.click ? 'pointer' : 'not-allowed',
          '--background-color': item.click ? '#f58989' : '#eeacac',
          '--hover-color': item.click ? '#eeacac' : '#eeacac',
        }"
      >
        <div
          v-if="item.show"
          @click.stop="onItemClick(item)"
          class="action-container"
        >
          <img :src="item.img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive } from "@vue/runtime-core";
import { defineProps, h } from "vue";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import useEventBus from "@/plugin/event";
import editMix from "@/plugin/edit";
import { useRoute } from "vue-router";

const { startModify, deleteModule, changeOrder } = editMix();

const eventBus = useEventBus();
const store = useStore();

const props = defineProps({
  mainId: {
    type: String,
  },
  subId: {
    type: String,
  },
  subIds: {
    type: Array,
  },
  showAdd: {
    type: Boolean,
    default: true,
  },
});

const getModuleClass = computed(() => {
  if (props.subId) {
    return {
      subModule: true,
    };
  } else {
    return {
      mainModule: true,
    };
  }
});

const getModuleAcitonClass = computed(() => {
  if (props.subId) {
    return {
      subModuleAction: true,
    };
  } else {
    return {
      mainModuleAction: true,
    };
  }
});

const modules = computed(() => store.state.edit.modules);

const acitonData = computed(() => {
  // 添加 删除 上移 下移

  // 父模块
  const showAdd =
    props.subId === null || props.subId === undefined || props.subId === "";
  const hideAll =
    props.subIds !== null &&
    props.subIds !== undefined &&
    props.subIds.length === 1;
  var upClick = false;
  var downClick = false;
  if (showAdd) {
    const modulesLength = modules.value.length;
    if (modulesLength === 2) {
      upClick = false;
      downClick = false;
    } else if (modulesLength > 2) {
      if (props.mainId === modules.value[1].id) {
        upClick = false;
        downClick = true;
      } else if (props.mainId === modules.value[modulesLength - 1].id) {
        upClick = true;
        downClick = false;
      } else {
        upClick = true;
        downClick = true;
      }
    }
  } else {
    const subModulesLength = props.subIds.length;
    if (subModulesLength === 1) {
      upClick = false;
      downClick = false;
    } else if (subModulesLength > 1) {
      if (props.subId === props.subIds[0]) {
        upClick = false;
        downClick = true;
      } else if (props.subId === props.subIds[subModulesLength - 1]) {
        upClick = true;
        downClick = false;
      } else {
        upClick = true;
        downClick = true;
      }
    }
  }

  return [
    {
      id: 1,
      show: showAdd && props.showAdd && !hideAll,
      click: true,
      img: require("@/assets/img/icon_module_add.svg"),
      desc: "添加",
    },
    {
      id: 2,
      show: !hideAll,
      click: true,
      img: require("@/assets/img/icon_module_delete.svg"),
      desc: "删除",
    },
    {
      id: 3,
      show: !hideAll,
      click: upClick,
      img: require("@/assets/img/icon_module_move_up.svg"),
      desc: "上移",
    },
    {
      id: 4,
      show: !hideAll,
      click: downClick,
      img: require("@/assets/img/icon_module_move_down.svg"),
      desc: "下移",
    },
  ];
});

const onItemClick = (item) => {
  console.log("itemClick", item);
  if (item.click) {
    if (item.id === 2) {
      onDeleteClick();
    } else if (item.id === 3) {
      onUpClick();
    } else if (item.id === 4) {
      onDownClick();
    } else if (item.id === 1) {
      onAddClick();
    }
  }
};

const onAddClick = () => {
  var module = "";
  modules.value.forEach((item) => {
    if (item.id === props.mainId) {
      module = item.module;
    }
  });
  startModify(module, null, props.mainId, false, true);
};

const onUpClick = () => {
  var module = "";
  modules.value.forEach((item) => {
    if (item.id === props.mainId) {
      module = item.module;
    }
  });
  changeOrder({
    moduleId: props.mainId,
    subId: props.subId,
    module: module,
    isUp: true,
  });
};

const onDownClick = () => {
  var module = "";
  modules.value.forEach((item) => {
    if (item.id === props.mainId) {
      module = item.module;
    }
  });
  changeOrder({
    moduleId: props.mainId,
    subId: props.subId,
    module: module,
    isUp: false,
  });
};

const onDeleteClick = () => {
  ElMessageBox({
    title: "删除提示",
    message: h("p", null, [
      h("span", null, "删除模块后"),
      h("span", { style: "color: #F56C6C" }, "将无法找回"),
      h("span", null, "，请确认是否删除"),
    ]),
    showCancelButton: true,
    confirmButtonText: "删除",
    distinguishCancelAndClose: true,
  }).then((action) => {
    if (
      props.subIds !== null &&
      props.subIds !== undefined &&
      props.subIds.length === 1
    ) {
      deleteModule({
        subId: null,
        moduleId: props.mainId,
      });
    } else {
      var module = "";
      modules.value.forEach((item) => {
        if (item.id === props.mainId) {
          module = item.module;
        }
      });
      console.log(modules.value);
      deleteModule({
        moduleId: props.mainId,
        subId: props.subId,
        module: module,
      });
      // ! 如果删除的是子模块或者父模块，则关闭编辑页面
      const moduleId = store.state.edit.editModuleId;
      const subModuleId = store.state.edit.editSubModuleId;
      console.log("deleteClick", moduleId, subModuleId);
      if (
        (moduleId === props.mainId &&
          subModuleId === props.subId &&
          subModuleId) ||
        (moduleId === props.mainId && !props.subId)
      ) {
        eventBus.emit("showModify", { value: false });
      }
    }
  });
};

onMounted(() => {});

const route = useRoute();
const canEdit = computed(
  () => route.query.edit !== "false" && route.name !== "resumePrint"
);
</script>

<style lang="scss" scoped>
@import "src/style/edit.scss";

.mainModule {
  position: relative;
  transition: all 350ms;
  .mainModuleAction {
    visibility: hidden;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    transition: all 250ms;
    opacity: 0;
    div {
      width: 20px;
      height: 20px;
      margin-left: 2px;

      .action-container {
        width: 20px;
        height: 20px;
        background: var(--background-color);
        border-radius: 4px;
        cursor: var(--item-click);
        transition: all 200ms;
        justify-content: center;
        align-items: center;
        display: flex;
        &:hover {
          background: var(--hover-color);
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  &:hover .mainModuleAction {
    visibility: visible;
    opacity: 1;
  }
}
.subModule {
  position: relative;
  transition: all 350ms;
  overflow-y: hidden;
  .subModuleAction {
    visibility: hidden;
    position: absolute;
    right: 10px;
    display: flex;
    transition: all 250ms;
    opacity: 0;
    div {
      width: 20px;
      height: 20px;
      margin-left: 2px;

      .action-container {
        width: 20px;
        height: 20px;
        background: var(--background-color);
        border-radius: 4px;
        cursor: var(--item-click);
        transition: all 200ms;
        justify-content: center;
        align-items: center;
        display: flex;
        &:hover {
          background: var(--hover-color);
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  &:hover .subModuleAction {
    visibility: visible;
    opacity: 1;
  }
}


</style>