<template>
  <div>
    <el-dialog
      v-model="showUploadDialog"
      width="398px"
      top="25vh"
      :destroy-on-close="true"
      :show-close="false"
      @open="onDialogOpen"
      @close="onDialogClose"
    >
      <div class="modal-resume" v-loading="showLoading">
        <div class="modal-import-resume" v-show="!showCropper">
          <p>{{ uploadData.uploadTitle }}</p>
          <el-upload
            class="upload-demo"
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            :accept="getAccept"
            :http-request="handleUpload"
            :exceed="handleUploadExceed"
          >
            <img src="@/assets/img/icon_upload_file.svg" alt="" class="upload_img"/>
            <div class="el-upload__text">
              将文件拖到此处，或 <em>点击上传</em>
            </div>
          </el-upload>
          <p>{{ uploadData.uploadSubTitle }}</p>
          <div class="upload-close" @click="onCloseClick">
            <img src="@/assets/img/icon_upload_close.svg" alt="" />
          </div>
        </div>
        <div class="modal-cropper-resume" v-show="showCropper">
          <p class="title">裁剪证件照</p>
          <vueCropper
            ref="cropper"
            class="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :fixedBox="option.fixedBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :centerBox="option.centerBox"
            :high="option.high"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :infoTrue="option.infoTrue"
            :mode="option.mode"
          ></vueCropper>
          <div class="bottom">
            <span class="cancel" @click="onCancelClick">取消</span>
            <span class="complete" @click="onCompleteClick">完成</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import authApi from "@/apis/auth_api";
import { onActivated, onMounted } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import aliOss from "@/utils/aliOss";
import ossApi from "@/apis/oss_api";
import editApi from "@/apis/edit_api";
import { useRoute, useRouter } from "vue-router";
import editMix from "@/plugin/edit";
import analyze from "@/plugin/analyze.js";

const store = useStore();
const router = useRouter();
const route = useRoute();
const { addResumeData, onSaveClick } = editMix();

const cropper = ref(null);
const showLoading = ref(false);
const showCropper = ref(false);
const imagePath = ref("");
const option = ref({
  filename: "",
  img: "",
  size: 1,
  full: false,
  outputType: "jpeg, png, webp",
  canMove: true,
  fixedBox: false,
  original: false,
  canMoveBox: true,
  autoCrop: true,
  // 只有自动截图开启 宽度高度才生效
  autoCropWidth: 72,
  autoCropHeight: 100,
  centerBox: true,
  high: true,
  fixed: true,
  fixedNumber: [72, 100],
  infoTrue: true,
  mode: "contain",
});
const dialogHeight = computed(() => {
  if (showCropper.value) {
    return "320px";
  } else {
    return "283px";
  }
});

const uploadData = computed(() => store.state.uploadData);

const showUploadDialog = computed({
  get: () => store.state.uploadData.uploadVisible,
  set: (val) => {
    store.commit("setUploadData", { uploadVisible: val });
  },
});

const onCancelClick = () => {
  console.log("onCancelClick");
  onCloseClick();
};

const onCompleteClick = async () => {
  cropper.value.getCropData(async (data) => {
    showLoading.value = true;
    const blob = dataURLtoBlob(data);
    const file = blobToFile(blob, option.value.filename);
    try {
      const result = await ossApi.policyIdAvatar(option.value.filename);
      if (result.code === 0) {
        aliOss.ossUploadFile(result.data, file);
        console.log("handleUpload", result.data);
        sleep(500);
        store.commit("updateBasicInfo", { avatarUrl: result.data.path });
        await onSaveClick(true, false);
        ElMessage.success("上传成功");
        onCloseClick();
      } else {
        ElMessage.error(result.msg);
      }
    } catch (e) {
      console.log("getCropData error", e);
    }
    showLoading.value = false;
  });
};

// base64转blob
const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

// blob转file
const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

const getAccept = computed(() => {
  const type = store.state.uploadData.uploadType;
  if (type === "resume") {
    return ".pdf,.PDF,.doc,.DOC,.docx,.DOCX";
  } else if (type === "photo" || type === "badge") {
    return ".jpg,.JPG,.jpeg,.JPEG,.png,.PNG";
  }
});

const onDialogOpen = () => {
  console.log("onDialogOpen");
  showCropper.value = false;
  analyzeImportResume(false);
};

const analyzeImportResume = (isSuccess) => {
  var from = "模板列表";
  if (route.name === "detail") {
    from = "模板详情";
  } else if (route.name === "resume") {
    from = "我的简历";
  }
  analyze.sendClickEvent(
    isSuccess ? "import_template_success" : "import_template_from",
    {
      value: from,
    }
  );
};

const onDialogClose = () => {
  console.log("onDialogClose");
};

const onCloseClick = () => {
  showUploadDialog.value = false;
};

const importResume = async (file) => {
  try {
    showLoading.value = true;
    console.log("import resume: ", file);
    let params = new FormData();
    params.append("file", file);
    if (store.state.uploadData.uploadResumeId) {
      params.append("templateId", store.state.uploadData.uploadResumeId);
    }
    const isTemplateResume = route.query.type === "template_resume";
    const result = await editApi.importResume(null, params, isTemplateResume);
    if (isTemplateResume && result && result.code === 0 && result.data) {
      // 单独处理模板导入简历，数据结果发生变化，需要转换一下
      result.data.resumeData = {
        contentModules: result.data.contentModules,
        formatInfo: result.data.formatInfo,
        title: result.data.title,
      };
    }
    if (result && result.code === 0 && result.data && result.data.resumeData) {
      ElMessage.success("导入简历成功");
      analyzeImportResume(true);
      // ! 如果登录，直接跳转到简历编辑页面，否则是预览页面
      if (store.getters.hasLogin) {
        if (isTemplateResume) {
          // 模板导入
          store.commit("setResumeData", result.data);
          // 直接创建
          await addResumeData();
        } else {
          router.push({ path: `/edit/${result.data.id}` });
        }
      } else {
        if (!result.data.resumeData.formatInfo) {
          result.data.resumeData.formatInfo = {
            themeColor: "#000000",
            font: 1,
            textSize: 12,
            rowSpace: 20,
            moduleSpace: 10,
            pageMargin: 10,
            titleStyle: 2,
            dateStyle: 1,
          };
        }
        store.commit("setImportResumeData", result.data);
        router.push({ path: `/preview/` });
      }
    } else {
      console.log("导入失败");
      ElMessage.error(result.msg);
    }
  } catch ({ msg: msg }) {
    console.log("导入失败");
    ElMessage.error(msg);
  }
  showLoading.value = false;
  onCloseClick();
};

const sleep = (time) => {
  var timeOut = new Date().getTime() + parseInt(time, 10);
  while (new Date().getTime() <= timeOut) {
    let a = 1;
  }
};

const handleUpload = async (param) => {
  const type = store.state.uploadData.uploadType;
  console.log("handleUpload", option);
  if (param.file.size > 5 * 1000 * 1000 && type === "resume") {
    ElMessage.error("文件大小不能超过5M");
    return;
  } else if (param.file.size > 2000 * 1000 && type === "photo") {
    ElMessage.error("图片大小不能超过2MB");
    return;
  } else if (param.file.size > 1000 * 1000 && type === "qr") {
    ElMessage.error("图片大小不能超过1MB");
    return;
  } else if (param.file.size > 500 * 1000 && type === "badge") {
    ElMessage.error("图片大小不能超过500Kb");
    return;
  }
  let filename = aliOss.randomString(5) + param.file.name;
  option.value.filename = filename;
  console.log("filename", filename, param);

  if (type === "photo") {
    // 如果是图片，需要进行裁切
    showCropper.value = true;
    const url = URL.createObjectURL(param.file);
    console.log("url", url);
    option.value.img = url;
    return;
  }

  try {
    var result = null;
    if (type === "badge") {
      result = await ossApi.policyUserUniversityLogo(filename);
    } else if (type === "qr") {
      result = await ossApi.policyQRPortfolio(filename);
    } else {
      await importResume(param.file);
      return;
    }
    if (result.code === 0) {
      aliOss.ossUploadFile(result.data, param.file);
      console.log("handleUpload", result.data);
      sleep(500);
      if (type === "badge") {
        store.commit("updateBasicInfo", { schoolBadgeUrl: result.data.path });
      } else if (type === "qr") {
        store.commit("updateCurrentEdityDataModify", {
          qrcodeUrl: result.data.path,
        });
      }
      await onSaveClick(true, false);
      ElMessage.success("上传成功");
      param.onSuccess(result.data);
      onCloseClick();
    } else {
      ElMessage.error(result.msg);
      param.error(result.msg);
    }
  } catch ({ msg: msg }) {
    ElMessage.error(msg);
  }
};

const handleUploadExceed = () => {
  ElMessage.error("最多上传一个文件");
};

const handleUploaded = (file) => {
  console.log("handleUploaded", file);
};
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

::v-deep(.el-dialog) {
  height: 283px;
  border-radius: 5px;
}

::v-deep(.el-dialog__header) {
  display: none;
  padding: 0px;
}

::v-deep(.el-dialog__body) {
  padding: 0px;
  margin: 0px;
}

::v-deep(.el-upload-list) {
  display: none;
}

.modal-resume {
  width: 398px;
  height: v-bind(dialogHeight);
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-import-resume {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-demo {
    width: 358px;
    height: 180px;

    .upload_img {
      margin-top: 0;
    }
  }

  p {
    &:nth-child(1) {
      text-align: center;
      align-self: flex-start;
      font-size: 16px;
      margin-top: 18px;
      margin-left: 20px;
      line-height: 22px;
      color: black;
      margin-bottom: 18px;
    }
    &:nth-child(3) {
      font-size: 12px;
      line-height: 18px;
      align-self: flex-start;
      margin-left: 20px;
      margin-top: 5px;
      color: rgba(0, 0, 0, 0.3);
    }
  }
  .el-upload {
    img {
      width: 64px;
      height: 64px;
      margin-top: 40px;
    }
  }
  .upload-close {
    width: 30px;
    height: 30px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: -10px;
    align-self: flex-end;
    img {
      width: 9px;
      height: 9px;
    }
  }
}

.modal-cropper-resume {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 16px;
    line-height: 26px;
    color: #303133;
    font-weight: bold;
    margin-top: 12px;
    margin-left: 20px;
    align-self: flex-start;
  }

  .cropper {
    margin-top: 40px;
    width: 358px;
    height: 200px;
    background: black;
    margin-top: 12px;
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 40px;
  }

  .cancel {
    width: 54px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    cursor: pointer;
    transition: all 250ms;
    color: #606266;
    font-size: 12px;
    text-align: center;
    margin-right: 10px;
    &:hover {
      background: rgba($color: #000000, $alpha: 0.05);
    }
  }

  .complete {
    width: 54px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
    transition: all 250ms;
    text-align: center;
    background: $primary-color;
    color: white;
    &:hover {
      background: rgba($color: $primary-color, $alpha: 0.8);
    }
  }
}
</style>