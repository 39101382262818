<template>
  <div class="modify-job-info">
    <el-form
      label-position="top"
      :model="edit_object"
      size="large"
      :inline="true"
      label-width="1000px"
      :rules="rules"
    >
      <el-form-item label="公司名称" prop="company">
        <AutoComplete
          v-model="company"
          type="Company"
          placeholder="请输入公司名称"
        />
      </el-form-item>
      <el-form-item label="职位名称" prop="position">
        <AutoComplete
          v-model="position"
          type="Job"
          placeholder="请输入职位名称"
        />
      </el-form-item>
      <el-form-item label="所在部门">
        <el-input
          v-model="department"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="所在城市">
        <AutoComplete v-model="city" type="City" placeholder="请输入城市" />
      </el-form-item>
      <el-form-item label="起止时间" prop="startTime">
        <el-date-picker
          v-model="start_time"
          type="month"
          placeholder="请选择"
          :editable="false"
          format="YYYY-MM"
          value-format="YYYY-MM"
          :disabled-date="disabledDate"
        >
        </el-date-picker>
      </el-form-item>
      <span class="job-split">至</span>
      <el-form-item label="&nbsp;" class="edu-end-time" prop="endTime">
        <el-date-picker
          v-model="end_time"
          type="month"
          placeholder="请选择"
          :editable="false"
          format="YYYY-MM"
          value-format="YYYY-MM"
          :shortcuts="shortcuts"
          :disabled-date="disabledDate"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <StrongEditor
      v-model="desc"
      placeholder="简单介绍在该公司期间负责的工作，尽可能使用STAR法则阐述"
    ></StrongEditor>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { onBeforeMount, onBeforeUnmount, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import StrongEditor from "@/components/StrongEditor.vue";
import utils from "@/utils/utils.js";

const store = useStore();

const shortcuts = [
  {
    text: "至今",
    value: new Date(),
  },
];

const company = computed({
  get: () => store.state.edit.edit_object.company,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { company: val });
  },
});

const position = computed({
  get: () => store.state.edit.edit_object.position,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { position: val });
  },
});

const department = computed({
  get: () => store.state.edit.edit_object.department,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { department: val });
  },
});

const city = computed({
  get: () => store.state.edit.edit_object.city,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { city: val });
  },
});

const start_time = computed({
  get: () => store.state.edit.edit_object.start,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", {
      start: val === null ? "" : val,
    });
  },
});

const end_time = computed({
  get: () => store.state.edit.edit_object.end,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", {
      end: val === null ? "" : val,
      stillActive: utils.formatDateTime2(new Date().getTime()) === val,
    });
  },
});

const desc = computed({
  get: () => store.state.edit.edit_object.desc,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { desc: val });
  },
});

const edit_object = computed(() => store.state.edit.edit_object);

const rules = reactive({
  // company: [
  //   {
  //     required: true,
  //     message: "请输入公司名称",
  //     trigger: "blur",
  //   },
  // ],
  // position: [
  //   {
  //     required: true,
  //     message: "请输入职位名称",
  //     trigger: "blur",
  //   },
  // ],
  // endTime: [
  //   {
  //     message: "结束时间需大于开始时间",
  //     trigger: "blur",
  //     validator: (rule, value) => {
  //       console.log("validator", start_time.value, value, end_time.value);
  //       if (
  //         start_time.value !== "" &&
  //         start_time.value !== null &&
  //         end_time.value !== "" &&
  //         end_time.value !== null
  //       ) {
  //         const startInt = parseInt(start_time.value.replace("-", ""));
  //         const valueInt = parseInt(end_time.value.replace("-", ""));
  //         console.log("validator", startInt, valueInt);
  //         return valueInt > startInt;
  //       }
  //       return true;
  //     },
  //   },
  // ],
  // startTime: [
  //   {
  //     message: "开始时间需小于结束时间",
  //     trigger: "blur",
  //     validator: (rule, value) => {
  //       console.log("start time validator", start_time.value);
  //       if (
  //         end_time.value !== "" &&
  //         end_time.value !== null &&
  //         start_time.value !== "" &&
  //         start_time.value !== null
  //       ) {
  //         const endInt = parseInt(end_time.value.replace("-", ""));
  //         const valueInt = parseInt(start_time.value.replace("-", ""));
  //         console.log("validator", endInt, valueInt);
  //         return valueInt <= endInt;
  //       }
  //       return true;
  //     },
  //   },
  // ],
});
const disabledDate = (time) => {
  return time.getTime() > Date.now();
};
</script>

<style lang="scss">
@import "src/style/base.scss";

.modify-job-info {
  .el-input {
    margin-top: -6px;
    width: var(--form-width);
    .el-input__validateIcon {
      display: none;
    }
  }
  .el-select {
    width: var(--form-width);
  }
  .el-form-item {
    width: var(--form-width);
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: 16px;
    &:nth-child(7) {
      margin-left: 0px;
    }
  }
  .job-split {
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 14px;
    margin-top: 48px;
    padding-left: 3px;
    padding-right: 3px;
  }
}
</style>