<template>
  <div
    class="portrait-container"
    v-loading="isLoading"
    element-loading-text="简历画像生成中"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="title">
      <img src="@/assets/img/icon_vip.svg" alt="" class="more" />
      <strong>{{ showTitle }}</strong>
    </div>
    <div class="not_select" v-if="state.current.state === ''">
      <img
        src="@/assets/img/icon_mine_resume_no_data.svg"
        alt=""
        class="more"
      />
      <p>从左侧“我的简历”中选择一个简历</p>
    </div>
    <div
      class="success"
      v-else-if="
        state.current.state === 'success' || state.current.state === 'fail'
      "
    >
      <div class="success_no_data" v-if="hasNoData">
        <img src="@/assets/img/icon_resume_portrait.svg" />
        <p>根据你的简历内容，计算简历画像</p>
        <p @click="onRefreshClick(state.id)" class="resume_button">立即计算</p>
      </div>
      <div class="success_content" v-else>
        <p @click="onRefreshClick(state.id)" class="resume_button">刷新数据</p>
        <p>{{ showTime }}</p>
        <div class="tag_root">
          <div
            class="tag_column"
            v-for="(itemData, index) in state.current.tagData"
            :key="index"
          >
            <span class="tag_title">{{ itemData.title }}</span>
            <div class="tag_container">
              <el-tag
                class="tag_item"
                type="info"
                v-for="(item, index) in itemData.tags"
                :key="index"
                >{{ item }}</el-tag
              >
            </div>
          </div>
        </div>
        <div class="chart_root">
          <div>
            <p>综合能力</p>
            <ResumeChart
              class="resume_chart_left"
              :data="state.current.detail.capability"
            />
          </div>
          <div>
            <p>匹配职业</p>
            <ResumeProfessionalChart
              class="resume_chart_right"
              :data="state.current.detail.titles"
              v-if="state.showProfessionalChart"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import editApi from "@/apis/edit_api";
import { ElMessage } from "element-plus";
import useEventBus from "@/plugin/event";
import { nextTick, onMounted, onUnmounted } from "@vue/runtime-core";
import ResumeChart from "@/components/resume/ResumeChart";
import ResumeProfessionalChart from "@/components/resume/ResumeProfessionalChart";
import utils from "@/utils/utils.js";
import analyze from "@/plugin/analyze.js";
import vipMix from "@/plugin/vip";

const eventBus = useEventBus();
const store = useStore();
const { onVipToast } = vipMix();

const state = reactive({
  id: "",
  title: "",
  showProfessionalChart: false,
  current: {
    detail: {},
    state: "", // '':未选择, 'success':加载成功, 'fail':加载失败
    tagData: [],
  },
  portraitData: [
    // {
    //   id: "",
    //   detail: {},
    // }
  ],
});

const hasNoData = computed(() => {
  if (
    state.current.detail === null ||
    !state.current.detail?.tags ||
    state.current.state === "fail"
  ) {
    return true;
  } else {
    return false;
  }
});

const isLoading = ref(false);

const onIdChange = async (id) => {
  const containData = contain(id);
  console.log("onIdChange", id, containData?.state);
  state.current.state = containData?.state;
  if (containData === null) {
    // 如果不存在，需要请求
    requestData(id);
    return;
  }
  if (containData.state === "fail") {
    // 如果加载失败
    return;
  }
  if (containData.state === "success") {
    // 如果已经加载成功，直接设置数据
    refreshView(id, containData.detail);
    return;
  }
};

const requestData = async (id) => {
  // 请求接口
  console.log("requestData", id);
  const itemData = {
    id: id,
    detail: {},
    state: "",
  };
  try {
    isLoading.value = true;
    const result = await editApi.getLastResumePortrait(id);
    if (result.code === 0 && result.data) {
      itemData.detail = result.data;
      itemData.state = "success";
      refreshView(id, result.data);
      isLoading.value = false;
    } else {
      // ElMessage.error(result?.msg ?? "简历画像为空");
      state.current.state = "fail";
      itemData.state = "fail";
      isLoading.value = false;
    }
  } catch ({ msg: msg }) {
    ElMessage.error(msg);
    state.current.state = "fail";
    itemData.state = "fail";
    isLoading.value = false;
  }
  state.portraitData.push(itemData);
};

const refreshView = async (id, data) => {
  console.log("更新 UI", id, state.id);
  if (id !== state.id) {
    return;
  }
  state.current.detail = data;
  state.showProfessionalChart = false;
  await nextTick();
  state.showProfessionalChart = true;
  state.current.state = "success";
  state.current.tagData = [];

  if (data.tags.educationTags.length > 0) {
    state.current.tagData.push({
      title: "教育背景",
      tags: data.tags.educationTags,
    });
  }
  if (data.tags.professionalSkillTags.length > 0) {
    state.current.tagData.push({
      title: "专业技能",
      tags: data.tags.professionalSkillTags,
    });
  }
  if (data.tags.softSkillTags.length > 0) {
    state.current.tagData.push({
      title: "软性技能",
      tags: data.tags.softSkillTags,
    });
  }
  if (data.tags.otherTags.length > 0) {
    state.current.tagData.push({
      title: "其他技能",
      tags: data.tags.otherTags,
    });
  }
};

const onRefreshClick = async (id) => {
  analyze.sendClickEvent("mine_resume_protrait_refresh_click");
  const itemData = {
    id: id,
    detail: {},
    state: "",
  };
  try {
    isLoading.value = true;
    const result = await editApi.refreshPortrait(id);
    if (result.code === 0 && result.data) {
      itemData.detail = result.data;
      itemData.state = "success";
      refreshView(id, result.data);
      isLoading.value = false;
    } else {
      if (result.code === 4 || result.code === 5) {
        onVipToast(result?.msg ?? "");
      } else {
        ElMessage.error(result?.msg ?? "简历画像为空");
      }
      state.current.state = "fail";
      itemData.state = "fail";
      isLoading.value = false;
    }
  } catch ({ msg: msg }) {
    ElMessage.error(msg);
    state.current.state = "fail";
    itemData.state = "fail";
    isLoading.value = false;
  }

  const containData = contain(id);
  // 如果存在，移除
  if (containData !== null) {
    if (itemData.state === "fail" && containData.state === "success") {
      // 如果当前请求失败，但是缓存中的数据是成功的，则不需要更新
      console.log("onRefreshClick-失败了，但是有数据");
      state.current.state = "success";
    } else {
      console.log("onRefreshClick-没有失败，但是有数据");
      state.portraitData.splice(state.portraitData.indexOf(containData), 1);
      state.portraitData.push(itemData);
    }
  } else {
    console.log("onRefreshClick-没有缓存");
    state.portraitData.push(itemData);
  }
};

const contain = (id) => {
  // 判断是否有数据
  const filterResult = state.portraitData.filter((item) => {
    return item.id === id;
  });
  if (filterResult.length > 0) {
    return filterResult[0];
  } else {
    return null;
  }
};

const showTitle = computed(() => {
  if (state.title) {
    return state.title;
  } else {
    return "StrongHire 简历画像";
  }
});

const showTime = computed(() => {
  if (state.current.detail.updateTime) {
    return `最后更新：${utils.getDateDiff(state.current.detail.updateTime)}`;
  } else {
    return "";
  }
});

onMounted(() => {
  eventBus.on("mineResumeSelect", async (data) => {
    if (!data.id) {
      state.id = "";
      state.title = "";
      state.current.state = "";
      state.current.detail = {};
      return;
    }
    if (state.id !== data.id) {
      state.id = data.id;
      state.title = data.name;
      onIdChange(data.id);
    }
  });
});

onUnmounted(() => {
  eventBus.off("mineResumeSelect");
});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.portrait-container {
  min-height: 410px;
  padding: 20px;
  padding-top: 23px;
  padding-bottom: 23px;
  @include card-bg;
  position: relative;

  .title {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 16px;
    }
    strong {
      margin-left: 5px;
      font-size: 16px;
      line-height: 22px;
      color: black;
    }
  }
}

.not_select {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 77px;
  }
  p {
    margin-top: 9px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
  }
}

.success {
  width: calc(100% - 40px);
}

.tag_root {
  margin-top: 20px;
}

.success_content {
  height: calc(100% - 57px);

  & > p {
    &:nth-child(1) {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    &:nth-child(2) {
      position: absolute;
      right: 126px;
      top: 30px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      line-height: 17px;
    }
  }

  .tag_column {
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    .tag_title {
      margin-top: 2px;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.7);
      font-weight: 700;
    }
  }

  .tag_container {
    margin-left: 5px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .tag_item {
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }
}

.success_no_data {
  position: absolute;
  top: 0;
  left: 0;
  & > img {
    width: 100px;
    height: 80px;
    position: absolute;
    left: 260px;
    top: 188px;
  }
  p {
    &:nth-child(2) {
      position: absolute;
      left: 380px;
      top: 195px;
      width: 220px;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.7);
    }
    &:nth-child(3) {
      position: absolute;
      left: 380px;
      top: 225px;
    }
  }
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.resume_button {
  width: 96px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  color: #606266;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  transition: all 250ms;
  font-weight: 500;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
}

.chart_root {
  margin-top: 20px;
  display: flex;
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }

    &:nth-child(1) {
      .resume_chart_left {
        margin-top: 20px;
      }
    }
    &:nth-child(2) {
      .resume_chart_right {
        margin-top: 10px;
      }
    }
  }
}

::v-deep(.path),
::v-deep(.el-loading-text) {
  color: rgba(0, 0, 0, 0.7);
  stroke: rgba(0, 0, 0, 0.7);
}
</style>