import { createGetApi, createPostApi, SERVER_URL } from './network'

const USER_URL = SERVER_URL + '/api/template_resume';
const VIP_URL = SERVER_URL + '/api/vip';

export default {
    getTemplateData: function (page, limit, keyword, keywordPinyin) {
        return createGetApi(`${USER_URL}/listByPage`, {
            page,
            limit,
            keyword,
            keywordPinyin,
        })
    },
    exchangeVipRedeemCode(code) {
        return createPostApi(`${VIP_URL}/exchangeVipRedeemCode`, { code })
    },
    queryOrder() {
        return createGetApi(`${VIP_URL}/queryOrder`)
    },
}