<template>
  <div class="preview-basic preview-content" @click="onBasicClick">
    <div class="preview-basic-container">
      <strong :style="name_style">{{ name }}</strong>
      <p
        v-if="store.getters.basicInfoIsEmpty"
        class="preview-empty"
        :style="{ textAlign: 'center' }"
      >
        填写你的基本信息
      </p>
      <div>
        <p v-for="(item, index) in row1" :key="index">{{ item }}</p>
      </div>
      <div>
        <p v-for="(item, index) in row2" :key="index">{{ item }}</p>
      </div>
      <div>
        <p v-for="(item, index) in row3" :key="index">{{ item }}</p>
      </div>
      <el-image
        :src="utils.getImageUrl(basicInfo.schoolBadgeUrl)"
        v-if="showBadge"
        class="basic-xiaohui"
        fit="contain"
      />
      <el-image
        :src="utils.getImageUrl(basicInfo.avatarUrl)"
        class="basic-avatar"
        fit="contain"
        @error="onAvatarError"
        @load="onAvatarSuccess"
        v-show="avatarSuccess"
      >
        <template #error>
          <img
            src="@/assets/img/icon_preview_edit_avatar.png"
            alt=""
            class="basic-avatar-error"
          />
        </template>
      </el-image>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import editMix from "@/plugin/edit";
import utils from "@/utils/utils.js";
import { useRoute } from "vue-router";

const store = useStore();

const name = computed(() => {
  console.log("xxxxx", basicInfo.value.name);
  if (basicInfo.value.name) {
    return basicInfo.value.name;
  } else {
    return "StrongHire";
  }
});

const showBadge = computed(
  () =>
    basicInfo.value.schoolBadgeUrl !== "" &&
    basicInfo.value.schoolBadgeUrl !== null &&
    basicInfo.value.schoolBadgeUrl !== undefined
);

const basicInfo = computed(() => store.state.edit.contentModules.basicInfo);

const phoneNum = computed(() => {
  var phoneCountryCode = "";
  if (store.state.edit.contentModules.basicInfo.phoneCountryCode) {
    phoneCountryCode =
      store.state.edit.contentModules.basicInfo.phoneCountryCode;
  }
  var phoneNumber = "";
  if (store.state.edit.contentModules.basicInfo.phone) {
    phoneNumber = store.state.edit.contentModules.basicInfo.phone;
  }
  if (!phoneNumber) {
    return "";
  }
  return `${phoneCountryCode} ${phoneNumber}`;
});

const liveCity = computed(() => {
  if (store.state.edit.contentModules.basicInfo.liveCity) {
    return `现居${store.state.edit.contentModules.basicInfo.liveCity}`;
  } else {
    return "";
  }
});

const nativeCity = computed(() => {
  if (store.state.edit.contentModules.basicInfo.nativeCity) {
    return `籍贯${store.state.edit.contentModules.basicInfo.nativeCity}`;
  } else {
    return "";
  }
});

const targetJob = computed(() => {
  if (store.state.edit.contentModules.basicInfo.targetJob) {
    return `目标岗位-${store.state.edit.contentModules.basicInfo.targetJob}`;
  } else {
    return "";
  }
});

const targetCity = computed(() => {
  if (store.state.edit.contentModules.basicInfo.targetCity) {
    return `目标城市-${store.state.edit.contentModules.basicInfo.targetCity}`;
  } else {
    return "";
  }
});

const row1 = computed(() => {
  // 性别 生日 手机号码 邮箱 居住地
  const basicInfo = store.state.edit.contentModules.basicInfo;
  const rowArray = Array.of(
    getGender(basicInfo.gender),
    getBirth(basicInfo.birth, basicInfo.birthType),
    phoneNum.value,
    basicInfo.email,
    liveCity.value
  );
  return rowArray.filter((v) => v !== "");
});

const row2 = computed(() => {
  // 汉族 辽宁省 未婚 185cm 80kg
  const basicInfo = store.state.edit.contentModules.basicInfo;
  const rowArray = Array.of(
    basicInfo.nation,
    nativeCity.value,
    basicInfo.maritalStatus,
    getHeight(basicInfo.bodyHeight),
    getWeight(basicInfo.bodyWeight),
    basicInfo.politicalStatus
  );
  return rowArray.filter((v) => v !== "");
});

const row3 = computed(() => {
  // 在职看机会 产品经理 25k-30k
  const basicInfo = store.state.edit.contentModules.basicInfo;
  var extraList = basicInfo.extraList;
  if (!extraList) {
    extraList = [];
  }
  const rowArray = Array.of(
    getJobStatus(basicInfo.jobHuntStatus),
    targetJob.value,
    targetCity.value,
    getTargetSalar(basicInfo.targetSalaryMin, basicInfo.targetSalaryMax),
    ...extraList,
  );
  return rowArray.filter((v) => v !== "");
});

const getJobStatus = (status) => {
  if (
    status !== null &&
    status !== "" &&
    status !== undefined &&
    status !== 0
  ) {
    if (status === 1) {
      return "应届生";
    } else if (status === 2) {
      return "随时到岗";
    } else if (status === 3) {
      return "在职，月内到岗";
    } else if (status === 4) {
      return "在职，考虑机会";
    }
  }
  return "";
};

const getTargetSalar = (min, max) => {
  if (min && max) {
    return `${min}k-${max}k`;
  } else if (min) {
    return min + "k";
  } else if (max) {
    return max + "k";
  } else {
    return "";
  }
};

const getHeight = (height) => {
  if (height) {
    return `${height}cm`;
  } else {
    return "";
  }
};

const getWeight = (weight) => {
  if (weight) {
    return `${weight}kg`;
  } else {
    return "";
  }
};


const getGender = (gender) => {
  if (!gender) {
    return "";
  }
  if (gender == 1) {
    return "男";
  } else {
    return "女";
  }
};

const getBirth = (birthValue, birthType) => {
  if (birthValue === "" || !birthValue) {
    return "";
  }
  var result = "";
  if (birthType === 0) {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const targetYear = parseInt(birthValue.split("-")[0]);
    const targetMonth = parseInt(birthValue.split("-")[1]);
    if (targetMonth <= currentMonth) {
      result = `${currentYear - targetYear} 岁`;
    } else {
      result = `${currentYear - targetYear - 1} 岁`;
    }
  } else {
    result = birthValue;
  }
  console.log("get birth result ", result);
  return result;
};

const gender = computed(() => store.state.edit.contentModules.basicInfo);

const name_style = computed(() => {
  return {
    fontSize: `${store.state.edit.formatInfo.textSize + 8}px`,
  };
});

const { startModify } = editMix();
const onBasicClick = () => {
  startModify("basic_info", basicInfo.value, null, false);
};

const avatarSuccess = ref(false);
const route = useRoute();

const onAvatarError = (e) => {
  console.log("onAvatarError", e);
  if (route.name === "resumePrint" && route.query.type !== "template_resume") {
    // ? 如果是打印页面，并且不是模板简历，不显示默认头像
    avatarSuccess.value = false;
  } else {
    avatarSuccess.value = true;
  }
};
const onAvatarSuccess = () => {
  console.log("onAvatarSuccess");
  avatarSuccess.value = true;
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
@import "src/style/edit.scss";

p {
  font-weight: normal;
  line-height: 24px !important;
}

.preview-basic {
  min-height: 120px;
  display: flex;
  @include module-card;
  cursor: pointer;
  transition: var(--transition);
  .preview-basic-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    margin-left: var(--page-margin);
    margin-right: var(--page-margin);
    transition: var(--transition);
    -webkit-transition: var(--transition);
    strong {
      max-width: 410px;
      font-family: var(--font-family);
      transition: var(--transition);
      font-weight: 700;
      line-height: 32px;
    }
    & > p {
      color: black;
      font-weight: bold;
      line-height: normal;
      width: 433px;
    }
    div {
      display: flex;
      flex-wrap: wrap;
      max-width: 520px;
      & > p {
        margin-left: 10px;
      }
      &:nth-child(2) {
        margin-top: 10px;
      }
    }
    .basic-xiaohui {
      max-width: 140px;
      max-height: 48px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .basic-avatar {
      width: 72px;
      height: 100px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .basic-avatar-error {
      width: 72px;
      height: 100px;
      object-fit: cover;
    }
  }
}
</style>