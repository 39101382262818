<template>
  <div>
    <div class="home-card-container" v-loading="state.isLoading">
      <homeResumeTemplate
        v-for="item in state.templateData"
        :key="item.id"
        :itemData="item"
      ></homeResumeTemplate>
    </div>
    <div class="home-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="state.total"
        :page-size="8"
        :small="false"
        :current-page="state.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script setup>
import homeResumeTemplate from "./HomeResumeTemplate.vue";
import { reactive, ref, defineProps, defineEmits } from "vue";
import { onMounted, watch } from "@vue/runtime-core";
import homeApi from "@/apis/home_api";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";

const props = defineProps({
  keyword: {
    type: String,
  },
});

const emit = defineEmits(["updateNoData"]);

const route = useRoute();

const state = reactive({
  isLoading: false,
  currentPage: 1,
  total: 0,
  templateData: [],
});

const handleSizeChange = (size) => {
  console.log(`${size} items per page`);
};

const handleCurrentChange = (current) => {
  console.log(`current page: ${current}`);
  getTemplateData(current);
};

watch(
  () => route.query.key,
  (val, oldVal) => {
    getTemplateData(1);
  }
);

const getTemplateData = async (page) => {
  try {
    state.isLoading = true;
    console.log("getTemplateData", page, route.path, route.params, route.query);
    const result = await homeApi.getTemplateData(
      page,
      8,
      route.query.key,
      route.params.keyword
    );
    if (result.code === 0) {
      state.total = result.data.total;
      state.templateData = result.data.record;
      if (state.templateData.length === 0 || result.data.isRecommend === true) {
        emit("updateNoData", true);
      } else {
        emit("updateNoData", false);
      }
      refreshTitle(result.data.keyword);
    } else {
      ElMessage.error(result.msg);
    }
    state.isLoading = false;
    state.currentPage = page;
  } catch ({ msg: msg }) {
    console.log("get template data error", msg);
    ElMessage.error(msg);
    state.isLoading = false;
  }
};

const refreshTitle = (keyword) => {
  if (keyword) {
    document.title = keyword.title;
    document
      .querySelector("meta[name='Keywords']")
      .setAttribute("content", keyword.keywords);
    document
      .querySelector("meta[name='Description']")
      .setAttribute("content", keyword.desc);
  }
};

onMounted(() => {
  console.log("path:", route.path);
  getTemplateData(1);
});
</script>

<style lang="scss" scoped>
.home-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 20px;
  height: 864px;

  .homeResumeTemplate {
    &:nth-child(n) {
      margin-right: 20px;
    }
    &:nth-child(4n) {
      margin-right: 0px;
    }
    &:nth-child(n + 5) {
      margin-top: 20px;
    }
  }
}

.home-pagination {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-pagination {
}

::v-deep(.number) {
  background-color: white !important;
  &.is-active {
    background-color: var(--el-color-primary) !important;
  }
}
::v-deep(.btn-next),
::v-deep(.btn-prev) {
  background-color: white !important;
}
</style>