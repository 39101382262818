<template>
  <div class="blog_relative" v-if="state.data.length > 0">
    <p>{{ blogRelativeTitle }}</p>
    <div class="blog_list">
      <a
        class="blog_item"
        v-for="item in state.data"
        :key="item.id"
        :href="`/job_strategy_detail/${item.id}`"
        target="_blank"
      >
        <el-image :src="utils.getImageUrl(item.coverPicUrl)" />
        <div class="right">
          <p>{{ item.title }}</p>
          <div class="right_bottom">
            <img src="@/assets/img/icon_blog_list_view_count.svg" alt="" />
            <p>{{ item.readBaseNum + item.readNum}}</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch, defineProps, computed } from "vue-demi";
import blogApi from "@/apis/blog_api";
import { ElMessage } from "element-plus/lib/components";
import { useRoute, useRouter } from "vue-router";
import utils from "@/utils/utils.js";

const router = useRouter();
const route = useRoute();

const state = reactive({
  data: [],
});

const props = defineProps({
  category: {
    type: String,
    default: "",
  },
});

watch(
  () => route.params.id,
  (val, oldVal) => {
    console.log("blog-relative id change", val, oldVal);
    requestData();
  }
);

const blogRelativeTitle = computed(() => {
  if (route.name === "jobStrategyDetail") {
    return "相关文章";
  } else {
    return "求职攻略";
  }
});

const requestData = async () => {
  // 如果是求职攻略后者是简历详情，会展示相关的文章
  if (route.name === "jobStrategyDetail" || route.name === "detail") {
    try {
      const maxCount = 5;
      const result = await blogApi.getRecommendList(maxCount, props.category);
      if (result.code === 0 && result.data) {
        state.data = result.data;
      } else {
        ElMessage.error(result.msg);
      }
    } catch (error) {
      ElMessage.error(error.message);
      console.log(error);
    }
  }
};

onMounted(() => {
  console.log("blog relative mounted category: ", props.category);
  requestData();
});

</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
.blog_relative {
  @include card-bg;
  box-sizing: border-box;
  padding: 20px;
  width: 330px;
}

.blog_item {
  display: flex;
  height: 60px;
  width: 100%;
  margin-top: 20px;
  position: relative;
  cursor: pointer;

  & > .el-image {
    width: 96px;
    height: 100%;
    border-radius: 5px;
  }

  & > .right {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 10px;
    position: relative;

    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 14px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.7);
    }

    .right_bottom {
      position: absolute;
      bottom: 0px;
      left: 0px;
      display: flex;

      p {
        font-size: 12px;
        line-height: 17px;
        margin-left: 2px;
        color: rgba(0, 0, 0, 0.7);
      }

      img {
        width: 17px;
        height: 17px;
      }
    }
  }
}
</style>