<template>
  <div
    class="header_avatar"
    :style="{
      '--bg-color': bgColor,
      '--box-shadow': bgBoxShadow,
      '--text-color': textColor,
      '--desc-text-color': descTextColor,
    }"
  >
    <img id="img_avatar" src="@/assets/img/icon_avatar.svg" />
    <div class="avatar_expand">
      <div>
        <img id="img_avatar1" src="@/assets/img/icon_avatar.svg" />
        <div class="name_container">
          <span>{{ userData?.name }}</span>
          <img src="@/assets/img/icon_not_vip.svg" v-if="vipType === 0"/>
          <img src="@/assets/img/icon_vip.svg" v-else/>
        </div>
        <p class="desc">{{ vipDesc }}</p>
        <div @click="openVip" class="open_vip" v-if="!isInVipPage && vipType !== 2">
          {{ vipType === 0 ? '立即开通' : '续费VIP会员' }}
        </div>
        <div
          class="operate_item"
          v-for="item in avatarData"
          :key="item.id"
          @click="onItemClick(item.id)"
        >
          <div class="divider1"></div>
          <img :src="getItemIcon(item.id)" />
          <div class="divider2"></div>
          <span>{{ item.desc }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import myCookie from "@/apis/cookie";
import { ElMessage } from "element-plus";

const router = useRouter();
const route = useRoute();
const store = useStore();

const userData = computed(() => store.state.userData);
const vipType = ref(0)

const avatarData = ref([
  {
    desc: "联系求职顾问",
    id: 3,
  },
  {
    desc: "退出",
    id: 4,
  },
]);

const openVip = () => {
  router.push("/account/buy_vip");
};

const vipDesc = computed(() => {
  // 会员有效期至 2022年3月13日
  // 开通StrongHire会员，尊享8大权益
  // 您已是终身会员
  const vipExpireTime = userData.value.vipExpireTime;
  const now = new Date().getTime();
  if (vipExpireTime >= 30000000000000) {
    vipType.value = 2;
    return "您已是终身会员";
  } else if (vipExpireTime > now) {
    // 格式化时间为 2020年3月13日
    const date = new Date(vipExpireTime);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    vipType.value = 1;
    return `会员有效期至 ${year}年${month}月${day}日`;
  } else {
    vipType.value = 0;
    return "开通StrongHire会员，尊享8大权益";
  }
});

const onItemClick = (index) => {
  if (index === 4) {
    logout();
    // store.commit("updateVipDialog", true);
  } else if (index === 3) {
    store.commit("updateAddWechatDialog", {
      show: true,
      type: 1,
    });
  }
};

const logout = () => {
  myCookie.delStaffUserInfo();
  store.commit("resetUserData");
  ElMessage.success("退出成功");
  setTimeout(() => {
    router.push("/");
  }, 500);
};

const getItemIcon = (index) => {
  console.log(isInVipPage.value);
  if (index === 3) {
    return isInVipPage.value
      ? require("@/assets/img/icon_avatar_question_night.svg")
      : require("@/assets/img/icon_avatar_question_light.svg");
  } else if (index === 4) {
    return isInVipPage.value
      ? require("@/assets/img/icon_avatar_quit_night.svg")
      : require("@/assets/img/icon_avatar_quit_light.svg");
  } else {
    return isInVipPage.value
      ? require("@/assets/img/icon_avatar_quit_night.svg")
      : require("@/assets/img/icon_avatar_quit_night.svg");
  }
};

const isInVipPage = computed(() => {
  return route.path === "/account/buy_vip" || route.path === "/account/vip_welcome";
});

const bgColor = computed(() => {
  if (isInVipPage.value) {
    return "#2a2c33";
  } else {
    return "white";
  }
});

const bgBoxShadow = computed(() => {
  if (isInVipPage.value) {
    return "0px 4px 5px rgba(71, 71, 71, 0.3)";
  } else {
    return "0px 4px 5px rgba(203, 203, 203, 0.3)";
  }
});

const textColor = computed(() => {
  if (isInVipPage.value) {
    return "white";
  } else {
    return "black";
  }
});

const descTextColor = computed(() => {
  if (isInVipPage.value) {
    return "rgba(255, 255, 255, 0.3)";
  } else {
    return "rgba(0, 0, 0, 0.3)";
  }
});
</script>

<style lang="scss" scoped>
.header_avatar {
  cursor: pointer;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  #img_avatar {
    width: 30px;
    height: 30px;
    border-radius: 40px;
    object-fit: cover;
    filter: drop-shadow(0px 0px 5px rgba(203, 203, 203, 0.2));
  }

  .avatar_expand {
    width: 200px;
    position: absolute;
    top: 50px;
    left: -85px;
    visibility: hidden;
    opacity: 0;
    transition: all 150ms;
    display: flex;
    flex-direction: column;

    & > div {
      cursor: auto;
      width: 200px;
      margin-top: 15px;
      background: var(--bg-color);
      box-shadow: 0px 4px 5px rgba(71, 71, 71, 0.3);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .name_container {
        display: flex;
        align-items: center;
        margin-top: 10px;
        & > span {
          font-size: 14px;
          line-height: 20px;
          color: var(--text-color);
        }
        & > img {
          width: 30px;
          height: 16px;
          margin-left: 4px;
        }
      }

      .desc {
        font-size: 10px;
        line-height: 14px;
        margin-top: 3px;
        color: var(--desc-text-color);
      }

      .open_vip {
        cursor: pointer;
        width: 140px;
        height: 30px;
        display: flex;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        border-radius: 30px;
        background: #f56c6c;
        color: white;
        transition: all 250ms;
        &:hover {
          background: rgba($color: #f56c6c, $alpha: 0.8);
        }
      }
    }

    #img_avatar1 {
      width: 40px;
      height: 40px;
      margin-top: 17px;
      border-radius: 40px;
      object-fit: cover;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }

  &:hover .avatar_expand {
    visibility: visible;
    opacity: 1;
  }

  .operate_item {
    height: 50px;
    width: 200px;
    color: var(--text-color);
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    transition: all 250ms;
    margin-top: 5px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }

    img {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      color: v-bind(--text-color);
      font-weight: 400;
    }

    .divider1 {
      width: 20px;
      height: 1px;
    }

    .divider2 {
      width: 10px;
      height: 1px;
    }
  }
}
</style>