<template>
  <div class="preview-collection">
    <PreviewModuleHolder
      class="preview-collection-container moudle-left-right"
      :mainId="props.moduleId"
    >
      <div class="module-gap-div"></div>
      <PreviewTitle moduleType="portfolios" :moduleId="props.moduleId"></PreviewTitle>
      <p
        class="preview-empty"
        v-if="portfolios === null || portfolios.length === 0"
        @click="onEmptyClick"
      >
        上传作品链接，如小程序、产品原型等
      </p>
      <TransitionGroup
        tag="ul"
        name="fade"
        class="container preview-collection-item-container"
      >
        <div
          class="preview-collection-item preview-content"
          v-for="item in portfolios"
          :key="item.id"
          @click="onItemClick(item)"
        >
          <div class="item">
            <div class="qrcode">
              <div
                class="stub"
                v-if="
                  item.qrcodeUrl === '' ||
                  item.qrcodeUrl === undefined ||
                  item.qrcodeUrl === null
                "
              >
                <img src="@/assets/img/icon_upload_add.svg" alt="" />
              </div>
              <div v-else class="real">
                <el-image
                  :src="utils.getImageUrl(item.qrcodeUrl)"
                  fit="contain"
                >
                </el-image>
              </div>
              <div
                @click.stop="onDeleteClick(item.id)"
                v-if="portfolios.length > 1"
                class="delete"
              >
                <img src="@/assets/img/icon_module_delete.svg" alt="" />
              </div>
            </div>
            <a :href="hrefUrl(item)" target="_blank">{{ item.title }}</a>
          </div>
        </div>
      </TransitionGroup>
      <!-- <div class="module-gap-div"></div> -->
    </PreviewModuleHolder>
  </div>
</template>

<script setup>
import PreviewTitle from "@/components/preview/PreviewTitle.vue";
import PreviewModuleHolder from "@/components/preview/PreviewModuleHolder.vue";
import editMix from "@/plugin/edit";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { defineProps, h } from "vue";
import { ElMessageBox } from "element-plus";
import useEventBus from "@/plugin/event";
import utils from "@/utils/utils.js";

const eventBus = useEventBus();

const props = defineProps({
  moduleId: String,
});

const store = useStore();

const portfolios = computed(() => store.state.edit.contentModules.portfolios);

const hrefUrl = (item) => {
  if (!item.url) {
    return undefined;
  } else {
    if (item.url.startsWith("http")) {
      return item.url;
    } else {
      return "https://" + item.url;
    }
  }
};

const onEmptyClick = () => {
  startModify("portfolios", null, props.moduleId, true);
};

const onItemClick = (item) => {
  startModify("portfolios", item, props.moduleId, false);
};

const onDeleteClick = (id) => {
  ElMessageBox({
    title: "删除提示",
    message: h("p", null, [
      h("span", null, "删除模块后"),
      h("span", { style: "color: #F56C6C" }, "将无法找回"),
      h("span", null, "，请确认是否删除"),
    ]),
    showCancelButton: true,
    confirmButtonText: "删除",
  }).then((action) => {
    eventBus.emit("showModify", { value: false });
    deleteModule({
      moduleId: props.moduleId,
      subId: id,
      module: "portfolios",
    });
  });
};

const { startModify, deleteModule } = editMix();
</script>

<style lang="scss" scoped>
@import "src/style/edit.scss";
@import "src/style/base.scss";

.preview-collection {
  @include module-card;
  overflow: auto;
}

.preview-collection-item-container {
  display: flex;
}

.preview-collection-item {
  @include sub-module-card;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  a {
    margin-top: 5px;
    text-decoration: underline;
    &:visited {
      color: #007dff;
    }
    &:link {
      color: #007dff;
    }
  }

  .item {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;

    .qrcode {
      width: 80px;
      height: 80px;
      position: relative;

      & > .delete {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: #f58989;
        border-radius: 4px;
        visibility: hidden;
        opacity: 0;
        transition: all 250ms;
        &:hover {
          background: #eeacac;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }

      &:hover .delete {
        visibility: visible;
        opacity: 1;
      }

      .stub {
        position: absolute;
        width: 80px;
        height: 80px;
        left: 0px;
        top: 0px;
        border: 1px solid #e4e7ed;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 12px;
          height: 12px;
          border-radius: 4px;
        }
      }

      & > .real {
        position: absolute;
        width: 80px;
        height: 80px;
        left: 0px;
        top: 0px;
        & > .el-image {
          width: 80px;
          height: 80px;
          position: absolute;
          left: 0px;
          top: 0px;
        }
      }
    }
  }
}
</style>