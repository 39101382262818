<template>
  <div class="operate-add-module">
    <div
      v-for="(item, index) in listData"
      :key="index"
      class="module-item"
      @click="onItemClick(item)"
    >
      <img :src="item.icon" alt="" />
      <div class="title">{{ item.title }}</div>
      <div class="desc">{{ item.desc }}</div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import iconAwards from "@/assets/img/icon_add_awards.svg";
import iconCustom from "@/assets/img/icon_add_custom.svg";
import iconEdu from "@/assets/img/icon_add_edu.svg";
import iconPersonal from "@/assets/img/icon_add_personal.svg";
import iconProject from "@/assets/img/icon_add_project.svg";
import iconPortfolios from "@/assets/img/icon_add_portfolios.svg";
import iconSchool from "@/assets/img/icon_add_school.svg";
import iconWork from "@/assets/img/icon_add_work.svg";
import iconEvaluate from "@/assets/img/icon_add_evaluate.svg";
import { ElMessage } from "element-plus";
import editMix from "@/plugin/edit";

const store = useStore();
const { addParentModule } = editMix();

const data = [
  {
    id: 1,
    title: "教育经历",
    desc: "介绍个人学校学历背景及专业",
    module: "edu_experience",
    icon: iconEdu,
  },
  {
    id: 2,
    title: "工作经历",
    desc: "介绍过往工作或实习内容",
    module: "work_experience",
    icon: iconWork,
  },
  {
    id: 3,
    title: "校园经历",
    desc: "介绍学生会、社团等经历，凸显综合素质",
    module: "school_experience",
    icon: iconSchool,
  },
  {
    id: 4,
    title: "项目经历",
    desc: "介绍与岗位或专业相关的项目",
    module: "project_experience",
    icon: iconProject,
  },
  {
    id: 5,
    title: "自定义经历",
    desc: "可自定义过往经历",
    module: "custom_experience",
    icon: iconCustom,
  },
  {
    id: 6,
    title: "荣誉奖项",
    desc: "展示高含金量奖项，如奖学金、比赛",
    module: "awards",
    icon: iconAwards,
  },
  {
    id: 7,
    title: "个人总结",
    desc: "总结个人优势，尽可能契合岗位要求",
    module: "personal_summary",
    icon: iconPersonal,
  },
  {
    id: 8,
    title: "作品集",
    desc: "上传作品链接，如小程序、产品原型等",
    module: "portfolios",
    icon: iconPortfolios,
  },
  {
    id: 9,
    title: "自我评价",
    desc: "给出合理的自我评价有助于面试官了解你",
    module: "evaluation",
    icon: iconEvaluate,
  },
];

const modules = computed(() => store.state.edit.modules);

const listData = computed(() => {
  const list = [];
  var hasAddCustom = false;
  for (const item of data) {
    const module = modules.value.find(
      (module) => module.module === item.module
    );
    if (!module) {
      if (item.module === "custom_experience") {
        hasAddCustom = true;
      }
      list.push(item);
    }
  }
  if (!hasAddCustom) {
    list.push(data[4]);
  }
  return list;
});

const onItemClick = async (item) => {
  var customCount = 0;
  for (const module of modules.value) {
    if (module.module === "custom_experience") {
      customCount++;
    }
  }
  if (item.id === 5 && customCount >= 6) {
    // 判断自定义经历的数量
    ElMessage.error("自定义经历最多只能添加6条");
  } else {
    await addParentModule({
      id: `${new Date().getTime()}`,
      module: item.module,
      title: "",
    });
    const editPreviewEle = document.querySelector(".edit-preview");
    if (editPreviewEle) {
      editPreviewEle.scrollTop = editPreviewEle.scrollHeight;
    }
    // 给添加的元素添加 bounce 动画
    const addModuleEles = document.getElementsByClassName("mainModule");
    const addModule = addModuleEles[addModuleEles.length - 1];
    if (addModule) {
      addModule.classList.add("bounceIn"); // animate__animated
      setTimeout(() => {
        addModule.classList.remove("bounceIn");
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
.operate-add-module {
  width: $operate-width;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.module-item {
  width: 264px;
  height: 59px;
  box-shadow: 0px 2px 5px rgba(203, 203, 203, 0.2);
  border-radius: 5px;
  background: #f9f9f9;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: all 250ms;

  &:nth-child(n + 2) {
    margin-top: 10px;
  }

  img {
    width: 20px;
    height: 20px;
    top: 10px;
    left: 10px;
    position: absolute;
  }
  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
    left: 35px;
    top: 10px;
    position: absolute;
  }
  .desc {
    font-size: 12px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
    left: 35px;
    top: 32px;
    position: absolute;
  }
  &:hover {
    background: #dfdfdf;
  }
}
</style>