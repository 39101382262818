<template>
  <div class="modify-head">
    <div class="head-back" @click="onBackClick">
      <img src="@/assets/img/icon_head_back.svg" />
      <span>返回</span>
    </div>
    <div class="head-title">
      <span>{{ title }}</span>
      <img
        src="@/assets/img/icon_modify_head_edit.svg"
        alt="编辑"
        @click="onEditModuleTitleClick"
        :style="{ display: showEdit }"
      />
    </div>
    <div class="head-save" @click="onSaveClick" v-if="false">保存</div>
    <el-dialog
      v-model="dialogVisible"
      title="修改模块名称"
      width="420px"
      top="35vh"
      :destroy-on-close="true"
      @open="onDialogOpen"
      :append-to-body="true"
    >
      <el-input
        v-model="nameInput"
        maxlength="30"
        show-word-limit
        placeholder="请输入简历名称"
        size="large"
        clearable
        autofocus
        class="name-input"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click="onConfirmClick"
            :disabled="nameInput === ''"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import useEventBus from "@/plugin/event";
import { ElMessage, ElMessageBox } from "element-plus";
import editMix from "@/plugin/edit";
import { nextTick, onMounted, onUnmounted } from "@vue/runtime-core";
import editApi from "@/apis/edit_api";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();
const eventBus = useEventBus();
const { onBackDeal, onSaveClick, getModuleTitleValue, updateModuleTitle } =
  editMix();

const onBackClick = async () => {
  if (await onBackDeal()) {
    eventBus.emit("showModify", { value: false });
  }
};

const title = computed(() => {
  const modifyModule = store.state.edit.modifyModule;
  const editModuleId = store.state.edit.editModuleId;
  return getModuleTitleValue(modifyModule, editModuleId);
});

const showEdit = computed(() => {
  const modifyModule = store.state.edit.modifyModule;
  if (modifyModule === "basic_info") {
    return "none";
  } else {
    return "block";
  }
});

const escEvent = (e) => {
  if (e.keyCode === 27 && store.state.edit.isInEditMode) {
    onBackClick();
  }
};

const dialogVisible = ref(false);
const nameInput = ref("");

const onConfirmClick = async () => {
  console.log(nameInput.value);
  dialogVisible.value = false;
  await updateModuleTitle(nameInput.value);
};

const onDialogOpen = () => {
  nameInput.value = title.value;
  nextTick(() => {
    document
      .getElementsByClassName("name-input")[0]
      .getElementsByClassName("el-input__inner")[0]
      .focus();
  });
};

const onEditModuleTitleClick = () => {
  dialogVisible.value = true;
  const modifyModule = store.state.edit.modifyModule;
};

onMounted(() => {
  document.addEventListener("keyup", escEvent);
});

onUnmounted(() => {
  document.removeEventListener("keyup", escEvent);
});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
.modify-head {
  width: var(--modify-width);
  height: 60px;
  max-height: 60px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: var(--transition);
}

.head-back {
  display: flex;
  cursor: pointer;
  height: 100%;
  align-items: center;
  margin-left: 20px;
  img {
    width: 5px;
    height: 10px;
    transition: all 250ms;
  }
  span {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
    margin-left: 5px;
    transition: all 250ms;
  }

  &:hover span {
    color: rgba(0, 0, 0, 0.5);
  }
  &:hover img {
    opacity: 0.5;
  }
}
.head-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
  }

  img {
    margin-left: 2px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all 250ms;
    &:hover {
      opacity: 0.8;
    }
  }
}
.head-save {
  margin-right: 20px;
  border-radius: 100px;
  width: 64px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #f56c6c;
  color: #f56c6c;
  transition: all 250ms;
  &:hover {
    background-color: rgba($color: #f56c6c, $alpha: 0.1);
  }
}

.el-input {
  width: calc(100% - 40px);
  margin-left: 20px;
}
</style>