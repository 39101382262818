<template>
  <div class="blog_list">
    <div class="content_container">
      <div class="content_list">
        <a
          class="blog_item"
          v-for="(item, index) in state.data"
          :key="item.id"
          :href="`/job_strategy_detail/${item.id}`"
          target="_blank"
        >
          <p class="title">
            {{ item.title }}
          </p>
          <div class="middle">
            <el-image :src="utils.getImageUrl(item.coverPicUrl)" />
            <div class="right">
              <p>
                {{ item.description }}
              </p>
              <div class="right_bottom">
                <el-avatar :src="item.author.avatarUrl" />
                <p>{{ item.author.name }}</p>
                <img src="@/assets/img/icon_blog_list_view_count.svg" alt="" />
                <p>{{ item.readBaseNum + item.readNum }}</p>
              </div>
            </div>
            <div
              class="bottom_line"
              v-show="index !== state.data.length - 1"
            ></div>
          </div>
        </a>
      </div>
    </div>
    <div class="blog_pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="state.total"
        :page-size="6"
        :small="false"
        :current-page="state.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import blogApi from "@/apis/blog_api";
import { ElMessage } from "element-plus";
import { routerKey, useRoute, useRouter } from "vue-router";
import utils from "@/utils/utils.js";

const store = useStore();
const router = useRouter();
const route = useRoute();

const state = reactive({
  isLoading: false,
  currentPage: 1,
  total: 0,
  data: [],
});

watch(
  () => route.params.category,
  (to, from) => {
    console.log("blog-list category change: ", to);
    if (to) {
      requestData(to, 1);
    }
  }
);

const handleSizeChange = (size) => {
  console.log(`${size} items per page`);
};

const handleCurrentChange = (current) => {
  console.log(`current page: ${current}`);
  requestData(store.state.blog.category, current);
};

const requestData = (category, page) => {
  state.isLoading = true;
  if (category === "latest") {
    category = "";
  } else if (category === "xiaozhaoqiuzhi") {
    category = "校招求职";
  } else if (category === "jianlimiji") {
    category = "简历秘籍";
  } else if (category === "bijingmianjing") {
    category = "笔经面经";
  } else if (category === "hangyezixun") {
    category = "行业资讯";
  } else if (category === "zhichangrensheng") {
    category = "职场人生";
  }
  blogApi
    .listByPage(page, category, 6)
    .then((result) => {
      if (result.code === 0) {
        state.total = result.data.total;
        state.data = result.data.record;
      } else {
        ElMessage.error(result.msg);
      }
      state.isLoading = false;
      state.currentPage = page;
    })
    .catch((msg) => {
      ElMessage.error(msg);
      state.isLoading = false;
    });
};

onMounted(() => {
  if (route.params.category) {
    requestData(route.params.category, 1);
  }
});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.blog_list {
  width: 700px;
  display: flex;
  flex-direction: column;
}

.content_container {
  width: 100%;
  min-height: 984px;
}

.content_list {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  @include card-bg;
}

.blog_pagination {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.blog_item {
  width: 100%;
  height: 164px;
  box-sizing: border-box;
  transition: all 250ms;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 20px;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.01);
  }

  .title {
    font-size: 17px;
    line-height: 24px;
    color: #252933;
    font-weight: 500;
  }

  .middle {
    display: flex;
    height: 90px;
    width: 100%;
    margin-top: 10px;
    position: relative;

    & > .el-image {
      width: 144px;
      height: 100%;
      border-radius: 5px;
    }

    & > .right {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 20px;
      position: relative;

      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        font-size: 14px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: 400;
      }
    }

    .bottom_line {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.05);
      position: absolute;
      bottom: -20px;
      left: 0;
    }
  }
}

.right_bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;

  .el-avatar {
    width: 19px;
    height: 19px;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    margin-left: 2px;
    color: rgba(0, 0, 0, 0.7);
  }

  img {
    margin-left: 15px;
  }
}

::v-deep(.number) {
  background-color: white !important;
  &.is-active {
    background-color: var(--el-color-primary) !important;
  }
}

::v-deep(.btn-next),
::v-deep(.btn-prev) {
  background-color: white !important;
}
</style>