<template>
  <div class="modify-basic-photo">
    <div class="basic-photo-title">照片</div>
    <div class="basic-photo-upload" @click="onUploadClick(true)">
      <img src="@/assets/img/icon_basic_upload.svg" alt="" />
      <span>{{ showRealAvatarImage ? "重新上传" : "立即上传" }}</span>
    </div>
    <div class="avatar-delete" @click="onAvatarClick">删除</div>
    <div class="basic-xiaohui-title">
      <span>校徽</span>
      <span>（已收录部分高校，若未收录可手动上传）</span>
    </div>
    <div class="basic-xiaohui-input">
      <AutoComplete
        v-model="state.schoolName"
        type="University"
        placeholder="输入学校名称搜索"
        @onChange="onSchoolChange"
        :isBadge="true"
      />
      <div class="basic-xiaohui-upload" @click="onUploadClick(false)">
        <img src="@/assets/img/icon_basic_upload.svg" alt="" />
        <span>手动上传</span>
      </div>
    </div>
    <div class="basic-xiaohui-preview">
      <el-image :src="emptyBadgeIcon" class="stub" v-if="!showRealImage" />
      <el-image
        :src="utils.getImageUrl(schoolBadgeUrl)"
        fit="contain"
        class="real"
        v-else
      />

      <img
        src="@/assets/img/icon_badge_delete.svg"
        class="delete"
        @click="onDeleteClick"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import emptyBadgeIcon from "@/assets/img/icon_basic_xiaohui_preview.svg";
import utils from "@/utils/utils.js";
import editMix from "@/plugin/edit";

const { onSaveClick } = editMix();
const store = useStore();
const state = reactive({
  schoolName: "",
});

const avatarUrl = computed({
  get: () => store.state.edit.contentModules.basicInfo.avatarUrl,
  set: (val) => {
    store.commit("updateBasicInfo", { avatarUrl: val });
  },
});

const showRealAvatarImage = ref(false);

const showAvatarDelete = computed(() => {
  if (avatarUrl.value) {
    showRealAvatarImage.value = true;
    return "flex";
  } else {
    showRealAvatarImage.value = false;
    return "none";
  }
});

const onAvatarClick = async () => {
  avatarUrl.value = "";
  await onSaveClick(true, false);
};

const schoolBadgeUrl = computed({
  get: () => store.state.edit.contentModules.basicInfo.schoolBadgeUrl,
  set: (val) => {
    store.commit("updateBasicInfo", { schoolBadgeUrl: val });
  },
});

const showRealImage = ref(false);

const showDelete = computed(() => {
  if (schoolBadgeUrl.value) {
    showRealImage.value = true;
    return "block";
  } else {
    showRealImage.value = false;
    return "none";
  }
});

const onSchoolChange = async (val) => {
  console.log("onSchoolChange", val);
  if (val && val.logo) {
    schoolBadgeUrl.value = val.logo;
    await onSaveClick(true, false);
  }
};

const onDeleteClick = async () => {
  schoolBadgeUrl.value = "";
  await onSaveClick(true, false);
};

const onUploadClick = (isPhoto) => {
  store.commit("setUploadData", {
    uploadVisible: true,
    uploadTitle: isPhoto ? "上传证件照" : "上传校徽",
    uploadType: isPhoto ? "photo" : "badge",
    uploadSubTitle: isPhoto
      ? "仅支持jpeg、jpg、png格式，不超过2MB"
      : "仅支持jpeg、jpg、png格式，不超过500KB",
  });
};
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.basic-photo-title {
  font-size: 14px;
  line-height: 20px;
  width: var(--modify-width);
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.basic-photo-upload {
  width: 112px;
  height: 36px;
  background: rgba($color: #e4e7ed, $alpha: 0.5);
  border-radius: 4px;
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 250ms;
  img {
    width: 14px;
    height: 14px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-left: 2px;
    color: rgba($color: #000000, $alpha: 0.5);
  }
  &:hover {
    background: rgba($color: #e4e7ed, $alpha: 1);
  }
}

.modify-basic-photo {
  position: relative;

  .avatar-delete {
    width: 68px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    display: v-bind(showAvatarDelete);
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    color: #606266;
    transition: all 250ms;
    position: absolute;
    left: 142px;
    top: 30px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  .basic-xiaohui-title {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    margin-left: 20px;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 20px;
    span {
      &:nth-child(2) {
        font-size: 12px;
      }
    }
  }
  .basic-xiaohui-input {
    margin-top: 10px;
    display: flex;
    height: 38px;
    align-items: center;
    margin-left: 20px;

    .el-input {
      width: var(--form-width);
      height: 36px;
    }

    .basic-xiaohui-upload {
      width: 112px;
      height: 38px;
      margin-bottom: 10px;
      background: rgba($color: #e4e7ed, $alpha: 0.5);
      border-radius: 4px;
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 250ms;
      img {
        width: 14px;
        height: 14px;
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-left: 2px;
        color: rgba($color: #000000, $alpha: 0.5);
      }
      &:hover {
        background: rgba($color: #e4e7ed, $alpha: 1);
      }
    }
  }
  .basic-xiaohui-preview {
    background: #ffffff;
    border: 1px solid #e4e7ed;
    box-sizing: border-box;
    margin-top: 10px;
    position: relative;
    width: var(--form-width);
    height: 75px;
    margin-left: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .stub {
      width: 20px;
      height: 20px;
    }
    .real {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    .delete {
      width: 20px;
      height: 20px;
      right: 0px;
      bottom: 0px;
      cursor: pointer;
      position: absolute;
      display: v-bind(showDelete);
    }
  }
}
</style>