<template>
  <div class="chart">
    <Charts :options="options" />
  </div>
</template>

<script setup>
import Charts from "@/components/Charts.vue";
import { onMounted, ref, defineProps, watch, toRefs, computed } from "vue";

const props = defineProps({
  data: {
    type: Array,
    default: function () {
      return [];
    }, //dark为深色模式
    required: false,
  },
});

const { data } = toRefs(props);

const options = computed(() => {
  // 计算 data 数组中的和
  var sum = 0;
  data.value.forEach((item) => {
    sum += item.score;
  });
  // 计算占比
  var percent = [];
  data.value.forEach((item) => {
    percent.push(item.score / sum);
  });
  
  console.log("options", sum);
  const chartData = props.data.map((item) => {
    const percentValue = Math.round(item.score / sum * 100);
    return {
      name: item.title,
      value: item.score / sum,
      percentValue: percentValue,
      label: {
        formatter: `${item.title}\n${percentValue}%`,
        color: 'rgba(0, 0, 0, 0.7)',
      },
    };
  });
  var percentTotalValue = 0;
  chartData.forEach((item) => {
    percentTotalValue += item.percentValue;
  });
  if (percentTotalValue > 100) {
    const maxData = chartData.reduce((prev, current) => {
      return prev.percentValue > current.percentValue ? prev : current;
    });
    maxData.percentValue = maxData.percentValue - (percentTotalValue - 100);
    maxData.label = {
      formatter: `${maxData.name}\n${maxData.percentValue}%`,
      color: 'rgba(0, 0, 0, 0.7)',
    };
    console.log("maxData", maxData);
  }

  return {
    color: ["#FC6E6D", "#FFE78D", "#FFDA43", "#FEB74D"],
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["30%", "70%"],
        avoidLabelOverlap: false,
        labelLine: {
          show: false,
        },
        data: chartData,
      },
    ],
  };
});

onMounted(() => {
  console.log("data", data.value);
});
</script>

<style lang="scss" scoped>
.chart {
  width: 420px;
  height: 240px;
}
</style>