<template>
  <div class="edit-head">
    <el-header>
      <div class="head-back" @click="onBackClick" v-if="!fromCMS">
        <img src="@/assets/img/icon_head_back.svg" />
      </div>
      <div class="head-name" @click="onEditTitleClick">
        <span>{{ title }}</span>
        <img src="@/assets/img/icon_head_edit.svg" v-if="canEdit" />
      </div>
      <div class="head-right">
        <span v-if="canUpload" @click="onUploadClick">导入</span>
        <span
          v-if="canPublish"
          @click="onDownloadLoginClick"
          v-loading="state.isPublishing"
          >{{ getPublishDesc() }}</span
        >
        <span v-if="canExport" @click="onDownloadLoginClick">下载</span>
        <span v-if="!hasLogin" @click="onDownloadLoginClick">登录</span>
        <Avatar v-if="hasLogin" class="edit-head"></Avatar>
      </div>
      <div class="auto-update-desc" v-if="route.query.edit !== 'false'">
        {{ editSaveDesc }}
      </div>
    </el-header>
    <el-dialog
      v-model="dialogVisible"
      title="修改简历名称"
      width="420px"
      top="35vh"
      :destroy-on-close="true"
      @open="onDialogOpen"
    >
      <el-input
        v-model="nameInput"
        placeholder="请输入简历名称"
        size="large"
        maxlength="30"
        show-word-limit
        clearable
        autofocus
        class="name-input"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click="onConfirmClick"
            :disabled="nameInput === ''"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import Avatar from "@/components/common/Avatar";
import { useStore } from "vuex";
import { h, nextTick, onMounted, onUnmounted } from "vue";
import editMix from "@/plugin/edit";
import editApi from "@/apis/edit_api";
import analyze from "@/plugin/analyze.js";

const router = useRouter();
const route = useRoute();
const store = useStore();
const { onBackDeal, addResumeData, updateFormatInfo } = editMix();

const dialogVisible = ref(false);
const nameInput = ref("");

const onConfirmClick = () => {
  console.log(nameInput.value);
  dialogVisible.value = false;
  store.commit("updateResumeTitle", nameInput.value);
  updateFormatInfo(true);
};

const onEditTitleClick = () => {
  if (canEdit.value) {
    dialogVisible.value = true;
    analyze.sendClickEvent("edit_rename_click");
  }
  // router.push({ path: `/resumePrint/${route.params.id}` });
};

const getPublishDesc = () => {
  return state.isPublishing ? "发布中" : "发布";
};

const fromCMS = computed(() => {
  return (
    route.query.type === "template_resume" || route.query.type === "user_resume"
  );
});

const onBackClick = async () => {
  console.log(
    "edit-head onBackClick hasLogin:",
    hasLogin,
    route.redirectedFrom
  );

  if (hasLogin.value) {
    if (await onBackDeal()) {
      // router.push("/");
      router.back();
    }
  } else {
    // ? 未登录
    // ? 如果没有修改，则直接退出
    // ? 需要先判断数据是否修改，是否数据为空，是否有必填项未填
    // ? 如果有必填项未填，则提示用户；如果没有提示是否需要登录
    console.log(
      "edit-head onBackClick",
      store.state.edit.needShowDialogWhenBack,
      store.state.edit.contentHasChanged
    );
    if (
      store.state.edit.needShowDialogWhenBack ||
      store.state.edit.contentHasChanged
    ) {
      // 如果需要展示dialog，或者内容发生变化，则弹窗提示
      if (await onBackDeal(false)) {
        ElMessageBox({
          title: "请登录后再离开",
          message: h("p", null, [
            h("span", null, "当前为预览模式，离开后内容将丢失。"),
            h(
              "span",
              { style: "color: #F56C6C" },
              "请点击立即登录，即可将简历安全存储在云端并随时修改"
            ),
          ]),
          showCancelButton: true,
          confirmButtonText: "立即登录",
          cancelButtonText: "仍然返回",
          distinguishCancelAndClose: true,
        })
          .then(async (action) => {
            console.log("backClick action", action);
            if (action === "confirm") {
              store.commit("updateLoginDialog", {
                showLoginDialog: true,
                from: "编辑器左上角返回",
              });
            }
          })
          .catch((err) => {
            if (err === "cancel") {
              // router.push("/");
              router.back();
            }
          });
      }
    } else {
      router.back();
    }
  }
};

const onDialogOpen = () => {
  nameInput.value = store.state.edit.title;
  console.log("onDialogOpen");
  nextTick(() => {
    document
      .getElementsByClassName("name-input")[0]
      .getElementsByClassName("el-input__inner")[0]
      .focus();
  });
};

const hasLogin = computed(() => store.getters.hasLogin);
const editSaveDesc = computed(() => {
  if (hasLogin.value) {
    return store.state.edit.editSaveDesc;
  } else {
    return "";
  }
});

const onPublishClick = async () => {
  // ? 检查必填项
  const editTemplateInfo = store.state.edit.editTemplateInfo;
  if (!editTemplateInfo.title) {
    ElMessage.error("请填写模板标题");
    return;
  }
  if (!editTemplateInfo.desc) {
    ElMessage.error("请填写模板描述");
    return;
  }
  if (!editTemplateInfo.baseUseNum && editTemplateInfo.baseUseNum !== 0) {
    ElMessage.error("请填写模板已用基数");
    return;
  }

  if (state.isPublishing) {
    return;
  }
  store.commit("setIsPublish", true);
  try {
    state.isPublishing = true;
    await onBackDeal(true);
    const result = await editApi.publish(route.params.id);
    if (result?.code === 0) {
      ElMessage.success("发布成功");
      closeWindow();
    } else {
      ElMessage.error("发布失败");
      state.isPublishing = false;
    }
  } catch (e) {
    ElMessage.error("发布失败");
    state.isPublishing = false;
  }
  store.commit("setIsPublish", false);
};

const onUploadClick = () => {
  store.commit("setUploadData", {
    uploadVisible: true,
    uploadType: "resume",
    uploadTitle: "导入已有简历",
    uploadSubTitle: "仅支持Word、PDF格式的简历，不超过5MB",
    uploadResumeId: "",
  });
};

const state = reactive({
  isLoading: false, // 虚拟进度 2s
  downloadUrl: "",
  intervalId: null,
  isPublishing: false,
});

const exportProgress = computed({
  get: () => store.state.edit.editExportDialog.progress,
  set: (val) => {
    store.commit("updateEditExportDialog", {
      progress: val,
    });
  },
});

import vipMix from "@/plugin/vip";
const { onVipToast } = vipMix();

const onExportClick = async () => {
  // 如果当前的样式是 vip，但是用户不是 vip 需要提示 您使用了会员模板样式~
  const style = store.state.edit.style;
  const isVip = store.getters.isVip;
  console.log("onExportClick", style, isVip);
  if (style !== 1 && !isVip) {
    onVipToast("您使用了会员模板样式~");
    return;
  }

  store.commit("updateEditExportDialog", {
    visible: true,
    progress: 0,
  });
  state.isLoading = true;
  requestExportData();
  state.intervalId = setInterval(() => {
    exportProgress.value += 1;
    if (state.intervalId && exportProgress.value >= 95) {
      // ? 如果进度超过95，则清除定时器
      console.log("进度结束， 清楚定时器");
      clearInterval(state.intervalId);
      state.intervalId = null;
      if (state.downloadUrl) {
        // 如果下载地址不为空，则下载
        downloadResume();
      }
      state.isLoading = false;
    }
  }, 20);
};

const requestExportData = async () => {
  try {
    const result = await editApi.exportResume(route.params.id);
    if (result?.code === 0 && result.data) {
      state.downloadUrl = result.data;
      console.log("请求导出简历成功 loading ", state.isLoading);
      if (state.isLoading === false) {
        // ? 如果2s假的进度已经结束，则开始下载
        downloadResume();
      }
    } else {
      onExportFinish(false, result.msg);
    }
  } catch (e) {
    onExportFinish(false);
  }
};

const downloadResume = async () => {
  console.log("downloadResume");
  downloadfun(`${store.state.edit.title}.pdf`, state.downloadUrl);

  onExportFinish(true);
  state.downloadUrl = "";
};

// 下载文件
const downloadfun = (file_name, file_path) => {
  console.log(file_name);
  var ajax = new XMLHttpRequest();
  ajax.open("GET", file_path, true);
  ajax.setRequestHeader("Cache-Control", "no-cache");
  ajax.setRequestHeader("Access-Control-Allow-Origin", "*");
  ajax.responseType = "blob";
  ajax.onload = (e) => {
    console.log(e);
    let res = e.target.response;
    let blob = new Blob([res]);
    let aLink = document.createElement("a");
    aLink.download = file_name; // 下载文件的名字
    aLink.href = URL.createObjectURL(blob);
    aLink.click();
  };
  ajax.send();
};

const onExportFinish = (isSuccess, msg = "下载失败") => {
  if (isSuccess) {
    exportProgress.value = 100;
    ElMessage.success("下载成功");
    setTimeout(() => {
      store.commit("updateEditExportDialog", {
        visible: false,
        progress: 0,
      });
    }, 1000);
  } else {
    ElMessage.error(msg);
    store.commit("updateEditExportDialog", {
      visible: false,
      progress: 0,
    });
  }
};

const onDownloadLoginClick = async () => {
  if (hasLogin.value) {
    if (canPublish.value) {
      await onPublishClick();
    } else {
      analyze.sendClickEvent("edit_export_resume_click");
      await onExportClick();
    }
  } else {
    if (await onBackDeal(false)) {
      store.commit("updateLoginDialog", {
        showLoginDialog: true,
        from: "编辑器右上角按钮",
      });
    }
  }
};

const title = computed(() => {
  if (store.state.edit.title) {
    return store.state.edit.title;
  } else {
    return "未命名简历";
  }
});

const canPublish = computed(
  () => route.query.type === "template_resume" && route.params.id
);

const canUpload = computed(
  () => route.query.type === "template_resume" && !route.params.id
);

const canExport = computed(
  () => hasLogin.value && !route.query.type && route.name === "edit"
);

const canEdit = computed(
  () => route.query.edit !== "false" && route.query.type !== "template_resume"
);

const closeWindow = () => {
  window.removeEventListener("beforeunload", beforeunloadHandler);
  if (
    navigator.userAgent.indexOf("Firefox") != -1 ||
    navigator.userAgent.indexOf("Chrome") != -1
  ) {
    window.location.href = "about:blank";
    window.close();
  } else {
    window.opener = null;
    window.open("", "_self");
    window.close();
  }
};

const beforeunloadHandler = (event) => {
  console.log("beforeunloadHandler", event);
  if (event) {
    event.returnValue = "关闭提示";
  }
  return "关闭提示";
};

onMounted(() => {
  if (route.query.edit !== "false") {
    window.addEventListener("beforeunload", beforeunloadHandler);
  }
  store.commit("setIsPublish", false);
});

onUnmounted(() => {
  window.removeEventListener("beforeunload", beforeunloadHandler);
});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
.el-header {
  height: $header-height;
  background-color: white;
  width: 100vw;
  box-shadow: 0px 5px 10px rgba(203, 203, 203, 0.1);
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 120px;
  position: relative;
}

.auto-update-desc {
  position: absolute;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.head-back {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 9px;
    height: 11px;
    margin-bottom: 2px;
  }
}

.head-name {
  height: 100%;
  margin-left: 5px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  flex: 1;
  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: black;
  }
  img {
    width: 15px;
    height: 15px;
    margin-left: 8px;
  }
}

.head-right {
  display: flex;
  align-items: center;
  & > span {
    border-radius: 100px;
    width: 64px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    transition: all 250ms;

    &:nth-child(1) {
      margin-left: 10px;
      background: $primary-color;
      color: white;
      &:hover {
        background: rgba($color: $primary-color, $alpha: 0.8);
      }
    }

    &:nth-child(2) {
      margin-left: 10px;
      background: $primary-color;
      color: white;
      &:hover {
        background: rgba($color: $primary-color, $alpha: 0.8);
      }
    }
  }
  .edit-head {
    margin-left: 9px;
  }
}

::v-deep(.el-dialog__body) {
  padding-left: 20px;
  padding-right: 20px;
}

::v-deep(.circular) {
  width: 30px !important;
  height: 30px !important;
  margin-left: 15px;
  margin-top: 5px;
}
</style>