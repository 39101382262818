import {createGetApi, createPostApi, SERVER_URL} from './network'

const VIP_URL = SERVER_URL + '/api/vip';

export default {
    createOder: function (tradeType, vipType, accountId, authCode = "") {
        return createPostApi(`${VIP_URL}/createOder`, {
            tradeType,
            vipType,
            accountId,
            authCode
        })
    },
}