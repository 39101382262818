<template>
  <div class="mobile">
    <router-view v-slot="{ Component }">
      <Transition name="fade" mode="out-in">
        <component :is="Component"></component>
      </Transition>
    </router-view>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.mobile {
  background-color: #f4f5f5;
}
</style>