<template>
  <div class="modify-honor-info">
    <el-form
      label-position="top"
      :model="edit_object"
      size="large"
      :inline="true"
      label-width="1000px"
      :rules="rules"
    >
      <el-form-item label="荣誉奖项名称" prop="title">
        <el-input v-model="title" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="获得时间" prop="time">
        <el-date-picker
          v-model="time"
          type="month"
          placeholder="请选择"
          :editable="false"
          format="YYYY-MM"
          value-format="YYYY-MM"
          :disabled-date="disabledDate"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { onBeforeMount, onBeforeUnmount, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";

const store = useStore();

const edit_object = computed(() => store.state.edit.edit_object);
const title = computed({
  get: () => store.state.edit.edit_object.title,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { title: val });
  },
});
const time = computed({
  get: () => store.state.edit.edit_object.time,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { time: val === null ? "" : val });
  },
});

const shortcuts = [
  {
    text: "至今",
    value: new Date(),
  },
];

const rules = reactive({
  // title: [
  //   {
  //     required: true,
  //     message: "请输入荣誉奖项名称",
  //     trigger: "blur",
  //   },
  // ],
  // time: [
  //   {
  //     required: true,
  //     message: "请输入荣誉奖获得时间",
  //     trigger: "blur",
  //   },
  // ],
});
const disabledDate = (time) => {
  return time.getTime() > Date.now();
};

</script>

<style lang="scss">
@import "src/style/base.scss";

.modify-honor-info {
  .el-input {
    margin-top: -6px;
    width: calc(var(--form-width) * 2);
    .el-input__validateIcon {
      display: none;
    }
  }
  .el-select {
    width: calc(var(--form-width) * 2);
  }
  .el-form-item {
    width: calc(var(--form-width) * 2);
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: 16px;
    &:nth-child(9) {
      margin-left: 0px;
    }
  }
}
</style>