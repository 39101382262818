import axios from "axios";

var OSS = require('ali-oss')

export default {
    /**
     * 创建随机字符串
     * @param num
     * @returns {string}
     */
    randomString (num) {
        let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
        let res = ''
        for (let i = 0; i < num; i++) {
            var id = Math.ceil(Math.random() * 35)
            res += chars[id]
        }
        return res
    },

    /**
     * 创建oss客户端对象
     * @returns {*}
     */
    createOssClient () {
        return new OSS({
            region: 'oss-cn-hangzhou',
            accessKeyId: 'LTAI5t9FpRZQ1eunZB8W7yWd',
            accessKeySecret: 'vVv6tIrY06ruG4K6CZQmsQJg27WjZ9',
            bucket: 'stronghire'
        })
    },

    ossUploadFile (policyData, file) {
        let params = new FormData();
        params.append('key',policyData.path);
        params.append('policy',policyData.policy);
        params.append('OSSAccessKeyId',policyData.accessKeyId);
        params.append('signature',policyData.signature);
        params.append('file',file);
        console.log("params", params);
        return new Promise((resolve, reject) => {
            axios.post(policyData.host, params, {
                timeout: 10000,
            }).then((response) => {
                console.log(response)
            }).catch((error) => {
                console.log(error)
            })
        })
    },

    /**
     * 文件下载
     * @param fileName 文件名
     * @param option 参考csdn: https://blog.csdn.net/qq_27626333/article/details/81463139
     */
    ossDownloadFile (fileName) {
        return this.createOssClient().signatureUrl(fileName)
    }
}