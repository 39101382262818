<template>
  <div class="pay-bottom">
    <div class="qr_code_container">
      <qrcode-vue class="qr-code" :value="qrCodeUrl" :size="114" level="H" />
    </div>
    <div class="right">
      <p class="refresh" @click="onRefreshClick" v-show="refreshButtonVisible">
        我已支付
      </p>
      <div class="money">
        <span>￥</span>
        <span>{{ money }}</span>
      </div>
      <div class="icon">
        <img src="@/assets/img/icon_ali_pay.svg" />
        <img src="@/assets/img/icon_wechat_pay.svg" />
      </div>
      <p>
        请使用微信或支付宝扫码支付 支付则代表您同意<a
          href="/vip_service"
          target="_blank"
          >《会员服务协议》</a
        >
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, defineExpose, defineEmits } from "vue";
import myCookie from "@/apis/cookie";
import QrcodeVue from "qrcode.vue";
import homeApi from "@/apis/home_api";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  vipType: {
    type: Number,
    default: 1,
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
});

const qrCodeUrl = computed(() => {
  const appUrl = process.env.VUE_APP_URL;
  const accountId = Buffer.from(
    myCookie.getStaffUserInfo()?.accountId ?? ""
  ).toString("base64");
  // 压缩文本
  const param = `?a=${accountId}&v=${props.vipType}`;
  const url = `${appUrl}/m/vip_pay/${param}`;
  console.log(url);
  return url;
});

const money = computed(() => {
  switch (props.vipType) {
    case 1:
      return "19";
    case 2:
      return "45";
    case 3:
      return "79";
    case 4:
      return "99";
    default:
      return "";
  }
});

const refreshButtonVisible = ref(false);

const showRefreshButton = () => {
  setTimeout(() => {
    refreshButtonVisible.value = true;
  }, 5000);
};

defineExpose({ showRefreshButton });

const emits = defineEmits(['onPaySuccess'])

const onPaySuccess = () => {
  if (props.isDialog) {
    console.log("isDialog");
    emits('onPaySuccess');
  } else {
    router.push("/account/vip_welcome")
  }
}

const onRefreshClick = async () => {
  try {
    const result = await homeApi.queryOrder();
    if (result.code === 0) {
      if (!result.data) {
        ElMessage.error("未进行支付");
      } else if (result.data.state === 1) {
        ElMessage.success("支付成功");
        setTimeout(() => {
          onPaySuccess();
        }, 500);
      } else {
        ElMessage.error("支付失败");
      }
    } else {
      ElMessage.error("支付失败");
    }
  } catch ({ msg: msg }) {
    ElMessage.error(msg);
  }
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
.pay-bottom {
  display: flex;

  .qr_code_container {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;

    .qr-code {
      width: 114px;
      height: 114px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    position: relative;

    .refresh {
      width: 102px;
      height: 40px;
      background: #d49d77;
      border-radius: 222222px;
      position: absolute;
      top: -12px;
      left: 105px;
      font-size: 14px;
      line-height: 22px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 250ms;
      cursor: pointer;
      &:hover {
        background: rgba($color: #d49d77, $alpha: 0.85);
      }
    }
    .money {
      display: flex;
      align-items: baseline;
      & > span {
        color: white;
        &:nth-child(1) {
          font-size: 25px;
        }
        &:nth-child(2) {
          font-size: 40px;
          font-weight: 600;
        }
      }
    }
    .icon {
      display: flex;
      margin-top: 5px;
      img {
        width: 30px;
        height: 30px;
        &:nth-child(2) {
          margin-left: 10px;
        }
      }
    }
    & > p {
      font-size: 16px;
      line-height: 22px;
      color: white;
      margin-top: 15px;
      a {
        color: white;
        font-weight: 600;
      }
    }
  }
}
</style>