import { useRoute } from 'vue-router';
import { createGetApi, createPostApi, SERVER_URL } from './network'

const TEMPLATE_URL = SERVER_URL + '/api/template_resume';
const DATA_URL = SERVER_URL + '/api/data';
const USER_URL = SERVER_URL + '/api/user_resume';

export default {
    // 直接获取模板的简历数据
    getDetail(id) {
        return createGetApi(`${TEMPLATE_URL}/getDetail`, { id })
    },
    // 获取用户的简历数据
    getUserDetail(id) {
        return createGetApi(`${USER_URL}/getDetail`, { id })
    },
    // 获取自动填充的数据
    getAutoData(keyword, type, hasLogo) {
        return createGetApi(`${DATA_URL}/get${type}`, { keyword, hasLogo })
    },
    // 添加数据
    addResume(data, isTemplate) {
        if (isTemplate) {
            return createPostApi(`${TEMPLATE_URL}/add`, data)
        } else {
            return createPostApi(`${USER_URL}/add`, data)
        }
    },
    // 获取用户所有简历
    getAllResumeData(data) {
        return createGetApi(`${USER_URL}/listAll`)
    },
    // 导入简历
    importResume(templateId, file, isTemplate) {
        if (isTemplate) {
            return createPostApi(`${TEMPLATE_URL}/importResume`, file, false)
        } else {
            return createPostApi(`${USER_URL}/addByImportResume`, file, false)
        }

    },
    // 编辑简历
    editResume(data, isTemplate) {
        if (isTemplate) {
            return createPostApi(`${TEMPLATE_URL}/edit`, data, false)
        } else {
            return createPostApi(`${USER_URL}/edit`, data, false)
        }
    },
    // 编辑模块标题
    editModuleTitle(data, isTemplate) {
        if (isTemplate) {
            return createPostApi(`${TEMPLATE_URL}/editModuleTitle`, data)
        } else {
            return createPostApi(`${USER_URL}/editModuleTitle`, data)
        }
    },
    // 删除模块
    deleteModule(data, isTemplate) {
        if (isTemplate) {
            return createPostApi(`${TEMPLATE_URL}/deleteModule`, data)
        } else {
            return createPostApi(`${USER_URL}/deleteModule`, data)
        }
    },
    // 添加模块
    addModule(data, isTemplate) {
        if (isTemplate) {
            return createPostApi(`${TEMPLATE_URL}/addModule`, data)
        } else {
            return createPostApi(`${USER_URL}/addModule`, data)
        }
    },
    // 编辑父模块顺序
    editModuleSequence(data, isTemplate) {
        if (isTemplate) {
            return createPostApi(`${TEMPLATE_URL}/editModuleSequence`, data)
        } else {
            return createPostApi(`${USER_URL}/editModuleSequence`, data)
        }
    },
    // 编辑子模块顺序
    editSubModuleSequence(data, isTemplate) {
        if (isTemplate) {
            return createPostApi(`${TEMPLATE_URL}/editSubModuleSequence`, data)
        } else {
            return createPostApi(`${USER_URL}/editSubModuleSequence`, data)
        }
    },
    // 解锁简历
    unlockResume(id) {
        return createPostApi(`${USER_URL}/unlock`, { id })
    },
    // 删除简历
    deleteResume(id) {
        return createPostApi(`${USER_URL}/delete`, { id })
    },
    // 复制简历
    duplicateResume(id) {
        return createPostApi(`${USER_URL}/duplicate`, { id })
    },
    // 获取最近一次的诊断数据
    getLastDiagnosisLog(id) {
        return createGetApi(`${USER_URL}/getLastDiagnosisLog`, { id })
    },
    // 简历诊断
    diagnosis(id, isTemplate) {
        if (isTemplate) {
            return createGetApi(`${TEMPLATE_URL}/diagnosis`, { id })
        } else {
            return createGetApi(`${USER_URL}/diagnosis`, { id })
        }
    },
    // 修改模板描述
    editTemplateInfo(data) {
        return createPostApi(`${TEMPLATE_URL}/editTemplateInfo`, data)
    },
    // 上线
    publish(id,) {
        return createPostApi(`${TEMPLATE_URL}/publish`, {
            id: id,
            online: true
        })
    },
    // 导入简历
    exportResume(id) {
        return createPostApi(`${USER_URL}/export`, { id })
    },
    // 查询最近一次简历画像结果
    getLastResumePortrait(id) {
        return createGetApi(`${USER_URL}/getLastResumePortrait`, { id })
    },
    // 刷新简历画像
    refreshPortrait(id) {
        return createPostApi(`${USER_URL}/refreshPortrait`, { id })
    },
    // 查询最近一次AI预测结果
    getLastResumeAiPrediction(id) {
        return createGetApi(`${USER_URL}/getLastResumeAiPrediction`, { id })
    },
    // 刷新AI预测
    refreshAiPrediction(id, jdCompany, jdTitle, jdDesc) {
        return createPostApi(`${USER_URL}/refreshAiPrediction`, { id, jdCompany, jdTitle, jdDesc })
    },
    // 获取搜索热词
    getRecommendKeywords() {
        return createGetApi(`${TEMPLATE_URL}/getRecommendKeywords`)
    },
}

// import editApi from "@/apis/edit_api";