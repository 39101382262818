<template>
  <div class="counseling_middle1">
    <p class="title">成效，</p>
    <p class="title">果然StrongHire</p>
    <div class="list_container">
      <div v-for="(item, index) in data" :key="index" class="list_item">
        <img :src="item.src" alt="" />
        <p class="title">{{ item.title }}</p>
        <p class="desc">
          {{ item.desc }}<span>{{ item.desc1 }}</span>
        </p>
      </div>
    </div>
    <div class="bottom_container">
      <div>
        <p>简历通过率</p>
        <div>
          <img src="@/assets/img/icon_counseling_up.svg" alt="" />
          <p class="p1">52.96</p>
          <p class="p2">%</p>
        </div>
      </div>
      <div>
        <p>面试通过率</p>
        <div>
          <img src="@/assets/img/icon_counseling_up.svg" alt="" />
          <p class="p1">65.18</p>
          <p class="p2">%</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const data = [
  {
    title: "简历优化",
    desc: "挖掘亮点，重组经历描述，打磨岗位关键词，让你的简历",
    desc1: "了不得",
    src: require("@/assets/img/icon_counseling_middle1.svg"),
  },
  {
    title: "模拟面试",
    desc: "一对一100%模拟真实面试场景，更加",
    desc1: "身临其境",
    src: require("@/assets/img/icon_counseling_middle2.svg"),
  },
  {
    title: "面试辅导",
    desc: "解决面试的各种疑难杂症，提升面试通过率，各种面试",
    desc1: "放马过来",
    src: require("@/assets/img/icon_counseling_middle3.svg"),
  },
  {
    title: "售后答疑",
    desc: "7至14天的辅导后答疑，",
    desc1: "尽管问，全天奉陪",
    src: require("@/assets/img/icon_counseling_middle4.svg"),
  },
];
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.counseling_middle1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    align-self: flex-start;
    font-size: 40px;
    line-height: 56px;
    color: black;
    font-weight: bold;
  }
}

.list_container {
  width: 100%;
  display: flex;
  margin-top: 40px;
}

.list_item {
  flex: 1;
  height: 294px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(203, 203, 203, 0.3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:nth-child(n + 2) {
    margin-left: 20px;
  }

  img {
    width: 175px;
    height: 102px;
    margin-top: 52px;
  }

  .title {
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 19px;
    align-self: center;
  }

  .desc {
    font-size: 16px;
    line-height: 22px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: rgba(0, 0, 0, 0.7);
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
    span {
      color: $primary-color;
    }
  }
}

.bottom_container {
  display: flex;
  width: 100%;
  margin-top: 60px;

  & > div {
    flex: 1;

    & > p {
      font-size: 30px;
      line-height: 42px;
      font-weight: 700;
      color: black;
    }

    & > div {
      display: flex;
      align-items: baseline;
      margin-top: 10px;

      img {
        width: 42px;
        height: 42px;
      }

      .p1 {
        font-size: 70px;
        line-height: 98px;
        color: $primary-color;
        font-weight: bold;
      }

      .p2 {
        font-size: 30px;
        line-height: 42px;
        color: $primary-color;
      }
    }
  }
}
</style>