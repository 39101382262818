
<template>
  <div id="calendar">
      calendar
  </div>
</template>

<script setup>
</script>

<style  scoped lang="scss">
  #calendar {
    height: 100vh;
  }
</style>