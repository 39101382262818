<template>
  <div class="modify-collection-info">
    <el-form
      label-position="top"
      :model="edit_object"
      size="large"
      :inline="true"
      label-width="1000px"
    >
      <el-form-item label="名称">
        <el-input
          v-model="title"
          placeholder="如：产品原型、网页、小程序等"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="链接">
        <el-input
          v-model="url"
          placeholder="请粘贴作品链接"
          clearable
        ></el-input>
      </el-form-item>
      <div class="collection-qrcode-title">
        <span>二维码或小程序码</span>
        <span> （小于500k）</span>
      </div>
      <div
        class="collection-qrcode-upload"
        @click="onQrCodeUploadClick"
        v-if="qrcodeUrl === '' || qrcodeUrl === undefined || qrcodeUrl === null"
      >
        <img src="@/assets/img/icon_upload_add.svg" alt="" />
      </div>
      <div v-else class="real" @click="onQrCodeUploadClick">
        <el-image :src="utils.getImageUrl(qrcodeUrl)" fit="contain"></el-image>
      </div>
    </el-form>
  </div>
</template>

<script setup>
import { computed, reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import { onMounted } from "@vue/runtime-core";
import utils from "@/utils/utils.js";

const store = useStore();

const edit_object = computed(() => store.state.edit.edit_object);

const title = computed({
  get: () => store.state.edit.edit_object.title,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { title: val });
  },
});

const url = computed({
  get: () => store.state.edit.edit_object.url,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { url: val });
  },
});

const qrcodeUrl = computed({
  get: () => store.state.edit.edit_object.qrcodeUrl,
  set: (val) => {
    store.commit("updateCurrentEdityDataModify", { qrcodeUrl: val });
  },
});

const shortcuts = [
  {
    text: "现在",
    value: new Date(),
  },
];

const state = reactive({
  name: "",
  link: "",
});

const onQrCodeUploadClick = () => {
  store.commit("setUploadData", {
    uploadVisible: true,
    uploadTitle: "上传二维码或小程序码",
    uploadType: "qr",
    uploadSubTitle: "仅支持jpeg、jpg、png格式，不超过500KB",
  });
};
</script>

<style lang="scss">
@import "src/style/base.scss";

.modify-collection-info {
  .el-input {
    margin-top: -6px;
    width: 500px;
    .el-input__validateIcon {
      display: none;
    }
  }
  .el-select {
    width: 500px;
  }
  .el-form-item {
    width: 500px;
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: 16px;
    &:nth-child(9) {
      margin-left: 0px;
    }
  }
  .collection-qrcode-title {
    display: flex;
    margin-left: 20px;
    width: var(--modify-width);
    align-items: baseline;
    span {
      &:nth-child(1) {
        font-size: 14px;
        line-height: 20px;
        color: rgba($color: #000000, $alpha: 0.7);
        // &::before {
        //   content: "*";
        //   color: $primary-color;
        // }
      }
      &:nth-child(2) {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
  .collection-qrcode-upload {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    border: 1px solid #e4e7ed;
    box-sizing: border-box;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .real {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    box-sizing: border-box;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    & > .el-image {
      width: 70px;
      height: 70px;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
}
</style>