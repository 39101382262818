<template>
  <div class="template_info_drawer">
    <p>模板简介</p>
    <el-form
      label-position="top"
      :model="editTemplateInfo"
      size="large"
      :inline="false"
      label-width="400px"
      :rules="rules"
    >
      <el-form-item label="模板标题" prop="title">
        <el-input
          v-model="title"
          placeholder="请输入模板标题"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="模板描述" prop="desc">
        <el-input
          v-model="desc"
          :autosize="{ minRows: 2, maxRows: 6 }"
          type="textarea"
          placeholder="请输入模板描述"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="已用基数" prop="baseUseNum">
        <el-input
          v-model="baseUseNum"
          placeholder="请输入模板已用基数"
          clearable
          type="number"
        ></el-input>
      </el-form-item>

      <el-form-item label="是否VIP">
        <div class="is_vip">
          <div :class="{ vip_select: vip }" @click="onVipClick(true)">是</div>
          <div :class="{ vip_select: !vip }" @click="onVipClick(false)">否</div>
        </div>
      </el-form-item>
      <el-form-item label="模板标签">
        <div class="template_tag">
          <div class="tag_array">
            <el-checkbox
              v-for="item in state.data"
              :key="item.id"
              v-model="item.checked"
              :label="item.label"
              size="large"
              @change="onChange(item)"
            />
          </div>
          <AutoComplete
            v-model="state.company"
            type="Company"
            placeholder="适用公司"
            @keyup.enter="handleInputConfirm('Company')"
            @onSelectChange="handleInputConfirm('Company')"
          />
          <AutoComplete
            v-model="state.university"
            type="University"
            placeholder="适用学校"
            @keyup.enter="handleInputConfirm('University')"
            @onSelectChange="handleInputConfirm('University')"
            :style="{ marginTop: '5px' }"
          />
          <AutoComplete
            v-model="state.job"
            type="Job"
            placeholder="适用岗位"
            @keyup.enter="handleInputConfirm('Job')"
            @onSelectChange="handleInputConfirm('Job')"
            :style="{ marginTop: '5px' }"
          />
          <el-input
            v-model="state.other"
            placeholder="输入其他标签"
            clearable
            @keyup.enter="handleInputConfirm('Other')"
          ></el-input>
          <div class="tag_show">
            <el-tag
              v-for="tag in labels"
              :key="tag"
              closable
              size="default"
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { onMounted, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";

const store = useStore();

const editTemplateInfo = computed(() => store.state.edit.editTemplateInfo);

const state = reactive({
  data: [
    { checked: false, label: "实习", id: 1 },
    { checked: false, label: "校招", id: 2 },
    { checked: false, label: "社招", id: 3 },
    { checked: false, label: "留学生", id: 4 },
  ],
  company: "",
  university: "",
  job: "",
  other: "",
});

const title = computed({
  get: () => store.state.edit.editTemplateInfo.title,
  set: (val) => {
    store.commit("updateEditTemplateInfo", { title: val });
  },
});

const desc = computed({
  get: () => store.state.edit.editTemplateInfo.desc,
  set: (val) => {
    store.commit("updateEditTemplateInfo", { desc: val });
  },
});

const baseUseNum = computed({
  get: () => store.state.edit.editTemplateInfo.baseUseNum,
  set: (val) => {
    store.commit("updateEditTemplateInfo", { baseUseNum: val });
  },
});

const vip = computed({
  get: () => store.state.edit.editTemplateInfo.vip,
  set: (val) => {
    store.commit("updateEditTemplateInfo", { vip: val });
  },
});

const labels = computed({
  get: () => store.state.edit.editTemplateInfo.labels,
  set: (val) => {
    store.commit("updateEditTemplateInfo", { labels: val });
  },
});

const rules = reactive({
  title: [
    {
      required: true,
      message: "请输入模板标题",
      trigger: "blur",
    },
  ],
  desc: [
    {
      required: true,
      message: "请输入模板描述",
      trigger: "blur",
    },
  ],
  baseUseNum: [
    {
      required: true,
      message: "请输入模板已使用基数",
      trigger: "blur",
    },
  ],
});

const onVipClick = (isVip) => {
  vip.value = isVip;
};

const onChange = (val) => {
  console.log("onChang1", val, labels.value);
  if (val.checked) {
    labels.value.push(val.label);
  } else {
    labels.value.splice(labels.value.indexOf(val.label), 1);
  }
};

const refreshFixTagValue = () => {
  state.data.forEach((item) => {
    item.checked = labels.value.includes(item.label);
  });
};

const handleClose = (tag) => {
  console.log("handleClose", tag);
  labels.value.splice(labels.value.indexOf(tag), 1);
  refreshFixTagValue();
};

const handleInputConfirm = (type) => {
  console.log("handleInputConfirm", type);
  if (type === "Company") {
    if (!labels.value.includes(state.company) && state.company) {
      labels.value.push(state.company);
    }
    state.company = "";
  } else if (type === "University") {
    if (!labels.value.includes(state.university) && state.university) {
      labels.value.push(state.university);
    }
    state.university = "";
  } else if (type === "Job") {
    if (!labels.value.includes(state.job) && state.job) {
      labels.value.push(state.job);
    }
    state.job = "";
  } else if (type === "Other") {
    if (!labels.value.includes(state.other) && state.other) {
      labels.value.push(state.other);
    }
    state.other = "";
  }
  refreshFixTagValue();
};

onMounted(() => {
  refreshFixTagValue();
});
</script>

<style lang="scss">
@import "src/style/base.scss";
.template_info_drawer {
  width: 400px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  position: absolute;
  top: 0;

  & > p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: black;
    margin-top: 20px;
  }

  .el-form {
    margin-top: 20px;
  }

  .el-input {
    margin-top: -12px;
  }

  ::v-deep(.el-input__validateIcon) {
    display: none;
  }

  .el-textarea {
    margin-top: -12px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }
}

.is_vip {
  width: 400px;
  display: flex;
  margin-top: -12px;
  div {
    flex: 1;
    height: 36px;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 250ms;

    border: 0.5px solid #dcdfe6;
    box-sizing: border-box;
    border-radius: 4px;
    color: #606266;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
  .vip_select {
    background-color: $primary-color;
    color: white;
    &:hover {
      background-color: rgba($color: $primary-color, $alpha: 0.8);
    }
  }
}

.template_tag {
  width: 400px;
  display: flex;
  margin-top: -12px;
  flex-direction: column;
  .tag_array {
    display: flex;
  }
  .tag_show {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    & > span {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
  .el-input {
    margin-top: 5px;
  }
}

.edit-drawer-introduce {
  position: relative;
  .el-drawer__header {
    margin: 0;
    padding-top: 12px;
  }
  .el-drawer__body {
    padding: 0;
  }
}
</style>