<template>
  <div class="blog_detail">
    <div class="break">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          ><a href="/job_strategy/latest">求职攻略</a></el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          state.data.category === "latest" ? "最新" : "推荐"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item v-show="state.data.category !== 'latest'">{{
          state.data.category
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="blog_content" v-if="state.data.author !== undefined">
      <div class="left">
        <div class="detail_content">
          <h1 class="title">
            {{ state.data.title }}
          </h1>
          <div class="author">
            <p v-if="state.data.original === true" class="p1">StrongHire原创</p>
            <p v-if="state.data.original === true" class="p2"></p>
            <p class="p3">{{ state.data.author.name }}</p>
          </div>
          <div class="content" v-html="state.data.content"></div>
          <div class="bottom_desc">
            <div class="bottom_time">
              <p>发布于{{ publishTime }}</p>
              <p class="num">
                {{ state.data.readBaseNum + state.data.readNum }}人阅读
              </p>
            </div>
            <p v-if="state.data.original === true">
              如需转载请联系我们并获得授权
            </p>
          </div>
          <div class="label_tag" v-if="labels && labels.length > 0">
            <el-tag v-for="(item, index) in labels" :key="index">{{
              item
            }}</el-tag>
          </div>
        </div>
        <BlogMayLike :style="{ marginTop: '20px' }" />
      </div>
      <div class="right">
        <BlogAuthor
          :authorData="state.data.author"
          v-if="state.data.author !== undefined"
        />
        <BlogRelative
          :category="state.data.category"
          v-if="state.data.category !== undefined"
          :style="{ marginTop: '20px' }"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue-demi";
import { useRoute, useRouter } from "vue-router";
import blogApi from "@/apis/blog_api";
import { ElMessage } from "element-plus/lib/components";
import BlogAuthor from "@/components/blog/BlogAuthor";
import BlogRelative from "@/components/blog/BlogRelative";
import BlogMayLike from "@/components/blog/BlogMayLike";
import utils from "@/utils/utils.js";
import seo from "@/plugin/seo.js";

const route = useRoute();
const router = useRouter();

const state = reactive({
  isLoading: false,
  data: {},
});

watch(
  () => route.params.id,
  (val, oldVal) => {
    console.log("id change", val, oldVal);
    requestData(val);
  }
);

onMounted(() => {
  if (route.params.id) {
    requestData(route.params.id);
    onReadUpload(route.params.id);
  } else {
    router.push("/job_strategy/latest");
  }
});

const readUploadFilter = () => {
  const url = window.location.href;
  if (
    url.indexOf("resumePrint") > -1 ||
    url.indexOf("template_resume") > -1 ||
    url.indexOf("user_resume") > -1
  ) {
    return true;
  }
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf("StrongHireScreenShotService") > -1) {
    return true;
  }
  return false;
};

const onReadUpload = (id) => {
  if (readUploadFilter()) {
    console.log("readUploadFilter");
    return;
  }
  blogApi
    .read(id)
    .then((res) => {
      console.log("read", res);
    })
    .catch((err) => {
      console.log("read err", err);
    });
};

const labels = computed(() => {
  if (state.data?.tags) {
    return state.data.tags.split(",");
  } else {
    return [];
  }
});

const publishTime = computed(() => {
  if (state.data?.createTime) {
    return utils.formatDateTime(state.data.createTime);
  } else {
    return "";
  }
});

const requestData = (id) => {
  console.log("blog-detail request data:", id, route.name);
  if (id && route.name === "jobStrategyDetail") {
    blogApi
      .getDetail(id)
      .then((result) => {
        if (result.code === 0 && result.data) {
          state.data = result.data;
          document.title = `${result.data.title}-StrongHire简历`;
          document
            .querySelector("meta[name='Keywords']")
            .setAttribute("content", result.data.tags);
          document
            .querySelector("meta[name='Description']")
            .setAttribute("content", result.data.description);
          seo.refreshCurrenJsonId();
        } else {
          ElMessage.error(result.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.blog_detail {
  width: $content-width;
  display: flex;
  margin: auto;
  flex-direction: column;
}

.blog_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 15px;

  .left {
    width: 850px;
    display: flex;
    flex-direction: column;
  }

  .right {
    width: 330px;
    display: flex;
    flex-direction: column;
  }
}

.detail_content {
  @include card-bg;
  width: 100%;
  box-sizing: border-box;
  padding: 40px;

  .title {
    font-size: 28px;
    line-height: 39px;
    color: black;
    margin: 0;
    font-weight: bold;
  }

  .content {
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-top: 20px;

    ::v-deep(p) {
      margin-top: 18px;
      margin-bottom: 18px;
    }
  }
}

.author {
  display: flex;
  margin-top: 10px;
  align-items: center;

  .p1 {
    width: 96px;
    height: 26px;
    background: #fef0f0;
    border-radius: 2px;
    font-size: 12px;
    line-height: 14px;
    color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p2 {
    width: 10px;
    height: 10px;
  }
  .p3 {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
}

.bottom_desc {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  p {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
  }

  .bottom_time {
    display: flex;

    .num {
      margin-left: 20px;
    }
  }
}

.label_tag {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  & > span {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
</style>