<template>
  <el-autocomplete
    :fetch-suggestions="querySearchAsync"
    :placeholder="props.placeholder"
    @select="handleSelect"
    @input="onInputChange"
    :model-value="props.modelValue"
    :debounce="10"
    :hide-loading="true"
  >
    <template #default="{ item }">
      <div class="item">
        <div v-html="htmlValue(item.value)"></div>
        <div></div>
        <div v-html="htmlValue(item.name)"></div>
      </div>
    </template>
  </el-autocomplete>
</template>

<script setup>
import { defineProps, defineEmits, onMounted } from "vue";
import countryCodeData from "@/assets/data/country_code.json";

const props = defineProps({
  modelValue: String,
  placeholder: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const onInputChange = (value) => {
  emit("update:modelValue", getValue(value));
};


const listData = [];
var key = "";

const querySearchAsync = (queryString, cb) => {
  key = queryString;
  if (key && key.indexOf("+") > -1) {
    key = key.replace("+", " ");
  }
  if (!key) {
    cb(listData);
  } else {
    key = key.trim();
    console.log("querySearchAsync", key);
    const result = listData.filter((item) => {
      if ((item.value + item.name).indexOf(key) > -1) {
        return true;
      }
    });
    cb(result);
  }
};

const handleSelect = (item) => {
  console.log(item);
  emit("update:modelValue", getValue(item.value));
};

const htmlValue = (val) => {
  if (key.length > 0) {
    return val.replace(
      new RegExp(key, "g"),
      "<span style='color:#F56C6C;'>" + key + "</span>"
    );
  }
  return val;
};

const getValue = (value) => {
  console.log("gatValue", value);
  if (!value) {
    return "";
  }
  const splitData = value.split(" ");
  if (splitData.length >= 1) {
    if (splitData[0].indexOf("+") > -1) {
      return splitData[0].trim();
    } else {
      return "+" + splitData[0].trim();
    }
  } else {
    return value.trim();
  }
};

onMounted(() => {
  countryCodeData.map((item) => {
    listData.push({
      value: `+${item.dialCode}`,
      name: item.CNName,
    });
  });
});
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
}


</style>