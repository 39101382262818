<template>
  <div
    class="el-container"
    v-loading.fullscreen.lock="state.showLoading"
    element-loading-background="rgba(255, 255, 255, 0.5)"
    :element-loading-text="editFullLoadingText"
    :style="{
      '--modify-width': `${modifyWidth}px`,
      '--form-width': `${formWidth}px`,
      '--font-family': font_family,
      '--operate-left': `${operateLeft}px`,
      '--preview-left': `${previewLeft}px`,
      '--preview-left-modify': `${previewLeftModify}px`,
      '--translation-x': `${translationX}px`,
      '--modify-left': `${modifyLeft}px`,
      '--modify-left-operate': `${modifyLeftOperate}px`,
      '--operate-height': `${operateHeight}px`,
      '--preview-height': `${previewHeight}px`,
      '--modify-height': `${modifyHeight}px`,
      '--scale-value': 0.8,
      '--content-visible': contentVisible,
    }"
  >
    <div v-show="false">{{ fullLoadingNotification }}</div>
    <EditHead class="edit-head"></EditHead>
    <el-main class="eidt-content" v-if="canEdit">
      <div
        class="edit-operate-container"
        :class="{ 'modify-mode': state.showModify }"
      >
        <EditOperate class="edit-operate"></EditOperate>
        <div class="operate-mask" v-if="state.showModify"></div>
      </div>
      <div
        class="edit-preview-container"
        :class="{ 'modify-mode': state.showModify }"
      >
        <EditPreviw
          class="edit-preview analyze-animation"
          :class="{
            'analyze-animation-reverse': state.analyzeAnimationCount % 2 === 1,
          }"
        ></EditPreviw>
        <div class="edit-preview-bottom"></div>
      </div>
      <div
        class="edit-modify-container"
        :class="{ 'modify-mode': state.showModify }"
      >
        <ModifyHead></ModifyHead>
        <div class="edit-modify-without-head">
          <EditModify class="edit-modify"></EditModify>
          <ModifyExperienceLibs
            class="edit-modify-experience-libs"
          ></ModifyExperienceLibs>
        </div>
        <div class="modify-mask" v-if="!state.showModify"></div>
      </div>
    </el-main>
    <el-main class="eidt-content" v-if="!canEdit">
      <div class="edit-preview-container edit-preview-container-only">
        <EditPreviw
          class="edit-preview analyze-animation"
          :class="{
            'analyze-animation-reverse': state.analyzeAnimationCount % 2 === 1,
          }"
        ></EditPreviw>
        <div class="edit-preview-bottom"></div>
      </div>
    </el-main>
    <div v-if="!hasLogin">
      <transition name="fade">
        <div class="edit-not-login" v-show="state.bottomNotLogin">
          <span
            >当前为预览模式，简历内容不会被保存至云端，为防止内容丢失，请点击登录</span
          >
          <span @click="onLoginClick(false)">登录</span>
          <img
            src="@/assets/img/icon_white_close.svg"
            @click="onNotLoginClose"
            class="close"
          />
        </div>
      </transition>
      <transition name="fade">
        <div
          v-show="!state.bottomNotLogin"
          class="edit-float-login"
          @click="onLoginClick(true)"
        >
          <img src="@/assets/img/icon_edit_login_float.svg" alt="" />
          <div>
            <span v-for="(item, index) in '简历云存储'" :key="index">{{
              item
            }}</span>
          </div>
        </div>
      </transition>
    </div>
    <div
      v-if="route.query.type === 'template_resume'"
      class="edit-float-introduce"
      @click="onModuleIntroducClick"
    >
      <img src="@/assets/img/icon_edit_login_float.svg" alt="" />
      <div>
        <span v-for="(item, index) in '模板简介'" :key="index">{{ item }}</span>
      </div>
    </div>
    <el-drawer
      v-model="state.moduleIntroducVisible"
      :with-header="true"
      direction="ltr"
      :size="400"
      @close="onDrawerClose"
      custom-class="edit-drawer-introduce"
    >
      <TemplateInfoDrawer></TemplateInfoDrawer>
    </el-drawer>
    <ExportDialog></ExportDialog>
  </div>
</template>

<script setup>
import EditHead from "../components/edit/EditHead.vue";
import EditOperate from "../components/edit/EditOperate.vue";
import EditPreviw from "../components/edit/EditPreview.vue";
import EditModify from "../components/edit/EditModify.vue";
import ModifyExperienceLibs from "../components/modify/ModifyExperienceLibs.vue";
import ModifyHead from "@/components/modify/ModifyHead.vue";
import TemplateInfoDrawer from "@/components/template/TemplateInfoDrawer.vue";
import ExportDialog from "@/components/common/ExportDialog";
import { computed, reactive, ref } from "@vue/reactivity";
import {
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  watch,
} from "@vue/runtime-core";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import useEventBus from "@/plugin/event";
import editApi from "@/apis/edit_api";
import { ElMessage, ElLoading } from "element-plus";
import editMix from "@/plugin/edit";
import analyze from "@/plugin/analyze.js";

const operateWidth = 300;
const previewWidth = 794;
const marginContent = 20;

const eventBus = useEventBus();
const router = useRouter();
const route = useRoute();
const store = useStore();
const { onBackDeal, updateContentData, refreshSaveStates, editTemplateInfo } =
  editMix();

import vipMix from "@/plugin/vip";
const { onVipToast } = vipMix();

const state = reactive({
  showModify: false,
  showLoading: false,
  bottomNotLogin: true,
  moduleIntroducVisible: false,

  polling: null,
  pollingTime: 0,

  analyzeAnimationCount: 0,
});

const contentVisible = computed(() => {
  if (state.showLoading) {
    return "hidden";
  } else {
    return "visible";
  }
});

const onSwitchClick = () => {
  state.showModify = !state.showModify;
};

const onNotLoginClose = () => {
  state.bottomNotLogin = false;
  analyze.sendClickEvent("edit_bottom_login_close_click");
};

const hasLogin = computed(() => store.getters.hasLogin);
const onLoginClick = async (bottom) => {
  if (await onBackDeal(false)) {
    store.commit("updateLoginDialog", {
      showLoginDialog: true,
      from: bottom ? "编辑器底部引导" : "编辑器右侧悬浮球",
    });
  }
};

const onModuleIntroducClick = async () => {
  state.moduleIntroducVisible = true;
};

const font_family = computed(() => {
  const font = store.state.edit.formatInfo.font;
  switch (font) {
    case 2:
      return "SimHei, STHeiti"; // 黑体
    case 3:
      return `"Source Han Serif SC", SimSun, STSong`; // 宋体
    case 4:
      return "KaiTi, STKaiti, STKaitiSc"; // 楷体
    case 5:
      return "FangSong, STFangsong"; // 仿宋
    default:
      return `"PingFang SC", "Microsoft YaHei"`; // default-0  微软雅黑
  }
});

const getModifyWidth = (width) => {
  // 710 590 470
  if (width >= 710 + previewWidth + 300) {
    return 710;
  } else if (width >= 590 + previewWidth + 250) {
    return 590;
  } else {
    return 470;
  }
};

const editContainerMinWidth = computed(() => {
  if (store.state.edit.isInEditMode) {
    return `${Math.max(width.value, previewWidth + modifyWidth.value)}px`;
  } else {
    return `${Math.max(width.value, previewWidth + operateWidth)}px`;
  }
});

const modifyWidth = ref(getModifyWidth(document.documentElement.clientWidth));

const formWidth = computed(() => {
  return (modifyWidth.value - marginContent * 3) / 2;
});

const operateLeft = computed(() => {
  return (width.value - operateWidth - previewWidth - marginContent) / 2;
});

const previewLeft = computed(() => {
  return operateLeft.value + operateWidth + marginContent;
});

const previewLeftModify = computed(() => {
  return (width.value - modifyWidth.value - previewWidth - marginContent) / 2;
});

const translationX = computed(() => {
  return previewLeftModify.value - previewLeft.value;
});

const modifyLeft = computed(() => {
  return previewLeftModify.value + previewWidth + marginContent;
});

const modifyLeftOperate = computed(() => {
  return previewLeft.value + previewWidth + marginContent;
});

const operateHeight = computed(() => {
  return height.value - 56 - marginContent * 2 - 83;
});

const previewHeight = computed(() => {
  return height.value - 56 - marginContent * 2 - 6;
});

const modifyHeight = computed(() => {
  return height.value - 56 - marginContent * 2 - 66;
});

const handleResize = (event) => {
  console.log("handleResize");
  width.value = document.documentElement.clientWidth;
  height.value = document.documentElement.clientHeight;
  modifyWidth.value = getModifyWidth(width.value);
};

const width = ref(document.documentElement.clientWidth);
const height = ref(document.documentElement.clientHeight);

const initData = () => {
  window.addEventListener("resize", handleResize);
  document
    .getElementsByClassName("analyze-animation")[0]
    .addEventListener("animationiteration", onAnalyzeAnimationEnd);
  store.commit("updateContentChanged", false);
  store.commit("updateInEditMode", false);
  store.commit("setEditSaveDesc", "您的简历将自动保存在云端");
  eventBus.on("showModify", (data) => {
    state.showModify = data.value;
    store.commit("updateContentChanged", false);
    store.commit("updateInEditMode", data.value);
  });
  eventBus.on("loginSuccess", (data) => {
    // 登录成功后，将本地数据同步到服务器
    store.commit("setEditFullLoading", {
      editFullLoading: true,
      editFullLoadingText: "正在保存中...",
    });
    store
      .dispatch("addResumeData", route.query.type === "template_resume")
      .then((data) => {
        if (data.code === 0) {
          store.commit("setEditFullLoading", {
            editFullLoading: false,
            editFullLoadingText: "",
          });
          console.log("addResumeData", data);
          router.push({ path: `/edit/${data.data}` });
          store.dispatch("deleteLocalData");
        } else {
          if (data.code === 4 || data.code === 5) {
            onVipToast(data.msg);
            router.back();
          } else {
            ElMessage.error(data.msg);
            store.commit("setEditFullLoading", {
              editFullLoading: false,
              editFullLoadingText: "",
            });
          }
        }
      })
      .catch((msg) => {
        store.commit("setEditFullLoading", {
          editFullLoading: false,
          editFullLoadingText: "",
        });
        ElMessage.error(msg);
      });
  });
  console.log("param id: ", route.params.id, route.name);
  doAction();
  if (hasLogin.value) {
    // 如果已经登录，需要删除本地缓存数据
    store.dispatch("deleteLocalData");
  }
};

watch(
  () => route.name,
  (to, from) => {
    if (to !== from) {
      if (to === "edit") {
        requestData(false);
      }
    }
  }
);

const checkAndCreateEmptyModule = () => {
  // ! 如果是 CMS-创建模板，则创建模板
  if (
    route.query.type === "template_resume" &&
    !route.params.id &&
    !store.state.edit.importResumeData
  ) {
    const contentModules = [];
    contentModules.push({
      id: "1",
      module: "basic_info",
      basicInfo: {},
    });
    store.commit("setImportResumeData", {
      resumeData: { contentModules: contentModules },
    });
  }
};

// 生命周期
onMounted(() => {
  initData();
  // ! 1. 如果导入数据不为空，说明是未登录下导入数据
  checkAndCreateEmptyModule();
  if (store.state.edit.importResumeData) {
    store.commit("setResumeData", store.state.edit.importResumeData);
    return;
  }

  if (!route.params.id) {
    setTimeout(() => {
      onResumeNotFound();
    }, 500);
  }
  requestData(route.name === "preview");
});

const requestData = async (isPreview) => {
  // 从模板进来后，进入预览模式，只有编辑成功才会进入编辑模式
  console.log("requestData isPreview: ", isPreview, route.query.type);
  state.showLoading = true;
  try {
    var result = null;
    if (route.query.type === "user_resume") {
      result = await editApi.getUserDetail(route.params.id);
    } else if (route.query.type === "template_resume") {
      result = await editApi.getDetail(route.params.id);
    } else if (isPreview) {
      result = await editApi.getDetail(route.params.id);
      // 创建模板，需要将 title 置为 ”“
      result.data.resumeData.title = "";
    } else {
      result = await editApi.getUserDetail(route.params.id);
    }
    if (result.code === 0) {
      store.commit("setResumeId", route.params.id);
      console.log("get detail", result);
      state.showLoading = false;
      store.commit("resetResumeData");
      store.commit("setResumeData", result.data);
    } else {
      ElMessage.error(result.msg);
      router.back();
    }
  } catch ({ msg: msg }) {
    ElMessage.error("简历不存在");
    setTimeout(() => {
      onResumeNotFound();
    }, 500);
  }
};

const onResumeNotFound = () => {
  router.replace({ path: "/" });
};

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
  document
    .getElementsByClassName("analyze-animation")[0]
    .removeEventListener("animationiteration", onAnalyzeAnimationEnd);
  store.commit("resetResumeData");
  clear();
});

onBeforeRouteLeave((to, from, next) => {
  next();
});

var elLoading = null;

const fullLoadingNotification = computed(() => {
  state.showLoading = store.state.edit.editFullLoading;
  return store.state.edit.editFullLoading;
});

const editFullLoadingText = computed(() => {
  return store.state.edit.editFullLoadingText;
});

const clear = () => {
  console.log("clear");
  clearTimeout(state.polling);
};

const doAction = async () => {
  const routeName = route.name;
  const isPublish = store.state.edit.isPublish;
  console.log("doAction routeName: ", routeName, "isPublish: ", isPublish);
  if (routeName === "edit" && isPublish !== true) {
    // ! 自动保存：内容发生变化，只有在编辑内容的时候，而不是添加内容
    const editObject = store.state.edit.edit_object;
    if (editObject && editObject.id && editObject.id.length < 15) {
      console.log("当前处于正在添加数据阶段，不需要自动保存");
      state.polling = setTimeout(() => {
        clear();
        doAction();
      }, 30000);
      return;
    }
    if (store.state.edit.contentHasChanged) {
      refreshSaveStates(false);
      const result = await updateContentData();
      refreshSaveStates(true, result);
      if (result) {
        store.commit("updateContentChanged", false);
      }
    }
  }
  state.polling = setTimeout(() => {
    clear();
    doAction();
  }, 30000);
};

const onAnalyzeAnimationEnd = (e) => {
  state.analyzeAnimationCount++;
};

const showAnalyzeAnimation = computed(() => {
  state.analyzeAnimationCount = 0;
  return store.state.edit.showAnalyzeAnimation;
});

const canEdit = computed(() => route.query.edit !== "false");

const templateEdit = computed(
  () =>
    route.query.type === "template_resume" || route.query.type === "user_resume"
);

const onDrawerClose = () => {
  editTemplateInfo();
};
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
::-webkit-scrollbar {
  display: none;
}
.el-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .edit-head {
    width: 100%;
    height: $header-height;
    min-height: $header-height;
  }

  .edit-float-login {
    width: 56px;
    height: 126px;
    position: absolute;
    right: 20px;
    top: 30%;
    cursor: pointer;
    & > img {
      z-index: 1;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    & > div {
      z-index: 12;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      position: absolute;
      span {
        font-size: 14px;
        line-height: 18px;
        color: white;
      }
    }
  }

  .edit-float-introduce {
    width: 56px;
    height: 126px;
    position: absolute;
    left: 20px;
    top: 30%;
    cursor: pointer;
    & > img {
      z-index: 1;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    & > div {
      z-index: 12;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      position: absolute;
      span {
        font-size: 14px;
        line-height: 18px;
        color: white;
      }
    }
  }
}
.eidt-content {
  width: v-bind(editContainerMinWidth);
  overflow: hidden;
  flex: 1;
  margin-top: 0;
  position: relative;

  .edit-operate-container {
    width: $operate-width;
    height: var(--operate-height);
    position: absolute;
    left: var(--operate-left);
    transition: all 300ms;
    .operate-mask {
      width: $operate-width;
      height: 80%;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    .edit-operate {
      width: 100%;
      @include card-bg;
      position: absolute;
    }
    &.modify-mode {
      transform: translateX(var(--translation-x)) scale(var(--scale-value));
      opacity: 0;
    }
  }

  .edit-preview-container {
    width: $preview-width;
    position: absolute;
    left: var(--preview-left);
    transition: all 300ms;
    &.modify-mode {
      transform: translateX(var(--translation-x));
    }
    .edit-preview {
      width: 100%;
      height: var(--preview-height);
      @include card-bg;
      overflow: auto;
    }
    .edit-preview-bottom {
      width: 100%;
      height: $content-margin;
    }
  }

  .edit-preview-container-only {
    left: 50%;
    transform: translateX(-50%);
  }
  .edit-modify-container {
    width: var(--modify-width);
    position: absolute;
    transition: all 300ms;
    left: var(--modify-left-operate);
    opacity: 0;
    transform: scale(var(--scale-value));
    transform-origin: 0 0;

    .modify-mask {
      width: var(--modify-width);
      height: 90%;
      position: absolute;
      top: 0px;
      left: 0px;
    }
    .edit-modify-without-head {
      height: var(--modify-height);
      overflow: auto;
    }
    &.modify-mode {
      transform: translateX(var(--translation-x));
      opacity: 1;
    }
    .edit-modify {
      width: var(--modify-width);
      background-color: white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      transition: var(--transition);
    }
    .edit-modify-experience-libs {
      width: cal(var(--modify-width) - 20px);
      transition: var(--transition);
      @include card-bg;
      margin-top: 20px;
    }
  }
}

.edit-not-login {
  width: 100vw;
  height: 100px;
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/img/icon_edit_bottom_not_login_bg.svg");

  & > span {
    transition: all 250ms;
    &:nth-child(1) {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
    &:nth-child(2) {
      cursor: pointer;
      margin-left: 30px;
      background: white;
      color: #f56c6c;
      font-size: 14px;
      border-radius: 100px;
      font-weight: 500;
      width: 74px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 20px;
      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.8);
      }
    }
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .close {
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    right: 22px;
    top: 22px;
  }
}

.el-drawer {
  position: relative;
}
::v-deep(section.el-drawer__body) {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}
::v-deep(.el-drawer__close-btn) {
  z-index: 999;
}

.analyze-animation {
  &::after {
    display: v-bind(showAnalyzeAnimation);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110px;
    animation: analyze-animation 1s infinite ease-in-out;
    animation-direction: alternate;
    background: linear-gradient(
      0deg,
      rgba(245, 108, 108, 0.3) 0%,
      rgba(245, 108, 108, 0) 100%
    );
  }
  &.analyze-animation-reverse {
    &::after {
      background: linear-gradient(
        180deg,
        rgba(245, 108, 108, 0.3) 0%,
        rgba(245, 108, 108, 0) 100%
      );
    }
  }
}

@keyframes analyze-animation {
  from {
    top: -50px;
  }
  to {
    top: calc(100% - 110px);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>