<template>
  <div class="counseling_middle">
    <p>StrongHire 求职辅导，你的造梦引擎</p>
    <div class="container">
      <div class="colunm1">
        <div class="item_bg item_bg1">辅导内容</div>
        <div
          v-for="(item, index) in colunm1Data"
          :key="index"
          class="item_bg"
          :class="{ item_bg3: index % 2 === 1, item_bg2: index % 2 === 0 }"
        >
          {{ item }}
        </div>
      </div>
      <div class="right">
        <div
          v-for="item in colum2Data"
          :key="item.price"
          class="colum2"
          :class="{ highlight: item.price === 599 }"
        >
          <div class="item_bg1 item_bg4">
            <p>{{ item.desc }}</p>
            <p>￥{{ item.price }}</p>
            <p @click="onChoiceClick">立即选择</p>
          </div>
          <div
            v-for="(subItem, index) in item.data"
            :key="index"
            class="item_bg"
            :class="{ item_bg3: index % 2 === 1, item_bg2: index % 2 === 0 }"
          >
            <img
              src="@/assets/img/icon_counseling_highlight.svg"
              alt=""
              v-if="item.price === 599 && subItem === '1'"
            />
            <img
              src="@/assets/img/icon_counseling_normal.svg"
              alt=""
              v-if="item.price !== 599 && subItem === '1'"
            />
            <p v-if="subItem !== '1'">{{ subItem }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";

const store = useStore();

const colunm1Data = [
  "需求背景了解",
  "简历优化",
  "简历问题答疑",
  "模拟面试",
  "面试评价及提升点",
  "面试问题答疑",
  "薪资谈判辅导",
  "辅导后答疑",
];

const colum2Data = [
  {
    price: 599,
    desc: "全流程辅导",
    data: [
      "1",
      "1",
      "语音20分钟",
      "语音30分钟",
      "文字输出",
      "语音20分钟",
      "语音20分钟",
      "辅导后14日内",
    ],
  },
  {
    price: 499,
    desc: "面试辅导",
    data: [
      "1",
      "1",
      "语音20分钟",
      "语音30分钟",
      "文字输出",
      "语音20分钟",
      "",
      "辅导后7日内",
    ],
  },
  {
    price: 149,
    desc: "简历辅导",
    data: ["1", "1", "语音20分钟", "", "", "", "", ""],
  },
];

const onChoiceClick = () => {
  store.commit("updateAddWechatDialog", {
    show: true,
    type: 1,
  });
};
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

.counseling_middle {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #000;
  align-items: center;

  & > p {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
  }

  .container {
    width: 1000px;
    height: 616px;
    background: #ffffff;
    box-shadow: 0px 2px 20px rgba(203, 203, 203, 0.3);
    border-radius: 20px;
    margin-top: 40px;
    display: flex;
  }
}

.right {
  flex: 1;
  display: flex;
}

.colunm1 {
  width: 250px;
  height: 100%;
}

.colum2 {
  flex: 1;
  color: rgba(0, 0, 0, 0.7);
}

.item_bg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item_bg1 {
  height: 136px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.item_bg2 {
  background: rgba(251, 249, 248, 0.5);
  height: 60px;
}

.item_bg3 {
  height: 60px;
}

.highlight {
  color: $primary-color;
}

.item_bg4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  p {
    &:nth-child(1) {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
    }
    &:nth-child(2) {
      font-size: 18px;
      line-height: 25px;
      margin-top: 3px;
    }
    &:nth-child(3) {
      font-size: 18px;
      line-height: 25px;
      width: 116px;
      height: 40px;
      font-size: 14px;
      line-height: 22px;
      margin-top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background: #f56c6c;
      border-radius: 222222px;
      transition: all 250ms;
      cursor: pointer;

      &:hover {
        background: rgba($color: $primary-color, $alpha: 0.85);
      }
    }
  }
}
</style>