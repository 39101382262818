import {createGetApi, createPostApi, SERVER_URL} from './network'

const OSS_URL = SERVER_URL + '/api/oss';

export default {
    // 校徽
    policyUserUniversityLogo: function (fileName) {
        return createGetApi(`${OSS_URL}/policyUserUniversityLogo`, {
            fileName
        })
    },

    // 证件照
    policyIdAvatar: function (fileName) {
        return createGetApi(`${OSS_URL}/policyIdAvatar`, {
            fileName
        })
    },

    // 作品二维码
    policyQRPortfolio: function (fileName) {
        return createGetApi(`${OSS_URL}/policyQRPortfolio`, {
            fileName
        })
    },
}
