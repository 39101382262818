import editApi from "@/apis/edit_api";
import { useRoute } from "vue-router";

const deleteChildModule = (data, subId) => {
    // 删除子模块
    try {
        data.forEach(item => {
            if (item.id === subId) {
                data.splice(data.indexOf(item), 1)
                throw new Error('loopinterrupt');
            }
        })
    } catch (error) {
        console.log('LoopInterrupt');
    }
}
const changeChildModuleOrder = (data, subId, isUp) => {
    // 改变子模块的顺序
    try {
        if (isUp) {
            data.forEach(item => {
                if (item.id === subId) {
                    const index = data.indexOf(item);
                    if (index > 0) {
                        const temp = data[index - 1];
                        data[index - 1] = item;
                        data[index] = temp;
                    }
                    throw new Error('LoopInterrupt');
                }
            });
        } else {
            data.forEach(item => {
                if (item.id === subId) {
                    const index = data.indexOf(item);
                    if (index < data.length - 1) {
                        const temp = data[index + 1];
                        data[index + 1] = item;
                        data[index] = temp;
                    }
                    throw new Error('LoopInterrupt');
                }
            });
        }
    } catch (error) {
        console.log('LoopInterrupt');
    }
}
const changeParentModuleOrder = (data, moduleId, isUp) => {
    // 改变父模块的顺序
    try {
        if (isUp) {
            data.forEach(item => {
                if (item.id === moduleId) {
                    const index = data.indexOf(item);
                    if (index > 0) {
                        const temp = data[index - 1];
                        data[index - 1] = item;
                        data[index] = temp;
                    }
                    throw new Error('LoopInterrupt');
                }
            });
        } else {
            data.forEach(item => {
                if (item.id === moduleId) {
                    const index = data.indexOf(item);
                    if (index < data.length - 1) {
                        const temp = data[index + 1];
                        data[index + 1] = item;
                        data[index] = temp;
                    }
                    throw new Error('LoopInterrupt');
                }
            });
        }
    } catch (error) {
        console.log('LoopInterrupt');
    }
}
const isModuleContentEmpty = (state) => {
    // * 判断当前编辑的模块内容是否为空, 优先判断必填项，然后判断是否为空，只有个人总结存在为空的可能性
    const module = state.modifyModule;
    if (module === 'basic_info') {
        return !state.contentModules.basicInfo.name || !state.contentModules.basicInfo.phone || !state.contentModules.basicInfo.email;
    } else if (module === 'work_experience') {
        return !state.edit_object.company || !state.edit_object.position;
    } else if (module === 'edu_experience') {
        return !state.edit_object.school || !state.edit_object.education;
    } else if (module === 'project_experience') {
        return !state.edit_object.project || !state.edit_object.role;
    } else if (module === 'school_experience') {
        return !state.edit_object.title || !state.edit_object.role;
    } else if (module === 'personal_summary') {
        return !state.contentModules.personalSummary.skill && !state.contentModules.personalSummary.certificate
            && !state.contentModules.personalSummary.languageLevel && !state.contentModules.personalSummary.hobby;
    } else if (module === 'evaluation') {
        return !state.contentModules.evaluation.desc;
    } else if (module === 'awards') {
        return !state.edit_object.title || !state.edit_object.time;
    } else if (module === 'portfolios') {
        return !state.edit_object.qrcodeUrl;
    } else if (module === 'custom_experience') {
        return !state.edit_object.title || !state.edit_object.role;
    }
    return true;
}
const onDataChange = (state, type) => {
    // * 数据变化时，更新状态: 修改模板，增删改出模块，修改名称，排序
    console.warn('onDataChange', type);
}
export default {
    getters: {
        dataCanSave(state) {
            return isModuleContentEmpty(state);
        },
        basicInfoIsEmpty(state) {
            if (!state.contentModules || !state.contentModules.basicInfo) {
                return true;
            }
            return !state.contentModules.basicInfo.avatarUrl
                && !state.contentModules.basicInfo.birth
                && (state.contentModules.basicInfo.birthType === null || state.contentModules.basicInfo.birthType === undefined)
                && (state.contentModules.basicInfo.bodyHeight === null || state.contentModules.basicInfo.bodyHeight === undefined)
                && (state.contentModules.basicInfo.bodyWeight === null || state.contentModules.basicInfo.bodyWeight === undefined)
                && !state.contentModules.basicInfo.email
                && (state.contentModules.basicInfo.gender === null || state.contentModules.basicInfo.gender === undefined)
                && (state.contentModules.basicInfo.jobHuntStatus === null || state.contentModules.basicInfo.jobHuntStatus === undefined)
                && !state.contentModules.basicInfo.liveCity
                && !state.contentModules.basicInfo.maritalStatus
                && !state.contentModules.basicInfo.nation
                && !state.contentModules.basicInfo.name
                && !state.contentModules.basicInfo.nativeCity
                && !state.contentModules.basicInfo.phone
                && !state.contentModules.basicInfo.politicalStatus
                && !state.contentModules.basicInfo.schoolBadgeUrl
                && !state.contentModules.basicInfo.targetCity
                && !state.contentModules.basicInfo.targetJob
                && (state.contentModules.basicInfo.targetSalaryMin === null || state.contentModules.basicInfo.targetSalaryMin === undefined)
                && (state.contentModules.basicInfo.targetSalaryMin === null || state.contentModules.basicInfo.targetSalaryMin === undefined)
                && !state.contentModules.basicInfo.phoneCountryCode
        }
    },
    state: {
        formatInfo: {  // 排版格式
            themeColor: "#000000",  // 主题色。RGB色值，如FF0000。Defaut:NULL
            font: 1,  // 字体。定义见数据定义-Font字体。Default:0
            textSize: 12,  // 字号。Default:0
            rowSpace: 20,  // 行间距。Default:0
            moduleSpace: 10,  // 模块间距。Default:0
            pageMargin: 10,  // 页边距。Default:0
            titleStyle: 2,  // 标题样式 1-单行 2-多行。Default:0
            dateStyle: 1,  // 日期样式 1-YYYY年M月 2-YYYY.M。Default:0
        },
        style: 1, // 排版格式
        title: '',
        edit_object: null, // 正在编辑的对象数据
        edit_last_object: null, // 用于取消保存时，恢复到之前的数据
        modules: [{
            id: '',
            module: '',
            title: '',
        }], // 模块列表
        contentModules: {
            // basicInfo: {  // 基本信息，必须
            //     id: "1",
            //     avatarUrl: '',
            //     birth: '', // 生日，格式 YYYY-MM Default:NULL
            //     birthType: 0, // 生日展示类别 0-21岁 1-2020-9 Defalt:0
            //     bodyHeight: null,  // 身高 Default:0
            //     bodyWeight: null,  // 体重 Default:0
            //     email: '',
            //     gender: null,  // 性别 0-Male 1-Female Default:0
            //     jobHuntStatus: null,  // 求职状态 Default:0
            //     liveCity: '',  // 现居地 Default:NULL
            //     maritalStatus: '',  // 婚姻状况 Default:NULL
            //     name: '',
            //     nation: '',  // 民族 Default:NULL
            //     nativeCity: '',  // 籍贯 Default:NULL
            //     phone: '',
            //     politicalStatus: '',  // 政治面貌 Default:NULL
            //     schoolBadgeUrl: "",  // 校徽url Default:NULL
            //     targetCity: '',  // 目标城市 Default:NULL
            //     targetJob: '',  // 目标岗位 Default:NULL
            //     targetSalaryMin: null,  // 目标薪资，单位元 Default:0
            //     targetSalaryMax: null,  // 目标薪资，单位元 Default:0
            //     phoneCountryCode: '', // 手机区号
            //     extras:[], // 附加信息
            // },
            // eduExperience: [  // 教育经历，非必须
            //     {
            // id: "1",
            // sequence: 1,
            // city: '1',  // 所在城市 
            // college: '',  // 学院
            // desc: '',  // 描述
            // education: null,  // 学历。
            // educationType: null,  // 全日制/非全日制
            // start: '',  // 开始时间 YYYY-MM
            // end: '',  // 结束时间 YYYY-MM
            // major: '1',  // 专业
            // school: '',  // 学校
            // showLables: false,
            // labels: [], // 学校标签
            // showSchoolLabels: false,
            // schoolLabels: [],
            //     }
            // ],
            // workExperience: [  // 工作经历，非必须
            //     {
            //         id: "1",
            //         sequence: 1,
            //         city: "",  // 城市
            //         company: "",  // 公司名称
            //         department: "",  // 所在部门
            //         desc: "1",  // 描述
            //         position: "",  // 职位名称
            //         start: "",  // 开始时间 YYYY-MM
            //         end: "",  // 结束时间 YYYY-MM
            //     },
            // ],
            // projectExperience: [  // 项目经历，非必须
            //     {
            //         id: '1',
            //         sequence: 1,
            //         project: "天气",  // 项目名称
            //         role: "",  // 角色
            //         department: "",  // 所在部门
            //         city: "",  // 城市
            //         start: "",  // 开始时间 YYYY-MM
            //         end: "",  // 结束时间 YYYY-MM
            //         desc: "",  // 描述
            //     }
            // ],
            // schoolExperience: [  // 校园经历，非必须
            //     {
            //         id: "1",
            //         sequence: 1,
            //         title: "捐血",  // 组织活动名称
            //         role: "",  // 角色
            //         department: "",  // 所在部门
            //         city: "",  // 城市
            //         start: "",  // 开始时间 YYYY-MM
            //         end: "",  // 结束时间 YYYY-MM
            //         desc: "",  // 描述
            //     }
            // ],
            // personalSummary: {  // 个人总结，非必须
            //     skill: "CAD，Photoshop，AE",  // 擅长技能
            //     certificate: "",  // 职业证书
            //     languageLevel: "",  // 语言等级
            //     hobby: "",  // 兴趣爱好
            // },
            // awards: [  // 荣誉奖项，非必须
            //     {
            //         id: '1',
            //         sequence: 1,
            //         title: "奥赛一等奖",  // 奖项名称
            //         time: "2022-11",  // 获得时间 YYYY-MM
            //     }
            // ],
            // portfolios: [
            //     {
            //         id: '1',
            //         sequence: 1,
            //         title: "我的作品集",  // 我的作品集
            //         qrcodeUrl: "",
            //         url: "",
            //     }
            // ],
            // customExperience: [
            //     {
            //         id: "1",
            //         moduleId: "1",
            //         sequence: 1,
            //         title: "捐血1",  // 组织活动名称
            //         role: "",  // 角色
            //         department: "",  // 所在部门
            //         city: "",  // 城市
            //         start: "",  // 开始时间 YYYY-MM
            //         end: "",  // 结束时间 YYYY-MM
            //         desc: "",  // 描述
            //     },
            //     {
            //         id: "2",
            //         moduleId: "2",
            //         sequence: 2,
            //         title: "捐血2",  // 组织活动名称
            //         role: "",  // 角色
            //         department: "",  // 所在部门
            //         city: "",  // 城市
            //         start: "",  // 开始时间 YYYY-MM
            //         end: "",  // 结束时间 YYYY-MM
            //         desc: "",  // 描述
            //     }
            // ],
            // evaluation: {
            //     id: "1",
            //     desc: "",
            // },

        },

        // 其他状态
        resumeId: '', // 简历 id
        contentHasChanged: false, // 编辑内容是否有更改
        modifyModule: '',  // 当前正在修改的模块名称
        editModuleId: '', // 当前正在修改的模块id
        editSubModuleId: "", // 当前正在修改的子模块id
        isInEditMode: false, // 是否处在编辑模式
        addResumeState: 0, // 添加简历状态 0-未调用 1-正在调用 2-调用成功 3-调用失败
        importResumeData: null, // 导入简历数据
        editFullLoading: false, // 编辑状态下 loading 状态
        editFullLoadingText: "正在加载中...",
        editSaveDesc: "您的简历将自动保存在云端", // 编辑状态下保存提示
        showAnalyzeAnimation: "none", // 是否显示分析动画
        analyzeData: {}, // 智能监测数据
        previewContentHeight: 0, // 预览内容高度
        needShowDialogWhenBack: false, // 需要在返回时弹出提示框(未登录情况下)
        isPublish: false, // 是否正在发布内容，如果正在发布内容，则不能自动更新

        editTemplateInfo: {
            id: "",
            title: "",
            desc: "",
            baseUseNum: null,
            vip: false,
            labels: [], // 标签 比如 实习，2020届
        }, // 编辑模板信息
        editExportDialog: {
            visible: false,
            progress: 0,
        }
    },
    mutations: {
        setIsPublish(state, payload) {
            state.isPublish = payload;
        },
        updateEditExportDialog(state, payload) {
            Object.assign(state.editExportDialog, payload)
        },
        updateEditTemplateInfo(state, payload) {
            state.contentHasChanged = true;
            Object.assign(state.editTemplateInfo, payload)
        },
        setPreviewContentHeight(state, payload) {
            state.previewContentHeight = payload;
        },
        setAnalyzeData(state, payload) {
            state.analyzeData = payload;
        },
        setShowAnalyzeAnimation(state, show) {
            state.showAnalyzeAnimation = show;
        },
        setResumeId(state, payload) {
            state.resumeId = payload;
        },
        setEditSaveDesc(state, desc) {
            state.editSaveDesc = desc;
        },
        setEditFullLoading(state, payload) {
            state.editFullLoading = payload.editFullLoading;
            state.editFullLoadingText = payload.editFullLoadingText;
        },
        setImportResumeData(state, payload) {
            state.importResumeData = payload;
        },
        updateInEditMode(state, payload) {
            state.isInEditMode = payload;
        },
        updateEditModuleId(state, payload) {
            console.log('updateEditModuleId', payload);
            state.editModuleId = payload.moduleId;
            state.editSubModuleId = payload.subModuleId;
        },
        updateContentChanged(state, payload) {
            state.contentHasChanged = payload;
            if (payload) {
                onDataChange(state, 'descChange');
            }
        },
        updateModifyModule(state, payload) {
            // 打开右侧编辑模式，切换模块名称
            state.modifyModule = payload.modifyModule
        },
        updateFormatInfo(state, payload) {
            Object.assign(state.formatInfo, payload)
            onDataChange(state, 'formatInfo');
        },
        updateBasicInfo(state, payload) {
            Object.assign(state.contentModules.basicInfo, payload)
            state.contentHasChanged = true;
            onDataChange(state, 'basicInfo');
        },
        updatePersonalSummary(state, payload) {
            Object.assign(state.contentModules.personalSummary, payload)
            state.contentHasChanged = true;
            onDataChange(state, 'personalSummary');
        },
        updateEvaluation(state, payload) {
            Object.assign(state.contentModules.evaluation, payload)
            state.contentHasChanged = true;
            onDataChange(state, 'evaluation');
        },
        updateStyle(state, payload) {
            state.style = payload.style
            onDataChange(state, 'style');
        },
        updateCurrentEdityData(state, payload) {
            // 设置编辑的数据
            state.edit_object = payload
            // 深拷贝
            state.edit_last_object = JSON.parse(JSON.stringify(payload))
        },
        updateCurrentEdityDataModify(state, payload) {
            // 修改当前编辑的数据
            if (payload.desc === undefined) {
                state.contentHasChanged = true;
                onDataChange(state, 'other');
            }
            Object.assign(state.edit_object, payload)
        },
        restoreData(state, payload) {
            const value = localStorage.getItem('edit_data')
            if (value) {
                const data = JSON.parse(value)
                if (data !== null) {
                    if (state.contentModules) {
                        Object.assign(state.contentModules.eduExperience, data.contentModules.eduExperience)
                        Object.assign(state.contentModules.basicInfo, data.contentModules.basicInfo)
                        Object.assign(state.contentModules.workExperience, data.contentModules.workExperience)
                        Object.assign(state.contentModules.schoolExperience, data.contentModules.schoolExperience)
                        Object.assign(state.contentModules.personalSummary, data.contentModules.personalSummary)
                        Object.assign(state.contentModules.evaluation, data.contentModules.evaluation)
                        Object.assign(state.contentModules.awards, data.contentModules.awards)
                        Object.assign(state.contentModules.portfolios, data.contentModules.portfolios)
                        Object.assign(state.contentModules.customExperience, data.contentModules.customExperience)
                    }
                    Object.assign(state.formatInfo, data.formatInfo)
                    Object.assign(state.style, data.style)
                }
            }
        },
        resetResumeData(state) {
            state.contentModules = {
                basicInfo: {},
                personalSummary: {},
                evaluation: {},
                eduExperience: [],
                workExperience: [],
                schoolExperience: [],
                awards: [],
                portfolios: [],
                customExperience: [],
                projectExperience: [],
            };
            state.modules = [];
            state.analyzeData = {};
            state.editTemplateInfo = {};
            state.style = 1;
            state.formatInfo = {};
            state.needShowDialogWhenBack = false;
        },
        setResumeData(state, payload) {
            // ! 将获取的数据，填充到 vuex 数据中
            // ! 1. 导入数据 2. 模板数据 3. 个人数据
            console.log("set resume data", payload.resumeData.style);
            if (payload.diagnosis) {
                state.analyzeData = payload.diagnosis
            }
            if (payload.resumeData.formatInfo) {
                Object.assign(state.formatInfo, payload.resumeData.formatInfo)
            } else {
                state.formatInfo = {
                    themeColor: "#000000",
                    font: 1,
                    textSize: 12,
                    rowSpace: 20,
                    moduleSpace: 10,
                    pageMargin: 10,
                    titleStyle: 2,
                    dateStyle: 1,
                }
            }
            if (state.formatInfo.textSize < 10) {
                state.formatInfo.textSize = 12
            } if (state.formatInfo.rowSpace < 12) {
                state.formatInfo.rowSpace = 15
            } if (state.formatInfo.moduleSpace < 10) {
                state.formatInfo.moduleSpace = 20
            } if (state.formatInfo.pageMargin < 5) {
                state.formatInfo.pageMargin = 5
            }
            if (state.formatInfo.dateStyle === 0) {
                state.formatInfo.dateStyle = 1;
            }
            if (state.formatInfo.titleStyle === 0) {
                state.formatInfo.titleStyle = 1;
            }
            if (!state.formatInfo.themeColor) {
                state.formatInfo.themeColor = '#000000';
            }
            if (payload.resumeData.style) {
                state.style = payload.resumeData.style;
            } else {
                state.stype = 1;
            }
            state.title = payload.resumeData.title;
            if (payload.resumeData.contentModules && payload.resumeData.contentModules.length > 0) {
                var customExperience = [];
                const modules = [];
                payload.resumeData.contentModules.forEach(item => {
                    modules.push({
                        id: item.id,
                        module: item.module,
                        title: item.title
                    });
                    if (item.module === 'basic_info') {
                        if (item.basicInfo) {
                            state.contentModules.basicInfo = item.basicInfo;
                        } else {
                            state.contentModules.basicInfo = {}
                        }
                    } else if (item.module === 'edu_experience') {
                        if (item.eduExperience) {
                            state.contentModules.eduExperience = item.eduExperience;
                        } else {
                            state.contentModules.eduExperience = [];
                        }
                    } else if (item.module === 'project_experience') {
                        if (item.projectExperience) {
                            state.contentModules.projectExperience = item.projectExperience;
                        } else {
                            state.contentModules.projectExperience = [];
                        }
                    } else if (item.module === 'work_experience') {
                        if (item.workExperience) {
                            state.contentModules.workExperience = item.workExperience;
                        } else {
                            state.contentModules.workExperience = [];
                        }
                    } else if (item.module === 'school_experience') {
                        if (item.schoolExperience) {
                            state.contentModules.schoolExperience = item.schoolExperience;
                        } else {
                            state.contentModules.schoolExperience = [];
                        }
                    } else if (item.module === 'personal_summary') {
                        if (item.personalSummary) {
                            state.contentModules.personalSummary = item.personalSummary;
                        } else {
                            state.contentModules.personalSummary = {};
                        }
                    } else if (item.module === 'evaluation') {
                        if (item.evaluation) {
                            state.contentModules.evaluation = item.evaluation;
                        } else {
                            state.contentModules.evaluation = {};
                        }
                    } else if (item.module === 'awards') {
                        if (item.awards) {
                            state.contentModules.awards = item.awards;
                        } else {
                            state.contentModules.awards = [];
                        }
                    } else if (item.module === 'portfolios') {
                        if (item.portfolios) {
                            state.contentModules.portfolios = item.portfolios;
                        } else {
                            state.contentModules.portfolios = [];
                        }
                    } else if (item.module === 'custom_experience') {
                        customExperience = customExperience.concat(item.customExperience)
                    }
                });
                if (customExperience.length > 0) {
                    state.contentModules.customExperience = customExperience;
                } else {
                    state.contentModules.customExperience = [];
                }
                state.modules = modules;
            }
            // ! 2. 设置模板数据
            state.editTemplateInfo = {
                id: payload.id,
                title: payload.resumeData.title,
                desc: payload.desc,
                baseUseNum: payload.totalUseNum,
                vip: payload.vip,
                labels: payload.labels ? payload.labels : [], // 标签 比如 实习，2020届
            },
                state.importResumeData = null;
        },
        updateResumeTitle(state, payload) {
            console.log('updateResumeTitle', payload);
            state.title = payload;
            onDataChange(state, 'title');
        },
        updateModuleTitle(state, payload) {
            // ? 更新module的title
            state.modules.forEach((item) => {
                if (item.id === payload.moduleId) {
                    item.title = payload.title;
                }
            });
        }
    },
    actions: {
        saveLocalData({ state, rootGetters }) {
            return new Promise((resolve, reject) => {
                localStorage.removeItem('edit_data');
                localStorage.setItem('edit_data', JSON.stringify(state))
                state.needShowDialogWhenBack = true;
                // console.log("保存本地数据", localStorage.getItem('edit_data'));
                resolve();
            })
        },
        restoreData({ state }) {
            console.log("restoreData");
            return new Promise((resolve, reject) => {
                const localData = localStorage.getItem('edit_data')
                if (localData) {
                    const data = JSON.parse(localData);
                    state.title = data.title;
                    state.style = data.style;
                    state.formatInfo = data.formatInfo;
                    state.contentModules = data.contentModules;
                    state.modules = data.modules;
                }
                console.warn("从本地恢复到 state");
                resolve();
            })
        },
        changeOrder({ state }, payload) {
            return new Promise((resolve, reject) => {
                // 改变模块顺序
                console.log('changeOrder', payload);
                const isUp = payload.isUp;
                const subId = payload.subId;
                const moduleId = payload.moduleId;
                const module = payload.module;
                if (subId) {
                    console.log("改变子模块顺序");
                    if (module === 'work_experience') {
                        changeChildModuleOrder(state.contentModules.workExperience, subId, isUp);
                    } else if (module === 'edu_experience') {
                        changeChildModuleOrder(state.contentModules.eduExperience, subId, isUp);
                    } else if (module === 'project_experience') {
                        changeChildModuleOrder(state.contentModules.projectExperience, subId, isUp);
                    } else if (module === 'school_experience') {
                        changeChildModuleOrder(state.contentModules.schoolExperience, subId, isUp);
                    } else if (module === 'awards') {
                        changeChildModuleOrder(state.contentModules.awards, subId, isUp);
                    } else if (module === 'portfolios') {
                        changeChildModuleOrder(state.contentModules.portfolios, subId, isUp);
                    } else if (module === 'custom_experience') {
                        changeChildModuleOrder(state.contentModules.customExperience, subId, isUp);
                    }
                } else {
                    console.log("改变父模块顺序");
                    changeParentModuleOrder(state.modules, moduleId, isUp);
                }
                resolve();
            })
        },
        addParentModule({ state }, payload) {
            return new Promise((resolve, reject) => {
                // 添加父模块
                console.log('addParentModule', payload);
                state.modules.push(payload)
                const module = payload.module;

                if (module === 'edu_experience') {
                    state.contentModules.eduExperience = [];
                } else if (module === 'project_experience') {
                    state.contentModules.projectExperience = [];
                } else if (module === 'work_experience') {
                    state.contentModules.workExperience = [];
                } else if (module === 'school_experience') {
                    state.contentModules.schoolExperience = [];
                } else if (module === 'personal_summary') {
                    state.contentModules.personalSummary = {};
                } else if (module === 'evaluation') {
                    state.contentModules.evaluation = {};
                } else if (module === 'awards') {
                    state.contentModules.awards = [];
                } else if (module === 'portfolios') {
                    state.contentModules.portfolios = [];
                } else if (module === 'custom_experience') {
                    const length = state.contentModules.customExperience.length;
                }
                onDataChange(state, 'addParentModule');
                resolve();
            })
        },
        deleteModule({ state }, payload) {
            return new Promise((resolve, reject) => {
                // 删除模块
                console.log('deleteModule', payload);
                const subId = payload.subId;
                const moduleId = payload.moduleId;
                const module = payload.module;
                if (subId) {
                    console.log('删除子模块');
                    if (module === 'edu_experience') {
                        deleteChildModule(state.contentModules.eduExperience, subId);
                    } else if (module === 'project_experience') {
                        deleteChildModule(state.contentModules.projectExperience, subId);
                    } else if (module === 'work_experience') {
                        deleteChildModule(state.contentModules.workExperience, subId);
                    } else if (module === 'school_experience') {
                        deleteChildModule(state.contentModules.schoolExperience, subId);
                    } else if (module === 'awards') {
                        deleteChildModule(state.contentModules.awards, subId);
                    } else if (module === 'portfolios') {
                        deleteChildModule(state.contentModules.portfolios, subId);
                    } else if (module === 'custom_experience') {
                        deleteChildModule(state.contentModules.customExperience, subId);
                    }
                } else {
                    console.log('删除父模块');
                    state.modules.forEach(item => {
                        if (item.id === moduleId) {
                            state.modules.splice(state.modules.indexOf(item), 1)
                        }
                    });
                    if (module === 'edu_experience') {
                        state.contentModules.eduExperience = [];
                    } else if (module === 'project_experience') {
                        state.contentModules.projectExperience = [];
                    } else if (module === 'work_experience') {
                        state.contentModules.workExperience = [];
                    } else if (module === 'school_experience') {
                        state.contentModules.schoolExperience = [];
                    } else if (module === 'personal_summary') {
                        state.contentModules.personalSummary = {};
                    } else if (module === 'evaluation') {
                        state.contentModules.evaluation = {};
                    } else if (module === 'awards') {
                        state.contentModules.awards = [];
                    } else if (module === 'portfolios') {
                        state.contentModules.portfolios = [];
                    } else if (module === 'custom_experience') {
                        const length = state.contentModules.customExperience.length;
                        for (var i = 0; i < length; i++) {
                            if (state.contentModules.customExperience[i] && state.contentModules.customExperience[i].moduleId === moduleId) {
                                state.contentModules.customExperience.splice(i, 1);
                                i--;
                            }
                        }
                    }
                }
                resolve();
            })
        },
        onEmptyClick({ state, rootGetters }, payload) {
            return new Promise((resolve, reject) => {
                // 空模块点击
                const id = `${new Date().getTime()}`;
                const moduleId = payload.moduleId;
                const module = payload.module;
                if (module === 'edu_experience') {
                    state.contentModules.eduExperience = [{ desc: '', id: id }];
                    state.edit_object = state.contentModules.eduExperience[0]
                } else if (module === 'project_experience') {
                    state.contentModules.projectExperience = [{ desc: '', id: id }];
                    state.edit_object = state.contentModules.projectExperience[0]
                } else if (module === 'work_experience') {
                    state.contentModules.workExperience = [{ desc: '', id: id }];
                    state.edit_object = state.contentModules.workExperience[0]
                } else if (module === 'school_experience') {
                    state.contentModules.schoolExperience = [{ desc: '', id: id }];
                    state.edit_object = state.contentModules.schoolExperience[0]
                } else if (module === 'personal_summary') {
                    state.contentModules.personalSummary = { id: id };
                    state.edit_object = state.contentModules.personalSummary
                } else if (module === 'evaluation') {
                    state.contentModules.evaluation = { id: id, desc: '' };
                    state.edit_object = state.contentModules.evaluation
                } else if (module === 'awards') {
                    state.contentModules.awards = [{ id: id }];
                    state.edit_object = state.contentModules.awards[0]
                } else if (module === 'portfolios') {
                    state.contentModules.portfolios = [{ id: id }];
                    state.edit_object = state.contentModules.portfolios[0]
                } else if (module === 'custom_experience') {
                    state.contentModules.customExperience.push({ desc: '', id: id, moduleId: moduleId });
                    state.edit_object = state.contentModules.customExperience[state.contentModules.customExperience.length - 1]
                }
                state.edit_last_object = JSON.parse(JSON.stringify(state.edit_object))
                resolve();
            })
        },
        addChildModule({ state, rootGetters }, payload) {
            return new Promise((resolve, reject) => {
                console.log('addChildModule', payload);
                const id = `${new Date().getTime()}`;
                const moduleId = payload.moduleId;
                const module = payload.module;
                if (module === 'edu_experience') {
                    state.contentModules.eduExperience.push({ desc: '', id: id });
                    state.edit_object = state.contentModules.eduExperience[state.contentModules.eduExperience.length - 1]
                } else if (module === 'project_experience') {
                    state.contentModules.projectExperience.push({ desc: '', id: id });
                    state.edit_object = state.contentModules.projectExperience[state.contentModules.projectExperience.length - 1]
                } else if (module === 'work_experience') {
                    state.contentModules.workExperience.push({ desc: '', id: id });
                    state.edit_object = state.contentModules.workExperience[state.contentModules.workExperience.length - 1]
                } else if (module === 'school_experience') {
                    state.contentModules.schoolExperience.push({ desc: '', id: id });
                    state.edit_object = state.contentModules.schoolExperience[state.contentModules.schoolExperience.length - 1]
                } else if (module === 'awards') {
                    state.contentModules.awards.push({ desc: '', id: id });
                    state.edit_object = state.contentModules.awards[state.contentModules.awards.length - 1]
                } else if (module === 'portfolios') {
                    state.contentModules.portfolios.push({ desc: '', id: id });
                    state.edit_object = state.contentModules.portfolios[state.contentModules.portfolios.length - 1]
                } else if (module === 'custom_experience') {
                    state.contentModules.customExperience.push({ desc: '', id: id, moduleId: moduleId });
                    state.edit_object = state.contentModules.customExperience[state.contentModules.customExperience.length - 1]
                }
                state.edit_last_object = JSON.parse(JSON.stringify(state.edit_object))
                onDataChange(state, 'addChildModule');
                state.editSubModuleId = id;
                resolve();
            });
        },
        checkTitle({ state }) {
            return new Promise((resolve, reject) => {
                // ? 处理 title 为空的场景
                if (!state.title || state.title === undefined || state.title === "未命名简历") {
                    if (state.contentModules.basicInfo) {
                        if (state.contentModules.basicInfo.name
                            && state.contentModules.basicInfo.name !== "StrongHire"
                            && state.contentModules.basicInfo.targetJob) {
                            state.title = `${state.contentModules.basicInfo.name}-${state.contentModules.basicInfo.targetJob}`
                        } else if (state.contentModules.basicInfo.name
                            && state.contentModules.basicInfo.name !== "StrongHire") {
                            state.title = `${state.contentModules.basicInfo.name}的简历`
                        } else {
                            state.title = "未命名简历";
                        }
                    } else {
                        state.title = "未命名简历";
                    }
                }
                resolve();
            });
        },
        async addResumeData({ state, dispatch }, isTemplate) {
            // ! 未登录，登录后，主动添加数据。 已登录用户，数据变更时，被动添加数据
            console.log("start add resume data");
            const data = {};
            await dispatch("checkTitle");
            data.title = state.title;
            console.log("add resume data title", data.title);
            data.style = state.style;
            data.formatInfo = state.formatInfo;
            const contentModules = [];
            if (state.modules && state.modules.length > 0) {
                state.modules.forEach(module => {
                    if (module.module === "basic_info") {
                        contentModules.push({
                            module: module.module,
                            title: module.title,
                            basicInfo: state.contentModules.basicInfo,
                        })
                    } else if (module.module === "edu_experience") {
                        contentModules.push({
                            module: module.module,
                            title: module.title,
                            eduExperience: state.contentModules.eduExperience,
                        })
                    } else if (module.module === "project_experience") {
                        contentModules.push({
                            module: module.module,
                            title: module.title,
                            projectExperience: state.contentModules.projectExperience,
                        })
                    } else if (module.module === "work_experience") {
                        contentModules.push({
                            module: module.module,
                            title: module.title,
                            workExperience: state.contentModules.workExperience,
                        })
                    } else if (module.module === "school_experience") {
                        contentModules.push({
                            module: module.module,
                            title: module.title,
                            schoolExperience: state.contentModules.schoolExperience,
                        })
                    } else if (module.module === "personal_summary") {
                        contentModules.push({
                            module: module.module,
                            title: module.title,
                            personalSummary: state.contentModules.personalSummary,
                        })
                    } else if (module.module === "evaluation") {
                        contentModules.push({
                            module: module.module,
                            title: module.title,
                            evaluation: state.contentModules.evaluation,
                        })
                    } else if (module.module === "awards") {
                        contentModules.push({
                            module: module.module,
                            title: module.title,
                            awards: state.contentModules.awards,
                        })
                    } else if (module.module === "portfolios") {
                        contentModules.push({
                            module: module.module,
                            title: module.title,
                            portfolios: state.contentModules.portfolios,
                        })
                    } else if (module.module === "custom_experience") {
                        contentModules.push({
                            module: module.module,
                            title: module.title,
                            customExperience: state.contentModules.customExperience.filter(item => item.moduleId === module.id),
                        })
                    }
                });
            }
            data.contentModules = contentModules;
            data.templateId = state.resumeId;
            return editApi.addResume(data, isTemplate);
        },
        deleteLocalData() {
            console.log("删除本地数据");
            localStorage.removeItem('edit_data')
        },
        async editTemplateInfo({ state }) {
            return editApi.editTemplateInfo({
                id: state.editTemplateInfo.id,
                title: state.editTemplateInfo.title,
                desc: state.editTemplateInfo.desc,
                baseUseNum: state.editTemplateInfo.baseUseNum,
                vip: state.editTemplateInfo.vip,
                labels: state.editTemplateInfo.labels.join(','),
            });
        },
    }
}