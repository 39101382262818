<template>

  <div style="background-color: #FFFFFF">
    <iframe
        id="bdIframe"
        frameborder="no"
        scrolling="auto"
        style="border:none;"
        width="100%"
        height="0"
        src="https://cdn.stronghire.cn/web/study_join_article_v10.html"/>
<!--    <iframe-->
<!--        id="bdIframe"-->
<!--        frameborder="no"-->
<!--        scrolling="auto"-->
<!--        style="border:none;"-->
<!--        width="100%"-->
<!--        height="0"-->
<!--        src="/study_join_article_v10.html"/>-->
  </div>
  <transition name="run">
  <div class="floor" v-show="state.btn_show">
    <img src="@/assets/img/study_join_article_btn_single_149.svg" alt="logo" class="logo" @click="doPay" />
  </div>
  </transition>
</template>

<script setup>
import {computed, reactive, ref} from "@vue/reactivity";
import {nextTick, onMounted, onUnmounted} from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import studyApi from "../../apis/study_api";

const route = useRoute();
const router = useRouter();

const state = reactive({
  payStatus: 0, // 0: 未支付 1: 支付成功 2: 支付失败 3: 支付取消
  code:"",
  openid:"",
  btn_show:false
});

const payDesc = computed(() => {
  switch (state.payStatus) {
    case 0:
      return "支付准备中...";
    case 1:
      return "支付成功，准备跳转中...";
    case 2:
      return "支付失败";
    case 3:
      return "支付取消";
    default:
      return "";
  }
});

const getOpenid = async (code) => {
  try {
    const result = await studyApi.getOpenid(
        code
    );
    if (result.code === 0) {
      state.openid = result.data;
    } else {
      ElMessage.warning(result.msg);
    }
  } catch ({ msg: msg }) {
    ElMessage.warning(msg);
  }
};

const jumpWeixin = async (code) => {
  try {
    const result = await studyApi.createOder(
      code
    );
    if (result.code === 0) {
      if (typeof WeixinJSBridge == "undefined") {
        console.log("WeixinJSBridg undefined");
        state.payStatus = 2;
      } else {
        onWeixinBridgeReady(result.data);
      }
    } else {
      ElMessage.warning(result.msg);
      state.payStatus = 2;
    }
  } catch ({ msg: msg }) {
    ElMessage.warning(msg);
    state.payStatus = 2;
  }
};

const jumpH5Weixin = async () => {
  try {
    const result = await studyApi.createWapOder();
    if (result.code === 0) {
      const appUrl = process.env.VUE_APP_URL;
      window.location.replace(result.data['mwebUrl'] + '&redirect_url=' + encodeURI(`${appUrl}/m/study_pay_result`));
    } else {
      ElMessage.warning(result.msg);
      state.payStatus = 2;
    }
  } catch ({ msg: msg }) {
    ElMessage.warning(msg);
    state.payStatus = 2;
  }
};

const jumpWeixinDouble = async (code) => {
  try {
    const result = await studyApi.createOderDouble(
        code
    );
    if (result.code === 0) {
      if (typeof WeixinJSBridge == "undefined") {
        console.log("WeixinJSBridg undefined");
        state.payStatus = 2;
      } else {
        onWeixinBridgeReady(result.data);
      }
    } else {
      ElMessage.warning(result.msg);
      state.payStatus = 2;
    }
  } catch ({ msg: msg }) {
    ElMessage.warning(msg);
    state.payStatus = 2;
  }
};

const onWeixinBridgeReady = (data) => {
  WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      appId: data.appId,
      timeStamp: data.timeStamp,
      nonceStr: data.nonceStr,
      package: data.packageValue,
      signType: data.signType,
      paySign: data.paySign,
    },
    function (res) {
      const appUrl = process.env.VUE_APP_URL;
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        //成功
        console.log("ok");
        state.payStatus = 1;
        setTimeout(() => {
          window.location.replace(`${appUrl}/m/study_pay_result`)
        }, 500);
      } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
        //取消
        console.log("cancel");
        state.payStatus = 3;
      } else {
        //失败
        console.log("fail" + res.err_desc);
        state.payStatus = 2;
      }
    }
  );
};

const doPay = () => {
  if (/MicroMessenger/.test(window.navigator.userAgent)) {
    jumpWeixin(state.openid)
  } else {
    jumpH5Weixin()
  }
}

const doPayDouble = () => {
  jumpWeixinDouble(state.openid)
}

const initData = () => {
  // 判断是微信客户端 or 支付宝客户端，做不同支付逻辑
  if (/MicroMessenger/.test(window.navigator.userAgent)) {
    var study_pay_has_redirected = sessionStorage.getItem(
      "study_pay_has_redirected"
    );
    if (!route.query || !route.query.code) {
      if (study_pay_has_redirected) {
        sessionStorage.removeItem("study_pay_has_redirected");
        nextTick(() => {
          WeixinJSBridge.call("closeWindow");
        });
      } else {
        sessionStorage.setItem("study_pay_has_redirected", true);
        const appUrl = process.env.VUE_APP_URL;
        let redirectUrl = encodeURIComponent(`${appUrl}/m/study_join`);
        const wxUrl = (window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc520e71a0a38fde5&redirect_uri=" +
          redirectUrl +
          "?response_type=code&scope=snsapi_base&state=123&connect_redirect=1#wechat_redirect");
        window.location.href = wxUrl;
      }
    } else {
      // 已经有code参数，直接调用微信接口
      state.code = route.query.code;
      getOpenid(state.code);
    }
  } else if (/AlipayClient/.test(window.navigator.userAgent)) {
    console.log("not support alipay: ua=" + window.navigator.userAgent);
    state.payStatus = 2;
  } else {
    // h5支付
    console.log("not weixin or alipay: ua=" + window.navigator.userAgent);
  }
};

onMounted(() => {
  document.title = "StrongHire自习室加入方法";

  initData();

  window.addEventListener('message', function (e) {
    if (e.data === 'doPay') {
      doPay()
    } else {
      if (!state.btn_show) {
        if (e.data > 2000) {
          state.btn_show = true;
        }
      }
    }
  });

  nextTick(() => {
    var ifm = document.getElementById("bdIframe");
    ifm.height = document.documentElement.clientHeight;
  });
});

</script>

<style lang="scss" scoped>
// 元素开始进入的状态 | 元素离开结束的状态
.run-enter-from,
.run-leave-to {
  opacity: 0;
}
// 元素进入结束的状态 ｜ 元素开始离开的状态。     这里不写也可以！！！！！！
.run-enter-to,
.run-leave-from {
  opacity: 1;
}
// 元素进入 ｜ 结束时，过渡的效果
.run-enter-active,
.run-leave-active {
  // 过渡动画的使用
  transition: opacity 0.8s linear 0s;
}

.floor {
  width: 100%;			/*宽度为100%*/
  height: 100px;			/*高度度为70px*/
  //background-color: #000;	/*背景颜色为黑色*/
  text-align: center;		/*内容居中*/
  color:#999;				/*字体颜色*/
  /*放置的位置设置*/
  position: fixed;		/*1、【relative】相对定位；2、【absolute】绝对定位；3、【fixed】固定定位；4、【static】默认值；5、【sticky】粘性定位。*/
  left: 0px; 				/*定位：离页面左边0px的位置*/
  bottom: 0px;			/*定位：离页面底部0px的位置*/
  /*top: 92%;*/				/*定位：离页面顶部92%的位置*/
  /*border: 1px solid #000;*/
}
//.logo {
//  width: 175px;
//  height: 53px;
//}
.mobile_pay {
  width: 100vw;
  height: 100vh;
  background-color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

  & > img {
    margin-top: calc(0.1 * 100vh);
    width: 200px;
    height: 39px;
  }
  & > p {
    margin-top: 5.3px;
    span {
      font-size: 12px;
      line-height: 17px;
      color: white;
      &:nth-child(2) {
        color: #e47470;
      }
    }
  }
  .mobile_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;

    .title {
      font-size: 25px;
      line-height: 35px;
      background: linear-gradient(90deg, #fed8b1 0%, #d1a17b 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 500;
    }

    .qr-code {
      width: 200px;
      height: 200px;
      margin-top: 40px;
    }

    .tips {
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      margin-top: 10px;
    }
  }
  .pay_not_success {
    margin-top: 70px;
    font-size: 20px;
    line-height: 28px;
    color: white;
  }

}
</style>
