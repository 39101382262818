import { useRoute } from "vue-router";
import store from '@/store'

export default {

    analyzeEnable: function () {
        return process.env.VUE_APP_ENVIRONMENT === 'production';
    },
    initUmeng: function (route) {
        var aplus_queue = aplus_queue || [];
        (function (d, s, i) {
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s);
            j.async = true;
            j.id = 'beacon-aplus';
            j.src = 'https://d.alicdn.com/alilog/mlog/aplus/' + i + '.js';
            f.parentNode.insertBefore(j, f);
            window['aplus_queue'] = aplus_queue
        })(document, 'script', '203467608');

        //集成应用的appKey
        if (process.env.VUE_APP_ENVIRONMENT === 'production') {
            aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['appKey', '62772b08d024421570e691dd']
            });
        } else {
            aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['appKey', '62772b26d024421570e691e0']
            });
        }
        /************************以下内容为可选配置内容****************************/
        //是否开启调试模式 
        aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['DEBUG', false]
        });
        aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['aplus-waiting', 'MAN']
        });

        aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['aplus-auto-exp', [
                {
                    // 需要曝光的元素class
                    cssSelector: '.auto-exp-component',
                    //如果页面模块是元素内滚动（某个区块内有滚动条）则需要增加positionSelector辅助定位曝光元素
                    logkey: 'template_show',  //事件管理中的事件id
                    props: ['data-itemid', 'ip', 'ua'], // 你要曝光的元素身上自定义属性
                },
                {
                    // 需要曝光的元素class
                    cssSelector: '.auto-exp-component_mobile',
                    //如果页面模块是元素内滚动（某个区块内有滚动条）则需要增加positionSelector辅助定位曝光元素
                    logkey: 'mobile_template_show',  //事件管理中的事件id
                    props: ['data-itemid', 'ip', 'ua'], // 你要曝光的元素身上自定义属性
                },
            ],
            ],
        });
    },

    initBaidu: function () {
        var _hmt = _hmt || [];
        (function () {
            console.log("head:", process.env.VUE_APP_ENVIRONMENT);
            var hm = document.createElement("script");
            if (process.env.VUE_APP_ENVIRONMENT === 'production') {
                hm.src = "https://hm.baidu.com/hm.js?bd8e3db02e1a15a185d5385802a83b66";
            } else {
                hm.src = "https://hm.baidu.com/hm.js?9262c1e477b71001622d7e5dd640dd63";
            }
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
            window['_hmt'] = _hmt
        })();
    },

    filterInit: function () {
        // 对部分页面进行过滤，不统计
        const url = window.location.href;
        if (url.indexOf('resumePrint') > -1 || url.indexOf('template_resume') > -1 || url.indexOf('user_resume') > -1) {
            console.log("页面被过滤");
            return true;
        }
        const userAgent = window.navigator.userAgent;
        if (userAgent.indexOf('StrongHireScreenShotService') > -1) {
            console.log("页面被过滤");
            return true;
        }
        return false;
    },

    init: function () {
        console.log("init analyze", process.env.VUE_APP_ENVIRONMENT, window.location.href);
        if (this.filterInit()) {
            // 页面被过滤
            return
        }
        if (!this.analyzeEnable()) {
            return
        }
        this.initUmeng()
        this.initBaidu()
    },

    sendPV: function (route) {
        const { aplus_queue, _hmt } = window;
        console.log('sendPV', route.name);
        if (this.filterInit()) {
            // 页面被过滤
            return
        }
        if (!this.analyzeEnable()) {
            return
        }
        _hmt.push(['_trackPageview', route.fullPath])
        aplus_queue.push({
            action: 'aplus.sendPV',
            arguments: [{ is_auto: false }, { page_name: route.name }]
        });
    },

    sendClickEvent: function (event_name, value) {
        if (this.filterInit()) {
            // 页面被过滤
            return
        }
        if (!this.analyzeEnable()) {
            return
        }
        try {
            // eslint-disable-next-line no-undef
            LA.track(event_name);
        } catch (e) {
            console.warn(e);
        }
        const ip = localStorage.getItem('extra_ip')
        console.log(ip);
        const localData = localStorage.getItem('user_data');
        var userStatus = "未登录";
        if (localData) {
            userStatus = "非会员";
            const data = JSON.parse(localData)
            if (data && data.vipExpireTime) {
                const now = new Date().getTime();
                if (data.vipExpireTime >= 30000000000000) {
                    userStatus = "终身会员";
                } else if (data.vipExpireTime > now) {
                    userStatus = "普通会员";
                }
            }
        }
        if (value) {
            Object.assign(value, { userStatus: userStatus, ua: navigator.userAgent, ip: ip })
        } else {
            value = { userStatus: userStatus, ua: navigator.userAgent, ip: ip }
        }

        console.log('sendClickEvent', event_name, value);
        const { aplus_queue, _hmt } = window;
        aplus_queue.push({
            action: 'aplus.record',
            arguments: [event_name, 'CLK', value]
        });
    },

}

// import analyze from "@/plugin/analyze.js";
// const { sendPV,sendClickEvent } = analyze();