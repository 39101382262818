<template>
  <el-container :style="{ backgroundColor: state.backgroundColor }">
    <el-header>
      <div class="header">
        <router-link to="/">
          <img
            id="img_logo"
            src="@/assets/img/sidebar_logo.svg"
            @click="onLogoClick"
          />
        </router-link>

        <!-- <p class="logo" style="fontsize: 20px; fontweight: 500">
          南京子丑寅卯网络科技有限公司
        </p> -->

        <div
          mode="horizontal"
          :ellipsis="false"
          :default-active="state.activeIndex"
          class="menu"
        >
          <a
            v-for="(tabName, index) in state.tabs"
            :key="index"
            class="menu-item"
            @click="onSelect(index + 1)"
            :href="getHref(index + 1)"
            :class="{
              select: '' + (index + 1) === state.activeIndex,
              'menu-item-unselect': '' + (index + 1) !== state.activeIndex,
            }"
          >
            <p>{{ tabName }}</p>
            <div
              class="menu-bottom-bar"
              :style="{
                visibility: checkBottomBarVisible(index),
                opacity: checkBottomBarAlpha(index),
              }"
            ></div>
          </a>
        </div>

        <Avatar v-if="hasLogin"></Avatar>
        <div v-else class="head-login-bt" @click="onLoginClick">登录</div>
      </div>
    </el-header>

    <el-main>
      <div class="main">
        <router-view v-slot="{ Component }">
          <Transition name="fade" mode="out-in">
            <component :is="Component"></component>
          </Transition>
        </router-view>
      </div>
    </el-main>

    <el-footer>
      <div class="footer">
        <el-space wrap :size="30">
          <el-link type="info" @click="onLinkUsClick">联系我们</el-link>
          <el-link type="info">关于我们</el-link>
          <el-link type="info" href="/agreement">用户协议</el-link>
          <el-link type="info" href="/privacy">隐私政策</el-link>
          <el-link type="info" href="/vip_service">会员服务协议</el-link>
        </el-space>
        <el-space wrap spacer="|" :size="15">
          <span>©2023 南京子丑寅卯网络科技有限公司</span>
          <el-link type="info" href="http://beian.miit.gov.cn/" target="_blank"
            >苏ICP备2022001160号-1</el-link
          >
          <div class="gov">
            <img src="@/assets/img/icon_gov.png" />
            <el-link
              type="info"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011402011116"
              target="_blank"
              >苏公网安备 32011402011116号</el-link
            >
          </div>
          <el-link type="info" href="/license_center">证照中心</el-link>
        </el-space>
      </div>
    </el-footer>

    <transition name="fade">
      <div
        class="edit-float-import"
        @click="onImportClick"
        v-show="showImportFloat"
      >
        <img src="@/assets/img/icon_edit_login_float.svg" alt="" />
        <div>
          <span v-for="(item, index) in '导入简历'" :key="index">{{
            item
          }}</span>
        </div>
      </div>
    </transition>
  </el-container>
</template>

<script setup>
//引入vue方法
import { useStore } from "vuex";
import {
  computed,
  reactive,
  ref,
  toRefs,
  watch,
  onBeforeMount,
  onMounted,
} from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import Avatar from "@/components/common/Avatar";
import analyze from "@/plugin/analyze.js";
import vipMix from "@/plugin/vip";

const router = useRouter();
const route = useRoute();
const store = useStore();
const { checkVipWhenCreateResume } = vipMix();

const state = reactive({
  activeIndex: "1",
  tabs: ["我的简历", "简历模板", "求职辅导", "求职攻略"],
  backgroundColor: "#F4F5F5",
});

const onLinkUsClick = () => {
  store.commit("updateAddWechatDialog", {
    show: true,
    type: 1,
  });
};

const hasLogin = computed(() => store.getters.hasLogin);
const onLoginClick = () => {
  // updateVipDialog updateLoginDialog
  store.commit("updateLoginDialog", {
    showLoginDialog: true,
    from: "首页右上角按钮",
  });
};

const showImportFloat = computed(() => {
  return route.name === "template";
});

const updateIndexView = (index) => {
  state.activeIndex = index;
};
const checkBottomBarVisible = (index) => {
  return `${index + 1}` === state.activeIndex ? "visible" : "hidden";
};
const checkBottomBarAlpha = (index) => {
  return `${index + 1}` === state.activeIndex ? 1 : 0;
};

const getHref = (index) => {
  index = "" + index;
  switch (index) {
    case "1":
      return undefined;
    case "2":
      return "/";
    case "3":
      return "/counseling";
    case "4":
      return "/job_strategy/latest";
    default:
      return undefined;
  }
};

const onSelect = (index) => {
  index = "" + index;
  console.log(`index: ${index}`);
  console.log(router);
  console.log(route.params);
  if (index === "1") {
    if (!hasLogin.value) {
      store.commit("updateLoginDialog", {
        showLoginDialog: true,
        redictPath: "/resume",
        from: "我的简历 TAB",
      });
      return;
    }
    router.push("/resume");
    analyze.sendClickEvent("top_tab_click", {
      value: "我的简历",
    });
  } else if (index === "2") {
    router.push("/");
    analyze.sendClickEvent("top_tab_click", {
      value: "简历模板",
    });
  } else if (index === "3") {
    router.push("/counseling");
  } else if (index === "4") {
    router.push("/job_strategy/latest");
    // router.push({ name: "calendar" });
  }
  updateIndexView(index);
};

const onPathChange = (to) => {
  if (to) {
    if (to === "template" || to === "search" || to === "detail") {
      updateIndexView("2");
    } else if (to === "jobStrategy" || to === "jobStrategyDetail") {
      updateIndexView("4");
    } else if (to === "resume") {
      updateIndexView("1");
    } else if (to === "counseling") {
      updateIndexView("3");
    } else if (to === "calendar") {
      updateIndexView("5");
    } else {
      updateIndexView("5");
    }
    if (
      to === "agreement" ||
      to === "privacy" ||
      to === "vipService" ||
      to === "licenseCenter" ||
      to === "counseling"
    ) {
      state.backgroundColor = "white";
      document.querySelector("body").style.backgroundColor = "white";
    } else {
      state.backgroundColor = "#F4F5F5";
      document.querySelector("body").style.backgroundColor = "#F4F5F5";
    }
  }
};

onBeforeMount(() => {
  onPathChange(route.name);
});

watch(
  () => route.path,
  (to, from) => {
    onPathChange(route.name);
    if (route.name === "template" && route.params.needLogin === true) {
      // 登录
    }
  }
);

const onLogoClick = () => {
  console.log("logo click");
  analyze.sendClickEvent("top_tab_click", {
    value: "LOGO",
  });
};

const onImportClick = async () => {
  if (await checkVipWhenCreateResume("导入简历")) {
    store.commit("setUploadData", {
      uploadVisible: true,
      uploadType: "resume",
      uploadTitle: "导入已有简历",
      uploadSubTitle: "仅支持Word、PDF格式的简历，不超过5MB",
      uploadResumeId: "",
    });
  }
};

onMounted(() => {});
</script>

<style scope lang="scss">
@import "src/style/base.scss";

.el-header {
  height: $header-height;
  background-color: white;
  position: fixed;
  width: 100%;
  box-shadow: 0px 5px 10px rgba($color: #cbcbcb, $alpha: 0.1);
  z-index: 100;
  .header {
    @include content-layout;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: auto;
    max-width: 1200px;
    min-width: 400px;

    .head-login-bt {
      @include primaryButton;
    }
  }
  #img_logo {
    width: 152px;
    height: 26px;
    cursor: pointer;
  }
}

.menu {
  height: $header-height;
  border: 0;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .menu-item {
    font-size: 16px;
    width: 104px;
    justify-content: center;
    height: $header-height;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: normal;
    transition: all 250ms;

    &:visited {
      color: black;
    }
    // 修改 a 标签访问过后的颜色
    &:visited {
      color: black;
    }

    &.select {
      color: #f56c6c;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
    }
  }
  .menu-item-unselect {
    &:hover {
      font-weight: 600;
    }
  }
  .menu-bottom-bar {
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: $primary-color;
    margin: auto;
    bottom: 10px;
    border-radius: 10px;
    transition: all 300ms;
  }
}

.el-main {
  margin-top: $header-height;
  padding-left: 0px;
  padding-right: 0px;
}

.footer {
  @include content-layout;
  display: flex;
  flex-direction: column;
  .el-link {
    color: $second-text-color;
    font-weight: normal;
    font-size: 12px;
  }
  .el-space {
    margin-top: 15px;
    span {
      color: $second-text-color;
      font-weight: normal;
      font-size: 12px;
    }
  }

  .gov {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
    .el-link {
      margin-top: 2px;
      margin-left: 2px;
    }
  }
}

.edit-float-import {
  width: 56px;
  height: 126px;
  position: fixed;
  right: 20px;
  top: 30%;
  cursor: pointer;
  & > img {
    z-index: 1;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & > div {
    z-index: 12;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;
    span {
      font-size: 14px;
      line-height: 18px;
      color: white;
    }
  }
}

.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease 0s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
</style>
