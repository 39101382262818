<template>
  <div>
    <el-dialog
      v-model="showLoginDialog"
      :width="600"
      top="25vh"
      :destroy-on-close="true"
      :show-close="false"
      @open="onDialogOpen"
      @close="onDialogClose"
    >
      <div class="login-parent">
        <div class="left">
          <img
            src="@/assets/img/icon_login_left_top.svg"
            alt=""
            class="left-top-bg"
          />
          <img
            src="@/assets/img/icon_login_left_bottom.svg"
            alt=""
            class="left-bottom-bg"
          />
          <img src="@/assets/img/icon_login_logo.svg" alt="" class="logo" />
          <ul>
            <li>1000+原创在线简历模板</li>
            <li>云端存储，随时修改</li>
            <li>多重加密，时刻保护你的隐私安全</li>
            <li>AI智能简历诊断，快速提升简历质量</li>
            <li>AI岗位匹配度检测，离Offer更进一步</li>
          </ul>
        </div>
        <div class="right">
          <div class="title">
            <img src="@/assets/img/icon_we_chat.svg" alt="" />
            <span>微信扫码安全登录</span>
          </div>
          <div
            class="qr-code-container"
            v-loading="state.loading"
            v-show="!state.error"
          >
            <qrcode-vue
              class="qr-code"
              :value="state.data.url"
              :size="125"
              level="H"
              v-show="!state.loading"
            />
          </div>
          <div class="retry" v-show="state.error">
            <div @click="getWechatQRCodes">重新加载</div>
            <p>请点击按钮重新加载二维码</p>
          </div>

          <p>
            <span>扫码即代表您已同意</span>
            <a type="info" href="/agreement" target="_blank">《用户协议》</a>
            <span>及</span>
            <a type="info" href="/privacy" target="_blank">《隐私政策》</a>
          </p>
        </div>
        <img
          src="@/assets/img/icon_upload_close.svg"
          alt=""
          @click="onCloseClick"
          class="close-dialog"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import authApi from "@/apis/auth_api";
import { onActivated, onMounted } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import QrcodeVue from "qrcode.vue";
import analyze from "@/plugin/analyze.js";
import { useRoute, useRouter } from "vue-router";
import myCookie from "@/apis/cookie";
import useEventBus from "@/plugin/event";
import editMix from "@/plugin/edit";
import { ElMessageBox } from "element-plus";
import { h } from "vue";
import vipMix from "@/plugin/vip";

const store = useStore();
const router = useRouter();
const route = useRoute();
const eventBus = useEventBus();
const { sysncLocalData } = editMix();
const { checkSyncResumeWhenLoginSuccess, fetchMineCoundResumeCount } = vipMix();

const state = reactive({
  loading: true,
  error: false,
  data: {
    url: "",
    scene: "",
    expireSeconds: 0,
  },
  polling: null,
  pollingTime: 0,
});

const showLoginDialog = computed({
  get: () => store.state.loginData.showLoginDialog,
  set: (val) => {
    store.commit("updateLoginDialog", {
      showLoginDialog: val,
    });
  },
});

const onDialogOpen = () => {
  console.log("onDialogOpen");
  getWechatQRCodes();
  analyze.sendClickEvent("login_dialog_show", {
    value: store.state.loginData.from,
  });
};

const onDialogClose = () => {
  console.log("onDialogClose");
  store.commit("updateLoginDialog", {
    redictPath: "",
    needAddResumeData: false,
  });
  clear();
};

const onCloseClick = () => {
  showLoginDialog.value = false;
};

const onGetWechatQRCodesError = (msg) => {
  ElMessage.error(msg);
  state.loading = false;
  state.error = true;
};

const getWechatQRCodes = async () => {
  state.loading = true;
  state.error = false;
  try {
    const result = await authApi.getWechatQRCodes();

    if (result.data.scene) {
      state.data = result.data;
      state.loading = false;
      // 开始轮询查询数据
      loginByQRScene();
    } else {
      onGetWechatQRCodesError("请求失败，请重试");
    }
  } catch ({ msg: msg }) {
    onGetWechatQRCodesError(msg);
  }
};

const clear = () => {
  console.log("clear");
  clearTimeout(state.polling);
  state.pollingTime = 0;
};

const queryTimeout = () => {
  // 如果超时，显示重试
  onGetWechatQRCodesError("二维码超时，请重试");
  clear();
};

const checkLocalDataNeedUpload = async (needAddResumeData) => {
  await fetchMineCoundResumeCount();
  // 检查本地是否有简历数据，如果有，则需要上传
  const localData = localStorage.getItem("edit_data");
  console.log(
    "checkLocalDataNeedUpload needAddResumeData ",
    needAddResumeData,
    "route name: ",
    route.name
  );
  if (localData) {
    if (needAddResumeData) {
      // 同步本地数据， 首页模板导入和模板创建
      sysncLocalData(true);
    } else if (route.name !== "preview" && route.name !== "edit") {
      // 如果在首页登录成功后，提示本地有简历需要同步
      console.log("checkLocalDataNeedUpload");
      if (await checkSyncResumeWhenLoginSuccess()) {
        sysncLocalData(false);
      }
    }
  }
};

const loginByQRScene = async () => {
  try {
    console.log("loginByQRScene", state.pollingTime);
    state.pollingTime += 2;
    const result = await authApi.loginByQRScene(state.data.scene);
    if (result.code === 0 && result.data) {
      ElMessage({
        message: "登录成功",
        type: "success",
      });
      analyze.sendClickEvent("login_dialog_success", {
        value: store.state.loginData.from,
      });
      store.commit("setUserData", result.data);
      setTimeout(() => {
        console.log("登录成功 path", store.state.loginData.redictPath);
        if (store.state.loginData.redictPath) {
          router.push(store.state.loginData.redictPath);
        }
        myCookie.setStaffUserInfo(result.data);
        const needAddResumeData = store.state.loginData.needAddResumeData;
        store.commit("updateLoginDialog", {
          showLoginDialog: false,
          redictPath: "",
          needAddResumeData: false,
        });
        eventBus.emit("loginSuccess", { value: true });
        checkLocalDataNeedUpload(needAddResumeData);
      }, 500);
    } else {
      if (state.pollingTime >= state.data.expireSeconds) {
        queryTimeout();
      } else {
        state.polling = setTimeout(() => {
          clearTimeout(state.polling);
          loginByQRScene();
        }, 2000);
      }
    }
  } catch ({ msg: msg }) {
    console.log("loginByQRScene error: ", msg);
    queryTimeout();
  }
};
</script>

<style lang="scss" scoped>
::v-deep(.el-dialog) {
  height: 400px;
  border-radius: 5px;
}

::v-deep(.el-dialog__header) {
  display: none;
  padding: 0px;
}

::v-deep(.el-dialog__body) {
  padding: 0px;
  margin: 0px;
}

.login-parent {
  height: 400px;
  display: flex;

  .close-dialog {
    width: 15px;
    height: 15px;
    right: 21px;
    top: 21px;
    position: absolute;
    cursor: pointer;
  }

  .left {
    width: 270px;
    height: 100%;
    position: relative;
    padding-left: 19px;

    .logo {
      width: 133px;
      height: 26px;
      position: absolute;
      top: 21px;
      left: 19px;
    }

    ul {
      position: absolute;
      top: 68px;
      padding: 0px;
      padding-left: 19px;
      margin: 0px;
      li {
        font-size: 12px;
        margin-top: 12px;
        font-size: 12px;
        color: white;
      }
    }

    .left-top-bg {
      position: absolute;
      top: -12px;
      left: 0px;
      width: 270px;
      height: 263px;
    }
    .left-bottom-bg {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 270px;
      height: 155px;
    }
  }

  .right {
    width: 330px;
    position: relative;
    .title {
      display: flex;
      position: absolute;
      top: 70px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 18px;
        height: 18px;
      }
      span {
        margin-left: 5px;
        font-size: 16px;
        font-weight: 500;
        color: black;
      }
    }
    .qr-code-container {
      width: 161px;
      height: 161px;
      border: 0.5px solid #dcdfe6;
      border-radius: 5px;
      position: absolute;
      top: 119px;
      left: 50%;
      transform: translateX(-50%);
      .qr-code {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .retry {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      & > div {
        width: 96px;
        height: 36px;
        cursor: pointer;
        background: #f56c6c;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 250ms;
        font-size: 14px;
        color: white;
        font-weight: 500;

        &:hover {
          background: rgba($color: #f56c6c, $alpha: 0.8);
        }
      }
      & > p {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        margin-top: 10px;
      }
    }

    & > p {
      position: absolute;
      bottom: 10px;
      width: 270px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
      color: rgba(0, 0, 0, 0.5);
    }
    span,
    a {
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      margin-top: 10px;
    }
  }
}
</style>