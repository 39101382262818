<template>
  <div class="content">
    <div class="title">
      <h1>StrongHire用户协议</h1>
      <p
        class="download"
        @click="onDownLoadClick"
        :style="{ opacity: downloadVisible }"
      >
        下载
      </p>
    </div>
    <p>StrongHire（见下列定义）是由南京子丑寅卯网络科技有限公司（下称“子丑寅卯网络科技”或“我们”）提供的互联网软件服务。本服务协议（下称“服务条款”或者“协议”）是您与子丑寅卯网络科技之间关于您（“您”或“用户”）访问和使用StrongHire并使用子丑寅卯网络科技提供的其他服务（下称“服务”）的主要协议。您注册、登录、使用StrongHire和/或享受子丑寅卯网络科技提供的服务，即表示您同意接受服务条款。因此，您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容。如您对协议有任何疑问，可向StrongHire客服service@stronghire.cn咨询。当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与StrongHire达成一致，成为StrongHire的“用户”。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</p>
<p>由于互联网高速发展，您与子丑寅卯网络科技签署的本协议列明的条款并不能完整罗列并覆盖您与子丑寅卯网络科技之间的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，子丑寅卯网络科技法律声明、会员服务协议及隐私权政策等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用StrongHire或子丑寅卯网络科技的其他服务，视为您同意上述补充协议。</p>
<p>子丑寅卯网络科技有权不时地对服务条款做任何修改和补充，并在StrongHire上公布。通常情况下（例如当需要进行修改或补充以满足适用法律要求时），对服务条款的修改和补充将在公布时立即生效。您继续访问和使用StrongHire即视为您接受修订后的服务条款。否则，您有权通过停止访问StrongHire且拒绝使用服务、删除您在StrongHire上的信息和帐户等方式来终止服务条款。</p>
<h2>1.定义</h2>
<p>StrongHire：指包括域名为https://www.stronghire.cn或其他与子丑寅卯网络科技同主体的网站、公众号、小程序、APP应用程序以及各类网络平台。</p>
<p>子丑寅卯网络科技：指南京子丑寅卯网络科技有限公司，StrongHire的经营者以及服务提供者。</p>
<p>StrongHire服务：子丑寅卯网络科技基于互联网，以包含网站、公众号、小程序、APP应用程序以及各类网络平台等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项免费或增值服务。</p>
<p>用户：指具有完全民事行为能力的StrongHire服务使用者。</p>
<p>账号：指用户为使用StrongHire服务及子丑寅卯网络科技服务而登记注册的用户身份识别信息；用户只有注册账号才能使用StrongHire服务及子丑寅卯网络科技服务的服务。</p>
<p>子丑寅卯网络科技的关联公司：指由子丑寅卯网络科技控股、参股或者通过协议控制子丑寅卯网络科技或者通过协议由子丑寅卯网络科技控制的公司。</p>
<h2>2.用户资格</h2>
<p>2.1请您确认，在您开始注册程序使用StrongHire服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，年龄满18周岁。如因您年龄未满18周岁，产生的一切后果需要您自行承担。</p>
<p>2.2此外，您还需确保您不是任何国家、地区或国际组织实施的贸易限制、经济制裁或其他法律法规限制的对象，也未直接或间接为前述对象提供资金、商品或服务，否则您应当停止使用StrongHire服务，同时您理解违反前述要求可能会造成您无法正常注册及使用StrongHire服务。</p>
<p>2.3为了合法、合规地为用户提供更好的服务，子丑寅卯网络科技将不时调整用户需提交的信息。如用户不同意提供上述信息，则应当停止使用子丑寅卯网络科技的服务。用户需同意子丑寅卯网络科技对其注册信息资料进行审查，并就相关疑问或问题进行解答。</p>
<h2>3.账户说明</h2>
<p>3.1当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得StrongHire账户并成为StrongHire用户。</p>
<p>3.2StrongHire只允许每位用户使用一个StrongHire账户。如有证据证明或子丑寅卯网络科技根据StrongHire规则判断您存在不当注册或不当使用多个StrongHire账户的情形，StrongHire可采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给StrongHire及相关方造成损失的，您还应承担赔偿责任。</p>
<p>3.3您有权使用您设置或确认的用户名、邮箱、手机号码（以下简称“账户名称”）及您设置的密码（账户名称及密码合称“账户”）登录StrongHire。</p>
<p>3.4由于您的StrongHire账户关联您的个人或者授权代表的信息，您的StrongHire账户仅限您本人以及授权代表使用。未经StrongHire同意，您直接或间接授权第三方使用您StrongHire账户或获取您账户项下信息的行为无效。如子丑寅卯网络科技认定您StrongHire账户的使用可能危及您的账户安全及/或StrongHire信息安全的，StrongHire可拒绝提供相应服务或终止本协议。</p>
<p>3.5仅当有法律明文规定、司法裁定或经子丑寅卯网络科技同意，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让，否则StrongHire有权追究您的违约责任，且由此产生的责任及后果均由您自行承担。</p>
<p>3.6您的StrongHire账户为您个人所有，禁止多人共用一个账号，若子丑寅卯网络科技有证据表明您违反如上要求，子丑寅卯网络科技有权注销您的账户并不承担任何责任，如给StrongHire及相关方造成损失的，您还应承担赔偿责任。</p>
<p>3.7为使您更好地使用StrongHire的各项服务，保障您的账户安全，子丑寅卯网络科技可要求您按相关法律法规规定完成实名认证。</p>
<h2>4.注册信息管理</h2>
<p>4.1真实合法</p>
<p>在使用StrongHire服务时，您应当按StrongHire页面的提示准确完整地提供您的信息（包括但不限于您的姓名及电子邮件地址、联系电话、联系地址、身份、毕业年份等），以便子丑寅卯网络科技或其他用户与您联系或更好地提供服务。您了解并同意，您有义务保持您提供信息的真实性及有效性。</p>
<p>您设置的子丑寅卯网络科技会员名不得违反国家法律法规及StrongHire关于会员名的管理规定，否则子丑寅卯网络科技可回收您的子丑寅卯网络科技会员名。子丑寅卯网络科技会员名的回收不影响您以邮箱、手机号码登录StrongHire并使用StrongHire服务。</p>
<p>尽管有前述规定，子丑寅卯网络科技无义务对任何用户的任何登记资料承担任何责任，包括但不限于鉴别、核实任何登记资料的真实性、准确性、完整性、适用性及/或是否为最新资料的责任。</p>
<p>4.2更新维护</p>
<p>您应当及时更新您提供的信息，在法律有明确规定要求子丑寅卯网络科技作为平台服务提供者必须对部分用户（如平台卖家等）的信息进行核实的情况下，子丑寅卯网络科技将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。</p>
<p>如子丑寅卯网络科技按您最后一次提供的信息与您联系未果、您未按子丑寅卯网络科技的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及子丑寅卯网络科技造成的全部损失与不利后果。子丑寅卯网络科技可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部StrongHire服务，子丑寅卯网络科技对此不承担责任。</p>
<p>4.3账户安全规范</p>
<p>您的账户为您自行设置并由您保管，子丑寅卯网络科技任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开StrongHire。</p>
<p>账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，子丑寅卯网络科技并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</p>
<p>除子丑寅卯网络科技存在过错外，您应对您账户项下的所有行为结果负责。</p>
<p>如发现任何未经授权使用您账户登录StrongHire或其他可能导致您账户遭窃、遗失的情况，建议您立即更改密码、通知子丑寅卯网络科技，并授权子丑寅卯网络科技将该信息同步给其他关联平台。您理解子丑寅卯网络科技对您的任何请求采取行动均需要合理时间，且子丑寅卯网络科技应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除子丑寅卯网络科技存在法定过错外，子丑寅卯网络科技不承担责任。</p>
<p>若您提供任何违法、虚假、不道德或子丑寅卯网络科技保认为不适合在StrongHire上展示的资料；或者子丑寅卯网络科技保有理由怀疑您的资料属于病毒程序或恶意操作；或者您违反本服务条款的规定；或者未经子丑寅卯网络科技保同意，将StrongHire用于商业目的，子丑寅卯网络科技保有权暂停或终止您的帐号，并拒绝您于现在和未来使用服务之全部或任何部分。</p>
<h2>5.服务内容</h2>
<p>StrongHire为您提供3类的简历服务：</p>
<p>• 提供在线简历撰写工具。</p>
<p>• 提供简历导师辅导服务。</p>
<p>• 提供人岗匹配算法及简历通过率预测服务。</p>
<h2>6.内容使用权</h2>
<p>6.1用户在StrongHire上填写的内容（包含但不限于StrongHire目前各产品功能里的内容）仅表明其个人的立场和观点，并不代表子丑寅卯网络科技的立场或观点。作为内容的发表者，需自行对所发表的内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律责任及连带责任，子丑寅卯网络科技不承担任何法律责任及连带责任。</p>
<p>6.2用户在StrongHire发布侵犯他人知识产权或其他合法权益的内容，StrongHire有权利但无义务予以删除并保留移交司法机关处理的权利。无论是否删除或意见司法机关处理，子丑寅卯网络科技和StrongHire均不承担任何法律责任及连带责任。</p>
<p>6.3用户不得使用StrongHire服务发送或传播敏感信息和违反国家法律制度的信息，包括但不限于下列信息：</p>
<p>• 反对宪法所确定的基本原则的；</p>
<p>• 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
<p>• 损害国家荣誉和利益的；</p>
<p>• 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
<p>• 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
<p>• 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
<p>• 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
<p>• 侮辱或者诽谤他人，侵害他人合法权益的；</p>
<p>• 侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；</p>
<p>• 存在可能破坏、篡改、删除、影响StrongHire或子丑寅卯网络科技任何系统正常运行或未经授权秘密获取子丑寅卯网络科技平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；</p>
<p>• 含有法律、行政法规禁止的其他内容的。</p>
<p>用户发布任何上述内容的，StrongHire和子丑寅卯网络科技将作出独立判断立即暂停或终止用户的服务帐号并且删除相关内容。用户需对自己在网上的行为承担法律责任，届时StrongHire和子丑寅卯网络科技不承担任何法律责任及连带责任。对于用户已支付的服务费用（如有），StrongHire和子丑寅卯网络科技有权不予退还。</p>
<p>6.4用户之间的保密义务</p>
<p>用户对利用StrongHire服务从而获取的其他用户的任何信息，负有保密的义务。保密的程度依据法律法规、用户间的合意以及一般人的合理注意义务决定。</p>
<h2>7.用户信息的保护及授权</h2>
<p>7.1个人信息的保护</p>
<p>子丑寅卯网络科技非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用子丑寅卯网络科技提供的服务时，您同意子丑寅卯网络科技按照在子丑寅卯网络科技上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。因此子丑寅卯网络科技建议您完整地阅读用户隐私条款，以帮助您更好地保护您的隐私权。</p>
<p>使用StrongHire和子丑寅卯网络科技提供的服务，即表示您同意子丑寅卯网络科技合法收集和使用有关您及您所使用服务的技术性或诊断性信息。收集到的这些信息将用于改进StrongHire产品的内容和技术，提升StrongHire的服务品质。 子丑寅卯网络科技不会将您的信息和内容提供或出售给其他的组织或个人，但以下情况除外：</p>
<p>• 您的事先同意或授权，或您于StrongHire上主动与第三方进行分享操作；</p>
<p>• 司法机关或有权政府机构或任何法律法规部门规章要求子丑寅卯网络科技提供该等信息；</p>
<p>• 您违反了本服务条款，且子丑寅卯网络科技需要向第三方提供的。</p>
<p>7.2非个人信息的保护和授权</p>
<p>对于您提供、发布及在使用StrongHire或子丑寅卯网络科技服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，均不会因上传、发布等行为发生知识产权、肖像权等权利的转移。除非我们另行说明，在法律允许的范围内，您免费授予子丑寅卯网络科技非排他的、无地域限制的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发上述信息或制作派生作品，以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内等）及可再许可第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。 为方便您使用StrongHire和子丑寅卯网络科技的其他相关服务，您授权子丑寅卯网络科技将您在账户注册和使用StrongHire和子丑寅卯网络科技服务过程中提供、形成的信息传递给子丑寅卯网络科技的关联服务提供者，或从关联服务提供者获取您在使用相关服务期间形成的信息。</p>
<h2>8.版权</h2>
<p>8.1 本协议条款明确规定的，以及任何与StrongHire或者子丑寅卯网络科技服务有关的内容的知识产权，由子丑寅卯网络科技所享有。该条款所指内容包括但不限于StrongHire的外观设计、计算机代码、内容、资讯、用户信息等。该条款所指知识产权包括但不限 于专利权、著作权、商标权等。未经子丑寅卯网络科技事先书面许可，您不能复制、拷贝、或者使用任何部分的代码和外观设计。</p>
<p>8.2子丑寅卯网络科技将通过技术手段对用户数据进行去标识化处理（去标识化处理的信息将无法识别主体），在此情况下子丑寅卯网络科技有权使用已经去标识化的信息。在个人信息进行脱敏处理的前提下，对用户数据进行分析并予以利用所形成的所有智力成果均归属于子丑寅卯网络科技所有（包括但不限于专利权、著作权；例如基于平台用户去标识化信息对互联网行业及招聘领域的分析报告等）。</p>
<h2>9.数据安全</h2>
<p>子丑寅卯网络科技将尽合理的努力保护您的信息安全，并为此采取合理的数据传输、存储、转换等预防保护措施。但是，互联网数据传输、存储、转换均可能存在一定未知且不确定的数据安全风险，该等风险将导致包括但不限于数据丢失、泄露、损坏、无法读取或提取等后果。您确认，您已明确知晓并同意接受该等因互联网引发的风险和后果，并已采取恰当的措施（例如数据备份等），以便在该等风险发生时将损失降至最低。</p>
<p>如果您发现了任何有关StrongHire的潜在安全风险（如系统漏洞等）或者个人信息安全事件，您可以向StrongHire及时反馈并联系我们。</p>
<p>因互联网技术本身等非子丑寅卯网络科技主观故意或重大过失导致危害您数据安全的，子丑寅卯网络科技不承担任何赔偿责任。因子丑寅卯网络科技重大过失危害您数据安全的，子丑寅卯网络科技的赔偿责任以向您收取的服务费用为上限。</p>
<h2>10.免责声明</h2>
<p>一旦您注册成为用户即表示您与子丑寅卯网络科技达成协议，完全接受本服务条款项下的全部条款。对免责声明的解释、修改及更新权均属于子丑寅卯网络科技所有。</p>
<p>• 由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因子丑寅卯网络科技原因导致的个人信息的泄漏，子丑寅卯网络科技不承担任何法律责任；</p>
<p>• 任何第三方根据子丑寅卯网络科技各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷，子丑寅卯网络科技不承担任何法律责任以及连带责任；</p>
<p>• 任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭，子丑寅卯网络科技不承担任何法律责任；</p>
<p>• 我们鼓励用户充分利用StrongHire自由地发布和共享自己的信息。您可以自由发布文字、图片等内容，但这些内容必须位于公共领域内，或者您拥有这些内容的使用权。同时，用户不应在自己的个人主页或StrongHire的任何其他地方发布受版权保护的内容；</p>
<p>• 用户在StrongHire发布侵犯他人知识产权或其他合法权益的内容，子丑寅卯网络科技有权予以删除，并保留移交司法机关处理的权利；</p>
<p>• 用户对于自己创作并在StrongHire上发布的合法内容依法享有著作权及其他相关权利；</p>
<p>• 互联网是一个开放平台，用户将图片等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识到此类风险的存在。用户明确同意使用子丑寅卯网络科技服务所存在的风险将完全由用户自己承担；因用户使用子丑寅卯网络科技服务而产生的一切后果也由用户自己承担，子丑寅卯网络科技对用户不承担任何责任。</p>
<p>• StrongHire对用户的真实资信状况、用户发布信息的真实性、合法性、准确性等不作保证。StrongHire不对简历的内容做实质性审查，不保证简历的真实、完整、准确;StrongHire不对用户实际经营状况做实质性审查，不对用户的资信状况做出保证。因上述原因给第三方造成的损失，StrongHire不承担责任。</p>
<p>• 用户通过StrongHire获取的任何资料和信息，无论其来自于StrongHire或任何外部链接，StrongHire对其不做出任何保证。StrongHire提供与其它互联网上的网站或资源的链接，用户可能会因此跳转至其它运营商经营的网站，但不表示StrongHire与这些运营商有任何关联关系。其它运营商经营的网站均由各经营者自行负责，不属于StrongHire控制及负责范围之内。对于存在或来源于此类网站或资源的任何内容、广告、物品或其它资料，StrongHire亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、物品或服务所产生的任何损害或损失，StrongHire不负任何直接或间接的责任。涉及上述条款中的情形，而造成的用户利润、商誉等损失，StrongHire不承担任何直接、间接、附带、惩罚性的赔偿。</p>
<p>• StrongHire将不时地指定子丑寅卯网络科技的关联公司或者第三方作为StrongHire的代理、基础服务商、技术支持方等，以便为用户提供本平台相应的服务。如用户需使用第三方服务以便使用StrongHire相应功能（例如使用第三方账号登录StrongHire、使用第三方支付工具购买StrongHire付费服务等），还应相应遵守第三方服务提供商的相应规则。</p>
<h2>11.服务变更/中断/终止</h2>
<p>11.1如因系统维护或升级的需要而暂停网络服务、调整服务功能的，子丑寅卯网络科技将尽可能事先在网站上（https://www.stronghire.cn）或者微信公众号等渠道进行通告。网站服务中断期间，StrongHire无需为此而承担对用户造成的任何经济赔偿。因不可抗力事件、情况紧急或政策等原因导致StrongHire以及子丑寅卯网络科技未能实现提供通告的，暂行中止履行本合同项下的义务，StrongHire以及子丑寅卯网络科技无需为此而承担违约责任在内的法律责任。</p>
<p>11.2如发生下列任何一种情形，子丑寅卯网络科技有权单方面中断或终止向用户提供服务而无需通知用户：</p>
<p>• 用户提供的个人资料不真实；</p>
<p>• 您存在盗用他人账户、发布违禁信息、骗取他人财物或采取不正当手段谋利等行为；</p>
<p>• 用户使用本服务的行为若有任何违反国家法律法规或侵犯任何第三方合法权益的情形；</p>
<p>• 用户违反本服务条款中约定的使用规则；</p>
<p>• 未经子丑寅卯网络科技同意，将StrongHire用于商业目的。</p>
<p>11.3本协议终止后，除法律有明确规定外，子丑寅卯网络科技无义务向您或您指定的第三方披露您账户中的任何信息。</p>
<p>11.4本协议终止后，子丑寅卯网络科技仍享有下列权利：</p>
<p>（一）继续保存您留存于StrongHire上的各类信息；</p>
<p>（二）对于您过往的违约行为，子丑寅卯网络科技仍可依据本协议向您追究违约责任。</p>
<h2>12.服务条款的完善和修改</h2>
<p>子丑寅卯网络科技有权根据互联网的发展和中华人民共和国有关法律、法规的变化，不时地完善和修改子丑寅卯网络科技服务条款。子丑寅卯网络科技保留随时修改服务条款的权利，用户在使用子丑寅卯网络科技的服务时，有必要对最新的子丑寅卯网络科技服务条款进行仔细阅读和重新确认，当发生相关争议时，以最新的服务条款为准。</p>
<h2>13.特别约定</h2>
<p>本服务条款及其下的服务受中华人民共和国法律管辖，并按之解释。 若用户利用本服务从事任何违法或侵权行为，由用户自行承担全部责任，子丑寅卯网络科技不承担任何法律责任及连带责任。因此给子丑寅卯网络科技或任何第三方造成任何损失的，用户应负责全额赔偿。</p>
<p>您因使用StrongHire或子丑寅卯网络科技符合所产生的有关争议，由子丑寅卯网络科技与您协商解决。协商不成时，任何一方均可向子丑寅卯网络科技住所地的人民法院提起诉讼。</p>
<h2>14.可分性</h2>
<p>本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
<h2>15.联系我们</h2>
<p>欢迎您对我们的服务条款提出意见。如有任何问题、意见或疑虑，请发邮件至service@stronghire.cn 此电子邮件地址作为本服务条款的组成部分可能会不时进行更新。</p>
<p>©南京子丑寅卯网络科技有限公司保留一切权利。</p>

  </div>
</template>

<script setup>
import htmlToPdf from "@/utils/htmlToPdf";
import { nextTick, ref } from "@vue/runtime-core";

const downloadVisible = ref(1);

const onDownLoadClick = () => {
  downloadVisible.value = 0;
  nextTick(async () => {
    htmlToPdf("content", "用户协议");
    setTimeout(() => {
      downloadVisible.value = 1;
    }, 2);
  });
};
</script>

<style lang="scss" scoped>
@import "src/style/agreement.scss";
</style>