<template>
  <div class="resume_detail_seo">
    <div v-for="(parentItem, parentIndex) in resumeData" :key="parentIndex">
      <p v-for="(item, index) in parentItem" :key="index">
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

const props = defineProps({
  data: Object,
});

const resumeData = ref([]);

onMounted(() => {
  console.log("resume detail seo", props.data.resumeData);
  try {
    props.data.resumeData.contentModules.forEach((item) => {
      const contentArray = [];
      if (item.module === "basic_info") {
        contentArray.push(...Object.values(item.basicInfo));
      } else if (item.module === "edu_experience") {
        if (item.eduExperience && item.eduExperience.length > 0) {
          item.eduExperience.forEach((eduItem) => {
            contentArray.push(...Object.values(eduItem));
          });
        }
      } else if (item.module === "project_experience") {
        if (item.projectExperience && item.projectExperience.length > 0) {
          item.projectExperience.forEach((projectItem) => {
            contentArray.push(...Object.values(projectItem));
          });
        }
      } else if (item.module === "work_experience") {
        if (item.workExperience && item.workExperience.length > 0) {
          item.workExperience.forEach((workItem) => {
            contentArray.push(...Object.values(workItem));
          });
        }
      } else if (item.module === "school_experience") {
        if (item.contentModules && item.contentModules.length > 0) {
          item.contentModules.forEach((schoolItem) => {
            contentArray.push(...Object.values(schoolItem));
          });
        }
      } else if (item.module === "personal_summary") {
        if (item.personalSummary) {
          contentArray.push(...Object.values(item.personalSummary));
        }
      } else if (item.module === "evaluation") {
        if (item.evaluation) {
          contentArray.push(...Object.values(item.evaluation));
        }
      } else if (item.module === "awards") {
        if (item.awards && item.awards.length > 0) {
          item.awards.forEach((awardItem) => {
            contentArray.push(...Object.values(awardItem));
          });
        }
      } else if (item.module === "portfolios") {
        if (item.portfolios && item.portfolios.length > 0) {
          item.portfolios.forEach((portfolioItem) => {
            contentArray.push(...Object.values(portfolioItem));
          });
        }
      } else if (item.module === "custom_experience") {
        if (item.customExperience && item.customExperience.length > 0) {
          item.customExperience.forEach((customItem) => {
            contentArray.push(...Object.values(customItem));
          });
        }
      }

      resumeData.value.push(contentArray);
    });
  } catch (e) {
    console.warn(e);
  }
  console.log("resumeData", resumeData.value);
});
</script>

<style lang="scss" scoped>
.resume_detail_seo {
  overflow: scroll;
}
</style>