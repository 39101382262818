import { useStore } from "vuex";
import { computed } from 'vue'
import useEventBus from "@/plugin/event"
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import editApi from "@/apis/edit_api";
import utils from "@/utils/utils.js";
import vipMix from "@/plugin/vip";


export default function () {
    const store = useStore()
    const eventBus = useEventBus()
    const route = useRoute()
    const router = useRouter()
    const { onVipToast } = vipMix();

    const date_style = computed(() => store.state.edit.formatInfo.dateStyle);

    const refreshSaveStates = (isFinish, isSuccess = true) => {
        if (isFinish) {
            setTimeout(() => {
                if (isSuccess) {
                    const desc = utils.formatDateTime1(new Date().getTime());
                    store.commit("setEditSaveDesc", `已于${desc}自动保存`)
                } else {
                    store.commit("setEditSaveDesc", "保存失败")
                }
            }, 500);
        } else {
            store.commit("setEditSaveDesc", "自动保存中...")
        }
    }

    const getOrderingSubModuleIds = (module, moduleId) => {
        // 获取正在排序的子模块 id
        if (module === 'work_experience') {
            return store.state.edit.contentModules.workExperience.map(item => item.id);
        } else if (module === 'edu_experience') {
            return store.state.edit.contentModules.eduExperience.map(item => item.id);
        } else if (module === 'project_experience') {
            return store.state.edit.contentModules.projectExperience.map(item => item.id);
        } else if (module === 'school_experience') {
            return store.state.edit.contentModules.schoolExperience.map(item => item.id);
        } else if (module === 'awards') {
            return store.state.edit.contentModules.awards.map(item => item.id);
        } else if (module === 'portfolios') {
            return store.state.edit.contentModules.portfolios.map(item => item.id);
        } else if (module === 'custom_experience') {
            return store.state.edit.contentModules.customExperience
                .filter(item => item.moduleId === moduleId)
                .map(item => item.id);
        }
    }

    const startModify = async (name, editData, moduleId, isEmptyClick, isAddChild = false) => {
        // ? 进入编辑模式 1. 新建子模块 2. 点击空白处 3. 点击已有子模块
        console.log('startModify', 'editData:', editData, 'moduleId', moduleId,
            'editSubId', store.state.edit.editSubModuleId, "isEmptyClick", isEmptyClick, "isAddChild", isAddChild);
        if (!isAddChild && editData && editData.id && editData.id === store.state.edit.editSubModuleId && store.state.edit.isInEditMode) {
            // 如果是点击时自己的模块，那么不用做任何的处理，并且处于编辑模式
            console.log('点击自己的模块，不做任何处理');
            return;
        }
        // ? 需要先判断内容是否修改，内容是否为空
        const backResult = await onBackDeal();
        if (!backResult) {
            return;
        }
        // ! 需要提前赋值，如果在 addChildModule 后赋值，将覆盖之前的值，导致还是会弹出数据未填弹窗
        var subModuleId = '';
        if (editData) {
            subModuleId = editData.id;
        }
        store.commit('updateEditModuleId', { moduleId: moduleId, subModuleId: subModuleId });

        if (isAddChild) {
            store.dispatch('addChildModule', {
                moduleId: moduleId,
                module: name,
            })
        } else if (isEmptyClick) {
            if (moduleId) {
                store.dispatch("onEmptyClick", {
                    module: name,
                    moduleId: moduleId,
                });
            }
        } else {
            if (editData) {
                subModuleId = editData.id;
                store.commit("updateCurrentEdityData", editData);
            }
        }

        eventBus.emit("showModify", { value: true });
        store.commit("updateModifyModule", { modifyModule: name });
    };

    const onSaveClick = async (isSave = true, hideModify = false) => {
        console.log("保存");
        if (isSave) {
            await saveResumeContentData();
        }
        if (hideModify) {
            // ? 关闭编辑模式
            eventBus.emit("showModify", { value: false });
        }
        return true;
        // * 注释掉必选项判断
        // if (store.getters.dataCanSave) {
        //     if (store.state.edit.modifyModule === "personal_summary") {
        //         // ? 个人总结是判断数据是否为空，其他都是判断必填项
        //         ElMessage.error("请至少填写一项数据");
        //     } else {
        //         ElMessage.error("有必填项未填写");
        //     }
        //     return false;
        // } else {

        // }
    }

    const saveResumeContentData = async () => {
        // ? 保存简历内容数据
        // ? 1. 保存到本地-未登录，2. 保存到服务器-已登录-edit 3. 同步到服务器-已登录-preview
        console.log("保存简历内容数据");
        const hasLogin = store.getters.hasLogin;
        const routeName = route.name;
        const module = store.state.edit.modifyModule;
        refreshSaveStates(false);
        if (!hasLogin) {
            console.log("保存到本地");
            await checkSubModuleContentEmpty()
            await store.dispatch("saveLocalData")
            refreshSaveStates(true);
        } else if (routeName === "edit") {
            const editObject = store.state.edit.edit_object;
            const editContentEmpty = await checkSubModuleContentEmpty()
            console.log("保存到服务器 editContentEmpty: ", editContentEmpty);
            if (editContentEmpty) {
                if (editObject.id.length >= 15) {
                    // 删除当前子模块
                    deleteModule({
                        module: module,
                        moduleId: store.state.edit.editModuleId,
                        subId: editObject.id,
                    }, false)
                }
                refreshSaveStates(true);
                return
            }
            if (editObject && editObject.id.length < 15) {
                // ? 1. 点击添加子模块； 2. 点击空白处；
                await addChildModuleServer();
            } else {
                const result = await updateContentData();
                refreshSaveStates(true, result);
            }
        } else if (routeName === "preview") {
            console.log("同步到服务器");
            await addResumeData();
        }
    }

    const checkModuleEmpty = (data, module) => {
        // ? 数据-检查父模块的是否有子模块的数据
        const result = !data || data.length === 0 || (data.length === 1 && checkContentEmpty(data[0], module))
        // console.log("checkModuleEmpty", module, result);
        return result;
    }

    const checkAddSubModuleMarginTop = (index, data, module) => {
        // ? 检查是否需要添加子模块和子模块的间距
        const result = (index !== 0 && data.length > 1 && index !== data.length - 1) || (index === data.length - 1 && !checkContentEmpty(data[data.length - 1], module))
        // console.log("checkAddSubModuleMarginTop", module, result, index);
        return result;
    }

    const checkContentEmpty = (data, module) => {
        // ? 内容-检查工作经历，教育经历，项目经理，校园经历，荣誉，自定义经历数据是否为空
        var result = false
        if (data && module) {
            if (module === 'work_experience') {
                result = !data.city
                    && !data.company
                    && !data.department
                    && !data.desc
                    && !data.position
                    && !data.start
                    && !data.end;
            } else if (module === 'edu_experience') {
                result = !data.city
                    && !data.college
                    && !data.desc
                    && !data.education
                    && !data.educationType
                    && !data.start
                    && !data.end
                    && !data.major
                    && !data.school
                    && !data.showLables;
            } else if (module === "project_experience") {
                result = !data.project
                    && !data.role
                    && !data.department
                    && !data.city
                    && !data.start
                    && !data.end
                    && !data.desc;
            } else if (module === "school_experience") {
                result = !data.title
                    && !data.role
                    && !data.department
                    && !data.city
                    && !data.start
                    && !data.end
                    && !data.desc;
            } else if (module === "awards") {
                result = !data.title
                    && !data.time;
            } else if (module === "portfolios") {
                result = !data.title
                    && !data.qrcodeUrl
                    && !data.url;
            } else if (module === "custom_experience") {
                result = !data.title
                    && !data.role
                    && !data.department
                    && !data.city
                    && !data.start
                    && !data.end
                    && !data.desc;
            }
        }
        // console.log("checkContentEmpty result: ", result, module);
        return result;
    }

    const checkSubModuleContentEmpty = async () => {
        // 检查正在编辑的子模块，内容是否为空，如果为空，则直接删除
        const editObject = store.state.edit.edit_object;
        const module = store.state.edit.modifyModule;
        console.log("start check submodule content empty!!", module);
        if (editObject && module) {
            if (checkContentEmpty(editObject, module)) {
                await store.dispatch("deleteModule", { module: module, subId: editObject.id });
                return true;
            }
        }
        return false
    }

    const updateContentData = async () => {
        // ? 保存简历数据到服务器
        try {
            const editObject = store.state.edit.edit_object;
            const module = store.state.edit.modifyModule;
            if (!editObject || !module) {
                return false;
            }
            await store.dispatch("checkTitle")
            const params = {
                id: route.params.id,
                formatInfo: store.state.edit.formatInfo,
                style: store.state.edit.style,
                title: store.state.edit.title,
            };
            if (module === 'basic_info') {
                params.basicInfo = editObject;
            } else if (module === 'work_experience') {
                params.workExperience = editObject;
            } else if (module === 'edu_experience') {
                params.eduExperience = editObject;
            } else if (module === 'project_experience') {
                params.projectExperience = editObject;
            } else if (module === 'school_experience') {
                params.schoolExperience = editObject;
            } else if (module === 'personal_summary') {
                params.personalSummary = editObject;
            } else if (module === 'evaluation') {
                params.evaluation = editObject;
            } else if (module === 'awards') {
                params.award = editObject;
            } else if (module === 'portfolios') {
                params.portfolio = editObject;
            } else if (module === 'custom_experience') {
                params.customExperience = editObject;
            }
            const result = await editApi.editResume(params, route.query.type === "template_resume");
            if (result.code === 0) {
                return true;
            } else {
                return false;
            }
        } catch ({ msg: msg }) {
            // ElMessage.error(msg);
            return false;
        }
    }


    const onBackDeal = async (isSave = true, hideModify = false) => {
        // ? 返回的时候，不仅要判断内容是否修改，还要判断内容是否为空
        // ? isSave: 是否进行保存； 在返回到首页时，如果是 preview 状态，需要调用添加简历接口
        console.log('onBackDeal', store.state.edit.contentHasChanged, "isSave: ", isSave);
        if (!store.state.edit.contentHasChanged) {
            // 数据没有改变
            await checkSubModuleContentEmpty()
            return true;
        } else {
            // 数据发生了改变
            return await onSaveClick(isSave);
            // var action = "";
            // try {
            //     action = await ElMessageBox({
            //         title: "是否保存更改项",
            //         message: "刚刚修改的内容未保存，请保存后离开",
            //         showCancelButton: true,
            //         confirmButtonText: "保存",
            //         cancelButtonText: "不保存",
            //         distinguishCancelAndClose: true,
            //     })
            // } catch (e) {
            //     action = e;
            // }
            // console.log("xxxxx", action);
            // if (action === "confirm") {
            //     // ? 保存数据
            //     return await onSaveClick(isSave);
            // } else if (action === "cancel") {
            //     // ? 取消保存
            //     console.log("取消保存", store.state.edit.edit_object, store.state.edit.edit_last_object,);
            //     Object.assign(store.state.edit.edit_object, store.state.edit.edit_last_object)
            //     return true;
            // }
            // return false;
        }
    }

    const deleteModule = async (data, showToast = true) => {
        console.log("删除模块", route.name, route.params.id);
        await store.dispatch("deleteModule", data);
        if (route.name === "edit") {
            try {
                const result = await editApi.deleteModule({
                    id: route.params.id,
                    moduleId: data.moduleId,
                    subModuleId: data.subId,
                }, route.query.type === "template_resume");
                if (result.code === 0) {
                    if (showToast) {
                        ElMessage.success("删除成功");
                    }
                } else {
                    if (showToast) {
                        ElMessage.error("删除失败");
                    }
                }
            } catch ({ msg: msg }) {
                if (showToast) {
                    ElMessage.error(msg);
                }
            }
        } else {
            if (showToast) {
                ElMessage.success("删除成功");
            }
            store.dispatch("saveLocalData")
        }
        refreshSaveStates(true);
    }

    const addParentModule = async (data) => {
        console.log("添加父模块", route.name);
        refreshSaveStates(false)
        if (route.name === "edit") {
            try {
                const result = await editApi.addModule({
                    id: route.params.id,
                    module: data.module,
                }, route.query.type === "template_resume");
                if (result.code === 0 && result.data.moduleId) {
                    data.id = result.data.moduleId;
                    await store.dispatch("addParentModule", data);
                    ElMessage.success("添加成功");
                    refreshSaveStates(true)
                } else {
                    ElMessage.error("添加失败");
                    refreshSaveStates(true, false)
                }
            } catch ({ msg: msg }) {
                refreshSaveStates(true, false)
                ElMessage.error(msg);
            }
        } else {
            await store.dispatch("addParentModule", data);
            refreshSaveStates(true)
            ElMessage.success("添加成功");
            store.dispatch("saveLocalData")
        }
    }

    const addChildModuleServer = async () => {
        console.log("添加子模块", route.name);
        try {
            const editObject = store.state.edit.edit_object;
            const editModuleId = store.state.edit.editModuleId;
            const module = store.state.edit.modifyModule;
            if (!editObject || !editModuleId && !module) {
                return;
            }
            const params = {
                id: route.params.id,
                moduleId: editModuleId,
                module: module,
            }
            if (module === 'work_experience') {
                params.contentWork = editObject;
            } else if (module === 'edu_experience') {
                params.contentEdu = editObject;
            } else if (module === 'project_experience') {
                params.contentProject = editObject;
            } else if (module === 'school_experience') {
                params.contentSchool = editObject;
            } else if (module === 'awards') {
                params.contentAward = editObject;
            } else if (module === 'portfolios') {
                params.contentPortfolio = editObject;
            } else if (module === 'custom_experience') {
                params.contentCustom = editObject;
            } else if (module === 'personal_summary') {
                params.contentSummary = editObject;
            } else if (module === 'evaluation') {
                params.contentEvaluation = editObject;
            }
            const result = await editApi.addModule(params, route.query.type === "template_resume");
            if (result.code === 0 && result.data.moduleId) {
                if (result.data.subModuleId) {
                    editObject.id = result.data.subModuleId;
                }
                if (module === 'custom_experience') {
                    editObject.moduleId = result.data.moduleId;
                }
                refreshSaveStates(true);
            } else {
                ElMessage.error("保存失败");
            }
        } catch ({ msg: msg }) {
            ElMessage.error(msg);
        }
    }

    const changeOrder = async (data) => {
        console.log("改变模块顺序", route.name, data.module);
        await store.dispatch("changeOrder", data);
        if (route.name === "edit") {
            try {
                var result = null;
                if (data.subId) {
                    const subModuleIds = getOrderingSubModuleIds(data.module, data.moduleId)
                        .filter(item => item.length >= 15);
                    console.log("改变模块顺序 child subModuleIds:", subModuleIds);
                    result = await editApi.editSubModuleSequence({
                        id: route.params.id,
                        moduleId: data.moduleId,
                        subModuleIds: subModuleIds,
                    }, route.query.type === "template_resume");
                } else {
                    const modulesIds = store.state.edit.modules.map(item => item.id);
                    console.log("改变模块顺序 parent modulesIds:", modulesIds);
                    result = await editApi.editModuleSequence({
                        id: route.params.id,
                        moduleIds: modulesIds,
                    }, route.query.type === "template_resume");
                }

                if (result.code !== 0) {
                    ElMessage.error("修改失败");
                }
            } catch ({ msg: msg }) {
                ElMessage.error(msg);
            }
        } else {
            store.dispatch("saveLocalData")
        }
    }

    const updateFormatInfo = async (changeTitle = false) => {
        console.log("更新模块格式", route.name);
        refreshSaveStates(false);
        const hasLogin = store.getters.hasLogin;
        if (!hasLogin) {
            console.log("未登录");
            await store.dispatch("saveLocalData")
        } else if (route.name === "edit") {
            try {
                await store.dispatch("checkTitle")
                const result = await editApi.editResume({
                    id: route.params.id,
                    formatInfo: store.state.edit.formatInfo,
                    style: store.state.edit.style,
                    title: store.state.edit.title,
                }, route.query.type === "template_resume");
                if (result.code !== 0) {
                    ElMessage.error("修改失败");
                } else if (changeTitle) {
                    ElMessage.success("修改成功");
                }
                refreshSaveStates(true);
            } catch ({ msg: msg }) {
                ElMessage.error(msg);
            }
        } else if (route.name === "preview") {
            console.log("同步到服务器");
            await addResumeData();
        }
    }

    const addResumeData = async () => {
        // ? 添加简历数据
        console.log("添加简历数据");
        store.commit("setEditFullLoading", {
            editFullLoading: false,
            editFullLoadingText: "",
        })
        eventBus.emit("showModify", { value: false });
        try {
            const result = await store.dispatch("addResumeData", route.query.type === "template_resume");
            if (result.code === 0) {
                console.log("添加成功", result);
                router.replace({ path: `/edit/${result.data}`, query: route.query, params: route.params });
                refreshSaveStates(true);
                return result.data;
            } else {
                if (result.code === 4 || result.code === 5) {
                    onVipToast(result.msg);
                    router.back();
                    return "";
                } else {
                    ElMessage.error(result.msg);
                    refreshSaveStates(true, false);
                    return "";
                }
            }
        } catch ({ msg: msg }) {
            ElMessage.error(msg);
            refreshSaveStates(true, false);
        }
        store.commit("setEditFullLoading", {
            editFullLoading: false,
            editFullLoadingText: "",
        })
        return "";
    }

    const getModuleTitleValue = (moduleName, moduleId) => {
        var module = { title: "" };
        store.state.edit.modules.forEach((item) => {
            if (item.id === moduleId) {
                module = item;
            }
        });
        if (module && module.title) {
            return module.title;
        }
        return getTitle(moduleName);
    }

    const getTitle = (module) => {
        switch (module) {
            case "basic_info":
                return "基本信息";
            case "work_experience":
                return "工作经历";
            case "edu_experience":
                return "教育经历";
            case "project_experience":
                return "项目经历";
            case "school_experience":
                return "校园经历";
            case "awards":
                return "荣誉奖项";
            case "portfolios":
                return "作品集";
            case "custom_experience":
                return "自定义";
            case "personal_summary":
                return "个人总结";
            case "evaluation":
                return "自我评价";
            default:
                return "";
        }
    }

    const updateModuleTitle = async (title) => {
        // ? 更新 Module 的 title
        refreshSaveStates(false);
        const hasLogin = store.getters.hasLogin;
        if (!hasLogin) {
            console.log("未登录");
            store.commit("updateModuleTitle", {
                moduleId: store.state.edit.editModuleId,
                title: title,
            });
            await store.dispatch("saveLocalData")
        } else if (route.name === "edit") {
            try {
                const result = await editApi.editModuleTitle({
                    id: route.params.id,
                    moduleId: store.state.edit.editModuleId,
                    title: title,
                }, route.query.type === "template_resume");
                if (result && result.code === 0) {
                    ElMessage.success("修改成功");
                    store.commit("updateModuleTitle", {
                        moduleId: store.state.edit.editModuleId,
                        title: title,
                    });
                    refreshSaveStates(true);
                } else {
                    ElMessage.error("修改失败");
                    refreshSaveStates(false);
                }
            } catch ({ msg: msg }) {
                ElMessage.error(msg);
            }
        } else if (route.name === "preview") {
            console.log("同步到服务器");
            store.commit("updateModuleTitle", {
                moduleId: store.state.edit.editModuleId,
                title: title,
            });
            await addResumeData();
        }
    }

    const editTemplateInfo = async () => {
        console.log("更新模板信息", route.query.type)
        if (store.state.edit.editTemplateInfo.title) {
            store.commit("updateResumeTitle", store.state.edit.editTemplateInfo.title);
        }
        if (route.query.type === "template_resume") {
            refreshSaveStates(false)
            if (route.name === "preview") {
                console.log("同步到服务器");
                const id = await addResumeData();
                if (id) {
                    store.commit("updateEditTemplateInfo", { id: id })
                    try {
                        const result = await store.dispatch("editTemplateInfo");
                        console.log("editTemplateInfo result: ", result);
                    } catch (e) {
                        console.log("editTemplateInfo error", e);
                    }
                }
            } else if (route.name === "edit") {
                try {
                    const result = await store.dispatch("editTemplateInfo");
                    console.log("editTemplateInfo result: ", result);
                } catch (e) {
                    console.log("editTemplateInfo error", e);
                }
            }
            refreshSaveStates(true, true)
        }
    }

    const sysncLocalData = async (needJump) => {
        console.log("同步本地数据 needJump: ", needJump);
        await store.dispatch("restoreData")
        try {
            const data = await store.dispatch("addResumeData", route.query.type === "template_resume");
            if (data.code === 0) {
                console.log("addResumeData", data);
                // 同步成功后，跳转到简历编辑页面，并重置 store data 数据
                store.commit("resetResumeData");
                if (needJump) {
                    router.push({ path: `/edit/${data.data}` });
                }
                store.dispatch("deleteLocalData");
            } else {
                if (data.code === 4 || data.code === 5) {
                    onVipToast(data.msg);
                    router.back();
                } else {
                    ElMessage.error(data.msg);
                }
            }
        } catch (e) {
            console.log("sysncLocalData error", e);
            // ElMessage.error("同步失败");
        }
    }

    const formatTime = (time) => {
        if (time) {
            if (date_style.value === 2) {
                return time.replace("-", ".");
            } else {
                return time.replace("-", "年") + "月";
            }
        } else {
            return "";
        }
    };

    const getTime = (item) => {
        const startTime = formatTime(item.start)
        var endTime = formatTime(item.end)
        endTime = item.stillActive ? "至今" : endTime;
        if (startTime && endTime) {
            return `${startTime}-${endTime}`;
        } else if (startTime) {
            return startTime;
        } else if (endTime) {
            return endTime;
        } else {
            return "";
        }
    };
    return {
        startModify,
        getTime,
        onSaveClick,
        onBackDeal,
        addResumeData,
        deleteModule,
        addParentModule,
        changeOrder,
        updateFormatInfo,
        updateContentData,
        refreshSaveStates,
        checkModuleEmpty,
        checkAddSubModuleMarginTop,
        getTitle,
        getModuleTitleValue,
        updateModuleTitle,
        editTemplateInfo,
        formatTime,
        sysncLocalData,
    }
}