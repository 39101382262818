<template>
  <div class="operate-style">
    <div class="style-typography">
      <div :class="{ select: true }" @click="onOnePageClick(true)">
        智能一页简历
      </div>
      <div :class="{ select: false }" @click="onOnePageClick(false)">
        恢复初始排版
      </div>
    </div>

    <div class="style-theme-color">
      <span class="style-title">主题色</span>
      <div>
        <div
          v-for="(item, index) in defaultColor"
          :key="index"
          @click="onThemeColorChange(item)"
        >
          <el-color-picker
            v-if="index === defaultColor.length - 1"
            v-model="theme_color"
            @change="onThemeColorChange"
          />
          <div
            class="static-color"
            v-else
            :style="{ 'background-color': item }"
          ></div>
          <img
            src="@/assets/img/icon_operate_style_color_select.svg"
            alt=""
            style="width: 13px; height: 13px"
            v-show="theme_color === item"
          />
        </div>
      </div>
    </div>

    <div class="style-font-type">
      <span class="style-title">字体</span>
      <el-select
        v-model="font"
        placeholder="微软雅黑"
        @change="onFontChange"
        style="font-family: var(--font-family); width: 194px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <span
            :style="{
              'font-family': item.fontFamily,
            }"
            >{{ item.label }}</span
          >
        </el-option>
      </el-select>
    </div>

    <div class="style-slider">
      <span class="style-title">字号</span>
      <el-slider
        v-model="text_size"
        :min="10"
        :max="18"
        :step="1"
        @change="onTextSizeChange"
      ></el-slider>
    </div>
    <div class="style-slider">
      <span class="style-title">行间距</span>
      <el-slider
        v-model="row_space"
        :min="12"
        :max="28"
        :step="1"
        @change="onRowSizeChange"
      ></el-slider>
    </div>
    <div class="style-slider">
      <span class="style-title">模块间距</span>
      <el-slider
        v-model="module_space"
        :min="10"
        :max="64"
        :step="2"
        @change="onModuleSizeChange"
      ></el-slider>
    </div>
    <div class="style-slider">
      <span class="style-title">页边距</span>
      <el-slider
        v-model="page_margin"
        :min="5"
        :max="25"
        :step="5"
        @change="onPageMarginChange"
      ></el-slider>
    </div>

    <div class="title-style title-date-style">
      <span class="style-title">标题样式</span>
      <div
        :class="{ select: title_style === 1 }"
        @click="onTitleStyleChange(1)"
      >
        单行
      </div>
      <div
        :class="{ select: title_style === 2 }"
        @click="onTitleStyleChange(2)"
      >
        双行
      </div>
    </div>

    <div class="date-style title-date-style">
      <span class="style-title">日期格式</span>
      <div :class="{ select: date_style === 1 }" @click="onDateStyleChange(1)">
        2021年1月
      </div>
      <div :class="{ select: date_style === 2 }" @click="onDateStyleChange(2)">
        2021.01
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import editMix from "@/plugin/edit";
import { ElMessage } from "element-plus";
import analyze from "@/plugin/analyze.js";

const { updateFormatInfo } = editMix();

const defaultColor = [
  "#000000",
  "#CA3832",
  "#EE8732",
  "#4183FF",
  "#6DB557",
  "#00000000",
];

const options = [
  {
    value: "1",
    label: "微软雅黑",
    fontFamily: "PingFang SC",
  },
  {
    value: "2",
    label: "黑体",
    fontFamily: "STHeiti",
  },
  {
    value: "3",
    label: "宋体",
    fontFamily: "STSong",
  },
  {
    value: "4",
    label: "楷体",
    fontFamily: "STKaiti",
  },
  {
    value: "5",
    label: "仿宋",
    fontFamily: "STFangsong",
  },
];

const customColor = ref("");

const state = reactive({
  onlyOnePage: false,
  selectColor: "",
  preData: {
    rowSpace: 0, // 智能一页之前的行间距
    moduleSpace: 0, // 智能一页之前的模块间距
  },
});

const store = useStore();

const text_size = computed({
  get: () => store.state.edit.formatInfo.textSize,
  set: (val) => {
    store.state.edit.formatInfo.textSize = val;
  },
});
const onTextSizeChange = (val) => {
  store.commit("updateFormatInfo", { textSize: val });
  onDataChange();
};

const row_space = computed({
  get: () => store.state.edit.formatInfo.rowSpace,
  set: (val) => {
    store.state.edit.formatInfo.rowSpace = val;
  },
});
const onRowSizeChange = (val) => {
  store.commit("updateFormatInfo", { rowSpace: val });
  onDataChange();
};

const module_space = computed({
  get: () => store.state.edit.formatInfo.moduleSpace,
  set: (val) => {
    store.state.edit.formatInfo.moduleSpace = val;
  },
});
const onModuleSizeChange = (val) => {
  store.commit("updateFormatInfo", { moduleSpace: val });
  onDataChange();
};

const page_margin = computed({
  get: () => store.state.edit.formatInfo.pageMargin,
  set: (val) => {
    store.state.edit.formatInfo.pageMargin = val;
  },
});
const onPageMarginChange = (val) => {
  store.commit("updateFormatInfo", { pageMargin: val });
  onDataChange();
};

const title_style = computed({
  get: () => store.state.edit.formatInfo.titleStyle,
  set: (val) => {
    store.state.edit.formatInfo.titleStyle = val;
  },
});
const onTitleStyleChange = (val) => {
  store.commit("updateFormatInfo", { titleStyle: val });
  onDataChange();
};

const date_style = computed({
  get: () => store.state.edit.formatInfo.dateStyle,
  set: (val) => {
    store.state.edit.formatInfo.dateStyle = val;
  },
});
const onDateStyleChange = (val) => {
  store.commit("updateFormatInfo", { dateStyle: val });
  onDataChange();
};

const font = computed({
  get: () => {
    if (
      !store.state.edit.formatInfo.font ||
      store.state.edit.formatInfo.font.toString() === "0"
    ) {
      return "1";
    } else {
      return store.state.edit.formatInfo.font.toString();
    }
  },
  set: (val) => {
    store.state.edit.formatInfo.font = parseInt(val);
  },
});
const onFontChange = (val) => {
  store.commit("updateFormatInfo", { font: parseInt(val) });
  onDataChange();
};

const theme_color = computed({
  get: () => store.state.edit.formatInfo.themeColor,
  set: (val) => {
    store.state.edit.formatInfo.themeColor = val;
  },
});
const onThemeColorChange = (val) => {
  console.log("onThemeColorChange", val);
  if (val !== "#00000000") {
    store.commit("updateFormatInfo", { themeColor: val });
    onDataChange();
  }
};

const adjustOnePage = async () => {
  // 智能一页
  let rowSpace = store.state.edit.formatInfo.rowSpace;
  let moduleSpace = store.state.edit.formatInfo.moduleSpace;
  let currentPreviewHeight = store.state.edit.previewContentHeight;
  let pageMargin = store.state.edit.formatInfo.pageMargin * 4 + 10;
  let onePageHeight = 1122 - pageMargin * 2; // 一页的高度
  let moduleStep = store.state.edit.modules.length; // 模块的步长，根据模块的数量来定，每2个触发一次
  let rowStep = Math.round(currentPreviewHeight / 1122 * 24); // 行 step 和整个高度有关
  console.log("preData", state.preData);

  var leftAdjustHeight = 0;
  var rowValue = 0; // 需要改变的行间距
  var moduleValue = 0; // 需要改变的模块间距
  const maxRowValue = 12; //
  const maxModuleValue = 10;


  let maxAdjustHeight =
    (rowSpace - maxRowValue) * rowStep + (moduleSpace - maxModuleValue) * Math.round(moduleStep / 2);
  console.log(
    "adjustOnePage",
    `currentPreviewHeight`,currentPreviewHeight,
    `onePageHeight:`, onePageHeight, 
    `maxAdjustHeight:`, maxAdjustHeight,
    `moduleStep:`, moduleStep,
    `rowStep:`, rowStep,
  );

  if (currentPreviewHeight > onePageHeight) {
    // * 当前超过一页数据，尝试调整成一页
    if (currentPreviewHeight - onePageHeight >= maxAdjustHeight - 10) {
      // * 超过最大调整高度，提示无法调整 内容过多，无法压缩至一页简历
      ElMessage.error("内容过多，无法压缩至一页简历");
      return;
    }
    leftAdjustHeight = currentPreviewHeight - onePageHeight;
    console.log("减少 before: ", leftAdjustHeight);
    while (leftAdjustHeight > -10) {
      if (moduleSpace - moduleValue > 10) {
        moduleValue += 2;
        leftAdjustHeight -= moduleStep;
      } else if (rowSpace - rowValue > 10) {
        rowValue += 1;
        leftAdjustHeight -= rowStep;
      } else {
        break;
      }
    }
    console.log("减少 after: ", leftAdjustHeight, store.state.edit.previewContentHeight);
    console.log(
      "最终调整的数据-减少",
      rowValue,
      rowSpace - rowValue,
      moduleValue,
      moduleSpace - moduleValue
    );
    if (
      state.preData.rowSpace === rowSpace - rowValue &&
      state.preData.moduleSpace === moduleSpace - moduleValue
    ) {
      // 已经调整过了
      ElMessage.success("已智能调整为一页简历");
      return;
    }
    // ? 已智能调整为一页简历
    // 保存压缩一页数据之前的数据
    state.preData = {
      rowSpace: store.state.edit.formatInfo.rowSpace,
      moduleSpace: store.state.edit.formatInfo.moduleSpace,
    };
    // 刷新本地的数据
    store.commit("updateFormatInfo", {
      rowSpace: rowSpace - rowValue,
      moduleSpace: moduleSpace - moduleValue,
    });
    // 更新网络数据
    await updateFormatInfo();
    ElMessage.success("已智能调整为一页简历");
  } else {
    // 当前没有超过一页数据
    leftAdjustHeight = onePageHeight - currentPreviewHeight;
    console.log("增大 before: ", leftAdjustHeight, store.state.edit.previewContentHeight);
    while (leftAdjustHeight > 14) {
      if (rowSpace - rowValue < 28) {
        rowValue += 1;
        leftAdjustHeight -= rowStep;
      } else if (moduleSpace + moduleValue < 64) {
        moduleValue += 2;
        leftAdjustHeight -= moduleStep;
      } else {
        break;
      }
    }
    console.log("增大 after: ", leftAdjustHeight, store.state.edit.previewContentHeight);
    console.log(
      "最终调整的数据-增大",
      rowValue,
      rowSpace + rowValue,
      moduleValue,
      moduleSpace + moduleValue
    );
    if (
      state.preData.rowSpace === rowSpace + rowValue &&
      state.preData.moduleSpace === moduleSpace + moduleValue
    ) {
      // 已经调整过了
      ElMessage.success("已智能调整为一页简历");
      return;
    }
    // ? 已智能调整为一页简历
    // 保存压缩一页数据之前的数据
    state.preData = {
      rowSpace: store.state.edit.formatInfo.rowSpace,
      moduleSpace: store.state.edit.formatInfo.moduleSpace,
    };
    // 刷新本地的数据
    store.commit("updateFormatInfo", {
      rowSpace: rowSpace + rowValue,
      moduleSpace: moduleSpace + moduleValue,
    });
    // 更新网络数据
    await updateFormatInfo();
    ElMessage.success("已智能调整为一页简历");
  }
};

const onOnePageClick = async (onePage) => {
  state.onlyOnePage = onePage;
  if (onePage) {
    analyze.sendClickEvent("edit_one_page_click");
    await adjustOnePage();
  } else {
    // 恢复到默认的数据 已恢复至初始排版
    analyze.sendClickEvent("edit_resume_click");
    let rowSpace = store.state.edit.formatInfo.rowSpace;
    let moduleSpace = store.state.edit.formatInfo.moduleSpace;
    if (
      (state.preData.rowSpace === rowSpace &&
        state.preData.moduleSpace === moduleSpace) ||
      (state.preData.rowSpace === 0 && state.preData.moduleSpace === 0)
    ) {
      // 已经调整过了
      ElMessage.success("已恢复至初始排版");
      return;
    }
    // 刷新本地的数据
    store.commit("updateFormatInfo", {
      rowSpace: state.preData.rowSpace,
      moduleSpace: state.preData.moduleSpace,
    });
    // 更新网络数据
    await updateFormatInfo();
    ElMessage.success("已恢复至初始排版");
  }
};

const onDataChange = () => {
  updateFormatInfo();
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
.operate-style {
  width: $operate-width;
}

.style-typography {
  display: flex;
  justify-content: center;
  div {
    width: 127px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
    color: #606266;
    cursor: pointer;
    transition: all 250ms;
    &:nth-child(2) {
      margin-left: 10px;
    }
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }
  div.select {
    background: $primary-color;
    border: 1px solid transparent;
    color: #ffffff;
    &:hover {
      background-color: rgba($color: $primary-color, $alpha: 0.8);
    }
  }
}

.style-title {
  font-size: 14px;
  line-height: 20px;
  font-family: PingFang SC;
  color: rgba(0, 0, 0, 0.7);
}

.style-theme-color {
  display: flex;
  align-items: flex-end;
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 25px;
  & > img {
    width: 20px;
    height: 10px;
    margin-bottom: 10px;
  }
  & > div {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    div {
      width: 25px;
      height: 25px;
      margin-left: 8px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;

      .static-color {
        width: 25px;
        height: 25px;
        position: absolute;
        left: -9px;
        top: 0px;
        transition: all 150ms;

        &:active {
          transform: scale(0.8, 0.8);
        }
      }
      span {
        font-size: 10px;
        line-height: 14px;
        color: #606266;
      }
      img {
        position: absolute;
        top: 6px;
        left: 6px;
      }
    }
  }
}

.style-font-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 25px;
  .el-input__inner {
    opacity: 0.2;
  }
}

.style-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 25px;

  .el-slider {
    width: 193px;
  }
}

.title-date-style {
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  justify-content: space-between;
  margin-top: 25px;

  div {
    width: 92px;
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    border: 0.5px solid #dcdfe6;
    border-radius: 4px;
    cursor: pointer;
    color: #606266;
    transition: all 250ms;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
    &.select {
      background: #909399;
      color: white;
      &:hover {
        background-color: rgba($color: #909399, $alpha: 0.8);
      }
    }
  }
}
</style>