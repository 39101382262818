<template>
  <div class="content">
    <p>
      以下证照解释权均为南京子丑寅卯网络科技有限公司，所有证照仅为官网公示使用，复制或打印无效。如有任何问题可联系service@stronghire.cn
    </p>
    <p>营业执照</p>
    <img src="@/assets/img/icon_license_center1.png" alt="营业执照" />
    <p>增值电信业务经营许可证</p>
    <img
      src="@/assets/img/icon_license_center2.png"
      alt="增值电信业务经营许可证"
    />
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
@import "src/style/agreement.scss";
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-top: 30px;
    &:nth-child(1) {
      font-weight: 600;
      color: #f56c6c;
      margin-top: 0px;
    }
  }

  img {
    &:nth-child(3) {
      margin-top: 20px;
      width: 696px;
      height: 486px;
    }
    &:nth-child(5) {
      width: 500px;
      height: 708px;
    }
  }
}
</style>