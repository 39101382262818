<template>
  <div id="side-bar">
    <div class="search-bar">
      <div class="seach-bar-input">
        <img src="@/assets/img/icon_search.svg" alt="" />
        <input
          v-model="searchKey"
          placeholder="搜索简历模板"
          @keyup.enter="onSearchClick"
          ref="searchRef"
        />
      </div>
      <el-button type="danger" @click="onSearchClick" v-if="false"
        >搜索</el-button
      >
    </div>
    <div class="tag-container">
      <div
        v-for="(itemData, index) in state.sideBarData"
        :key="index"
        class="tag-item"
      >
        <span>{{ itemData.tag }}</span>
        <div class="content-item">
          <a
            v-for="(contentItem, contentIndex) in itemData.content"
            :key="contentIndex"
            class="side-bar-tag-item"
            @click="onItemClick(contentItem)"
            :href="`/search/${contentItem.pinyin}`"
          >
            {{ contentItem.hotword }}
          </a>
        </div>
        <!-- 添加悬浮展示控件 -->
        <div class="sub-content">
          <div>
            <a
              v-for="(contentItem, contentIndex) in itemData.subContent"
              :key="contentIndex"
              class="side-bar-tag-item"
              @click="onItemClick(contentItem)"
              :href="`/search/${contentItem.pinyin}`"
            >
              {{ contentItem.hotword }}
            </a>
          </div>
        </div>
        <img src="@/assets/img/icon_arrow.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, toRefs } from "@vue/reactivity";
import { useRouter } from "vue-router";
import editApi from "@/apis/edit_api";
import { ElMessage } from "element-plus";
import { onMounted } from "@vue/runtime-core";
import analyze from "@/plugin/analyze.js";

const router = useRouter();
const state = reactive({
  sideBarData: [
    // {
    //   tag: "热门",
    //   content: [{}],
    //   subContent: [{}],
    // },
  ],
});

const onItemClick = (value) => {
  analyze.sendClickEvent("recommend_key_word_click", {
    value: value.hotword,
  });
};

const onSearchClick = () => {
  if (searchKey.value) {
    router.push({ path: "/search", query: { key: searchKey.value } });
    analyze.sendClickEvent("custom_key_word", {
      value: searchKey.value,
    });
  }
};

const requestData = async () => {
  try {
    const result = await editApi.getRecommendKeywords();
    if (result.code === 0) {
      // 成功获取到数据
      refreshView(result.data);
    } else {
      console.log(result.msg);
    }
  } catch ({ msg: msg }) {
    console.log(msg);
  }
};

const refreshView = (data) => {
  // 清楚原来的数据
  state.sideBarData = [];
  // 添加新的数据
  const hotData = data["热门"];
  const companyData = data["公司"];
  const jobData = data["岗位"];
  const schoolData = data["高校"];
  addData(hotData, "热门");
  addData(companyData, "公司");
  addData(jobData, "岗位");
  addData(schoolData, "高校");
  console.log("sideBarData", state.sideBarData);
};

const addData = (data, tag) => {
  if (data) {
    const result = {
      tag: tag,
      content: [],
      subContent: [],
    };
    // 根据文字长度进行分类
    var totalText = 0;
    data.forEach((item) => {
      totalText += item.hotword;
      let index = data.indexOf(item);
      if (getTextWidth(totalText) > 160 || index >= 4) {
        result.subContent.push(item);
      } else {
        result.content.push(item);
      }
    });
    state.sideBarData.push(result);
  }
};

const getTextWidth = (text) => {
  // 测量文字的宽度
  var span = document.createElement("span");
  span.style.fontSize = "12px";
  span.style.display = "inline-block";
  span.style.width = "auto";
  span.style.whiteSpace = "nowrap";
  span.innerText = text;
  document.body.appendChild(span);
  var width = span.offsetWidth;
  document.body.removeChild(span);
  return width;
};

onMounted(() => {
  requestData();
});

const searchKey = ref("");
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

#side-bar {
  display: flex;
  flex-direction: column;
  padding-top: 18px;
}

.search-bar {
  margin: auto;
  width: 294px;
  height: 36px;
  min-height: 36px;
  background: #f9f9f9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .seach-bar-icon {
    width: 13px;
    height: 14px;
  }

  .seach-bar-input {
    flex: 1;
    height: 36px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding-right: 18px;
    img {
      width: 13px;
      height: 14px;
      margin-left: 11px;
    }
    input {
      color: rgba(0, 0, 0, 0.7);
      text-decoration: none;
      height: 32px;
      width: 100%;
      margin-left: 7px;
      border: 0;
      background-color: transparent;
      outline: none;
      font-size: 14px;
    }
    input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgba(0, 0, 0, 0.2);
      font-size: 14px;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 ~ 18 */
      color: rgba(0, 0, 0, 0.2);
      opacity: 1;
      font-size: 14px;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(0, 0, 0, 0.2);
      opacity: 1;
      font-size: 14px;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10 ~ 11 */
      color: rgba(0, 0, 0, 0.2);
      font-size: 14px;
    }
    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(0, 0, 0, 0.2);
      font-size: 14px;
    }

    input::placeholder {
      /* 大部分现代浏览器 */
      color: rgba(0, 0, 0, 0.2);
      font-size: 14px;
    }
  }
}

.tag-container {
  flex: 1;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .tag-item {
    display: flex;
    padding-left: 18px;
    padding-right: 18px;
    align-items: center;
    height: 32px;
    transition: all 500ms;

    &:hover {
      background-color: white;
      // box-shadow: 0px 0px 15px rgba($color: #000000, $alpha: 0.15);
    }
    &:hover .sub-content {
      visibility: visible;
      z-index: 10;
      opacity: 1;
    }
    &:hover .sub-content div {
      transform: translateX(0px);
    }
    & > span {
      font-size: 16px;
      color: rgba($color: #000000, $alpha: 0.7);
      font-weight: bold;
    }
    .content-item {
      flex: 1;
      display: flex;
      flex-direction: row;
      height: 32px;
      align-items: center;
      a {
        font-size: 14px;
        line-height: 20px;
        color: rgba($color: #000000, $alpha: 0.5);
        margin-left: 10px;
        padding-bottom: 2px;
        transition: all 300ms;
        cursor: pointer;
        &:nth-child(1) {
          margin-left: 20px;
        }
      }
      a:hover {
        color: $primary-color;
        font-weight: 700;
      }
    }
    img {
      width: 6px;
      height: 11px;
    }

    .sub-content {
      max-width: 442px;
      background-color: white;
      box-shadow: 10px 0px 15px rgba($color: #000000, $alpha: 0.2);
      border-radius: 5px;
      position: absolute;
      visibility: hidden;
      z-index: 0;
      padding: 18px;
      padding-left: 8px;
      padding-bottom: 11px;
      padding-top: 11px;
      opacity: 0;
      margin-left: 313px;
      transition: all 400ms;

      div {
        transform: translateX(-12px);
        transition: all 300ms;
        transition-delay: 40ms;
        display: flex;
        flex-wrap: wrap;
      }

      a {
        font-size: 14px;
        color: rgba($color: #000000, $alpha: 0.5);
        margin-left: 10px;
        line-height: 35px;
        transition: all 150ms;
        cursor: pointer;
      }
      a:hover {
        color: $primary-color;
        font-weight: 700;
      }
    }
  }
}
</style>