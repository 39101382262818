<template>
  <div id="swiper">
    <el-carousel
      height="223px"
      :interval="5000"
      trigger="click"
      arrow="never"
      indicator-position="none"
    >
      <el-carousel-item v-for="(item, index) in state.swiperData" :key="index">
        <a @click="onBannerClick">
          <img src="@/assets/img/icon_banner.png" alt="" />
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script setup>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import analyze from "@/plugin/analyze.js";

const store = useStore();
const state = reactive({
  swiperData: [
    {
      img: "@/assets/img/icon_banner.png",
    },
  ],
});

const onBannerClick = () => {
  store.commit("updateAddWechatDialog", {
    show: true,
    type: 0,
  });
  analyze.sendClickEvent("banner_click", {
    value: "1",
  });
};
</script>

<style lang="scss" scoped>
#swiper {
  margin-left: 20px;
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  a {
  }
}
</style>