<template>
  <div class="job_strategy_container">
    <BlogSideBar />
    <BlogList />
    <BlogResumeTemplate />
  </div>
</template>

<script setup>
import blogApi from "@/apis/blog_api";
import { onMounted } from "vue-demi";
import BlogSideBar from "@/components/blog/BlogSideBar.vue";
import BlogList from "@/components/blog/BlogList.vue";
import BlogResumeTemplate from "@/components/blog/BlogResumeTemplate.vue";

onMounted(() => {
  document.title = "求职攻略指南笔经面经简历面试技巧 - StrongHire简历";
  document
    .querySelector("meta[name='Keywords']")
    .setAttribute(
      "content",
      "求职攻略,简历模板,简历攻略,简历怎么写,笔经,面经,求职技巧,求职指南,面试技巧,校招求职"
    );
  document
    .querySelector("meta[name='Description']")
    .setAttribute(
      "content",
      "海量求职攻略文章，应届生校招社招笔试面试技巧分享，介绍职场大环境及求职技能提升"
    );
});

</script>

<style scoped lang="scss">
@import "src/style/base.scss";

.job_strategy_container {
  width: $content-width;
  display: flex;
  justify-content: space-between;
  margin: auto;
}
</style>