<template>
  <div class="chart">
    <Charts :options="options" />
  </div>
</template>

<script setup>
import Charts from "@/components/Charts.vue";
import { onMounted, ref, defineProps, watch, toRefs, computed } from "vue";

const props = defineProps({
  data: {
    type: Object,
    default: function () {
      return {
        education: 0,
        honor: 0,
        language: 0,
        leadership: 0,
        socialexp: 0,
        workexp: 0,
      };
    }, //dark为深色模式
    required: false,
  },
});

const { data } = toRefs(props);

const options = computed(() => {
  return {
    color: ["#F56C6C"],
    radar: {
      shape: "circle",
      indicator: [
        { name: "语言能力", max: 10 },
        { name: "社会能力", max: 10 },
        { name: "领导力", max: 10 },
        { name: "工作能力", max: 10 },
        { name: "教育背景", max: 10 },
        { name: "所获荣誉", max: 10 },
      ],
      axisName: {
        color: "rgba(0, 0, 0, 0.7)",
      },
      axisLine: {
        lineStyle: {
          color: "#E3E7ED",
        },
      },
    },
    series: [
      {
        type: "radar",
        lineStyle: {
          width: 1,
        },
        symbol: "none",
        data: [
          {
            value: [
              props.data.language,
              props.data.socialexp,
              props.data.leadership,
              props.data.workexp,
              props.data.education,
              props.data.honor,
            ],
            areaStyle: {
              color: "rgba(245, 108, 108, 0.1)",
            },
          },
        ],
      },
    ],
  };
});

onMounted(() => {
  console.log("data", data.value);
});
</script>

<style lang="scss" scoped>
.chart {
  width: 420px;
  height: 220px;
}
</style>