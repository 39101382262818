import jwt from 'jsonwebtoken'

export default {
    setCookie: function (name, value) {
        var days = 30;
        var exp = new Date();
        exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
    },
    getCookie: function (name) {
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(name + "=")
            if (c_start != -1) {
                c_start = c_start + name.length + 1
                var c_end = document.cookie.indexOf(";", c_start)
                if (c_end == -1) c_end = document.cookie.length
                return unescape(document.cookie.substring(c_start, c_end))
            }
        }
        return ""
    },
    delCookie: function (name) {
        var vm = this
        var cval = vm.getCookie(name);
        if (cval != null) {
            var exp = new Date();
            exp.setTime(exp.getTime() - 1);
            document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
        }
    },
    
    setStaffUserInfo: function (staffUserInfo) {
        this.setCookie('staffUserInfo', JSON.stringify(staffUserInfo))
    },
    delStaffUserInfo: function () {
        this.delCookie("staffUserInfo")
    },
    getStaffUserInfo: function () {
        let strUserInfo = localStorage.getItem('user_data');
        if (strUserInfo) {
            return JSON.parse(strUserInfo)
        }
        return null
    },
    getStaffUserToken: function () {
        var staffUserInfo = this.getStaffUserInfo();
        if (staffUserInfo) {
            return staffUserInfo.token;
        }
        return null
    }
}