<template>
  <div class="content">
    <div class="title">
      <h1>StrongHire隐私政策</h1>
      <p
        class="download"
        @click="onDownLoadClick"
        :style="{ opacity: downloadVisible }"
      >
        下载
      </p>
    </div>
    <p>
      您的信任对我们非常重要，我们深知个人信息对您的重要性，我们根据《中华人民共和国网络安全法》、《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《常见类型移动互联网应用程序必要个人信息范围规定》等法律法规，并参考《信息安全技术个人信息安全规范》（GB/T
      35273-2020）等国家标准，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴于此，南京子丑寅卯网络科技有限公司（以下简称“我们”或者“子丑寅卯网络科技”）制定本《用户隐私条款》（下称“本协议”或“本政策”）并提醒您：
    </p>
    <p>
      本协议适用于StrongHire提供的所有服务。我们就其向您提供的服务单独设立有隐私权政策的，则相应服务适用相应隐私权政策。
    </p>
    <p>
      需要特别说明的是，本协议不适用于其他第三方向您提供的服务，也不适用于StrongHire中已另行独立设置隐私权政策的服务。
    </p>
    <p>
      在使用StrongHire各项服务前，请您务必仔细阅读并透彻理解本协议，在确认充分理解并同意后再开始使用。
    </p>
    <p>
      我们不会收集与提供服务无关的个人信息，若您不同意提供基本功能以外服务所需的个人信息被收集使用的，请您不要开启StrongHire应用的各项扩展业务功能。
    </p>
    <p>
      此外，如您不希望我们收集您的任何个人信息，我们将为您提供「仅浏览」模式。该模式下，您仅能浏览StrongHire产品与/或服务的部分内容，但无法使用其他任何功能，且我们不会收集您的任何个人信息。
    </p>
    <p>
      我们的个人信息权利实现方式包括但不限于访问或更正、删除、注销、拒绝个性化展示、及时得到响应等，以确保您实现对您个人信息的控制权。
    </p>
    <p>
      我们会严格依据本政策以及您的同意处理您的个人信息。如您不同意本政策，您应当停止使用StrongHire产品与/或服务。如对本协议内容有任何疑问、意见或建议，您可通过StrongHire提供的service@stronghire.cn与我们联系。
    </p>
    <p>
      除另有约定外，本协议所用定义与《StrongHire服务协议》中的定义具有相同的涵义。
    </p>
    <p>本协议部分将帮助您了解以下内容：</p>
    <p>一、我们如何收集和使用您的信息</p>
    <p>二、我们如何共享、转让、公开披露您的信息</p>
    <p>三、我们如何保护您的信息</p>
    <p>四、您如何管理您的信息</p>
    <p>五、如何保护您的个人信息</p>
    <p>六、如何存储您的个人信息</p>
    <p>七、本协议如何更新</p>
    <p>八、如何联系我们</p>
    <h2>一、 我们如何收集和使用您的信息</h2>
    <p>
      根据国家法律法规的规定，网络运营者为用户提供信息发布、即时通讯等服务，在与用户签订协议或者确认提供服务时，应当要求用户提供真实身份信息。用户不提供真实身份信息的，网络运营者不得为其提供相关服务。为了符合法律法规要求并更好地为您提供服务，我们将收集并使用您的个人信息。我们承诺将坚持“最小必要化”原则收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。
    </p>
    <p>我们需要/可能需要收集和使用的您的个人信息包括如下两种：</p>
    <p>
      1、为实现向您提供我们的服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的服务；
    </p>
    <p>
      2、为实现向您提供我们服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们的服务的基本功能。
    </p>
    <p>
      “个人信息”，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，在本隐私政策中涉及的“个人信息”包括但不限于：
    </p>
    <p>
      第一部分：简历信息。简历信息是您在编辑简历时主动填写的信息，所有信息将用于生成您的简历或提供与简历相关的其他服务，包括：
    </p>
    <p>
      （1）基本信息：包括姓名、电话、邮箱、现居地、出生日期、民族、照片、性别、籍贯、政治面貌、婚姻状况、身高、体重、当前状态、目标岗位、目标城市、期望薪资等；
    </p>
    <p>（2）教育经历：学校、专业、学历、所在城市、学院、起止时间等；</p>
    <p>（3）工作经历：公司名称、职位名称、所在部门、所在城市、起止时间等；</p>
    <p>（4）项目经历：项目名称、角色、所在部门、所在城市、起止时间等；</p>
    <p>（5）校园经历：组织/活动名称、角色、所在部门、所在城市、起止时间等；</p>
    <p>（6）自定义经历：经历名称、角色、所在部门、所在城市、起止时间等。</p>
    <p>第二部分：其他信息</p>
    <p>（1）账号信息：包括系统账号、IP地址、邮箱地址及与前述有关的密码等；</p>
    <p>
      （2）个人上网记录：包括网站及APP登录记录、浏览记录、使用记录、点击记录、操作记录等；
    </p>
    <p>
      （3）个人常用设备信息：包括硬件型号、设备 MAC
      地址、操作系统类型、设备识别码等；
    </p>
    <p>（4）个人位置信息：包括定位信息等；</p>
    <p>（5）个人财产信息：包括交易和支付记录等；</p>
    <p>
      （6）沟通记录：包括您与求职顾问、辅导老师等StrongHire工作人员的沟通及辅导记录。
    </p>
    <p>您理解并同意：</p>
    <p>
      1、我们致力于打造多样的服务以满足您的需求。因我们向您提供的服务种类众多，且不同用户选择使用的具体服务范围存在差异，相应的，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的服务功能为准；
    </p>
    <p>
      2、为给您带来更好的服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本协议、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过StrongHire提供的各种联系方式与我们联系，我们会尽快为您作出解答。
    </p>
    <p>我们会为实现本协议下述的各项功能，收集和使用您的个人信息：</p>
    <p>（一） 帮助您成为我们的会员</p>
    <p>1、基础会员服务</p>
    <p>
      我们通过StrongHire为您提供基础会员服务。为注册成为会员并使用我们的会员服务，您需要提供手机号码、拟使用的会员名和密码用于创建StrongHire。如果您仅需使用浏览、搜索服务，您不需要注册成为我们的会员及提供上述信息。
    </p>
    <p>
      对于需要通过StrongHire才能使用的服务，我们可能会根据您提供的上述信息校验您的会员身份，确保我们是在为您本人提供服务。
    </p>
    <p>
      我们会根据您的会员账户使用情况及平台设置的会员等级计算规则确定您当前的会员等级，并为您提供相应会员等级所对应的基本权益。
    </p>
    <p>2、附加会员服务</p>
    <p>
      特别会员权益：如果您选择提供真实姓名、性别、出生年月日、居住地、昵称、头像等非注册
      必须的个人资料，我们可以为您提供更加个性化的会员服务。其中，为保证辨识度，您的昵称、头像将公开显示。您补充的账户信息将有助于我们为您提供个性化求职或招聘体验等。
    </p>
    <p>
      授权登录：我们可能经您同意后向第三方共享您的账户信息（头像、昵称及其他页面提示的信息），使您可以便捷地实现第三方账户的注册或登录。此外，我们可能会根据您的授权从第三方处获取您的第三方账户信息，并与您的StrongHire账号进行绑定，使您可通过第三方账户直接登录、使用我们的服务。我们将在您授权同意的范围内使用您的相关信息。
    </p>
    <p>（二） 信息展示</p>
    <p>
      在您使用我们服务过程中，为识别账号异常状态、了解服务的适配性、向您提供更契合您需求的页面展示和搜索结果，我们可能会自动收集您的使用情况并存储为网络日志信息，包括：
    </p>
    <p>
      设备信息：我们会根据您在软件安装及/或使用中的具体操作，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、唯一设备标识符、设备环境等软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站等传感器信息）。
    </p>
    <p>
      服务日志信息：当您使用我们的网站或客户端提供的服务时，我们会自动收集您对我们服务的详细使用情况，作为服务日志保存。
    </p>
    <p>
      请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
    </p>
    <p>
      为向您提供更便捷、更符合您个性化需求的信息展示、搜索及推送服务，我们会根据您的设备信息和服务日志信息，提取您的偏好特征，并基于特征标签产出间接人群画像，用于展示、推送信息。
    </p>
    <p>此外，我们也会为了不断改进和优化上述的功能来使用您的上述信息。</p>
    <p>（三） 为您提供安全保障</p>
    <p>
      为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或StrongHire相关协议规则的情况，我们可能使用或整合您的会员信息、交易信息、设备信息、服务日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
    </p>
    <p>（四）其他</p>
    <p>
      1、若您提供的信息中含有其他用户的个人信息，在向StrongHire提供这些个人信息之前，您需确保您已经取得合法的授权。否则根据权利人的要求，我们将更正或删除相关个人信息的内容。
    </p>
    <p>
      2、若我们将信息用于本协议未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。
    </p>
    <p>
      若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们的专业安全团队对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。
    </p>
    <p>3、征得授权同意的例外</p>
    <p>
      您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：
    </p>
    <p>（1）与国家安全、国防安全有关的；</p>
    <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>（3）与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
    <p>
      （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p>（5）您自行向社会公众公开的个人信息；</p>
    <p>
      （6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
    </p>
    <p>（7）根据与您签订和履行相关协议或其他书面文件所必需的；</p>
    <p>
      （8）用于维护所提供的服务的安全稳定运行所必需的，例如发现、处置服务的故障；
    </p>
    <p>（9）为合法的新闻报道所必需的；</p>
    <p>
      （10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
    </p>
    <p>（11）法律法规规定的其他情形。</p>
    <p>
      请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善StrongHire的内容和布局，为商业决策提供服务支撑，以及改进我们的服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。
    </p>
    <p>
      4、如我们停止运营StrongHire的服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。
    </p>
    <h2>二、我们如何共享、转让、公开披露您的信息</h2>
    <p>（一）共享</p>
    <p>
      我们不会与子丑寅卯网络科技以及关联公司以外的公司、组织和个人共享您的个人信息，但以下情况除外：
    </p>
    <p>
      1、在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
    </p>
    <p>
      2、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
    </p>
    <p>
      3、与关联公司间共享：为便于我们基于StrongHire向您提供服务，推荐您可能感兴趣的信息，识别会员账号异常，保护StrongHire关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
    </p>
    <p>
      4、与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本协议声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。
    </p>
    <p>目前，我们的授权合作伙伴包括以下类型：</p>
    <p>
      （1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享。我们会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息，但不会提供您的个人身份信息，或者我们将这些信息进行去标识化处理，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。
    </p>
    <p>
      （2）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。
    </p>
    <p>
      我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本协议以及其他任何相关的保密和安全措施来处理个人信息。
    </p>
    <p>（二）转让</p>
    <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <p>
      1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
    </p>
    <p>
      2、在子丑寅卯网络科技发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本协议的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
    </p>
    <p>（三）公开披露</p>
    <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
    <p>1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</p>
    <p>
      2、如果我们确定您出现违反法律法规或严重违反StrongHire平台相关协议及规则的情况，或为保护StrongHire平台用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及StrongHire平台已对您采取的措施。
    </p>
    <p>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
    <p>
      以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
    </p>
    <p>1、与国家安全、国防安全有关的；</p>
    <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
    <p>
      4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p>5、您自行向社会公众公开的个人信息；</p>
    <p>
      6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
    </p>
    <p>
      请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。
    </p>
    <p>（五）通过第三方SDK收集信息的情形</p>
    <p>
      1.为保障我们服务的稳定运行、功能实现，使您能够使用和体验更丰富的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序，具体SDK使用情况如下：
    </p>
    <p>（1）登录类服务</p>
    <p>SDK名称：微信登录</p>
    <p>使用目的：帮助用户在应用内使用微信授权登录、微信分享</p>
    <p>（2）支付类服务</p>
    <p>SDK名称：微信支付</p>
    <p>使用目的：帮助用户在应用内使用微信支付</p>
    <p>SDK名称：Alipay支付宝</p>
    <p>使用目的：帮助用户在应用内使用支付宝支付</p>
    <p>（3）简历解析服务</p>
    <p>SDK名称：小析解析</p>
    <p>使用目的：为用户提供简历解析、人岗匹配等服务</p>
    <p>（4）埋点服务</p>
    <p>SKD名称：友盟统计</p>
    <p>使用目的：统计数据，优化产品体验</p>
    <p>SKD名称：百度统计</p>
    <p>使用目的：统计数据，优化产品体验</p>
    <p>
      2.我们会对前述应用程序接口（API）、软件工具开发包（SDK）及授权合作伙伴进行严格的安全检测，并约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。
    </p>
    <p>
      3.此外，当您通过本平台接入的第三方服务时，您的信息将适用该第三方的隐私政策，建议您在接受相关服务前阅读并确认理解相关协议。
    </p>
    <p>
      4.对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议以及信息保护约定，
      要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。
    </p>
    <h2>三、我们如何保护您的信息</h2>
    <p>
      （一）我们采取符合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
    </p>
    <p>
      （二）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本协议所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。
    </p>
    <p>
      （三）互联网并非绝对安全的环境，在使用StrongHire服务时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络StrongHire客服，以便我们根据您的申请采取相应措施。
    </p>
    <p>
      请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
    </p>
    <p>
      请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
    </p>
    <p>
      （四）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
    </p>
    <p>同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
    <h2>四、您如何管理您的信息</h2>
    <p>您可以通过以下方式访问及管理您的信息：</p>
    <p>（一）删除您的信息</p>
    <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
    <p>1、如果我们处理个人信息的行为违反法律法规；</p>
    <p>2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
    <p>3、如果我们处理个人信息的行为严重违反了与您的约定；</p>
    <p>4、如果您不再使用我们服务，或您主动注销了账号；</p>
    <p>5、如果我们永久不再为您提供服务。</p>
    <p>
      若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
    </p>
    <p>
      当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。您作为求职者用户直接向招聘者企业投递的简历或被企业搜索已下载过的简历，以及您与他人在本平台上（如通过动态消息或群组）所分享的信息，由于我们无法从第三方服务器删除其从本平台复制的信息，请您直接联系该第三方删除信息。
    </p>
    <p>（二）约束信息系统自动决策</p>
    <p>
      在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害StrongHire商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
    </p>
    <p>（三）响应您的上述请求</p>
    <p>
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
    </p>
    <p>我们将尽快做出答复。如您不满意，还可以通过StrongHire客服发起投诉。</p>
    <p>
      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
    </p>
    <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
    <p>1、与国家安全、国防安全有关的；</p>
    <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>3、与犯罪侦查、起诉、审判和执行判决等有关的；</p>
    <p>4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
    <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
    <p>6、涉及商业秘密的。</p>
    <h2>五、如何保护您的个人信息</h2>
    <p>
      我们十分重视您的个人信息安全，为此我们采用了符合行业标准的安全技术措施及配套的组织架构和管理体系等多层面保护措施，以最大程度防止您的个人信息被泄露、毁损、误用、未授权访问、未授权披露或更改，具体包括：
    </p>
    <p>1.数据安全技术措施</p>
    <p>
      （1）在数据安全传输方面，我们采用了SSL加密传输技术，保护数据在其传输过程中的安全，并使用妥善的保护机制以防止数据遭到恶意攻击；此外，我们还将对敏感信息采取AES加密算法或SHA256哈希算法等安全保护措施。
    </p>
    <p>
      （2）在数据访问和使用的安全控制方面，我们实施了严格的数据权限控制机制，采取多重身份认证技术，并对能够处理您的个人信息的行为进行监控，避免数据被违规访问和未授权使用。我们还对数据生命周期的全流程进行监控，防止您的个人信息遭遇未授权的访问、公开披露、使用、修改、人为或意外的损坏或丢失等。
    </p>
    <p>2.数据安全组织和管理措施</p>
    <p>
      （1）根据有关法规要求，我们任命了个人信息保护负责人和个人信息保护工作机构。我们还建立了相关的内控管理流程，以最小授权为原则，对可能接触到您个人信息的工作人员，严格设定信息访问权限，控制个人信息的知悉范围。
    </p>
    <p>
      （2）我们建立了数据安全使用的内部规范制度，保障对您个人信息的处理，在收集、传输、使用、存储、转移、销毁等环节均满足法律法规的安全要求。根据有关法规要求，对需要访问个人信息的工作人员，我们会要求其经过个人信息保护负责人或者其授权的管理人员审批，记录访问情况，并采取技术措施，避免违法访问、修改、复制、下载个人信息。
    </p>
    <p>
      （3）我们组织员工参加安全与隐私保护相关培训并要求其完成规定的考核，加强员工对于保护个人信息重要性的认知。
    </p>
    <p>（4）我们还采取了其他可行的安全组织和管理措施。</p>
    <p>3.合作协议条款保证</p>
    <p>
      在我们从第三方间接收集您的个人信息前，我们会明确以书面形式（如合作协议、承诺书）要求该第三方在已经取得您明示同意后收集以及处理您的个人信息，并要求第三方对个人信息来源的合法性和合规性以书面协议的形式作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应的法律责任。
    </p>
    <p>4.安全事件的处理</p>
    <p>
      (1)如不幸发生了个人信息安全事件，我们将立即启动应急预案，采取补救措施，记录事件内容，按照《国家网络安全事件应急预案》等有关规定及时上报。若可能会给您的合法权益造成严重损害的，如造成敏感个人信息的泄露，我们将向您告知该安全事件的基本情况和其可能的影响、我们已采取或将要采取的处置措施、您可选择的防范和降低风险的建议、我们针对您提供的补救措施以及个人信息保护负责人和个人信息保护工作机构的联系方式等。我们将及时将以上事件相关情况以邮件、信函、电话或推送通知等方式告知您，难以逐一告知时，我们会采取合理、有效的方式发布警示公告。
    </p>
    <p>
      (2)请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账号或密码发生泄露，请您立即根据本政策提供的联系方式联系我们，以便我们采取相应措施来保护您的信息安全。
    </p>
    <h2>六、如何存储您的个人信息</h2>
    <p>
      1.我们会将在中国境内运营所收集和产生的个人信息存储在中国境内，不会进行跨境传输。
    </p>
    <p>
      2.我们承诺，除非法律法规另有要求，我们对您的个人信息的存储时间将限于实现您授权使用的目的所必需的最短时间，但国家法律法规、规章、规范性文件或政府的政策、命令等另有要求或为履行我们的合规义务而保留您的个人信息的除外。例如：《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年；《中华人民共和国网络安全法》要求采取监测、记录网络运行状态、网络安全事件的技术措施，并按照规定留存相关的网络日志不少于六个月。
    </p>
    <p>3.超出上述存储期限后，我们将对您的个人信息进行删除或匿名化处理。</p>
    <p>
      4.如我们因经营不善或其他原因出现停止运营的情况，除非法律法规或规范性文件另有规定，我们将会向您告知此类情况，并及时停止对您个人信息进行收集，同时删除已收集到的个人信息或对其做匿名化处理。
    </p>
    <p>
      5.本平台仅面向十八周岁（含）以上用户 。我们不会在明知的情况下收集十八周岁以下的未成年人个人信息。如果您发现我们无意收集了未成年人的个人信息，请您立即通知我们，我们会尽快设法删除相关数据。
    </p>
    <h2>七、本协议如何更新</h2>
    <p>我们的隐私权政策可能变更。</p>
    <p>
      未经您明确同意，我们不会限制您按照本协议所应享有的权利。我们会通过我们会通过
      StrongHire网站、微信公众号等渠道公示的方式进行通知甚至向您提供弹窗提示发布对隐私权政策所做的任何变更。
    </p>
    <h2>八、如何联系我们</h2>
    <p>
      如对本协议内容有任何疑问、意见或建议，您可通过service@stronghire.cn与我们联系。
    </p>
    <p>
      如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向子丑寅卯网络科技住所地的法院提起诉讼来寻求解决方案。
    </p>
    <p>南京子丑寅卯网络科技有限公司</p>
    <p>江苏省南京市雨花台区西善桥街道善创文化园11层1200室</p>
  </div>
</template>

<script setup>
import htmlToPdf from "@/utils/htmlToPdf";
import { nextTick, ref } from "@vue/runtime-core";

const downloadVisible = ref(1);

const onDownLoadClick = () => {
  downloadVisible.value = 0;
  nextTick(async () => {
    htmlToPdf("content", "隐私政策");
    setTimeout(() => {
      downloadVisible.value = 1;
    }, 2);
  });
};
</script>

<style lang="scss" scoped>
@import "src/style/agreement.scss";
</style>