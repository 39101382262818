<template>
  <div class="buy_vip_container">
    <div></div>
    <img class="buy_vip_title" src="@/assets/img/icon_buy_vip_title.svg" />
    <VipCards></VipCards>
    <div></div>
  </div>
</template>

<script setup>
//引入vue方法
import { useStore } from "vuex";
import { reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import VipCards from "@/components/buy_vip/VipCards";
import Avatar from "@/components/common/Avatar";

const router = useRouter();
const route = useRoute();
const store = useStore();

const state = reactive({
  activeIndex: "1",
});

const onLogoClick = () => {
  router.push("/");
};
</script>

<style scope lang="scss">
@import "src/style/base.scss";

.buy_vip_container {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  .buy_vip_title {
    width: 305px;
    height: 88px;
  }
  & > div {
    width: 100%;
    &:nth-child(1) {
      flex: 10;
    }
    &:nth-child(4) {
      flex: 20;
    }
  }
}
</style>