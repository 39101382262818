<template>
  <div
    :style="{
      '--font-family': font_family,
    }"
  >
    <EditPreviw></EditPreviw>
  </div>
</template>

<script setup>
import EditPreviw from "../components/edit/EditPreview.vue";
import { computed, reactive, ref } from "@vue/reactivity";
import {
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  watch,
} from "@vue/runtime-core";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import useEventBus from "@/plugin/event";
import editApi from "@/apis/edit_api";
import { ElMessage, ElLoading } from "element-plus";
import editMix from "@/plugin/edit";

const router = useRouter();
const route = useRoute();
const store = useStore();

const font_family = computed(() => {
  const font = store.state.edit.formatInfo.font;
  switch (font) {
    case 2:
      return "SimHei, STHeiti"; // 黑体
    case 3:
      return `"Source Han Serif SC", SimSun, STSong`; // 宋体
    case 4:
      return "KaiTi, STKaiti, STKaitiSc"; // 楷体
    case 5:
      return "FangSong, STFangsong"; // 仿宋
    default:
      return `"PingFang SC", "Microsoft YaHei"`; // default-0  微软雅黑
  }
});

const width = ref(document.documentElement.clientWidth);
const height = ref(document.documentElement.clientHeight);

// 生命周期
onMounted(() => {
  requestData();
});

const requestData = async () => {
  try {
    var result = null;
    console.log("requestData", route.query.type);
    if (route.query.type !== "template_resume") {
      result = await editApi.getUserDetail(route.params.id);
    } else {
      result = await editApi.getDetail(route.params.id);
    }
    store.commit("setResumeId", route.params.id);
    console.log("get detail", result);
    store.commit("setResumeData", result.data);
    setTimeout(() => {
      window.status = "PDFComplete";
    }, 200);
  } catch ({ msg: msg }) {
    ElMessage.error("简历不存在");
    setTimeout(() => {
      onResumeNotFound();
    }, 500);
  }
};

const onResumeNotFound = () => {
  router.replace({ path: "/" });
};

onUnmounted(() => {});
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";
::-webkit-scrollbar {
  display: none;
}

.edit-preview {
  background-color: white;
  width: 100%;
  min-height: 100vh;
}
</style>