<template>
  <div
    class="edit-preview"
    :style="{
      '--page-margin': page_margin, // 页边距
      '--module-space': module_space, // 模块之间的边距
      '--theme-color': theme_color, // 主题颜色
      '--body-text-size': body_text_size, // 正文文字大小
      '--body-line-height': body_line_height, // 正文文字的行高
      '--transition': transition_config, // none
      '--main-module-item-hover-color': main_module_item_hover_color, // 模块 hover 颜色
      '--sub-module-item-hover-color': sub_module_item_hover_color, // 模块 hover 颜色
    }"
  >
    <TransitionGroup tag="ul" name="fade" class="container preview-container">
      <template v-for="item in modules" :key="item.id">
        <PreviewBasic v-if="item.module === 'basic_info'" />
        <PreviewEdu
          v-else-if="item.module === 'edu_experience'"
          :moduleId="item.id"
        />
        <PreviewJob
          v-else-if="item.module === 'work_experience'"
          :moduleId="item.id"
        />
        <PreviewProject
          v-else-if="item.module === 'project_experience'"
          :moduleId="item.id"
        />
        <PreviewSchool
          v-else-if="item.module === 'school_experience'"
          :moduleId="item.id"
        />
        <PreviewPersonSummary
          v-else-if="item.module === 'personal_summary'"
          :moduleId="item.id"
        />
        <PreviewEvaluation
          v-else-if="item.module === 'evaluation'"
          :moduleId="item.id"
        />
        <PreviewHonor
          v-else-if="item.module === 'awards'"
          :moduleId="item.id"
        />
        <PreviewCollection
          v-else-if="item.module === 'portfolios'"
          :moduleId="item.id"
        />
        <PreviewCustom
          v-else-if="item.module === 'custom_experience'"
          :moduleId="item.id"
        />
      </template>
      <div
        class="page-break"
        v-for="(breakItem, index) in breakState.data"
        :key="index"
        :style="{ top: breakItem.top }"
      >
        <span v-for="(item, index) in breakItem.desc" :key="index">{{
          item
        }}</span>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
import PreviewBasic from "@/components/preview/PreviewBasic.vue";
import PreviewEdu from "@/components/preview/PreviewEdu.vue";
import PreviewJob from "@/components/preview/PreviewJob.vue";
import PreviewProject from "@/components/preview/PreviewProject.vue";
import PreviewSchool from "@/components/preview/PreviewSchool.vue";
import PreviewPersonSummary from "@/components/preview/PreviewPersonSummary.vue";
import PreviewHonor from "@/components/preview/PreviewHonor.vue";
import PreviewCollection from "@/components/preview/PreviewCollection.vue";
import PreviewCustom from "@/components/preview/PreviewCustom.vue";
import PreviewEvaluation from "@/components/preview/PreviewEvaluation.vue";
import { computed, reactive } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import useEventBus from "@/plugin/event";
import { useRoute } from "vue-router";

const eventBus = useEventBus();
const store = useStore();
const route = useRoute();

const modules = computed(() => {
  return store.state.edit.modules;
});

const page_margin = computed(() => {
  if (route.name === "resumePrint") {
    return "0px";
  }
  return `${store.state.edit.formatInfo.pageMargin * 4 + 10}px`;
});

const module_space = computed(
  () => `${store.state.edit.formatInfo.moduleSpace / 2}px`
);
const theme_color = computed(() => store.state.edit.formatInfo.themeColor);
const body_text_size = computed(
  () => `${store.state.edit.formatInfo.textSize}px`
);
const body_line_height = computed(
  () => `${store.state.edit.formatInfo.rowSpace + 6}px`
);

const transition_config = computed(() => {
  const colorDuration = "300ms";
  const pageDuration = "350ms";
  const otherDuration = "150ms ease-out";
  // '--transition': 'margin-left 150ms, margin-right 150ms, height 150ms, line-height 150ms, font-size 150ms, padding-top 150ms, padding-bottom 150ms, color 300ms, background-color 300ms, background 300ms', // none
  return Array.of(
    `margin-left ${pageDuration} `,
    `margin-right ${pageDuration}`,
    `height ${otherDuration}`,
    `line-height ${otherDuration}`,
    `font-size ${otherDuration}`,
    `padding-top ${pageDuration}`,
    `padding-bottom ${pageDuration}`,
    `color ${colorDuration}`,
    `background-color ${colorDuration}`,
    `background ${colorDuration}`
  ).join(",");
});

const main_module_item_hover_color = computed(() => {
  if (route.name === "resumePrint" || route.query.edit === "false") {
    return "#00000000";
  } else {
    return "rgba(158, 158, 158, 0.2)";
  }
})

const sub_module_item_hover_color = computed(() => {
  if (route.name === "resumePrint" || route.query.edit === "false") {
    return "#00000000";
  } else {
    return "rgba(158, 158, 158, 0.4)";
  }
})

onMounted(() => {
  observerPreviewHeight();
});

const breakState = reactive({
  data: [],
});

const getPageDesc = (index) => {
  console.log("xxxx", index);
  // 获取中文汉字
  switch (index) {
    case 1:
      return "一页纸";
    case 2:
      return "二页纸";
    case 3:
      return "三页纸";
    case 4:
      return "四页纸";
    case 5:
      return "五页纸";
    case 6:
      return "六页纸";
    case 7:
      return "七页纸";
    case 8:
      return "八页纸";
    case 9:
      return "九页纸";
    case 10:
      return "十页纸";
  }
  return "";
};

const observerPreviewHeight = () => {
  if (route.name === "resumePrint") {
    // 如果是打印页面，则不设置分隔符
    return;
  }
  const previewEle = document.querySelector(".preview-container");
  const myObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const height = Math.floor(entry.contentRect.height);
      console.warn("previewHeight", height);
      store.commit("setPreviewContentHeight", height);
      if (height > 0) {
        let pageMargin = store.state.edit.formatInfo.pageMargin * 4 + 10;
        // 根据当前的高度，计算展示几个分页符
        let array = [];
        for (let i = 1; i <= 10; i++) {
          array.push(1122 * i - pageMargin * (2 * i - 1));
        }
        breakState.data = [];
        let index = array.findIndex((item) => item > height);
        for (let i = 0; i < index; i++) {
          breakState.data.push({
            desc: getPageDesc(i + 1),
            top: `${array[i] - 145}px`,
          });
        }
      }
    });
  });
  myObserver.observe(previewEle);
};
</script>

<style lang="scss" scoped>
@import "src/style/base.scss";

// 正文的文字样式
p,
span {
  font-size: var(--body-text-size);
  line-height: var(--body-line-height);
  font-weight: 200;
  font-family: var(--font-family);
  transition: var(--transition);
  user-select: none;
  // font-family: "STHeiti"; //STSong STKaiti STFangsong PingFang SC STHeiti
}
.edit-preview {
  padding-top: var(--page-margin);
  padding-bottom: var(--page-margin);
  transition: var(--transition);
  -webkit-transition: var(--transition);
  overflow: auto;
  visibility: var(--content-visible);
  box-sizing: border-box;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translate(0, 0px);
}

.fade-leave-active {
  position: absolute;
}

.container {
  position: relative;
  padding: 0;
  margin: 0;
}

.page-break {
  position: absolute;
  width: 100%;
  top: 40px;
  border-bottom: 1px dashed $primary-color;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 14px;
  padding-left: 5px;
  color: $primary-color;
  pointer-events: none;
  span {
    font-weight: 500;
  }
}
</style>